import moment from "moment";
import { TIME_FORMAT } from "../CommunicationUtils";

export const SEARCH_PARAM_KEY = "searchParamsCarte";
export const DELETE_CARTE_BY_ID = "carteDeleteById";
export const UPSERT_CARTE = "carteUpsert";
export const PUBLISH_CARTE = "cartePublish";
export const EXPIRE_CARTE = "carteExpire";
export const GET_CARTE_BY_ID = "carteGetById";
export const CARTE_LIST_GET = "carteListGet";

export const EMPTY_CARTE = {
    id: 0,
    status: 0,
    longTextFR: '',
    longTextEN: '',
    actionUrlFR: '',
    actionUrlEN: '',
    iconUrl: '',
    titleFR: '',
    titleEN: '',
    activationDate: moment().toDate(),
    activationTime: moment().add(5, "minute").format(TIME_FORMAT),
    expirationDate: moment().add(1, 'day').toDate(),
    expirationTime: moment().add(5, "minute").format(TIME_FORMAT),
    publicationDate: null,
    creationDate: moment().toDate(),
    modificationDate: null,
};
