/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { sendPostRequest, sendGetRequest } from "../../../auth/apiEffects";
import { Form} from "react-bootstrap";
import { showPopupConfirm } from "../../common/Popups";
import { LoadingIndicatorInApp } from "../../LoadingIndicator";
import Emitter from '../../common/helperEmitter';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const DeactivateGTFS = ({ onChangeActivation }) => {
    const [isGtfsActive, setIsGtfsActive] = useState(false);
    const [isLoadingConfig, setIsLoadingConfig] = useState(true);
    const [configGtfs, setConfigGtfs] = useState({});
    const [sendingData, setSendingData] = useState(false);


    useEffect(() => {
        setIsLoadingConfig(true);
        async function fetchData() {
            const results = await sendGetRequest(`/admin/gtfsconfigs`);
            var activatedConfigs = [];

            if (results)
                activatedConfigs = results.filter(config => {
                    return config.code === 'DEACTIVATED_IMPORT';
                });

            if (activatedConfigs && activatedConfigs.length > 0) {
                var active = activatedConfigs[0].value === 'false';
                setConfigGtfs(activatedConfigs[0]);
                setIsGtfsActive(active);
                onChangeActivation.onChangeActivation(active);
            }
            else {
                setConfigGtfs({
                    configId: 0,
                    createdBy: '',
                    updatedBy:''
                });
                setIsGtfsActive(true);
                onChangeActivation.onChangeActivation(true);
            }
            setIsLoadingConfig(false);
        }

        fetchData();
    }, []);

    const sendRequestUpdateConfig = async (isActive) => {
        if (!isActive)
            showPopupConfirm({
                message: 'Êtes-vous certain de vouloir désactiver la consommation automatique des GTFS ?',
                onClickYes: async () => {
                    setSendingData(true);
                    await sendRequest(true)
                    setIsGtfsActive(isActive);
                    setSendingData(false);
                    onChangeActivation.onChangeActivation(isActive);

                }
            });
        else {
            setSendingData(true);
            await sendRequest(false);
            setIsGtfsActive(isActive);
            setSendingData(false);
            onChangeActivation.onChangeActivation(isActive);
        }

    };


    const sendRequest = async (active) => {
        if (!configGtfs)
            return;

        await sendPostRequest('/admin/gtfsconfigs', {
            configId: configGtfs.configId,
            isGtfs: true,
            code: 'DEACTIVATED_IMPORT',
            value: active === true ? 'true' : 'false',
            createdBy: configGtfs.createdBy,
            updatedBy: configGtfs.updatedBy
        });

        Emitter.emit('ACTIVATION_GTFS_CHANGE', !active);
    }

    if (isLoadingConfig)
        return (<div style={{ height: '60px' }}>
            <LoadingIndicatorInApp />
            </div>);


    return (
        <div>
            {
                !sendingData &&
                <Form.Switch
                    id={'gtfsSwitch'}
                    label="Consommation automatique des GTFS"
                    checked={isGtfsActive}
                    onChange={() => sendRequestUpdateConfig(!isGtfsActive)} />

            }
            {
                sendingData &&
                <div>
                    <span style={{ paddingRight: '5px' }}><FontAwesomeIcon icon={faSpinner} spin={true} /></span>
                    Sauvegarde en cours
                </div>
            }

        </div>
    );
};

export default DeactivateGTFS;


