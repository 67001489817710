import React, { useReducer } from 'react';
import ChronoContext, { initialState } from './ChronoContext';
import {
    staticReducer,
    SET_ROLES,
    SET_STATUS,
    SET_USER_AGENCIES,
    SET_USER_PROFILE,
    SET_ALL_AGENCIES, ADD_NOTIFICATION, REMOVE_NOTIFICATION
} from './reducers';

const GlobalState = ({children}) => {
    const [staticData, dispatch] = useReducer(staticReducer, initialState);

    return (
        <ChronoContext.Provider
            value={{
                staticData,
                
                setRoles: (roles) => dispatch({ type: SET_ROLES, roles }),
                setStatus: (status) => dispatch({ type: SET_STATUS, status }),
                setAllAgencies: (allAgencies) => dispatch({ type: SET_ALL_AGENCIES, allAgencies }),
                
                setUserAgencies: (agencies) => dispatch({ type: SET_USER_AGENCIES, agencies }),
                setUserProfile: (profile) => dispatch({ type: SET_USER_PROFILE, profile }),

                addNotification: (notification) => dispatch({ type: ADD_NOTIFICATION, notification }),
                removeNotification: (message) => dispatch({ type: REMOVE_NOTIFICATION, message }),
            }}
        >
            {children}
        </ChronoContext.Provider>
    );
};

export default GlobalState;
