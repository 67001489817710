/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { PopupModal } from '../../../../lib/ui/index'
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'


const PopupDetails = ({ id, showModal, hideShowModal, title, text, btnText }) => {

    const [showCheck, setShowCheck] = useState(false);

    return (
        <PopupModal
            id={id}
            title={title}
            bodyStyle={{ minHeight: '80px' }}
            show={showModal}
            showFooter={false}
            cssClass={'js-popup-user'}
            headerStyle={{ width: '800px' }}

            onHide={() => {
                setShowCheck(false);
                hideShowModal();
            }
            }>
            <div>

                <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    {text}
                </div>
                <div style={{ paddingTop: '25px' }}>
                    <Button className='log-popover-button' onClick={() => {
                        setShowCheck(false)
                        navigator.clipboard.writeText(text)
                        setShowCheck(true)
                    }}>
                        {btnText}
                        {
                            showCheck == true &&
                            <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '7px' }} />
                        }
                    </Button>
                </div>
            </div>
        </PopupModal>
    )
};

export default PopupDetails
