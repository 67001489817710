// @ts-nocheck
import React, { useState, useEffect, FC } from "react";
import { useTable, usePagination, useSortBy, useRowSelect, TableOptions } from "react-table";
import { DropdownButton, Dropdown, Pagination, InputGroup, Button  } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAmountDown, faSortAmountUp, faSort, faFileCsv } from "@fortawesome/free-solid-svg-icons";

const generatePageNumbers = (currentPage, lastPageNumber) => {
    const pageNumbers: number[] = [];
    let isPreviousNumber = true;
    for (let i = 1; i <= lastPageNumber; i++) {
        if (i < 3 || i > lastPageNumber - 2 || (i > currentPage - 2 && i < currentPage + 2)) {
            isPreviousNumber = true;
            pageNumbers.push(i);
        } else if (isPreviousNumber) {
            isPreviousNumber = false;
            pageNumbers.push(0);
        }
    }

    return pageNumbers;
};

const CustomPagination = ({ totalPages, currentPage, setCurrentPage }) => {
    const generatedPageNumbers = generatePageNumbers(currentPage, totalPages);

    return (
        <Pagination variant="outline-secondary">
            <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1 || totalPages === 1} />
            {generatedPageNumbers.map((number, index) => {
                if (number > 0) {
                    return (
                        <Pagination.Item
                            key={index}
                            active={currentPage === number}
                            disabled={currentPage === number}
                            onClick={(x) => setCurrentPage(parseInt(x.target.text, 10))}
                        >
                            {number}
                        </Pagination.Item>
                    );
                } else if (number === 0) {
                    return <Pagination.Ellipsis disabled key={index} />;
                } else {
                    return null;
                }
            })}
            <Pagination.Next
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages || totalPages === 1}
            />
        </Pagination>
    );
};

const ChooserNumberPageDisplay = ({ itemPerPage, setItemPerPage, setCurrentPage }) => {
    return (
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>Afficher</InputGroup.Text>
            </InputGroup.Prepend>
            <DropdownButton
                id="chooserNumberPageDisplay"
                as={InputGroup.Append}
                title={itemPerPage}
                onSelect={(key, e) => {
                    setItemPerPage(e.target.text);
                    setCurrentPage(1);
                }}
                variant="outline-secondary"
            >
                <Dropdown.Item>5</Dropdown.Item>
                <Dropdown.Item>10</Dropdown.Item>
                <Dropdown.Item>15</Dropdown.Item>
                <Dropdown.Item>20</Dropdown.Item>
                <Dropdown.Item>25</Dropdown.Item>
            </DropdownButton>
        </InputGroup>
    );
};

interface GridProps extends TableOptions<object> {
    disabledSort?: boolean;
    enableSelectedRow?: boolean;
    invalidRow?: (x: any) => boolean;
    onSelectItem?: Function;
    onDoubleClickRow?: Function;
    onClickRow?: Function;
    cssClass?: string;
    resetGrid?: boolean;
    resetCallback?: Function;
    columnSizes?: number[];
    showExport?: boolean;
    csvFileName?: string;
    csvSeparator?: string;
}

export const Grid: FC<GridProps> = ({
    columns,
    data,
    disabledSort,
    enableSelectedRow = false,
    invalidRow,
    onSelectItem,
    onDoubleClickRow,
    onClickRow,
    cssClass,
    resetGrid,
    resetCallback,
    columnSizes,
    showExport,
    csvFileName,
    csvSeparator
}: GridProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [selected, setSelected] = useState(null);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageOptions,
        gotoPage,
        setPageSize,
        toggleAllRowsSelected,
        state: { pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
            disableSortBy: disabledSort,
        },
        useSortBy,
        usePagination,
        useRowSelect
    );

    const getCsvData = (): any => {
        const separator = csvSeparator ? csvSeparator : ',';

        let csvRow = [];
        let headers = [];

        data.forEach(row => {
            let ligne = [];
            for (let item = 0; item < columns.length; item++) {
                if (row[columns[item].accessor] !== undefined) {
                    if (csvRow.length === 0)
                        headers.push(columns[item].Header);
                    ligne.push(row[columns[item].accessor] === null ? "" : row[columns[item].accessor].toString().replace(/\n/g, ' '));
                }
            }
            // headers
            if (csvRow.length === 0)
                csvRow.push(headers.join(separator));
            csvRow.push(ligne.join(separator));
        });
        var csvStr = csvRow.join("\n");
        return csvStr;
    }

    const createDownloadLink = (csvStr, filename):void => {
        if (csvStr === '') return;

        let link = document.createElement("a");
        link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvStr));
        link.setAttribute("download", filename + ".csv");
        link.click();
    }
    const exportCsvData = ():void => {
        if (data && data.length > 0) {
            const csvDataToExport = getCsvData();
            createDownloadLink(csvDataToExport.replaceAll("#", ''), csvFileName ? csvFileName : 'exportCsv');
        }
    }

    useEffect(() => {
        if (resetGrid === true) {
            gotoPage(0);
            setCurrentPage(1);
            toggleAllRowsSelected(false);
            setSelected(-1);
            if (resetCallback) resetCallback();
        }
    }, [resetGrid]);

    // Render the UI for your table
    return (
        <>
            {
                showExport === true &&
                <div className='chronoGrid-tool-div'>
                    <Button onClick={exportCsvData} className='chronoGrid-tool-button'>
                        <span>
                            <FontAwesomeIcon icon={faFileCsv} /> Exporter
                        </span>
                    </Button>
                </div>
            }
            <table
                border="0"
                cellSpacing="0"
                cellPadding="0"
                className={"text-center table table-striped table-bordered table-hover " + cssClass}
                {...getTableProps()}
            >
                {columnSizes && (
                    <colgroup>
                        {columnSizes.map((column) => (
                            <col width={`${column}%`} />
                        ))}
                    </colgroup>
                )}
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>
                                        <FontAwesomeIcon
                                            style={{ color: column.isSorted ? "silver" : "transparent" }}
                                            icon={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? faSortAmountDown
                                                        : faSortAmountUp
                                                    : faSort
                                            }
                                        />
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps({
                                    onClick: () => {
                                        if (enableSelectedRow === true) setSelected(row.index);
                                        if (onClickRow) onClickRow(row.original, row.index);
                                        if (onSelectItem) onSelectItem();
                                    },
                                    onDoubleClick: () => {
                                        if (onDoubleClickRow) onDoubleClickRow(row.original, row.index);
                                    },
                                    style: {
                                        background:
                                            row.index === selected
                                                ? "#00928D"
                                                : invalidRow && invalidRow(row) === true
                                                ? "indianred"
                                                : null,
                                        color:
                                            row.index === selected || (invalidRow && invalidRow(row) === true)
                                                ? "white"
                                                : "black",
                                    },
                                })}
                            >
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="pagination pagination-container">
                <div>
                    <CustomPagination
                        totalPages={pageOptions.length}
                        currentPage={currentPage}
                        setCurrentPage={(val) => {
                            gotoPage(val - 1);
                            setCurrentPage(val);
                        }}
                    />
                </div>
                <div>
                    <ChooserNumberPageDisplay
                        itemPerPage={pageSize}
                        setItemPerPage={(val) => setPageSize(Number(val))}
                        setCurrentPage={() => {
                            gotoPage(0);
                            setCurrentPage(1);
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default Grid;
