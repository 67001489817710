export const ERROR_ALERT_ADDED = "ERROR_ALERT_ADDED"
export const RESOURCE_ADDED = "RESOURCE_ADDED"
export const UI_ITEM_ADDED = "UI_ITEM_ADDED"

export const GET_APPLICATION_LOGS_REQUESTED = "GET_APPLICATION_LOGS_REQUESTED"

export const GET_APPLICATION_LOG_DETAILS_REQUESTED = "GET_APPLICATION_LOG_DETAILS_REQUESTED"

export const GET_APPLICATION_LOG_DETAILS_FAILED = "GET_APPLICATION_LOG_DETAILS_FAILED"

export const GET_APPLICATION_LOG_CONFIGURATIONS_REQUESTED = "GET_APPLICATION_LOG_CONFIGURATIONS_REQUESTED"

export const GET_MOBILE_CONFIG_LOG_REQUESTED = "GET_MOBILE_CONFIG_LOG_REQUESTED"
export const GET_MOBILE_CONFIG_LOG_FAILED = "GET_MOBILE_CONFIG_LOG_FAILED"
export const SAVE_MOBILE_CONFIG_LOG = "SAVE_MOBILE_CONFIG_LOG"

export const GET_MOBILE_CONFIG_LOG_TYPE = "GET_MOBILE_CONFIG_LOG_TYPE"
export const GET_MOBILE_CONFIGURATION_LIST_ITEMS = "GET_MOBILE_CONFIGURATION_LIST_ITEMS"
export const GET_DROPDOWNLIST_ITEMS_LOG_DETAILS = "GET_DROPDOWNLIST_ITEMS_LOG_DETAILS"

export const GET_DROPDOWNLIST_EMAIL_USER_LIST = "GET_DROPDOWNLIST_EMAIL_USER_LIST"
export const GET_CHRONO_USER_LIST_REQUESTED = "GET_CHRONO_USER_LIST_REQUESTED"
export const ACTIVATE_CHRONO_USER_REQUESTED = "ACTIVATE_CHRONO_USER_REQUESTED"
export const RESEND_EMAIL_CONFIRMATION_CHRONO_USER = "RESEND_EMAIL_CONFIRMATION_CHRONO_USER"

export const SAVE_MESSAGE = "SAVE_MESSAGE"
export const SAVE_CARTE = "SAVE_CARTE"

export const GET_REFERENCE_ROUTE_REQUESTED = "GET_REFERENCE_ROUTE_REQUESTED"
export const GET_REFERENCE_STOP_REQUESTED = "GET_REFERENCE_STOP_REQUESTED"

export const SAVE_ALERT = "SAVE_ALERT"
export const SAVE_NOTIFICATION = "SAVE_NOTIFICATION"

export const GET_STATS_USER_CONNECTED_REQUESTED = "GET_STATS_USER_CONNECTED_REQUESTED"

export const GET_CALENDAR_MESSAGES = "GET_CALENDAR_MESSAGES"

export const GET_REQUEST_API_EVERY = "GET_REQUEST_API_EVERY"
export const GET_REQUEST_API_LATEST = "GET_REQUEST_API_LATEST"

export const POST_REQUEST_API_EVERY = "POST_REQUEST_API_EVERY"
export const PUT_REQUEST_API_EVERY = "PUT_REQUEST_API_EVERY"
export const DELETE_REQUEST_API_EVERY = "DELETE_REQUEST_API_EVERY"

export const POST_REQUEST_API_LATEST = "POST_REQUEST_API_LATEST"

export const ADD_REQUEST_STATUS = 'ADD_REQUEST_STATUS'
