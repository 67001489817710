import { sendGetRequest, sendPostRequest } from "../auth/apiEffects";
import {
    LogMobileSummaryViewModel,
    LogMobileDetailsViewModel,
    LogMobileConfigurationViewModel,
} from "../types/viewmodels/LogMobileViewModels";

export const getApplicationLogs = async (queryParams): Promise<false | LogMobileSummaryViewModel[] | undefined> => {
    try {
        const searchQueryParams = {
            LogType: queryParams.logType,
            StartDate: queryParams.startDate,
            EndDate: queryParams.endDate,
            Criticality: queryParams.criticality,
            Os: queryParams.os,
            Category: queryParams.category,
        };

        const searchQueryParamString = new URLSearchParams(searchQueryParams).toString();
        const response = await sendGetRequest<LogMobileSummaryViewModel[]>(`/log/all?${searchQueryParamString}`);
        return response;
    } catch (e) {
        console.log(e);
        return undefined;
    }
};

export const getApplicationLogConfigurations = async (): Promise<false | LogMobileConfigurationViewModel[] | undefined> => {
    try {
        const response = await sendGetRequest<LogMobileConfigurationViewModel[]>(`/log/configuration/all`);
        return response;
    } catch (e) {
        console.log(e);
        return undefined;
    }
};

export const getApplicationLogDetails = async (queryParams): Promise<false | LogMobileDetailsViewModel[] | undefined> => {
    try {
        const searchQueryParams = {
            StartDate: queryParams.StartDate,
            EndDate: queryParams.EndDate,
            LogMobileConfigurationId: queryParams.LogMobileConfigurationId,
            PhoneBrand: queryParams.PhoneBrand,
            OsVersion: queryParams.OsVersion,
            AppVersion: queryParams.AppVersion,
            SearchCriteria: queryParams.SearchCriteria,
            UserEmail: queryParams.UserEmail,
        };

        const searchQueryParamString = new URLSearchParams(searchQueryParams).toString();
        const response = await sendGetRequest<LogMobileDetailsViewModel[]>(`/log/detail?${searchQueryParamString}`);
        return response;
    } catch (e) {
        console.log(e);
        return undefined;
    }
};

export const getConfigurationLog = async (queryParams): Promise<false | LogMobileConfigurationViewModel[] | undefined> => {
    try {
        let searchQueryParams = {
            LogType: queryParams.LogType,
            OS: queryParams.OS,
            Active: queryParams.Active,
        };

        const searchQueryParamString = new URLSearchParams(searchQueryParams).toString();
        const response = await sendGetRequest<LogMobileConfigurationViewModel[]>(
            `/log/configuration/all?${searchQueryParamString}`
        );
        return response;
    } catch (e) {
        console.log(e);
        return undefined;
    }
};

export const saveConfigurationLog = async (param): Promise<false | LogMobileConfigurationViewModel | undefined> => {
    try {
        const response = await sendPostRequest<LogMobileConfigurationViewModel>(`/log/configuration/save`, param);
        return response;
    } catch (e) {
        console.log(e);
        return undefined;
    }
};
