import React, { useState, useContext, useEffect } from "react";
import { Badge, NavItem, NavLink, Nav } from "reactstrap";
import { logout } from "../../auth/authProvider";
import RoleHelper, {
    ROLE_CODENAME_ADMIN,
    ROLE_CODENAME_APPROBATOR,
    ROLE_CODENAME_EDITOR,
    ROLE_CODENAME_SUPPORT,
    ROLE_CODENAME_USER,
    ROLE_CODENAME_USER_ARTM,
} from "../common/RoleHelper";
import { RoleHelperMustHaveOne, RoleHelperInternal } from "../common/RoleHelper";
import { NavLink as RRNavLink } from "react-router-dom";
import styled from "styled-components";
import { useChronoApi, sendGetRequest } from "../../auth/apiEffects";
import HeaderNavMenu from "./HeaderNavMenu";
import {
    ROUTE_SUPPORT_CHRONO_USERS,
    ROUTE_ADMIN_GTFS_MENU,
    ROUTE_ADMIN_MOBILE_CONFIG,
    ROUTE_ADMIN_REALTIME,
    ROUTE_ADMIN_USERS,
    ROUTE_COMMUNICATION_ALERTS,
    ROUTE_COMMUNICATION_CARTES,
    ROUTE_COMMUNICATION_MESSAGES,
    ROUTE_HOME,
    ROUTE_MONITORING_GTFS,
    ROUTE_MONITORING_REALTIME,
    ROUTE_STATS_PLANIFICATIONS,
    ROUTE_STATS_FAVORIS,
    ROUTE_STATS_USAGE,
    ROUTE_TICKETING_ACCOUNT,
    ROUTE_TICKETING_ORDER,
    ROUTE_TICKETING_REPORT,
    ROUTE_STATS_TICKETING,
    ROUTE_ACHALANDAGE_CONFIG,
    ROUTE_STATS_REALTIME,
    ROUTE_COMMUNICATION_NOTIFICATIONS,
    ROUTE_MONITORING_APPLICATION_LOGS,
    ROUTE_MONITORING_MOBILE_CONFIGURATION_LOGS,
    ROUTE_COMMUNICATION_CALENDAR,
    ROUTE_DEBUGTOOL_RESULTCOMPARER
} from "../../routes";
import SvgIcon from "../common/SvgIcon";
import { ThemeContext } from "styled-components";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import Emitter from "../common/helperEmitter";

export const MENU_WIDTH = 250;
export const MEDIA_SCREEN_MENU_SWITCH_SIZE = 700;
export const MEDIA_SCREEN_LG = 992;

const Menu = styled(Nav)`
    position: fixed;
    flex-direction: column;
    flex-wrap: nowrap !important;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: ${(props) => props.theme.colors.primary};
    overflow: auto;
    width: ${MENU_WIDTH}px;

    @media screen and (max-width: ${MEDIA_SCREEN_MENU_SWITCH_SIZE}px) {
        width: 100%;
        height: auto;
        position: relative;
    }
`;

const NavSubTitle = styled(NavItem)`
    font-size: 1em;
    margin: 0 30px 10px 30px;
    color: ${(props) => props.theme.colors.lightGray};
    opacity: 0.3;
`;

const CustomNavLink = styled(NavLink)`
    color: ${(props) => props.theme.colors.lightGray};
    opacity: 0.8;
    font-size: 0.9em;
    letter-spacing: 0.02em;
    font-weight: 600;
    height: 40px;

    &:hover {
        color: ${(props) => props.theme.colors.lightGray};
        background-color: ${(props) => props.theme.colors.primaryDark};
        opacity: 1;
    }

    padding-left: 50px !important;
`;

const NavWithIcon = ({ to, icon, children }) => {
    const [active, setActive] = useState(false);
    return (
        <NavItem>
            <CustomNavLink
                to={to}
                activeClassName="activeNavItem"
                tag={RRNavLink}
                isActive={(match) => {
                    if (!match) {
                        setActive(false);
                        return false;
                    }
                    setActive(true);
                    return true;
                }}
            >
                <MenuSvg>
                    <SvgIcon icon={icon} active={active} />
                </MenuSvg>
                {children}
            </CustomNavLink>
        </NavItem>
    );
};

const CustomBadge = styled(Badge)`
    margin: 0px 15px;
`;

const MenuSvg = styled.span`
    font-size: 1em;
    margin: 0px 15px 0px 0px;
`;

const Space = styled.hr`
    border: none;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
`;

const LogoutButton = styled(CustomNavLink)`
    color: ${(props) => props.theme.colors.lightGray} !important;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    padding-left: 30px;
    padding-right: 50px;

    &:hover {
        color: ${(props) => props.theme.colors.lightGray} !important;
        background-color: ${(props) => props.theme.colors.primaryDark} !important;
    }
`;

const GtfsIconColor = () => {
    const { data: statsGtfs } = useChronoApi("/admin/gtfs/stats");
    const theme = useContext(ThemeContext);
    const daysLeftBeforeExpiry = (stat) => moment().diff(stat.maxDateService, "days") * -1;

    if (!statsGtfs.length) return null;

    const urgentGtfsChange = statsGtfs.filter((stat) => {
        return daysLeftBeforeExpiry(stat) <= 7;
    });

    const warningGtfsChange = statsGtfs.filter((stat) => {
        return daysLeftBeforeExpiry(stat) <= 15;
    });

    if (urgentGtfsChange.length > 0)
        return <FontAwesomeIcon style={{ marginLeft: 15 }} icon={faExclamationTriangle} color={theme.colors.messageError} />;
    else if (warningGtfsChange.length > 0)
        return <FontAwesomeIcon style={{ marginLeft: 15 }} icon={faExclamationTriangle} color={theme.colors.messageWarning} />;
    else return null;
};

const GtfsDesactivated = () => {
    const [isGtfsActive, setIsGtfsActive] = useState(true);
    const theme = useContext(ThemeContext);

    useEffect(() => {
        async function fetchData() {
            await loadData();
        }

        fetchData();

        Emitter.on("ACTIVATION_GTFS_CHANGE", (newValue) => setIsGtfsActive(newValue));
    }, []);

    const loadData = async () => {
        const results = await sendGetRequest(`/admin/gtfsconfigs`);
        var activatedConfigs = [];

        if (results)
            activatedConfigs = results.filter((config) => {
                return config.code === "DEACTIVATED_IMPORT";
            });

        setIsGtfsActive(activatedConfigs && activatedConfigs.length > 0 ? activatedConfigs[0].value === "false" : true);
    };

    if (!isGtfsActive)
        return <FontAwesomeIcon style={{ marginLeft: 15 }} icon={faExclamationTriangle} color={theme.colors.messageError} />;
    else return null;
};

const NavMenu = () => {
    const { data: nbAwaitingMessages } = useChronoApi("/messages/nbawaitingmessage", 0);
    const { data: nbAwaitingCartes } = useChronoApi("/cartes/nbawaitingMap", 0);

    return (
        <Menu>
            <HeaderNavMenu />

            <NavWithIcon to={ROUTE_HOME} icon={"home"}>
                Tableau de bord
            </NavWithIcon>

            <Space />

            <RoleHelper roleCodenameAllowed={ROLE_CODENAME_USER}>
                <NavSubTitle>COMMUNICATIONS</NavSubTitle>
                <NavWithIcon to={ROUTE_COMMUNICATION_MESSAGES("")} icon={"messages"}>
                    Messages
                    <CustomBadge>{nbAwaitingMessages}</CustomBadge>
                </NavWithIcon>
                <NavWithIcon to={ROUTE_COMMUNICATION_CALENDAR} icon={"calendar"}>
                    Calendrier
                </NavWithIcon>
            </RoleHelper>

            
            
            <RoleHelper roleCodenameAllowed={ROLE_CODENAME_APPROBATOR}>
            <NavWithIcon to={ROUTE_COMMUNICATION_CARTES("")} icon={"cartes"}>
                    Cartes
                    <CustomBadge>{nbAwaitingCartes}</CustomBadge>
                </NavWithIcon>
            </RoleHelper>
            

            <RoleHelperMustHaveOne roleCodeNameArray={[ROLE_CODENAME_EDITOR, ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_ADMIN]}>        
                <NavWithIcon to={ROUTE_COMMUNICATION_ALERTS("")} icon={"warnings"}>
                    Perturbations
                </NavWithIcon>
            </RoleHelperMustHaveOne>
            <RoleHelper roleCodenameAllowed={ROLE_CODENAME_APPROBATOR}>
                <NavWithIcon to={ROUTE_COMMUNICATION_NOTIFICATIONS("")} icon={"notifications"}>
                    Notifications
                </NavWithIcon>
            </RoleHelper>
            <Space />

            <RoleHelper roleCodenameAllowed={ROLE_CODENAME_USER}>
                <NavSubTitle>STATISTIQUES</NavSubTitle>
                <NavWithIcon to={ROUTE_STATS_USAGE} icon={"users"}>
                    Usages
                </NavWithIcon>
                <NavWithIcon to={ROUTE_STATS_PLANIFICATIONS} icon={"planification"}>
                    Planification
                </NavWithIcon>
                <NavWithIcon to={ROUTE_STATS_FAVORIS} icon={"favoris"}>
                    Favoris
                </NavWithIcon>
                <NavWithIcon to={ROUTE_STATS_REALTIME} icon={"real-time"}>
                    Temps Réel
                </NavWithIcon>
            </RoleHelper>

            <RoleHelperMustHaveOne roleCodeNameArray={[ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_ADMIN]}>
                <NavWithIcon to={ROUTE_STATS_TICKETING} icon={"stats_billetterie"}>
                    Billetterie
                </NavWithIcon>
                <NavWithIcon to={ROUTE_TICKETING_REPORT} icon={"rapport_billetterie"}>
                    Rapport billetterie
                </NavWithIcon>
            </RoleHelperMustHaveOne>

            <Space />

            <RoleHelperMustHaveOne roleCodeNameArray={[ROLE_CODENAME_SUPPORT, ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_ADMIN]}>
                <NavSubTitle>BILLETTERIE</NavSubTitle>
                <NavWithIcon to={ROUTE_TICKETING_ACCOUNT} icon={"compte"}>
                    Compte Client
                </NavWithIcon>
                <NavWithIcon to={ROUTE_TICKETING_ORDER} icon={"commande"}>
                    Commande
                </NavWithIcon>
                <Space />
            </RoleHelperMustHaveOne>

            <RoleHelperMustHaveOne roleCodeNameArray={[ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_ADMIN]}>
                <NavSubTitle>SUPPORT</NavSubTitle>
                <NavWithIcon to={ROUTE_SUPPORT_CHRONO_USERS} icon={"users"}>
                    Utilisateurs Chrono
                </NavWithIcon>
                <Space />
            </RoleHelperMustHaveOne>

            <RoleHelper roleCodenameAllowed={ROLE_CODENAME_ADMIN}>
                <NavSubTitle>ADMINISTRATION</NavSubTitle>
                <NavWithIcon to={ROUTE_ADMIN_USERS} icon={"users"}>
                    Utilisateurs
                </NavWithIcon>
                <NavWithIcon to={ROUTE_ADMIN_MOBILE_CONFIG} icon={"config"}>
                    Config de l'app
                </NavWithIcon>
                <NavWithIcon to={ROUTE_ADMIN_GTFS_MENU} icon={"gtfs"}>
                    Gestion GTFS
                    <GtfsDesactivated />
                </NavWithIcon>
                <NavWithIcon to={ROUTE_ADMIN_REALTIME} icon={"real-time"}>
                    Temps réel
                </NavWithIcon>
                <NavWithIcon to={ROUTE_ACHALANDAGE_CONFIG} icon={"achalandage"}>
                    Config achalandage
                </NavWithIcon>
                <Space />
            </RoleHelper>

            <RoleHelper roleCodenameAllowed={ROLE_CODENAME_ADMIN}>
                <NavSubTitle>MONITORING</NavSubTitle>
                <NavWithIcon to={ROUTE_MONITORING_APPLICATION_LOGS} icon={"applicationLogs"}>
                    Logs applicatifs
                </NavWithIcon>
                <NavWithIcon to={ROUTE_MONITORING_MOBILE_CONFIGURATION_LOGS} icon={"mobileLogsconfig"}>
                    Créer / éditer logs
                </NavWithIcon>
                <NavWithIcon to={ROUTE_MONITORING_REALTIME} icon={"monitoring"}>
                    Temps réel
                </NavWithIcon>
                <NavWithIcon to={ROUTE_MONITORING_GTFS} icon={"gtfs"}>
                    GTFS
                    <GtfsIconColor />
                </NavWithIcon>
                <Space />
            </RoleHelper>

            <RoleHelperInternal>
                <NavSubTitle>DEBUG</NavSubTitle>
                <NavWithIcon to={ROUTE_DEBUGTOOL_RESULTCOMPARER} icon={"monitoring"}>
                    TR - Comparaison
                </NavWithIcon>
                <Space />
            </RoleHelperInternal>

            <NavItem>
                <LogoutButton onClick={() => logout()}>
                    <MenuSvg>
                        <SvgIcon icon="logout" />
                    </MenuSvg>
                    Logout
                </LogoutButton>
            </NavItem>
        </Menu>
    );
};

export default NavMenu;
