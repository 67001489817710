import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import * as types from "../actions/types/types";
import { genericApi as api } from "../../api/genericApi";
import addResource from "../actions/addResource";
import addRequest from "../actions/addRequest";
import * as statusRequest from "./requestStatus";

export function* watchRequestApi() {
    yield takeLatest(types.GET_REQUEST_API_LATEST, get);
    yield takeLatest(types.POST_REQUEST_API_LATEST, post);
    yield takeEvery(types.GET_REQUEST_API_EVERY, get);
    yield takeEvery(types.POST_REQUEST_API_EVERY, post);
    yield takeEvery(types.PUT_REQUEST_API_EVERY, putRequest);
    yield takeEvery(types.DELETE_REQUEST_API_EVERY, deleteRequest);
}

export function* get(action) {
    const { request } = action.payload;
    yield call(executeRequest, request, "GET");
}

export function* post(action) {
    const { request } = action.payload;
    yield call(executeRequest, request, "POST");
}

export function* putRequest(action) {
    const { request } = action.payload;
    yield call(executeRequest, request, "PUT");
}

export function* deleteRequest(action) {
    const { request } = action.payload;
    yield call(executeRequest, request, "DELETE");
}

function* executeRequest(request, method) {
    try {
        yield put(
            addRequest(
                statusRequest.REQUEST_PENDING,
                request.requestDefinition.storeKey(
                    request.requestDefinition.extendedStore || request.requestDefinition.extendedStore
                )
            )
        );

        const results =
            method === "GET"
                ? yield call(api.get, request)
                : method === "POST"
                ? yield call(api.post, request)
                : method === "PUT"
                ? yield call(api.put, request)
                : yield call(api.delete, request);

        yield put(addResource(results, request.requestDefinition.storeKey(request.requestDefinition.extendedStore || request.requestDefinition.extendedStore)));

        yield put(
            addRequest(
                statusRequest.REQUEST_SUCCEEDED,
                request.requestDefinition.storeKey(
                    request.requestDefinition.extendedStore || request.requestDefinition.extendedStore
                )
            )
        );
        
    } catch (e) {
        console.log(e);
        console.log('fail fail')
        yield put(
            addRequest(
                statusRequest.REQUEST_FAILED,
                request.requestDefinition.storeKey(
                    request.requestDefinition.extendedUrl || request.requestDefinition.extendedUrl
                )
            )
        );
    }
}
