/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactElement, useEffect } from "react";
import { useState } from "react";
import "./Dropdown.css";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DropdownBox: FC<any> = (props, ref): ReactElement => {
    const { selectedValues, options, onChange, name } = props;
    const [values, setValues] = useState<any[]>([]);

    useEffect(() => {
        if (selectedValues.length > 0) {
            setValues(selectedValues);
        }
    }, [selectedValues]);

    const isActive = (values, option) => {
        return values.length > 0 && values.some((sv) => sv === option.value);
    };

    const onChangeDropdownBox = (e) => {
        if (e.target.checked) {
            setValues([...values, e.target.value]);
        } else {
            let newValues = [...values];
            const index = values.indexOf(e.target.value);
            newValues.splice(index, 1);
            setValues(newValues);
        }
        onChange(e);
    };

    return (
        <ul className="dropdownFlexContainer justify-content-around m-3">
            {options.map((option) => {
                return (
                    <li className={`d-flex blockButton m-2 ${isActive(values, option) ? "checked" : ""}`}>
                        {isActive(values, option) && <FontAwesomeIcon icon={faCheckCircle} className="checkedIcon" />}
                        <label
                            key={`dropdownBox_${option.value}`}
                            className={`w-100 h-100 d-flex  align-items-center justify-content-center`}
                        >
                            <input
                                type="checkbox"
                                className="checkboxDropdownBox "
                                name={name}
                                value={option.value}
                                onChange={onChangeDropdownBox}
                                checked={isActive(values, option) ? true : false}
                            />

                            {option.label}
                        </label>
                    </li>
                );
            })}
        </ul>
    );
};

export default DropdownBox;
