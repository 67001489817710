import React from 'react';

const GenericCell = ({ isEdit, value, rowId, onChange, invalid = false, maxSize = 512, disabled }) => {
  
    const onChangeValue = (event) => {
        if (onChange)
            onChange(event.target.value, rowId)
    }
    return (
        <div className='text-concat'>
            {
                isEdit ? <input disabled={disabled ? true : undefined} type='text' style={{ width: '100%', borderRadius: '5px', borderColor: !invalid ? 'lightgray' : 'red' }} value={value} maxLength={maxSize}  onChange={onChangeValue} /> : value
            }
        </div>
    )
}
    
        
export default GenericCell;