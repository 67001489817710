import * as  React from 'react';
import CommunicationActionButtonGrid from "../../CommunicationActionButtonGrid";
import { useAlertService } from "../../../../service/communication/useAlertService";
import { useCommunicationEvent } from '../../communicationEvent';
import { AlertButtonGridProps } from '../../../../types/components/alertComponent';

const AlertButtonGrid: React.FC<AlertButtonGridProps> = ({ data, onRefresh }: AlertButtonGridProps): React.ReactElement => {
    const alertService = useAlertService();
    const keySuffix = 'alertGrid';

    React.useEffect(() => {
        alertService.request.Update.changeResourceName({ alertId: data.id });
        alertService.request.Delete.changeResourceName({ alertId: data.id, keySuffix: keySuffix});
    }, []);


    //handle event publish notif
    useCommunicationEvent(
        {
            successCallback: () => {
                alertService.updateModel(alertService.request.Update.data)
            },
            afterPost: () => {
                 alertService.request.Update.refreshState(data.id);
                if (onRefresh)
                    onRefresh();
            }
        }, alertService.request.Update.isSuccess, alertService.request.Update.isFail
    )

    //handle event delete notif
    useCommunicationEvent(
        {
            successCallback: () => {
                alertService.removeElement(data.id)
            },
            afterPost: () => {
                alertService.request.Delete.refreshState(data.id);
                if (onRefresh)
                    onRefresh();
            }
        }, alertService.request.Delete.isSuccess, alertService.request.Delete.isFail
    )

    return (
        <div id={'containerButonGrid' + data.id} className='com-container'>

            <CommunicationActionButtonGrid
                isProcessing={alertService.request.Update.isLoading || alertService.request.Delete.isLoading}
                disabled={!alertService.isAlertEditable(data)}
                hideApprove={true}
                hidePublish={false}
                hideRefuse={true}
                canPublish={alertService.canPublish(data)}
                onClickSend={(requestType) => {
                    const model = alertService.mapToModel(data, requestType, data);
                    alertService.request.Update.process(model);

                }}
                onClickDelete={() => {
                    alertService.request.Delete.process(data.id, keySuffix);
                }}
                data={data}
            />
        </div>
    )
}

export default AlertButtonGrid;