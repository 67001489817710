/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, FC, ReactElement } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommunicationFormHeader from "../../CommunicationFormHeader";
import { getFormattedDate } from "../../../common/helper";
import { BoxHeaderDashed, BoxTextDashed, ColDashed, SiblingRowDashed } from "../../CommunicationStructure";
import { DATE_FORMAT } from "../../CommunicationUtils";
import CenteredComponent from "../../../common/CenteredComponent";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import NotificationStatusBadge from "../components/NotificationStatusBadge";
import { useNotificationService } from "../../../../service/communication/useNotificationService";
import { useCommunicationEvent } from "../../communicationEvent";
import { NotificationFormPublishedProps } from "../../../../types/components/notificationComponent";

const NotificationSummary: FC<NotificationFormPublishedProps> = ({ entity, operations, onClickEdit }: NotificationFormPublishedProps) => {
    const notificationService = useNotificationService();

  const { addToast } = useToasts();
  const [canStopPublicationNotification, setCanStopPublicationNotification] = useState(notificationService.canStopPublication(entity));

   // handle event unpublish notif
    useCommunicationEvent(
        {
            ...operations,
            successCallback: () => {
                setCanStopPublicationNotification(false);
                notificationService.updateModel(notificationService.request.Unpublish.data);
                if (operations?.post)
                    operations?.post(true)
            },
            afterPost: () => notificationService.request.Unpublish.refreshState(entity.id)
        },
        notificationService.request.Unpublish.isSuccess,
        notificationService.request.Unpublish.isFail
    )

  const onClickEditHandle = async () => {
      const canEditNotification = notificationService.canEditNotification(entity);
    if (!canEditNotification)
      addToast("La notification étant déjà publiée, vous ne pouvez plus la modifier", {
        appearance: "error",
        autoDismiss: true,
      });

    onClickEdit(canEditNotification);
  };

  return (
    <Container>
      <CommunicationFormHeader
        data={entity}
        icon={moment().isBefore(entity.startDate) ? faEdit : null}
        isSendingData={notificationService.request.Unpublish.isLoading}
        showStopPublication={canStopPublicationNotification}
        onClickEdit={onClickEditHandle}
        onClickStopPublication={() => {
            if (operations?.pre)
                operations.pre();

            notificationService.request.Unpublish.process(entity);
        }}
        statusBadge={<NotificationStatusBadge notification={entity} />}
      />

      <SiblingRowDashed>
        <Col xl={6} md={6} sm={6} xs={12} className="text-center">
                  <h2 className="custom-word-break">{entity.titleFR}</h2>
        </Col>
        <Col xl={6} md={6} sm={6} xs={12} className="text-center">
                  <h2 className="custom-word-break">{entity.titleEN}</h2>
        </Col>
      </SiblingRowDashed>

      <SiblingRowDashed>
        <ColDashed>
          <CenteredComponent>
            <BoxHeaderDashed>Message FR</BoxHeaderDashed>
                      <BoxTextDashed>{entity.descriptionFR}</BoxTextDashed>
          </CenteredComponent>
        </ColDashed>
        <ColDashed>
          <CenteredComponent>
            <BoxHeaderDashed>Message EN</BoxHeaderDashed>
                      <BoxTextDashed>{entity.descriptionEN}</BoxTextDashed>
          </CenteredComponent>
        </ColDashed>
      </SiblingRowDashed>

      <SiblingRowDashed>
        <ColDashed>
          <CenteredComponent>
            <BoxHeaderDashed>DÉBUT</BoxHeaderDashed>
                <BoxTextDashed>{getFormattedDate(entity.startDate, DATE_FORMAT)}</BoxTextDashed>
          </CenteredComponent>
        </ColDashed>
      </SiblingRowDashed>
    </Container>
  );
};

export default NotificationSummary;
