/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, ReactElement, useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

import "./Dropdown.css";

const DropdownFilter: FC<any> = (props): ReactElement => {
    const { options, filterOn, selectedFilterOptions, onChangeFilter } = props;

    const [filterDataList, setFilterDataList] = useState<any[]|null>(null);

    const createFilterDataList = (options, filterOn) => {
        let filterList:any[] = [];
        options.forEach((option) => {
            if (!filterList.some((item) => item.value === option["filterOn"])) {
                filterList.push({ label: option["filterOn"], value: option["filterOn"] });
            }
        });
        return filterList;
    };

    useEffect(() => {
        if (filterOn) {
            setFilterDataList(createFilterDataList(options, filterOn));
        }
    }, [filterOn]);

    return (
        <FormControl as="div" className="filterFlexItem filterMultiselectCheckbox noPadding">
            {filterDataList && (
                <ReactMultiSelectCheckboxes
                    options={filterDataList}
                    value={selectedFilterOptions}
                    defaultValue={filterDataList}
                    onChange={onChangeFilter}
                />
            )}
        </FormControl>
    );
};

export default DropdownFilter;
