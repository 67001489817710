import { saveState } from './helper'
import {
    UI_ITEM_ADDED
} from '../actions/types/types';

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {

        case UI_ITEM_ADDED:
            var { key, value } = action.payload;
            var ui = state;
            return saveState(ui, key, value);

        default:
            return state;
    }
}