import React from "react";
import type { FC, ReactNode } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MEDIA_SCREEN_MENU_SWITCH_SIZE, MENU_WIDTH } from "../../global";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TOP_BAR_SIZE = 80;

const TopBarContainer = styled(Row)`
    position: fixed;
    height: ${(props) => (props.barHeight ? props.barheight : TOP_BAR_SIZE)}px;
    width: calc(100% - ${MENU_WIDTH}px);
    background-color: white;
    align-items: center;
    box-shadow: 0 24px 59px 0 rgba(51, 59, 69, 0.15);
    margin: 0 !important;
    padding: 0 10px;
    z-index: 2;

    @media screen and (max-width: ${MEDIA_SCREEN_MENU_SWITCH_SIZE}px) {
        width: 100%;
        position: sticky;
        top: 0;
    }
`;

const TopBarTitle = styled.h2`
    color: #212121;
    font-size: 1.5em;
    margin: auto;
`;

export const BackRouteLink = styled(Link)`
    position: relative;
    top: 2px;
    margin: 0 10px 0 0;
`;

const BackRouteLinkButton = styled(FontAwesomeIcon)`
    font-size: 1.2em;
    color: ${(props) => props.theme.colors.text};
`;

interface TopBarProps {
    title?: string;
    backRouteLink?: string;
    barHeight?: number;
    col?: number;
    children?: ReactNode;
}

const TopBar: FC<TopBarProps> = (props: TopBarProps) => {
    const { title, backRouteLink, children, barHeight, col = 6 } = props;

    return (
        <TopBarContainer barheight={barHeight}>
            {(title || backRouteLink) && (
                <Col xl={col}>
                    <TopBarTitle data-testid="topbar-title">
                        {backRouteLink && (
                            <BackRouteLink to={backRouteLink}>
                                <BackRouteLinkButton icon={faArrowAltCircleLeft} />
                            </BackRouteLink>
                        )}
                        {title}
                    </TopBarTitle>
                </Col>
            )}

            {children}
        </TopBarContainer>
    );
};

export default TopBar;
