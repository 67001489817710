import React, {FC, ReactElement, useContext } from 'react';
import ChronoContext from "../../../../context/ChronoContext";
import Toast from "../Toast/Toast";

const ToastStack:FC<any> = ():ReactElement => {
    const context = useContext(ChronoContext);
    const staticData = context.staticData;
    const notifications = staticData.notifications || [];
    
    return (
        <div>
            { notifications.map(notification => <Toast message={notification.message} type={notification.type} />)}
        </div>
    )
};

export default ToastStack;
