/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import { Col } from 'react-bootstrap';
import StatsFilters from "./StatsFilters";
import StatsAgencyShown from "./StatsAgencyShown";
import StatsSummaryAgencyShown from "./StatsSummaryAgencyShown";
import StatsRouteShown from "./StatsRouteShown";
import StatsStopsShown from "./StatsStopsShown";
import ChronoContext from "../../../context/ChronoContext";
import AgenciesDropDown from "../global/AgenciesDropDown";
import { getSimplifiedAgencyLabels } from "../../common/helper";
import NoData from '../../common/generic/noData/NoData';
import moment from 'moment';

const maxDate = new Date();
maxDate.setHours(0);
maxDate.setMinutes(0);
maxDate.setSeconds(0);

const minDate = moment().subtract(1, 'month');
minDate.set({ hour: 0, minute: 0 });

const StatisticsFavoris = (props) => {
    const staticData = useContext(ChronoContext).staticData;
    const userAgencies = staticData.user.agencies;

    const [agencyCode, setAgencyCode] = useState<any>();
    const [route, setRoute] = useState<any>();
    const [currentDates, setCurrentDates] = useState({startDate: minDate, endDate: maxDate});
    const [agencyLabels, setAgencyLabels] = useState<string[]>([]);
    const [currentAgencyLabel, setCurrentAgencyLabel] = useState('');
    const [isAgencySelected, setIsAgencySelected] = useState(false);
    const [isUserHavingFavoriteMode, setIsUserHavingFavoriteMode] = useState(false);



    useEffect(() => {
        const agencyLabels: string[] = getSimplifiedAgencyLabels(userAgencies);
        setAgencyLabels(agencyLabels);
        setCurrentAgencyLabel(agencyLabels[0])
    }, [userAgencies]);

    useEffect(() => {
        setAgencyCode(false);
        setRoute(false);
        setIsAgencySelected(false)
        checkAgencySelection();
    }, [currentDates, currentAgencyLabel]);

    const onClickSelectAgency = (event) => {
        selectAgency(event.x);
    };
    const onClickUnselectAgency = () => {
        setAgencyCode(false);
        setRoute(false);
        setIsAgencySelected(false)
    };
    const onClickSelectRoute = (event) => {
        setRoute(event.x);
    };
    const onClickUnselectRoute = () => {
        setRoute(false);
    };

    function selectAgency(agency) {
        setAgencyCode(agency);
        setRoute(false);
        setIsAgencySelected(true)
    }

    function checkAgencySelection() {
        if (isExoSelected !== undefined && !isExoSelected) 
            selectAgency(currentAgencyLabel);
    }

    const showRoutes = agencyCode;
    const showStopsOrDirections = showRoutes && route;

    const isExoSelected = currentAgencyLabel === 'EXO';

    return (
        <Fragment>
            <TopBar title={`Statistiques Favoris ${currentAgencyLabel ? currentAgencyLabel : ''}`}>
                <Col  />
                {
                    agencyLabels.length > 1 
                        ?   <AgenciesDropDown
                                currentAgencyLabel={currentAgencyLabel}
                                setCurrentAgencyLabel={setCurrentAgencyLabel}
                                agencies={agencyLabels}
                            />
                        : agencyLabels.length 
                            ? <div style={{marginRight: 30, fontSize: 20}}>{agencyLabels[0]}</div>
                            : null
                }
            </TopBar>
            <ContentBelowTopBar>
                {
                    agencyLabels.length === 0
                        ? <NoData {...props} />
                        : <div>
                            <StatsFilters setCurrentDates={setCurrentDates} setIsUserHavingFavoriteMode={setIsUserHavingFavoriteMode} minDate={currentDates.startDate} maxDate={currentDates.endDate} isUserHavingFavoriteMode={isUserHavingFavoriteMode} />
                            {
                                isExoSelected ?
                                    <StatsAgencyShown
                                        onClickSelectBar={onClickSelectAgency}
                                        onClickUnselectBar={onClickUnselectAgency}
                                        currentDates={currentDates}
                                        isUserHavingFavoriteMode={isUserHavingFavoriteMode}
                                    /> :
                                    <StatsSummaryAgencyShown
                                        filterAgency={currentAgencyLabel}
                                        onClickSelectBar={onClickSelectAgency}
                                        onClickUnselectBar={onClickUnselectAgency}
                                        currentDates={currentDates}
                                        isSelected={isAgencySelected}
                                        isUserHavingFavoriteMode={isUserHavingFavoriteMode}
                                    />
                            }
                            {
                                showRoutes &&
                                <StatsRouteShown
                                    filterAgency={agencyCode}
                                    onClickSelectBar={onClickSelectRoute}
                                    onClickUnselectBar={onClickUnselectRoute}
                                    currentDates={currentDates}
                                    isUserHavingFavoriteMode={isUserHavingFavoriteMode}
                                />
                            }
                            {
                                showStopsOrDirections &&
                                <StatsStopsShown
                                    filterAgency={agencyCode}
                                    filterRoute={route}
                                    currentDates={currentDates}
                                    isUserHavingFavoriteMode={isUserHavingFavoriteMode}
                                />
                            }
                        </div>
                }
            </ContentBelowTopBar>
        </Fragment>
    );
};

export default StatisticsFavoris;
