/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch } from 'react-redux';
import * as keys from "../../redux/keys"
import * as requestStatus from "../../redux/sagas/requestStatus"
import useUI from "../../lib/hooks/useUI";
import useResources from "../../lib/hooks/useResources";
import { InputGroup, FormControl } from 'react-bootstrap';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const AutoComplete = ({ label, isRequired=false, style={}, value='', onChange, placeholder='', id, propertyname, maxLength='', getDropdownListItems, inputClassName = '', inputClassNameLoading = ''  }) => {
    const [criteria, setCriteria] = useState('');
    const [options, setOptions] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const data = useResources(keys.AUTOCOMPLETE_REFERENCES_DROPDOWNLIST_ITEMS + id);
    const status = useUI(keys.AUTOCOMPLETE_REFERENCES_DROPDOWNLIST_ITEMS + id + '_STATUS');

    useEffect(() => {
        if (isLoading === true && status === requestStatus.REQUEST_SUCCEEDED) {
            setOptions(data);
            setIsLoading(false);
        }
    }, [status]);

    useEffect(() => {
        if (isLoading) {
            dispatch(getDropdownListItems(keys.AUTOCOMPLETE_REFERENCES_DROPDOWNLIST_ITEMS, {
                propertyName: propertyname,
                criteria: criteria,
                resourceName: keys.AUTOCOMPLETE_REFERENCES_DROPDOWNLIST_ITEMS + id
            }));
        }
    }, [isLoading]);

    const onSelectData = (evt) => {
        if (onChange && evt && Array.isArray(evt) && evt.length > 0)
            onChange(evt[0].value)
    }

    const inputChange = (evt) => {
        if (evt && evt.length >= 3) {
            setCriteria(evt);
            setIsLoading(true);
        }

        if (onChange)
            onChange(evt);

    }

    return (
        <InputGroup>
            {
                label && label.length > 0 &&
                <InputGroup.Prepend style={style ? style : { minWidth: 130 }}>
                    <InputGroup.Text style={style ? style : { minWidth: 130 }}>
                        {label}
                        {isRequired ? " *" : ""}
                    </InputGroup.Text>
                </InputGroup.Prepend>
            }
            <FormControl as="div" className="custom-select" style={{ padding: 0, margin:0 }}>

        <Typeahead
                id={id}
                onInputChange={inputChange}
                onChange={onSelectData}
                value={value}
                options={options}
                maxResults={50}
                maxLength={maxLength}
                placeholder={placeholder}
                defaultInputValue={value}
                inputProps={{
                    className: isLoading == true && inputClassNameLoading ? inputClassNameLoading : inputClassName,
                }}
        

                />
                </FormControl>
            {
                isLoading &&
                <InputGroup.Append>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSpinner} spin={true} />
                    </InputGroup.Text>
                </InputGroup.Append>

            }
            {
                !isLoading && value && value.length > 0 && maxLength && maxLength.length > 0 &&
                <InputGroup.Append>
                    <InputGroup.Text>
                        <small className="text-muted appended-counter"> {value.length} / {maxLength} </small>
                    </InputGroup.Text>
                </InputGroup.Append>

            }
       </InputGroup>
    );
};

export default AutoComplete;

