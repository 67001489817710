import { useContext } from "react";
import { useApiRequest } from "../../redux/hooks/useApiRequest";
import moment from 'moment';
import { LABEL_STATUS_ALL } from "../../statusUtils";
import { LABEL_AGENCY_ALL } from "../../components/communication/CommunicationUtils";
import ChronoContext from "../../context/ChronoContext";

export const useComService = () => {

    const context = useContext(ChronoContext);
    const requestStatus = useApiRequest("getStatus");
    const requestOrg = useApiRequest("getUserOrganizations");

    const formatDropdownList = (list: any, label: string): Array<any> => {

        let status = list ? list.map(x => x) : [];
        return [{ label: label, value: null },
        ...status];
    }

    const handleDropdownFilterChange = (value: any, evt: any, changeValue: Function, optionList: any): void => {
        if (evt.action === "select-option" && evt.option.value === null)
            changeValue(optionList);
        else if (evt.action === "select-option" && evt.option.value !== null && value.length >= optionList.length - 1)
            changeValue(optionList);
        else if (evt.action === "deselect-option" && evt.option.value === null)
            changeValue([]);
        else if (evt.action === "deselect-option")
            changeValue(value.filter((x) => x.value !== null));
        else changeValue(value ? value : []);
    }

    const handleDropdownDateChange = (obj: any, changeValue: Function, formatDate:string): void => {
        changeValue("dateDebut", obj && obj.startDate ? obj.startDate : moment().format(formatDate));
        changeValue("dateFin", obj && obj.endDate ? obj.endDate : moment().format(formatDate));
        changeValue("filterDate", obj && obj.value ? obj.value : "1d");
    }

    const getOptionsStatus = ():Array<any> => {
        return formatDropdownList(requestStatus.result.data, LABEL_STATUS_ALL);
    }

    const getOptionsOrg = (): Array<any> => {
        const agencies = context.staticData.user.agencies;
        const orgList = agencies?.map((x, i) => {
            if (i === 0) {
                return { value: x.name, label: x.label, checked: true };
            }
            return { value: x.name, label: x.label };
        });

        return orgList && orgList.length > 0 ? formatDropdownList(orgList, LABEL_AGENCY_ALL) : [];
    }

    const getOrgBadge = (agencyCodeList?: Array<any>) => {
        const agencies = context.staticData.user.agencies;
        if (!agencyCodeList || !agencies) 
            return null;

        return agencyCodeList?.length === 1 
            ? { label: agencyCodeList[0], backgroundColor: agencies.length > 0 ? agencies.find((x) => x.label === agencyCodeList[0])?.color : "black", fontColor: 'white' }
            : { label: "MULTI", backgroundColor: "black", fontColor: 'white' };
    }

    return {
        request: {
            StatusGet: requestStatus, //remove after refacto in carte and alert
            getStatus: requestStatus,
            OrgGet: requestOrg, //remove after refacto in carte and alert
            getOrganization: requestOrg
        },
        formatDropdownList: formatDropdownList,
        handleDropdownFilterChange: handleDropdownFilterChange,
        handleDropdownDateChange: handleDropdownDateChange,
        getOptionsStatus: getOptionsStatus,
        getOptionsOrg: getOptionsOrg,
        getOrgBadge: getOrgBadge
    };
};





