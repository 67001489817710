import React, { useState, useEffect, FC, ReactElement } from 'react';
import { Container, Col } from 'react-bootstrap';
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import CommunicationFormHeader from "../../CommunicationFormHeader";
import { getFormattedDate } from "../../../common/helper";
import { BoxHeaderDashed, BoxTextDashed, ColDashed, SiblingRowDashed } from "../../CommunicationStructure";
import { DATE_FORMAT, TIME_FORMAT } from "../../CommunicationUtils";
import { ChronoIcon } from "../../../common/IconList";
import CenteredComponent from "../../../common/CenteredComponent";
import { useCarteService } from "../../../../service/communication/carteService";
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import { CarteFormPublishedProps } from "../../../../types/components/carteComponent";
import { useCommunicationEvent } from "../../communicationEvent";

const CarteSummary: FC<CarteFormPublishedProps> = ({ entity, operations, onClickEdit }: CarteFormPublishedProps): ReactElement => {
    const { addToast } = useToasts();
    const [isSendingData, setIsSendingData] = useState(false);
    const [canStopPublicationCard, setCanStopPublicationCard] = useState(false);

    const carteService = useCarteService();

    const canEditOrDeleteCard = () => {
        if (entity.activationDate) 
            return moment().isBefore(entity.activationDate)

        return false
    };

    useEffect(() => {
        setCanStopPublicationCard(entity && entity.activationDate && entity.expirationDate ? moment(entity.activationDate).isSameOrBefore(moment()) && moment().isSameOrBefore(moment(entity.expirationDate)) : false);
    }, [entity]);

    //handle event publish notif
    useCommunicationEvent(
        {
            successCallback: () => {
                carteService.updateModel(carteService.request.Publish.data);
                if (operations && operations.post)
                    operations.post(true);
            },
            afterPost: () => carteService.request.Publish.refreshState(entity.id)
        },
        carteService.request.Publish.isSuccess,
        carteService.request.Publish.isFail
    )

    //handle event expire notif
    useCommunicationEvent(
        {
            successCallback: () => {
                carteService.updateModel(carteService.request.Expire.data);
                if (operations && operations.post)
                    operations.post(true);
            },
            afterPost: () => {
                carteService.request.Expire.refreshState(entity.id);
                setIsSendingData(false);
            }
        },
        carteService.request.Expire.isSuccess,
        carteService.request.Expire.isFail
    )

    //handle event delete notif
    useCommunicationEvent(
        {
            successCallback: () => {
                carteService.removeElement(entity.id)
                if (operations && operations.post)
                    operations.post(true);
            },
            afterPost: () => carteService.request.Delete.refreshState(entity.id)
        }, carteService.request.Delete.isSuccess, carteService.request.Delete.isFail
    )

    const onClickStopPublicationHandle = async () => {
        setIsSendingData(true);
        carteService.request.Expire.process(entity.id)
    }

    const onClickEditHandle = async () => {
        var canEdit = canEditOrDeleteCard();
        if (!canEdit)
            addToast("La carte étant déjà publiée, vous ne pouvez plus la modifier", {
                appearance: 'error',
                autoDismiss: true,
            })

        onClickEdit(canEdit);
    }

    const onClickDeleteHandle = () => {
        if (operations?.pre)
            operations.pre();

        carteService.request.Delete.process(entity.id)
    };

    
    return (
        <Container>
            <CommunicationFormHeader
                data={entity}
                icon={canEditOrDeleteCard() ? faEdit : null}
                isSendingData={isSendingData}
                showStopPublication={canStopPublicationCard}
                showDeleteButton={canEditOrDeleteCard()}
                onClickEdit={onClickEditHandle}
                onClickStopPublication={onClickStopPublicationHandle}
                onClickDelete={onClickDeleteHandle}/>

            <SiblingRowDashed>
                <Col xl={6} md={6} sm={6} xs={12} className="text-center"><h2 className="custom-word-break">{entity.titleFR}</h2></Col>
                <Col xl={6} md={6} sm={6} xs={12} className="text-center"><h2 className="custom-word-break">{entity.titleEN}</h2></Col>
            </SiblingRowDashed>

            <SiblingRowDashed>
                <ColDashed>
                    <CenteredComponent>
                        <BoxHeaderDashed>Contenu FR</BoxHeaderDashed>
                        <BoxTextDashed>{entity.longTextFR}</BoxTextDashed>
                    </CenteredComponent>
                </ColDashed>
                <ColDashed>
                    <CenteredComponent>
                        <BoxHeaderDashed>Contenu EN</BoxHeaderDashed>
                        <BoxTextDashed>{entity.longTextEN}</BoxTextDashed>
                    </CenteredComponent>
                </ColDashed>
            </SiblingRowDashed>

            <SiblingRowDashed>
                <ColDashed>
                    <CenteredComponent>
                        <ChronoIcon isReadOnly url={entity.iconUrl} isChecked={false} onChange={undefined} name={"icon"} />
                    </CenteredComponent>
                </ColDashed>
                <ColDashed>
                    <CenteredComponent>
                        <BoxHeaderDashed>DÉBUT</BoxHeaderDashed>
                        <BoxTextDashed>{getFormattedDate(entity.activationDate ? entity.activationDate : entity.creationDate, DATE_FORMAT)}</BoxTextDashed>
                        <BoxTextDashed>{getFormattedDate(entity.activationDate ? entity.activationDate : entity.creationDate, TIME_FORMAT)}</BoxTextDashed>
                    </CenteredComponent>
                </ColDashed>
                <ColDashed>
                    <CenteredComponent>
                        <BoxHeaderDashed>FIN</BoxHeaderDashed>
                        <BoxTextDashed>{getFormattedDate(entity.expirationDate, DATE_FORMAT)}</BoxTextDashed>
                        <BoxTextDashed>{getFormattedDate(entity.expirationDate, TIME_FORMAT)}</BoxTextDashed>
                    </CenteredComponent>
                </ColDashed>
            </SiblingRowDashed>

            {entity.actionUrlFR && entity.actionUrlEN &&
                <SiblingRowDashed>
                    <ColDashed>
                        <CenteredComponent>
                            <BoxHeaderDashed>Url FR</BoxHeaderDashed>
                            <BoxTextDashed>{entity.actionUrlFR}</BoxTextDashed>
                        </CenteredComponent>
                    </ColDashed>
                    <ColDashed>
                        <CenteredComponent>
                            <BoxHeaderDashed>Url EN</BoxHeaderDashed>
                            <BoxTextDashed>{entity.actionUrlEN}</BoxTextDashed>
                        </CenteredComponent>
                    </ColDashed>
                </SiblingRowDashed>
            }
        </Container>
    )
};

export default CarteSummary;