import React from 'react';

const SelectAchalandageStatus = (props) => {

    const onChangeValue = (event) => {
        if(props.onChange)
            props.onChange(event.target.value, props.rowId);
    }
    return (
        <select disabled={props.disabled ? true : undefined} className="custom-select-table" style={{ width: '100%', height: '25px' }} value={props.value} onChange={onChangeValue}>
            <option key="emptyChoice" value="" />
            {
                props.data && props.data.length > 0 &&
                props.data.map((icone) =>
                    <option key={icone.value} value={icone.value}>{icone.label}</option>
                )
            }
        </select>
    );
};

export default SelectAchalandageStatus;
