export const debounce = (func, wait) =>{
  let timeout;
    return function (context:any) {
    const args = arguments;
    const later = function() {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};