import { useContext, useState, useEffect } from "react";
import { useApiRequest } from "../../redux/hooks/useApiRequest";
import moment from 'moment';
import { NotificationForm } from "../../types/forms/notificationForms";
import { NotificationParamViewModel, NotificationViewModel } from "../../types/viewmodels/notificationViewModel";
import { CommunicationStatus } from "../../types/entities/communicationEntities";
import ChronoContext from "../../context/ChronoContext";
import useUI from "../../lib/hooks/useUI";
import * as config from "../../components/communication/notification/NotificationConstants";
import { DATE_FORMAT } from "../../components/communication/CommunicationUtils";
import { CODENAME_STATUS_PUBLISHED, CODENAME_STATUS_CREATED } from "../../statusUtils";
import { Status } from "../../types/enum/Status";
import { useCacheService } from "../../redux/hooks/useCacheService";
import { Categories } from '../../redux/keys/generic/types';
import { TYPE_REQUEST_PUBLISH } from "../../components/communication/CommunicationFormActionButtons";
import {
    LABEL_STATUS_CREATED,
    LABEL_STATUS_PUBLISHED,
    LABEL_STATUS_SENT,
} from "../../components/communication/notification/NotificationConstants";


export const useNotificationService = () => {

    //states
    const [isProcessingSave, setIsProcessingSave] = useState(false);
    const [isErrorSave, setIsErrorSave] = useState(false);
    const [isSuccessSave, setIsSuccessSave] = useState(false);
    const [dataSave, setDataSave] = useState(null);
    const searchParams = useUI(config.SEARCH_PARAM_KEY);
    const [isNewNotification, setIsNewNotification] = useState(false);

    //hook
    const context = useContext(ChronoContext);
    const requestNotificationList = useApiRequest(config.GET_NOTIFS);
    const requestNotificationSave= useApiRequest(config.SAVE_NOTIF);
    const requestNotificationDeleteById = useApiRequest(config.DELETE_NOTIF_BY_ID);
    const requestNotificationPublish = useApiRequest(config.PUBLISH_NOTIF);
    const requestNotificationUnpublish = useApiRequest(config.UNPUBLISH_NOTIF);
   const cacheService = useCacheService();

    // hook for save
    useEffect(() => {
        if (requestNotificationSave.isFail === true && isProcessingSave === true) {
            setIsErrorSave(true);
            setIsProcessingSave(false);
        }
    }, [requestNotificationSave.isFail])

    useEffect(() => {
        if (requestNotificationSave.isSuccess === true && isProcessingSave === true) {
            requestNotificationList.actions.process(searchParams);
        }
    }, [isNewNotification, requestNotificationSave.isSuccess])

    useEffect(() => {
        if (!requestNotificationList.isLoading && isProcessingSave === true && requestNotificationSave.isSuccess === true) {
            setIsSuccessSave(true);
            setIsProcessingSave(false);
            setDataSave(requestNotificationSave.result.data);
        }
    }, [requestNotificationList.isLoading])

    //forms parameters
    const defaultValues = { status: [], startDate: moment().add(-15, 'd'), endDate: moment(), filterByDateType: '2' }

    //methods
    const mapToParam = (values: any, statusList: Array<CommunicationStatus>): NotificationParamViewModel => {
        const param = {
            status: statusList ? statusList.filter(x => x.value != null).map(x => x.value as unknown as Status) : [],
            startDate: values.startDate && values.startDate != null ? moment(values.startDate).utc().format(DATE_FORMAT) : values.startDate,
            endDate: values.endDate && values.endDate != null ? moment(values.endDate).utc().format(DATE_FORMAT + ' 23:59:59') : values.endDate,
            filterByDateType: values.filterByDateType
        };

        cacheService.Save(param, config.SEARCH_PARAM_KEY, Categories.UI);
        return param as NotificationParamViewModel;
    }

    const mapToModel = (model: NotificationForm, values? :NotificationForm, requestType?: number): NotificationViewModel => {
        const status = context.staticData.status;
        let entity = model as NotificationViewModel;

        if (values) {
            Object.keys(values).forEach((p) => {
                if (entity.hasOwnProperty(p))
                    entity[p] = values[p];
            });
            entity.startDate = moment(moment(model.startDate).format(DATE_FORMAT) + ' ' + model.startTime).toDate();
        }

        entity.statusCode = requestType === TYPE_REQUEST_PUBLISH ? status[CODENAME_STATUS_PUBLISHED] : status[config.CODENAME_STATUS_CREATED];
        entity.isPublished = (requestType === TYPE_REQUEST_PUBLISH ? true : false);

        return entity as NotificationViewModel;
    }

    const mapToForm = (model: NotificationViewModel): NotificationForm => {
        return { ...model, startTime: moment(model.startDate).format("HH:mm")} as NotificationForm
    }

    const isNotificationPublished = (notification: any): boolean => {
        const status = context.staticData.status;
        return !(notification.statusCode < status[CODENAME_STATUS_PUBLISHED] || notification.id === 0);
    }

    const getNewNotification = (): any => {
        return config.EMPTY_NOTIFICATION as NotificationForm
    }

    const clearCacheOperations = (id: any): void => {
        cacheService.Remove(config.DELETE_NOTIF_BY_ID + id, Categories.REQUEST);
        cacheService.Remove(config.SAVE_NOTIF + id, Categories.REQUEST);
        cacheService.Remove(config.PUBLISH_NOTIF + id, Categories.REQUEST);
        cacheService.Remove(config.UNPUBLISH_NOTIF + id, Categories.REQUEST);
    }

    const save = (model: NotificationViewModel): void => {
        clearCacheOperations(model.id);
        if (model.id === 0) {
            setIsNewNotification(model.id === 0);
            setIsProcessingSave(true);
        }
        requestNotificationSave.actions.process(model, { id: model.id }, { id: model.id });
    }

    const publish = (model: NotificationViewModel): void => {
        clearCacheOperations(model.id);
        requestNotificationPublish.actions.process(model, { id: model.id }, { id: model.id });
    }

    const unpublish = (model: NotificationViewModel): void => {
        clearCacheOperations(model.id);
        requestNotificationUnpublish.actions.process('', { id: model.id }, { id: model.id });
    }

    const deleteById = (id?: number, keySuffix?: any): void => {
        clearCacheOperations(id);
        requestNotificationDeleteById.actions.process('', { id: id }, { id: id, keySuffix: keySuffix });
    }

    const updateModel = (model: NotificationViewModel): void => {
        if (!model)
            return;

        const index = requestNotificationList.result.data.findIndex(x => x.id === model.id);
        requestNotificationList.result.data[index] = { ...model };
    }

    const removeElement = (id?: number): void => {
        const index = requestNotificationList.result.data.findIndex(x => x.id === id);
        requestNotificationList.result.data.splice(index, 1);
    }

    const cleanCache = (): void => {
        clearCacheOperations(0);
        cacheService.Remove(config.SEARCH_PARAM_KEY, Categories.UI);
        cacheService.Remove(config.NOTIF_LIST_GET, Categories.RESOURCES);
    }

    const canStopPublication = (entity: NotificationViewModel): boolean => {
        return moment().isBefore(entity.startDate) && !!entity.isPublished;
    }
    const canEditNotification = (entity: NotificationViewModel): boolean => {
        return moment().isBefore(entity.startDate);
    }

    const getNotificationStatus = (notification, contextStatus, contextTheme) => {
        switch (notification.statusCode) {
            case contextStatus[CODENAME_STATUS_CREATED]:
                return {
                    color: contextTheme.colors.messageError,
                    message: LABEL_STATUS_CREATED,
                };
            case contextStatus[CODENAME_STATUS_PUBLISHED]:
                if (moment(notification.startDate).isAfter(moment()))
                    return {
                        color: contextTheme.colors.messageInfo,
                        message: LABEL_STATUS_PUBLISHED,
                    };
                if (moment(notification.startDate).isBefore(moment()))
                    return {
                        color: contextTheme.colors.messageInfo,
                        message: LABEL_STATUS_SENT,
                    };
                break;
            default:
                return {
                    color: contextTheme.colors.messageError,
                    message: "Undefined label",
                };
        }

        return null;
    };

    // interfaces
    return {
        request: {
            GetAll: {
                isLoading: requestNotificationList.isLoading,
                isSuccess: requestNotificationList.isSuccess,
                isFail: requestNotificationList.isFail,
                process: requestNotificationList.actions.process,
                data: requestNotificationList.result.data
            },
            Save: {
                isLoading: isProcessingSave,
                isSuccess: isSuccessSave,
                isFail: isErrorSave,
                process: save,
                data: dataSave,
                refreshState: (id) => {
                    setIsSuccessSave(false);
                    setIsErrorSave(false);
                    clearCacheOperations(id);
                }
            },
            Update: {
                isLoading: requestNotificationSave.isLoading,
                isSuccess: requestNotificationSave.isSuccess,
                isFail: requestNotificationSave.isFail,
                process: save,
                data: requestNotificationSave.result.data,
                changeResourceName: requestNotificationSave.setResource,
                getResource: requestNotificationSave.getResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                }
            },
            Delete: {
                isLoading: requestNotificationDeleteById.isLoading,
                isSuccess: requestNotificationDeleteById.isSuccess,
                isFail: requestNotificationDeleteById.isFail,
                process: deleteById,
                data: requestNotificationDeleteById.result.data,
                changeResourceName: requestNotificationDeleteById.setResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                }
            },
            Publish: {
                isLoading: requestNotificationPublish.isLoading,
                isSuccess: requestNotificationPublish.isSuccess,
                isFail: requestNotificationPublish.isFail,
                process: publish,
                data: requestNotificationPublish.result.data,
                changeResourceName: requestNotificationPublish.setResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                }
            },
            Unpublish: {
                isLoading: requestNotificationUnpublish.isLoading,
                isSuccess: requestNotificationUnpublish.isSuccess,
                isFail: requestNotificationUnpublish.isFail,
                process: unpublish,
                data: requestNotificationUnpublish.result.data,
                changeResourceName: requestNotificationUnpublish.setResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                }
            }
        },
        Filter: {
            defaultValues: defaultValues,
            searchParams: searchParams
        },
        Constantes: {
            DISPLAY_DATE_FORMAT: DATE_FORMAT
        },
        mapToParam: mapToParam,
        mapToModel: mapToModel,
        mapToForm: mapToForm,
        isNotificationPublished: isNotificationPublished,
        getNewNotification: getNewNotification,
        cleanCache: cleanCache,
        updateModel: updateModel,
        removeElement: removeElement,
        canEditNotification: canEditNotification,
        canStopPublication: canStopPublication,
        getNotificationStatus: getNotificationStatus
    };
};





