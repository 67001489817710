import React, {FC, ReactElement, useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import styled from "styled-components";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericPopup from "../../../../components/common/generic/GenericPopup";
import { MultiStep } from "../MultiStep/MultiStep";
import { LoadingIndicatorInApp } from "../../../../components/LoadingIndicator";
import "./LineSummary.css";
import PopupModal from "../../popup/PopupModal";
import Emitter from "../../../../components/common/helperEmitter";

const ChronoCard = styled(Card)`
    margin: 20px 0px;
    padding: 20px;
    width: 100%;
    border: 2px solid lightgray !important;
    border-radius: 12px !important;
    box-shadow: 0 24px 59px 0 rgba(51, 59, 69, 0.15);
`;

export const LineSummary: FC<any> = (props):ReactElement => {
    const { children, eventKey, title, valueList, height, width, isProcessing, rowRenderer, customSummary } = props;
    const [isLoadingData, setIsLoadingData] = useState<Boolean>(false);
    const [valueKeys, setValueKeys] = useState<any[]>([]);
    const [showModal, setShowModal] = useState<Boolean>(false);

    useEffect(() => {
        if (valueList && valueList.length > 0) setValueKeys(Object.keys(valueList[0]));
    }, [valueList]);

    const closeModal = () => {
        hideModal()
        Emitter.emit('CLOSE_MODAL_MULTISTEP', null);
    }

    const hideModal = () => {
        setShowModal(false);
    };

    const showPopup = () => {
        setShowModal(true);
    };


    return (
        <ChronoCard style={{ height: height, width: width }}>
            {(isLoadingData === true || isProcessing === true) && <LoadingIndicatorInApp />}
            {isLoadingData !== true && isProcessing !== true && (
                <>
                    {customSummary && valueList && React.cloneElement(customSummary, { stepsValues: valueList })}
                    {!customSummary && (
                        <table style={{ width: "100%" }}>
                            <tbody>
                                {!customSummary &&
                                    valueList &&
                                    valueList.map((element, index) => {
                                        if (element.length === 0) return null;
                                        return (
                                            <tr key={"MultiStepSummary_" + index} style={{ borderBottom: "1px solid lightgray" }}>
                                                {rowRenderer && <div>{React.cloneElement(rowRenderer, { value: element })}</div>}
                                                {!rowRenderer &&
                                                    valueKeys.map((key, indexKey) => {
                                                        return (
                                                            <td
                                                                key={"td_" + indexKey}
                                                                style={{
                                                                    paddingLeft: "15px",
                                                                    paddingTop: "7px",
                                                                    paddingBottom: "2px",
                                                                    width: indexKey === 0 ? "60%" : "20%",
                                                                }}
                                                            >
                                                                {element[key] &&
                                                                    Array.isArray(element[key]) &&
                                                                    element[key].map((itm, ind) => (
                                                                        <Button
                                                                            className="btn btn-primary"
                                                                            style={{
                                                                                cursor: "default",
                                                                                border: "none",
                                                                                marginRight: "5px",
                                                                                marginBottom: "5px",
                                                                                backgroundColor: itm.color,
                                                                                color: "white",
                                                                            }}
                                                                            disabled
                                                                            key={"ChronoBadge_" + ind}
                                                                        >
                                                                            {itm.value}
                                                                        </Button>
                                                                    ))}
                                                                {element[key] && Array.isArray(element[key]) === false && (
                                                                    <Button
                                                                        className="btn btn-primary"
                                                                        style={{
                                                                            cursor: "default",
                                                                            border: "none",
                                                                            marginBottom: "5px",
                                                                            backgroundColor: element[key].color,
                                                                            color: "white",
                                                                        }}
                                                                        disabled
                                                                    >
                                                                        {element[key].value}
                                                                    </Button>
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    )}

                    <div>
                        <Button style={{ marginLeft: "10px" }} onClick={showPopup}>
                            {valueKeys && valueKeys.length > 0 ? (
                                <span>
                                    Modifier <FontAwesomeIcon icon={faPen} />
                                </span>
                            ) : (
                                <span>
                                    Ajouter <FontAwesomeIcon icon={faPlus} />
                                </span>
                            )}
                        </Button>
                    </div>
                </>
            )}
            <PopupModal title={title} show={showModal} showFooter={false}  cssClass={"js-popup-multi-step"} onHide={closeModal}>
                <MultiStep
                    key={eventKey}
                    onSave={hideModal}
                    onCancel={hideModal}
                    valueList={valueList}
                    multiStepkey={eventKey}
                    showSave={true}
                    showCancel={true}
                    nextButtonTitle={"Continuer"}
                    cancelButtonTitle={"Annuler"}
                    saveButtonTitle={"Enregistrer"}
                    previousButtonTitle={"Retour"}
                    sendEvents={false}
                >
                    {children}
                </MultiStep>
            </PopupModal>
        </ChronoCard>
    );
};

export default LineSummary;
