import React, { Fragment, useState } from 'react';
import { ContentBelowTopBar, TopBar } from '../common/ContentNavigation';
import { Row, Col, Button } from "react-bootstrap";
import ChronoCard from "../common/ChronoCard";
import styled from 'styled-components';
import Order from './Order';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericInput from "../common/generic/GenericInput";
import { useInput } from "../common/formEffects";


export const FormRow = styled(Row)`
    margin-top: 30px;
    margin-bottom: 20px;
`

const BilletterieCommande = () => {

    const noCommande = useInput('');
    const [validated, setValidated] = useState(true);
    const [searchParams, setSearchParams] = useState('');
    const [showOrder, setShowOrder] = useState(false);

    const onSearch = event => {
        handleEvent();
    }

    const keyPress = e => {
        if (e.charCode === 13) {
            handleEvent();
        }
    }

    function handleEvent() {
        const valide = noCommande.value.trim() !== '';

        setValidated(valide);

        if (valide) {
            const validSearchParams = `${noCommande.value.trim()}`;
            setSearchParams(validSearchParams);
            setShowOrder(true);
        }
    }

    return (
        <Fragment>
            <TopBar title="Commande" />
            <ContentBelowTopBar>
                <ChronoCard>
                    <FormRow style={{ marginTop: 10 }}>
                        <Col xl={7} md={12}>

                            <GenericInput onKeyPress={keyPress} label='Numéro commande *' placeholder="1594302343-243" maxLength="50" {...noCommande} format='format: 1594302343-243' feedback='Veuillez saisir un numéro de commande' invalid={!validated} />
                           
                        </Col>

                        <Col xl={3} md={12} style={{ paddingLeft: 0 }}>
                            <Button onClick={onSearch} >
                                <FontAwesomeIcon icon={faSearch} /> Rechercher
                            </Button>
                        </Col>
                        
                    </FormRow>
                   
                </ChronoCard>
                {
                    showOrder &&
                    <Order searchParams={searchParams} validated={validated} />
                }              
            </ContentBelowTopBar>
        </Fragment>
    )
}

export default BilletterieCommande;