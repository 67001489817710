/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Range } from "rc-slider";
import styled, { ThemeContext } from "styled-components";
import "rc-slider/assets/index.css";

const FloatingRight = styled.span`
    float: right;
    font-size: 0.8em;
`;

const FloatingLeft = styled.span`
    float: left;
    font-size: 0.8em;
`;

const getFormattedDate = (date) => {
    const options = {year: 'numeric', month: 'long', day: 'numeric'};
    return date.toLocaleDateString('fr', options)
};

const SliderDates = ({minDate, maxDate, onNewDates}) => {
    const theme = useContext(ThemeContext);
    const [sliderDates, setSliderDates] = useState({startDate: minDate, endDate: maxDate});

    const gap = (1000 * 60 * 60 * 24);
    const stepNumber = Math.round((maxDate - minDate) / gap);

    const currentMin = Math.round((sliderDates.startDate - minDate) / gap);
    const currentMax = Math.round((sliderDates.endDate - minDate) / gap);

    const onChange = ([minValue, maxValue]) => {
        const startDate = new Date(minDate.getTime());
        const endDate = new Date(minDate.getTime());

        startDate.setDate(minValue + 1);
        endDate.setDate(maxValue + 1);

        setSliderDates({startDate, endDate});
    };

    useEffect(() => {
        onNewDates(sliderDates);
    }, [sliderDates]);

    return (
        <div>
            <Range
                style={{marginBottom: 15}}
                dots
                allowCross={false}
                handleStyle={[
                    {backgroundColor: theme.colors.secondaryDark, border: 'none', boxShadow: '0 0 5px #57c5f7'},
                    {backgroundColor: theme.colors.secondaryDark, border: 'none', boxShadow: '0 0 5px #57c5f7'}
                ]}
                trackStyle={[
                    {backgroundColor: theme.colors.secondary, height: 10, marginLeft: 0, marginTop: -3}
                ]}
                dotStyle={{borderColor: theme.colors.darkGray}}
                activeDotStyle={{borderColor: theme.colors.secondary}}
                railStyle={{backgroundColor: theme.colors.lightGray, border: 'none'}}
                max={stepNumber}
                value={[currentMin, currentMax]}
                onChange={onChange}
            />

            <FloatingLeft>
                {sliderDates.startDate ?
                    getFormattedDate(sliderDates.startDate) : getFormattedDate(minDate)}
            </FloatingLeft>
            <FloatingRight>
                {sliderDates.endDate ?
                    getFormattedDate(sliderDates.endDate) : getFormattedDate(maxDate)}
            </FloatingRight>
        </div>
    );
};

export default SliderDates;
