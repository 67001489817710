import moment from "moment";
import { MessageForm } from "types/forms/MessageForms";



export const EMPTY_MESSAGE: MessageForm = {
    id: 0,
    statusCode: undefined,
    contentFR: "",
    contentEN: "",
    urlFR: "",
    urlEN: "",
    colors: {
        fontColor: "",
        backgroundColor: "",
    },
    startDate: moment().toDate(),
    startTime: moment().add(10, "minute").format("HH:mm"),
    endDate: moment().add(1, "days").toDate(),
    endTime: moment().add(10, "minute").format("HH:mm"),
    agencyCodeList: [],
    routeList: [],
    arretList: [],
    isVisibleInFeedOnly: true
};


export const SEARCH_PARAM_KEY = "searchParamsMessage";
export const SELECTED_MESSAGE = "SelectedMessage";
export const DELETE_MESSAGE_BY_ID = "deleteMessageById";
export const SAVE_MESSAGE = "saveMessage";
export const GET_MESSAGES = "getMessages";
export const GET_MESSAGE_BY_ID = "getMessageById";
export const GET_MESSAGES_STORE_KEY = "messages";
export const APPROVE_MESSAGE = "approveMessage";
export const PUBLISH_MESSAGE = "publishMessage";
export const REFUSE_MESSAGE = "refuseMessage";
export const EXPIRE_MESSAGE = "expireMessage";

