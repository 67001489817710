import React, {useState, useEffect} from 'react';
import { TitleCard } from "../../home/cards/BaseCardHome";
import ChronoXYPlot from "../../common/ChronoXYPlot";
import { StatsCard, StatsContainer } from "../global/StatsComponents";
import DiscreteColorLegend from 'react-vis/dist/legends/discrete-color-legend';
import { sendGetRequest } from "../../../auth/apiEffects";
import { LoadingIndicator } from "../../LoadingIndicator";
import moment from 'moment';

const translateData = (dataList) => {
    if (dataList) {
        return dataList
            .map((d) => ({
                x: d.routeShortName,
                y: d.amountFavorite
            }))
            .sort((a, b) => parseInt(a.x, 10) - parseInt(b.x, 10));
    }

    return [];
};

const StatsRouteShown = ({ filterAgency, onClickSelectBar, onClickUnselectBar, currentDates, isUserHavingFavoriteMode}) => {
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const initFavoris = {
        organisation: '',
        routeID: '',
        routeNomCourt: '',
        arretID: '',
        arretCode: '',
        direction: ''
    };

    const [favorisList, setFavorisList] = useState<any>(initFavoris);
    const [translatedFavorisList, setTranslatedFavorisList] = useState<any>();
    const title = (isUserHavingFavoriteMode ? 'Utilisateurs ayant un favoris' : 'Ajouts de favoris') + ' par ligne';
    const ITEMS = isUserHavingFavoriteMode ? ['Actifs'] : ['Nb favoris']

    useEffect(() => {
        setIsDataLoaded(false);

        async function fetchData() {
            const favorisList = await sendGetRequest(`/statistics/favoriteAmountByRoute?organizationCode=${filterAgency}
                                                 &startDate=${moment(currentDates.startDate).format("YYYY-MM-DD HH:mm")}
                                                 &endDate=${moment(currentDates.endDate).format("YYYY-MM-DD HH:mm")}
                                                 &userHavingFavorite=${isUserHavingFavoriteMode}`
            );

            setFavorisList(favorisList);
            setIsDataLoaded(true)
        }
        fetchData();
    }, [currentDates, filterAgency, isUserHavingFavoriteMode]);

    useEffect(() => {
        if (favorisList.length > 0) {
            setTranslatedFavorisList(translateData(favorisList));
        }
    }, [favorisList])

    const getCsvData = (dataList) => {
        let results: { Organisation: string; Route: string; 'Nombre de favoris': string; }[] = [];
        dataList.forEach(row => {
            results.push(
                {
                    Organisation: filterAgency,
                    Route: row.routeShortName,
                    'Nombre de favoris': row.amountFavorite
                });
        });

        return results;
    }

    return (
        <StatsContainer>
            <StatsCard>
                <TitleCard>{title}</TitleCard>

                <DiscreteColorLegend height={60} width={100} items={ITEMS} style={{marginBottom: -10}} />
                {
                    !isDataLoaded ? <LoadingIndicator style={{marginTop: -200}} /> :
                        translatedFavorisList.length > 0 ? <ChronoXYPlot data={translatedFavorisList} csvData={getCsvData(favorisList)} csvFileName={'statsRoutes'} isAxisYFixed={true} showExport={true} margin={{ left: 50, bottom: 50 }} onClickSelectBar={onClickSelectBar} onClickUnselectBar={onClickUnselectBar} tickLabelAngle={translatedFavorisList.length > 25 ? -60 : 0} />
                    : <h3 style={{margin: 'auto'}}>Aucun résultat</h3>
                }
            </StatsCard>
        </StatsContainer>
    );
};

export default StatsRouteShown;
