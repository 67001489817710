import React, { useEffect } from "react";
import CommunicationActionButtonGrid from "../../CommunicationActionButtonGrid";
import { useCommunicationEvent } from "../../communicationEvent";
import { MessageButtonGridProps } from "../../../../types/components/MessageComponent";
import { useMessageService } from "service/communication/useMessageService";
import moment from "moment";

export const MessageActionsButtonGrid: React.FC<MessageButtonGridProps> = ({
    data,
    onRefresh,
}: MessageButtonGridProps): React.ReactElement => {
    const messageService = useMessageService();

    useEffect(() => {
        messageService.request.Publish.changeResourceName({ id: data.id });
        messageService.request.Delete.changeResourceName({ id: data.id });
    }, []);

    //handle event publish notif
    useCommunicationEvent(
        {
            successCallback: () => {
                messageService.updateModel(messageService.request.Publish.data);
            },
            // afterPost: () => {
            //     messageService.request.Publish.refreshState(data.id);
            // },
        },
        messageService.request.Publish.isSuccess,
        messageService.request.Publish.isFail
    );
    //handle event delete notif
    useCommunicationEvent(
        {
            successCallback: () => messageService.removeElement(data.id),
            afterPost: () => {
                messageService.request.Delete.refreshState(data.id);
                if (onRefresh) onRefresh();
            },
        },
        messageService.request.Delete.isSuccess,
        messageService.request.Delete.isFail
    );

    return (
        <div id={"containerButonGrid" + data.id} className="com-container">
            <CommunicationActionButtonGrid
                isProcessing={
                    messageService.request.Publish.isLoading ||
                    messageService.request.Refuse.isLoading ||
                    messageService.request.Approve.isLoading ||
                    messageService.request.Delete.isLoading
                }
                disabled={messageService.isMessagePublished(data) && !(data && data.startDate ? moment().isBefore(moment(data.startDate)) : false)}
                hideApprove={true}
                hidePublish={false}
                hideRefuse={true}
                canPublish={messageService.canEditMessage(data)}
                onClickSend={(requestType) => {
                    console.log(requestType);
                    console.log(data);
                    const model = messageService.mapToModelForUpdate(data, requestType);
                    messageService.request.Publish.process(model);
                }}
                onClickDelete={() => {
                    messageService.request.Delete.process(data.id);
                }}
                data={data}
            />
        </div>
    );
};
