import React, { useContext} from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import ChronoBadge from "../../../common/ChronoBadge";
import * as calendarService from "../../../../service/calendarService";
import ChronoContext from "../../../../context/ChronoContext";
import { ROUTE_COMMUNICATION_MESSAGES } from "../../../../routes";
import { useHistory } from "react-router-dom";

const CalendarMessageForm = ({ messageList }) => {
    const staticData = useContext(ChronoContext).staticData;
    const agencies = staticData.user.agencies;
    const history = useHistory();

    return (
        <div>
            {
                messageList && messageList.length > 0  &&
                <div className='list-group calendar-message-form-container'>
                    {
                        messageList.map((message, i) => {
                            const title = '#' + message.id;
                            const subTitle = moment(message.startDate).format("YYYY-MM-DD") + ' - ' + moment(message.endDate).format("YYYY-MM-DD");
                            const badge = calendarService.getBadge(message, agencies);
                            return (
                                <a key={'calendar_Form_' + i}  onClick={() => {
                                    history.push(ROUTE_COMMUNICATION_MESSAGES(message.id));
                                }} className="list-group-item list-group-item-action flex-column align-items-start calendar-cursor" >
                                    <div className="d-flex w-100 justify-content-between">
                                        <small className="mb-0 calendar-popup-bold calendar-padding-left-13">{title}</small>
                                        <small className='calendar-popup-bold'>{subTitle}</small>
                                    </div>
                                    <Row className='calendar-padding-top-5'>
                                        <Col xs={3} sm={2} md={1} className='calendar-margin-auto'>
                                            <ChronoBadge color={badge?.backgroundColor} code={badge?.label} textSize={'0.7em'} />
                                        </Col>
                                        <Col xs={9} sm={10} md={11}>
                                            <div className='ml-4 calendar-popup-message'>
                                                {message.title}
                                            </div>
                                        </Col>
                                    </Row>
                                </a>
                            )
                        })
                    }
                    
                </div>
            }
            {
                messageList.length === 0 &&
                <div className='calendar-popup-empty'>
                    Aucun message
                </div>

            }
        </div>
    )
}

export default CalendarMessageForm