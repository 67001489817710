// @ts-nocheck
import React, { FC, Fragment, useContext, useEffect, useState, SyntheticEvent } from "react";
import { InputGroup, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { ThemeContext } from "styled-components";
import moment from "moment";

export const CustomDatePicker = styled(DatePicker)`
    height: 100%;
    padding: 10px 10px 0px 10px;
    border: none;
    width: inherit;
    cursor: pointer;
    background-color: ${(props) => (props && props.theme && props.theme.colors && props.disabled ? props.theme.colors.invalidInput : null)};
`;

interface FormDatePickerProps {
    isDisabled?: boolean;
    minDate?: Date | null;
    maxDate?: Date | null;
    isRequired?: boolean;
    onDateChange?: (date: Date | [Date | null, Date | null] | null, event: SyntheticEvent<any, Event> | undefined) => void;
    onInputChange?: (event: React.FocusEvent<HTMLInputElement>) => void;
    date?: string;
    invalid?: any;
    validated?: any;
    canUserEdit?: boolean;
    isClearable?: boolean;
    cssClass?: string;
}

export const FormDatePicker: FC<FormDatePickerProps> = ({
    isDisabled,
    minDate,
    maxDate,
    isRequired,
    onDateChange,
    onInputChange,
    date,
    invalid,
    validated,
    canUserEdit = true,
    isClearable = false,
    cssClass = "",
}: FormDatePickerProps) => {
    const theme = useContext(ThemeContext);
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        if (!date) setSelectedDate(new Date());
        else setSelectedDate(new Date(moment(date).year(), moment(date).month(), moment(date).date()));
    }, [date]);

    const invalidStyling = invalid
        ? { border: "1px solid #dc3545", padding: 0, backgroundColor: "" }
        : validated && !invalid
        ? { border: "1px solid #28a745", padding: 0 }
        : { padding: 0 };
    if (!canUserEdit && theme && theme.colors) invalidStyling.backgroundColor = theme.colors.invalidInput;

    return (
        <Form.Control as="div" style={invalidStyling}>
            <CustomDatePicker
                disabled={isDisabled || !canUserEdit}
                required={isRequired}
                onChange={onDateChange}
                onChangeRaw={onInputChange}
                value={selectedDate}
                className={cssClass}
                maxDate={maxDate}
                minDate={minDate}
                openToDate={selectedDate}
                selected={selectedDate}
                isClearable={isClearable}
                popperModifiers={{
                    preventOverflow: {
                        enabled: true,
                    },
                }}
            />
        </Form.Control>
    );
};

interface GenericDateProps {
    date?: string;
    label: string;
    isDisabled?: boolean;
    minDate?: Date | null;
    maxDate?: Date | null;
    isRequired?: boolean;
    onDateChange?: (date: Date | [Date | null, Date | null] | null, event: SyntheticEvent<any, Event> | undefined) => void;
    onInputChange?: (event: React.FocusEvent<HTMLInputElement>) => void;
    width: string | number;
    isInputGroup?: boolean;
    invalid?: any;
    validated?: any;
    canUserEdit?: boolean;
    isClearable?: boolean;
    cssClass?: string;
}

const GenericDate = ({
    date,
    label,
    isDisabled = false,
    minDate,
    maxDate,
    isRequired,
    onDateChange,
    onInputChange,
    width = "auto",
    isInputGroup = true,
    invalid,
    validated,
    canUserEdit,
    isClearable,
    cssClass = "",
}: GenericDateProps) => {
    const requiredLabel = isRequired ? " *" : "";

    const input = (
        <Fragment>
            <InputGroup.Prepend>
                <InputGroup.Text style={{ width: width }}>{label + requiredLabel}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormDatePicker
                isDisabled={isDisabled}
                isRequired={isRequired}
                onDateChange={onDateChange}
                onInputChange={onInputChange}
                date={date}
                invalid={invalid}
                validated={validated}
                minDate={minDate}
                maxDate={maxDate}
                canUserEdit={canUserEdit}
                isClearable={isClearable}
                cssClass={cssClass}
            />
        </Fragment>
    );

    if (isInputGroup) {
        return <InputGroup>{input}</InputGroup>;
    } else {
        return input;
    }
};

export default GenericDate;
