/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Form, Card, Collapse, Button } from "react-bootstrap";
import requestApplicationsLogs from "../../../redux/actions/applicationLogs/requestApplicationsLogs";
import { getMobileConfigurationListItems } from "../../../redux/actions/applicationLogs/requestConfigurationLogs";
import { FormRow, Dropdown, InputDate, ErrorMessage } from "../../../lib/ui";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import * as keys from "../../../redux/keys";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPlus, faMinus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { OS_OPTIONS, CRITICALITY_OPTIONS } from "../constantes";
import "../../../styles/components/monitoring/applicationLogs.scss";
import AutoComplete from "../../common/autocomplete";
import SelectLastReceptionDate from "../../common/generic/select/SelectLastReceptionDate";
import addUIItem from "../../../redux/actions/addUIItem";
import useUI from "../../../lib/hooks/useUI";
import { convertDateToEasterTime } from "../../common/helper";
import useResources from "lib/hooks/useResources";
import GenericDate from 'components/common/generic/GenericDate';

const DISPLAY_DATE_FORMAT = "YYYY-MM-DD";

const validationSchema = yup.object().shape({
    startDate: yup.date().required(),
    endDate: yup
        .date()
        .required()
        .test("is-greater", "La date de fin ne peut être antérieure", function (value) {
            const { startDate } = this.parent;
            return moment(value).isSameOrAfter(startDate);
        }),
});

const SERVER_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

interface IApplicationLogsFilterParams {
    logdateFilter: string;
    startDate: string;
    endDate: string;
    logType: string;
    os: string;
    category: string;
    criticality: string;


}
const ApplicationLogsFilters = () => {
    const [collapse, setCollapse] = useState(false);

    const params = useUI(keys.LOG_SUMMARY_PARAMS) as IApplicationLogsFilterParams;
    const [isCustomLogDateFilter, setIsCustomLogDateFilter] = useState(params != undefined && params.logdateFilter != undefined && params.logdateFilter === "0");

    const elementSelected = useUI(keys.SELECTED_APPLICATION_LOG_INDEX) as { selectedIndex: number };

    const formDefaultConfig = {
        defaultValues: {
            startDate: params && params.startDate ? convertDateToEasterTime(params.startDate, SERVER_DATE_FORMAT) : moment().subtract(1, "hour").format(SERVER_DATE_FORMAT),
            endDate: params && params.endDate ? convertDateToEasterTime(params.endDate, SERVER_DATE_FORMAT)  : moment().format(SERVER_DATE_FORMAT),
            logType: params && params.logType ? params.logType : "",
            os: params && params.os ? params.os : "",
            category: params && params.category ? params.category : "",
            logdateFilter: params && params.logdateFilter ? params.logdateFilter : "1h",
            criticality: params && params.criticality ? params.criticality : "",
        },
        reValidateMode: "onChange",
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    };

    const { handleSubmit, watch, errors, control, setValue, setError, getValues, } = useForm(formDefaultConfig as any);

    const values = watch();
    useEffect(()=> {
        const formValues = getValues();
        if(formValues.startDate && formValues.endDate) {
            if (moment(formValues.endDate).isSameOrAfter(formValues.startDate)) {
                setError('endDate', { message: '' });
            } else {
                setError('endDate', { message: 'La date de fin ne peut être antérieure' });
            }
        }
    }, [values.startDate, values.endDate])

    const dispatch = useDispatch();
    const categories = useResources(keys.APPLICATION_LOG_CONFIGURATION_TYPE) as { label: string; value: string; }[];

    useEffect(() => {
        if (elementSelected && elementSelected.selectedIndex >= 0) return;

        dispatch(
            getMobileConfigurationListItems(keys.APPLICATION_LOG_CONFIGURATION_TYPE, {
                propertyName: "Category",
                criteria: "",
                resourceName: keys.APPLICATION_LOG_CONFIGURATION_TYPE,
            })
        );

        const data = {
            ...values,
            startDate: moment(values.startDate).utc().format(SERVER_DATE_FORMAT),
            endDate: moment(values.endDate).utc().format(
                isCustomLogDateFilter ? "YYYY-MM-DD 23:59:59" : SERVER_DATE_FORMAT
            ),
        };

        dispatch(requestApplicationsLogs(data));
    }, []);

    const onChangeLastReceptionDate = (obj) => {
        setValue("startDate", obj && obj.startDate ? obj.startDate : moment().format(DISPLAY_DATE_FORMAT));
        setValue("endDate", obj && obj.endDate ? obj.endDate : moment().format(DISPLAY_DATE_FORMAT));
        setValue("logdateFilter", obj && obj.value ? obj.value : "1h");
        setIsCustomLogDateFilter(obj.value === "0");
    };

    return (
        <Fragment>
            <Card className={`mt-4`}>
                <Card.Header
                    onClick={() => {
                        setCollapse(!collapse);
                    }}
                >
                    <span className={`filterCardHeaderTitle`}>
                        <FontAwesomeIcon icon={faFilter} /> Filtres
                    </span>
                    <Button
                        variant="light"
                        style={{ float: "right", marginTop: "-8px" }}
                        onClick={() => {
                            setCollapse(!collapse);
                        }}
                    >
                        <FontAwesomeIcon icon={collapse ? faPlus : faMinus} />
                    </Button>
                </Card.Header>
                <Collapse in={!collapse}>
                    <Card.Body>
                        <Row>
                            <Col>
                                <Form
                                    onSubmit={handleSubmit((data) => {
                                        data = {
                                            ...data,
                                            startDate: moment(data.startDate).utc().format(SERVER_DATE_FORMAT),
                                            endDate: moment(data.endDate).utc().format(
                                                isCustomLogDateFilter ? "YYYY-MM-DD 23:59:59" : SERVER_DATE_FORMAT
                                            ),
                                        };
                                        dispatch(addUIItem(data, keys.LOG_SUMMARY_PARAMS));

            
                                        dispatch(requestApplicationsLogs(data));
                                    })}
                                >
                                    <FormRow className={`mb-0`}>
                                        <Col xl={3} lg={5} md={11} className={`p-0 ml-3`}>
                                            <Controller
                                                control={control}
                                                name="category"
                                                value={values.categories}
                                                render={({ onChange, value }) => {
                                                    return (
                                                        <Dropdown
                                                            mode="select"
                                                            label="Categories"
                                                            labelWidth={10}
                                                            defaultValue={""}
                                                            selectedValue={value}
                                                            options={
                                                                categories && [
                                                                    { label: "Toutes les catégories", value: "" },
                                                                    ...categories,
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                setValue("category", e.target.selectedOptions[0].value)
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </Col>

                                        <Col xl={2} lg={5} md={11} className={`p-0 ml-3 mt-lg-0 mt-md-4`}>
                                            <Controller
                                                control={control}
                                                name="os"
                                                value={values.os}
                                                render={({ onChange, value }) => {
                                                    return (
                                                        <Dropdown
                                                            mode="select"
                                                            label="OS"
                                                            labelWidth={10}
                                                            defaultValue={""}
                                                            selectedValue={value}
                                                            options={OS_OPTIONS}
                                                            onChange={(e) => setValue("os", e.target.selectedOptions[0].value)}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col xl={3} lg={5} md={11} className={`p-0 ml-3 mt-xl-0 mt-lg-4 mt-lg-0  mt-md-4`}>
                                            <Controller
                                                control={control}
                                                name="logType"
                                                value={values.logType}
                                                render={({ onChange, value }) => {
                                                    return (
                                                        <AutoComplete
                                                            value={value}
                                                            label="ID"
                                                            onChange={onChange}
                                                            propertyname={"LogType"}
                                                            style={{ width: "30" }}
                                                            id="configurationFilter_AutoComplete"
                                                            getDropdownListItems={getMobileConfigurationListItems}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col xl={3} lg={5} md={11} className={`p-0 ml-3 mt-xl-0 mt-lg-4mt-lg-0  mt-md-4`}>
                                            <Controller
                                                control={control}
                                                name="criticality"
                                                value={values.criticality}
                                                labelWidth={10}
                                                render={({ onChange, value }) => {
                                                    return (
                                                        <Dropdown
                                                            mode="select"
                                                            label="Criticité"
                                                            defaultValue={""}
                                                            selectedValue={value}
                                                            options={CRITICALITY_OPTIONS}
                                                            onChange={(e) =>
                                                                setValue("criticality", e.target.selectedOptions[0].value)
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </FormRow>
                                    <FormRow>
                                        <Col xl={4} lg={10} md={11} className={`p-0 ml-xl-3 ml-lg-4 ml-md-3 mt-lg-0  mt-md-4`}>
                                            <Controller
                                                control={control}
                                                name="logdateFilter"
                                                render={({ onChange, value }, { invalid }) => {
                                                    return (
                                                        <SelectLastReceptionDate
                                                            value={value}
                                                            onChange={(evt) => {
                                                                onChangeLastReceptionDate(evt);
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col xl={2} lg={5} md={11} className={`p-0 mt-xl-0 mt-lg-4 ml-3 mt-md-4`}>
                                            <Controller
                                                control={control}
                                                name="startDate"
                                                defaultValue={values.startDate}
                                                render={({ onChange, value }, { invalid }) => {
                                                    return (
                                                        <GenericDate
                                                            label="Du"
                                                            date={value}
                                                            onDateChange={onChange}
                                                            width={'80'}
                                                            isDisabled={!isCustomLogDateFilter}
                                                            //format={DISPLAY_DATE_FORMAT}
                                                            //title="start date"
                                                        />
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col xl={2} lg={5} md={11} className={`p-0 mt-xl-0 mt-lg-4 ml-3 mt-md-4`}>
                                            <Controller
                                                control={control}
                                                name="endDate"
                                                defaultValue={values.endDate}
                                                render={({ onChange, value }, { invalid }) => {
                                                    return (
                                                        <GenericDate
                                                            label="Au"
                                                            date={value}
                                                            onDateChange={onChange}
                                                            width={'80'}
                                                            isDisabled={!isCustomLogDateFilter}
                                                           // format={DISPLAY_DATE_FORMAT}
                                                           // title="end date"
                                                            maxDate={new Date()}
                                                        />
                                                    );
                                                }}
                                            />
                                            {errors.endDate && <ErrorMessage>{errors.endDate.message}</ErrorMessage>}
                                        </Col>
                                        <Col xl={2} lg={2} md={11} className={`p-0 mt-xl-1 mt-lg-4 ml-3 mt-md-4 my-auto`}>
                                            <Button type="submit">
                                                <FontAwesomeIcon icon={faSearch} /> Rechercher
                                            </Button>
                                        </Col>
                                    </FormRow>
                                </Form>
                            </Col>
                        </Row>
                    </Card.Body>
                </Collapse>
            </Card>
        </Fragment>
    );
};

export default ApplicationLogsFilters;
