import { Alert } from "react-bootstrap";
import React from "react";

const AlertAfterPost = ({isStatusOk}) => {
    if (isStatusOk === undefined) {
        return null;
    } else if (isStatusOk) {
        return (
            <Alert variant="success">
                Effectué avec succès.
            </Alert>
        );
    } else {
        return (
            <Alert variant="danger">
                Erreur lors de la tentative.
            </Alert>
        );
    }
};

export default AlertAfterPost;
