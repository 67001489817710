/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Row, Col, InputGroup, FormControl, Form } from 'react-bootstrap';
import { sendPostRequest, sendGetRequest } from "../../../auth/apiEffects";
import { useInput, useInputCheckBox } from "../../common/formEffects";
import { getRoleObjectFromRoleValue } from "../../common/RoleHelper";
import { validateEmail } from "../../common/helper";
import GenericInput from "../../common/generic/GenericInput";
import { LoadingIndicatorInApp } from "../../LoadingIndicator";
import ChronoContext from "../../../context/ChronoContext";
import SelectRoles from "../../common/generic/select/SelectRoles";
import ChronoMultiSelect from "../../common/ChronoMultiSelect";
import styled from "styled-components";
import Alert from 'react-bootstrap/Alert';
import { components } from "react-select";
import { string } from 'yup/lib/locale';
import { IUserProfilePage, ISendGetRequest, IOptions, IPemissionOption, IPermission } from './UserProfileInterface'

const initUser = {
    displayName: '',
    email: '',
    role: '',
    agencies: [],
    permissions: [],
    utilisateurId: '',
};

const ErrorMessage = styled.p`
    margin-top: 0.1rem;
    padding:0px;
    font-size: 100%;
    color: #dc3545;
`
const MultiValue = ({ children, components, ...rest }) => {
    return (
        <div style={{ padding: rest.index < 4 ? '3px' :'0px' }}>
            {
                rest.index < 4 &&
                <Button className="btn btn-primary" disabled style={{ cursor: 'default' }}>
                    {rest.data.label} <FontAwesomeIcon className="js-remove-org" style={{ cursor: 'pointer' }} onMouseDown={(e) => {
                        rest.removeProps.onClick();
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    }
                    } icon={faTimesCircle} />
                </Button>

            }
            {
                rest.index >= 4 && rest.getValue().length > 0 && rest.index === rest.getValue().length-1  &&
                <Button className="btn btn-primary"  style={{ cursor: 'pointer' }}>
                    +{rest.getValue().length - 4}
                </Button>

            }
        </div>
    );
}

const CustomOptionAgency = (props) => {
    return (
      <div className={props.label === "Select all" ? 'js-dropdown-header' : ''}>
          {
              props.label === "Select all" &&
              <components.Option {...props} style={{height:'60px'}}>
                  <Button style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faCheckCircle} style={{paddingRight:'5px'}}></FontAwesomeIcon>
                      Sélectionner toutes les agences</Button>
                  </components.Option>
            }
            {
              props.label !== "Select all" &&
                <components.Option {...props}>
                    <Form.Switch
                        label={props.label}
                        checked={props.isSelected}
                        onChange={() => null} />

                    <label></label>
                </components.Option>
            }
        </div>
    )
};

const CustomOptionPermssion = (props) => {
    return (
        <div className={props.label === "Select all" ? 'js-dropdown-header' : ''}>
            {
                props.label === "Select all" &&
                <components.Option {...props} style={{ height: '60px' }}>
                    <Button style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faCheckCircle} style={{ paddingRight: '5px' }}></FontAwesomeIcon>
                      Sélectionner toutes les permissions</Button>
                </components.Option>
            }
            {
                props.label !== "Select all" &&
                <components.Option {...props}>
                    <Form.Switch
                        label={props.label}
                        checked={props.isSelected}
                        onChange={() => null} />

                    <label></label>
                </components.Option>
            }
        </div>
    )
};

const customStyles = {
    option: (styles, state) => ({
        ...styles,
        color: state.isSelected ? "#FFF" : styles.color,
        backgroundColor: state.isSelected ? "lightgray" : styles.color,
        borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
        height:'55px',
        "&:hover": {
            color: state.isSelected ? 'white': "unset",
            backgroundColor: state.isSelected ? 'lightgray' :"unset"
        }
    })
};

const NAME_MAX_LENGTH = 255;

const UserProfilePage = forwardRef(({params, onLoaded}: IUserProfilePage, ref) => {
    const staticData = useContext(ChronoContext).staticData;
    const [user, setUser] = useState<ISendGetRequest>(initUser);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [skipSetPermission, setSkipSetPermission] = useState(false);
    const [userAgencies, setUserAgencies] = useState<IOptions[]>([]);
    const [optionsAgencies, setOptionsAgencies] = useState([]);
    const [userPermissionList, setUserPermissionList] = useState<IOptions[]>([]);
    const [optionPermissionList, setOptionPermissionList] = useState<IPemissionOption[]>([]);
    const bindDisplayName = useInput(user.displayName);
    const [isValidated, setIsValidated] = useState(true);
    const bindRole = useInput(getRoleObjectFromRoleValue(staticData?.roles || [], user?.role || '')?.codename || '');
    const bindEmail = useInput('');
    const [errorMessageToDisplay, setErrorMessageToDisplay] = useState('');
    const bindFirstName = useInput('');
    const bindLastName = useInput('');

    useEffect(() => {
        setIsLoadingUser(true);

        async function fetchData() {
            if (params.id > 0) {
                const result: ISendGetRequest | undefined | false = await sendGetRequest(`/users/${params.id}`);
                if (result && result.displayName == null) result.displayName = "";
                setUser(result as ISendGetRequest);
            }

            const enhancedOptions = staticData.allAgencies.map(agency => {
                return {
                    value: agency.label,
                    label: agency.label,
                }
            });
            setOptionsAgencies(enhancedOptions)

            const permissionList = await sendGetRequest(`/users/getPermissions/${user.role}`);
            formatSetOptionPermissionList(permissionList);
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (params.id > 0 && user.email === '')
            return;

        if (user && user.email !== '') {

            const event = {
                target: {
                    value: getRoleObjectFromRoleValue(staticData?.roles || [], user?.role || '')?.codename || ''
                }
            };

            bindRole.onChange(event)

            const userAgencies = user.agencies.map(agency => {
                return {
                    value: agency.agencyCode,
                    label: agency.agencyCode,
                }
            });

            setUserAgencies(userAgencies);

            const userPermissions = user.permissions.map(permission => {
                return {
                    value: permission.permissionId,
                    label: permission.title,
                }
            });

            setUserPermissionList(userPermissions);
            setSkipSetPermission(true);
        }
        setIsLoadingUser(false);
        if (onLoaded)
            onLoaded();

    }, [user]);

    useEffect(() => {
        async function fetchRolePermission() {
            const rolePermissionList: IPermission[] | undefined | false = await sendGetRequest(`/users/getPermissions/${bindRole.value}`);

            if (rolePermissionList) {
                formatSetOptionPermissionList(rolePermissionList);

                // If the trigger was caused by the loading of a user and it is still loading or we skip the set of permission then we do so
                if (params.id > 0 && (isLoadingUser || skipSetPermission)) {
                    setSkipSetPermission(false);
                    return;
                }

                const permissionList = rolePermissionList.map(permission => {
                    return {
                        value: permission.permissionId,
                        label: permission.title,
                    }
                });

                setUserPermissionList(permissionList);
                
            }
        }

        fetchRolePermission();
    }, [bindRole.value]);

    const validateParam = (param) => {
        return  param !== '' && param.trim().length > 0;
    }

    const formatSetOptionPermissionList = (permissionList) => {
        const permissionOptionList: IPemissionOption[] = []

        if (permissionList.length > 0) {
            var groupedPermission = Object.create(null);

            permissionList.forEach(function (permission) {
                groupedPermission[permission.group] = groupedPermission[permission.group] || [];
                groupedPermission[permission.group].push(permission);
            });

            Object.keys(groupedPermission).forEach(function (group) {
                const actionBloc = {
                    label: group,
                    options: groupedPermission[group].map(permission => {
                        return {
                            value: permission.permissionId,
                            label: permission.title,
                        }
                    })
                }

                permissionOptionList.push(actionBloc)
            });

            setOptionPermissionList(permissionOptionList);
        }
    }

    useImperativeHandle(ref, () => ({

        async saveData() {
            setErrorMessageToDisplay('');
            let isModelValidated = validateParam(bindRole.value) && (userAgencies && userAgencies.length > 0);

            isModelValidated = params.id === 0 ? isModelValidated && validateParam(bindLastName.value) && validateParam(bindFirstName.value)  && validateParam(bindEmail.value) && validateEmail(bindEmail.value) 
                                                : isModelValidated && validateParam(bindDisplayName.value || user.displayName);

            if (!isModelValidated) {
                setIsValidated(isModelValidated);
                return false;
            }

            let obj: any = {
                utilisateurId: user.utilisateurId,
                name: bindDisplayName.value || user.displayName,
                role: bindRole.value,
                agencies: userAgencies.map(agency => agency.value),
                permissions: userPermissionList.map(permission => permission.value)
            };

            if (params.id === 0) {
                obj = {
                    firstName: bindFirstName.value,
                    lastName: bindLastName.value,
                    role: bindRole.value,
                    agencies: userAgencies.map(agency => agency.value),
                    permissions: userPermissionList.map(permission => permission.value),
                    courriel: bindEmail.value
                };
            }
            const url = params.id > 0 ? '/users/' + user.utilisateurId : '/users/invite/';
            const res = await sendPostRequest(url, obj);
            if ( res === false) {
                setErrorMessageToDisplay(`Une erreur est survenue durant la sauvegarde.  Veuillez contacter l'administrateur du système`);
                return false;
            }
            return true;
        }

    }));

    if (isLoadingUser) {
        return <LoadingIndicatorInApp />;
    }

    return (
        <Fragment>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Row>
                        <Col>
                            {
                                params.id == 0 &&
                                <div>
                                    <GenericInput isRequired label="Nom"
                                            value={bindLastName.value}
                                            onChange={bindLastName.onChange}
                                        maxLength={NAME_MAX_LENGTH}  />
                                    {
                                        !isValidated && !validateParam(bindLastName.value) ? <ErrorMessage>Le nom ne peut être vide</ErrorMessage> : null
                                    }
                                    <br />
                                    <GenericInput isRequired label="Prénom"
                                        value={bindFirstName.value}
                                        onChange={bindFirstName.onChange}
                                        maxLength={NAME_MAX_LENGTH} />
                                    {
                                        !isValidated && !validateParam(bindFirstName.value) ? <ErrorMessage>Le prénom ne peut être vide</ErrorMessage> : null
                                    }
                                    <br />
                                    </div>
                            }
                            
                          
                          
                            {
                                params.id > 0 &&
                                <div>
                                <GenericInput isRequired label="Nom"
                                    value={bindDisplayName.value || user.displayName}
                                    onChange={bindDisplayName.onChange}
                                        maxLength={NAME_MAX_LENGTH} />
                                    {
                                        !isValidated && !validateParam(bindDisplayName.value || user.displayName) ? <ErrorMessage>Le nom ne peut être vide</ErrorMessage> : null
                                    }
                                </div>
                            }
                            {
                                params.id > 0 && <br />
                            }
                            {
                                params.id === 0 &&
                                <div>
                                    <GenericInput isRequired label="Email" placeholder="exemple@rtm.quebec" {...bindEmail} />
                                    <small className="form-text text-muted">Le courriel doit correspondre au compte
                                            d'organisation.</small>
                                    {
                                        !isValidated && !validateParam(bindEmail.value) ? <ErrorMessage>Le courriel ne peut être vide</ErrorMessage> : null
                                    }
                                    {
                                        !isValidated && validateParam(bindEmail.value) && !validateEmail(bindEmail.value) ? <ErrorMessage>Le format courriel n'est pas valide</ErrorMessage> : null
                                    }
                                </div>
                            }
                            {
                                params.id > 0 &&
                                <GenericInput label="Courriel" value={user.email} isDisabled />
                            }

                            <br />

                            <SelectRoles isRequired {...bindRole} />
                            {
                                !isValidated && !validateParam(bindRole.value) ? <ErrorMessage>Veuillez sélectionner un rôle</ErrorMessage> : null
                            }

                            <br />

                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: '130px' }}>
                                    <InputGroup.Text style={{ minWidth: '130px' }}>Droits</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl as="div" style={{ padding: 0, display: 'inline-table', width: '75%', zIndex: 5 }}>

                                    <ChronoMultiSelect
                                        isMulti
                                        allowSelectAll
                                        value={userPermissionList}
                                        onChange={setUserPermissionList}
                                        components={{ MultiValue: MultiValue, Option: CustomOptionPermssion }}
                                        options={optionPermissionList}
                                        hasOptionWithGroup={true}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        isSearchable={false}
                                        styles={customStyles}
                                    />
                                </FormControl>
                            </InputGroup>

                            <br />

                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: '130px' }}>
                                    <InputGroup.Text style={{ minWidth: '130px' }}>Organisations</InputGroup.Text>
                                </InputGroup.Prepend>     
                                <FormControl as="div" style={{ padding: 0, display: 'inline-table', width: '75%', zIndex: 3 }}>

                                    <ChronoMultiSelect
                                        isMulti
                                        allowSelectAll
                                        value={userAgencies}
                                        onChange={setUserAgencies}
                                        components={{ MultiValue: MultiValue, Option: CustomOptionAgency }}
                                        options={optionsAgencies}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        isSearchable={false}
                                        styles={customStyles}
                                            />
                                </FormControl>
                            </InputGroup>

                            {
                                !isValidated && !(userAgencies && userAgencies.length > 0) ? <ErrorMessage>Veuillez sélectionner au moins une agence</ErrorMessage> : null
                            }

                        </Col>
                    </Row>
                    <div style={{ paddingTop: '10px' }}>
                        {
                            errorMessageToDisplay && errorMessageToDisplay.length > 0 &&
                            <Alert variant='danger'  >
                                {errorMessageToDisplay}
                            </Alert>
                        }
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
});

export default UserProfilePage;
