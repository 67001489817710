import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import * as types from "../../actions/types/types";
import * as consoleApi from "../../../api";
import addResource from "../../actions/addResource";
import addUIItem from "../../actions/addUIItem";
import * as keys from '../../keys/index';
import * as statusRequest from '../requestStatus';

export function* watchChronouserRequest() {
    yield takeEvery(types.GET_DROPDOWNLIST_EMAIL_USER_LIST, getDropDownEmailListItem);
    yield takeLatest(types.GET_CHRONO_USER_LIST_REQUESTED, getChronoUsers);
    yield takeEvery(types.ACTIVATE_CHRONO_USER_REQUESTED, activateChronoUsers);
    yield takeEvery(types.RESEND_EMAIL_CONFIRMATION_CHRONO_USER, resendEmailConfirmationChronoUser);
}


export function* getDropDownEmailListItem(action) {
    const { queryParams } = action.payload;
    const status = queryParams.resourceName + '_STATUS';

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));

        const results = yield call(
            consoleApi.getDropdownItemForUserEmailList,
            { email: queryParams.criteria}
        );

        if (!results) {
            throw new Error("Unable to get data.");
        }

        yield put(addResource(results, queryParams.resourceName));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {

        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}

export function* getChronoUsers(action) {
    const { queryParams } = action.payload;
    const status = keys.CHRONO_USERR_LIST + '_STATUS';

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));

        const list = yield call(
            consoleApi.getChronoUserList,
            queryParams
        );

        if (!list) {
            throw new Error("Unable to get the user list.");
        }

        yield put(addResource(list, keys.CHRONO_USERR_LIST));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {
        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}

export function* activateChronoUsers(action) {
    const { queryParams } = action.payload;
    const status = keys.CHRONO_USER_ACTIVATE + '_STATUS' + (queryParams.userId ? queryParams.userId : '');

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));
        console.log(action)
        const result = yield call(
            consoleApi.activateChronoUser,
            queryParams
        );

        if (!result) {
            throw new Error("Unable to activate the user.");
        }

        yield put(addResource(result, keys.CHRONO_USER_ACTIVATE + (queryParams.userId ? queryParams.userId : '')));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {
        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}

export function* resendEmailConfirmationChronoUser(action) {
    const { queryParams } = action.payload;
    const status = keys.CHRONO_USER_RESEND_EMAIL_CONFIRMATION + '_STATUS' + (queryParams.email ? queryParams.email : '');

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));
        console.log(action)
        const result = yield call(
            consoleApi.resendEmailConfirmationChronoUser,
            queryParams
        );

        if (!result) {
            throw new Error("Unable to resend the email confirmation.");
        }

        yield put(addResource(result, keys.CHRONO_USER_RESEND_EMAIL_CONFIRMATION + (queryParams.email ? queryParams.email : '')));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {
        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}

