import _ from "lodash";
import { ArraySchema } from "yup";

export const saveState = (container, key, value) => {
    // if (container[key] && Array.isArray(container[key])) {
    //     const index = _.findIndex(container[key], { id: value.id });
    //     let newContainerValues = [...container[key]];
    //     if (index > -1 && value !== undefined) {
    //         newContainerValues.splice(index, 1, value);
    //     } else if (index > -1 && value === undefined) {
    //         newContainerValues.splice(index, 1);
    //     } else {
    //         newContainerValues.unshift(value);
    //         newContainerValues = newContainerValues.flat();
    //     }

    //     return {
    //         ...container,
    //         [key]: newContainerValues,
    //     };
    // } else {
        container[key] = value;
        return {
            ...container,
        };
    // }
};
