import * as requestTypeConstante from "../components/communication/CommunicationFormActionButtons";
import * as codenameStatusConstante from "../statusUtils";
import moment from "moment";
import { getMergedDate } from "../components/common/helper";
import { getReferenceRoutes, getReferenceStops } from "../api/references/references";
import { DATE_FORMAT } from "../components/communication/CommunicationUtils";
import {
    LABEL_STATUS_APPROVED,
    LABEL_STATUS_CREATED,
    LABEL_STATUS_AWAITING_ACTIVATION,
    LABEL_STATUS_ACTIVE,
    LABEL_STATUS_EXPIRED,
    LABEL_STATUS_APPROVED_REFUSED,
    LABEL_STATUS_PUBLISHED_REFUSED,
} from "../statusUtils";

export const MessageMapper = (requestType, statusContext, formData, themeContext = null) => {
    const _requestType = requestType;
    const _status = statusContext;
    const _formData = formData;
    const _theme = themeContext;

    const messageRequestObject = {
        [requestTypeConstante.TYPE_REQUEST_APPROVE]: {
            statusCode: _status[codenameStatusConstante.CODENAME_STATUS_APPROVED],
            actionUrl: "/approve",
        },
        [requestTypeConstante.TYPE_REQUEST_PUBLISH]: {
            statusCode: _status[codenameStatusConstante.CODENAME_STATUS_PUBLISHED],
            actionUrl: "/publish",
        },
        [requestTypeConstante.TYPE_REQUEST_REFUSE]: {
            statusCode: _status[codenameStatusConstante.CODENAME_STATUS_APPROVED_REFUSED],
            actionUrl: "/refuse",
        },
        [requestTypeConstante.TYPE_REQUEST_SAVE]: {
            statusCode: _status[codenameStatusConstante.CODENAME_STATUS_CREATED],
            actionUrl: "",
        },
        [requestTypeConstante.TYPE_REQUEST_EXPIRE]: {
            statusCode: "",
            actionUrl: "/messages/expire",
        },
    };

    const _formatRouteList = (routeList) => {
        return routeList.map((route) => {
            const routeObj = JSON.parse(route);
            return {
                ...routeObj,
                agencyCode: routeObj.organizationCode,
            };
        });
    };

    const _formatRouteListOptions = async (agencyCodeList, routeList) => {
        const routes = await getReferenceRoutes({ agencyCodeList });
        const selectedRoutes = routeList.map((messageRoute) => {
            const route = routes.find((x) => x.routeId === messageRoute.routeId && x.organizationCode === messageRoute.agencyCode );
            if (route) {
                return `{"organizationCode": "${route.organizationCode}", "routeId": "${route.routeId}", "routeLabel": "${route.shortName} - ${route.longName}", "backgroundColor": "${route.backgroundColor}", "fontColor": "${route.fontColor}"}`;
            }

            return null;
        });
        return selectedRoutes
    };

    const _formatStopListOptions = async (routeList) => {
        let queryReferenceRouteListParams = [];
        routeList.forEach((route) => {
            const queryReferenceRouteListParamsIndex = queryReferenceRouteListParams.findIndex(
                (x) => x.organizationCode === route.agencyCode
            );
            if (queryReferenceRouteListParamsIndex >= 0) {
                queryReferenceRouteListParams[queryReferenceRouteListParamsIndex] = {
                    ...queryReferenceRouteListParams[queryReferenceRouteListParamsIndex],
                    routeGtfsIdList: [
                        ...queryReferenceRouteListParams[queryReferenceRouteListParamsIndex].routeGtfsIdList,
                        route.routeId,
                    ],
                };
            } else {
                queryReferenceRouteListParams.push({
                    organizationCode: route.agencyCode,
                    routeGtfsIdList: [route.routeId],
                });
            }
        });
        const stops = await getReferenceStops(queryReferenceRouteListParams);

        return routeList
            .map((route) => {
                return route.arrets.map((stopItem) => {
                    const stopItemCode = stopItem.split("-")[2];
                    const stopIndex = stops.findIndex((x) => x.organization === route.agencyCode && x.routeId === route.routeId);
                    const stop = stops[stopIndex].options.find((x) => x.stopId === stopItemCode);
                    if (!stop) {
                        throw Error("stop is empty not found");
                    }
                    return `{"organizationCode": "${stop.organizationCode}", "routeId": "${stop.routeId}", "stopCode": "${stop.code}", "stopId": "${stop.stopId}", "stopLabel": "${stop.routeId} - ${stop.code} - ${stop.name} (${stop.direction})"}`;
                });
            })
            .flat();
    };

    const _formatStopList = (stopsList) => {
        return stopsList.map((stop) => {
            const stopObj = JSON.parse(stop.replace(/\t/g, ' '));
            console.log(stopObj);
            return stopObj.organizationCode + "-" + stopObj.routeId + "-" + stopObj.stopId;
        });
    };

    const _getStatusBadge = (message) => {
        const statusBadgeOptions = {
            [_status.CODENAME_STATUS_CREATED]: {
                color: _theme.colors.messageError,
                label: LABEL_STATUS_CREATED,
            },
            [_status.CODENAME_STATUS_APPROVED]: {
                color: _theme.colors.messageWarning,
                label: LABEL_STATUS_APPROVED,
            },
            [_status.CODENAME_STATUS_APPROVED_REFUSED]: {
                color: _theme.colors.messageError,
                label: LABEL_STATUS_APPROVED_REFUSED,
            },
            [_status.CODENAME_STATUS_PUBLISHED_REFUSED]: {
                color: _theme.colors.messageError,
                label: LABEL_STATUS_PUBLISHED_REFUSED,
            },
        };

        return statusBadgeOptions[message.statusCode]
            ? statusBadgeOptions[message.statusCode]
            : {
                  color: _theme.colors.messageInfo,
                  label: (() => {
                      if (!message) return "";
                      const endDate = message.expirationDate
                          ? message.expirationDate
                          : message.endDate
                          ? message.endDate
                          : message.end;
                      const startDate = message.activationDate
                          ? message.activationDate
                          : message.startDate
                          ? message.startDate
                          : message.start;
                      if (moment(endDate).isBefore(moment())) return LABEL_STATUS_EXPIRED;
                      if (moment(startDate).isAfter(moment())) return LABEL_STATUS_AWAITING_ACTIVATION;
                      return LABEL_STATUS_ACTIVE;
                  })(),
              };
    };

    const getStatusCode = () => {
        if (_requestType) return messageRequestObject[_requestType].statusCode;

        return messageRequestObject[requestTypeConstante.TYPE_REQUEST_SAVE].statusCode;
    };

    const getActionUrl = () => {
        if (_requestType) return messageRequestObject[_requestType].actionUrl;

        return messageRequestObject[requestTypeConstante.TYPE_REQUEST_SAVE].actionUrl;
    };

    const prepareDataToForm = async (message) => {
        try {
            return {
                ...message,
                startDate: moment(message.startDate).format(DATE_FORMAT),
                startTime: moment(message.startDate).format("HH:mm"),
                endDate: moment(message.endDate).format(DATE_FORMAT),
                endTime: moment(message.endDate).format("HH:mm"),
                agencyCodeList: message.agencyCodeList,
                routeList:
                    message.agencyCodeList &&
                    message.routeList &&
                    (await _formatRouteListOptions(message.agencyCodeList, message.routeList)),
                arretList: message.routeList && (await _formatStopListOptions(message.routeList)),
                isVisibleInFeedOnly: message.isVisibleInFeedOnly,
                contentFR: message.contentFR,
                urlFR: message.urlFR ? message.urlFR : "",
                contentEN: message.contentEN,
                urlEN: message.urlEN ? message.urlEN : "",
                isNow: false,
                statusBadge: _getStatusBadge(message),
            };
        } catch (error) {
            console.log(error);
            return undefined
        }
    };

    const buildSaveDataRequest = () => {
        const startDate = moment(_formData.startDate);
        const splittedStartTime = _formData.startTime.split(":");
        const startTime = startDate.set({
            hour: splittedStartTime[0],
            minute: splittedStartTime[1],
        });

        const endDate = moment(_formData.endDate);
        const splittedEndTime = _formData.endTime.split(":");
        const endTime = endDate.set({
            hour: splittedEndTime[0],
            minute: splittedEndTime[1],
        });

        const data = {
            ..._formData,
            id: _formData.id,
            routeList: _formatRouteList(_formData.routeList),
            arretList: _formatStopList(_formData.arretList),
            startDate: getMergedDate(startDate, startTime),
            endDate: getMergedDate(endDate, endTime),
            agencyCodeList: _formData.agencyCodeList,
            fontColor: _formData.colors.fontColor,
            backgroundColor: _formData.colors.backgroundColor,
            statusCode: getStatusCode(),
            refusedReason: _formData.refusedReason ? _formData.refusedReason : "",
        };

        delete data.startTime;
        delete data.endTime;
        delete data.isNow;

        return data;
    };

    return { buildSaveDataRequest, getStatusCode, getActionUrl, prepareDataToForm };
};

export const synchronizeStepsValues = (stepsValues) => {
    if (stepsValues[1]) {
        stepsValues[1] = stepsValues[1].filter((x) => {
            const value = JSON.parse(x);
            return stepsValues[0].indexOf(value.organizationCode) > -1;
        });
    }

    if (stepsValues[2]) {
        const routeIds = stepsValues[1].map((x) => {
            const value = JSON.parse(x);
            return value.routeId;
        });
        stepsValues[2] = stepsValues[2].filter((x) => {
            const value = JSON.parse(x);
            return stepsValues[0].indexOf(value.organizationCode) > -1 && routeIds.indexOf(value.routeId) > -1;
        });
    }

    return stepsValues;
};

export const setMessageNotFound = (emptyValue, selectedMessage, isCopy) => {
    if (isCopy === true)
        return {
            ...selectedMessage,
            id: -1,
            statusCode: '',
            startDate: moment(),
            endDate: moment().add(1, "day")
        };
    return emptyValue;
};
