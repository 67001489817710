import * as yup from "yup";
import moment from 'moment';


export const messageFilterFormValidator = yup.object({
    startDate: yup.date().nullable()
        .test("is-null-startDate", "La date de début ne peut être vide si la date de fin n'est pas vide", function (value) {
            const { dateFin } = this.parent;
            return !(value == null && dateFin != null);
        }).test("is-smaller", "La date de fin ne peut être antérieure", function (value) {
            const { startDate, endDate } = this.parent;
            if (value != null && startDate != null && startDate != null)
                return moment(value).isBefore(endDate);

            return true;
        }),
    endDate: yup.date().nullable()
        .test("is-greater", "La date de fin ne peut être antérieure", function (value) {
            const { startDate } = this.parent;
            if (value != null && startDate != null)
                return moment(value).isAfter(startDate);

            return true;
        })
        .test("is-null-endDate", "La date de fin ne peut être vide si la date de début n'est pas vide", function (value) {
            const { startDate } = this.parent;
            return !(value == null && startDate != null);
        })
});


