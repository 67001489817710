// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, FC, ReactElement } from "react";
import { Col, Row, Form, Dropdown, Button, ButtonGroup } from "react-bootstrap";
import GenericDate from "../../../common/generic/GenericDate";
import GenericInput from "../../../common/generic/GenericInput";
import GenericTime from "../../../common/generic/GenericTime";
import GenericArea from "../../../common/generic/GenericArea";
import IconList from "../../../common/IconList";
import CommunicationFormHeader from "../../CommunicationFormHeader";
import { DATE_FORMAT } from "../../CommunicationUtils";
import { CODENAME_STATUS_CREATED } from "../../../../statusUtils";
import CommunicationFormActionButtons, { TYPE_REQUEST_PUBLISH, TYPE_REQUEST_SAVE } from "../../CommunicationFormActionButtons";
import { CommunicationContainer } from "../../CommunicationContainer";
import ChronoContext from "../../../../context/ChronoContext";
import RoleHelper, { ROLE_CODENAME_ADMIN, ROLE_CODENAME_EDITOR } from "../../../common/RoleHelper";
import { validateDate, isValidUrl } from "../../../common/helper";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { useCarteService } from "../../../../service/communication/carteService";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { CarteForm } from "../../../../types/forms/carteForms";
import { CarteFormEditProps } from "../../../../types/components/carteComponent";
import { validationFormSchema } from "../validators/saveValidator";
import * as requestStatusList from "../../../../redux/sagas/requestStatus";
import * as keys from "../../../../redux/keys/communication/carte";
import useUI from "../../../../lib/hooks/useUI";
import { useCommunicationEvent } from "../../communicationEvent";
import { EMPTY_CARTE } from "../CarteConstants";

const FormRow = styled(Row) `
    margin-top: 20px;
    margin-bottom: 20px;
`

const ErrorMessage = styled.p`
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
`

const CarteFormEditable: FC<CarteFormEditProps> = ({ entity, setEntity, operations }: CarteFormEditProps): ReactElement => {

    const staticData = useContext(ChronoContext).staticData;
    const status = staticData.status;
    const roles = staticData.roles;
    const requestStatus = useUI(keys.SAVED_CARTE_STATUS);
    const carteService = useCarteService();

    const [hasBeenModified, setHasBeenModified] = useState(false);
    const [isNow, setIsNow] = useState(false);

    const [isStartDateValid, setIsStartDateValid] = useState(true);
    const [isStartTimeValid, setIsStartTimeValid] = useState(true);
    const [isEndDateValid, setIsEndDateValid] = useState(true);
    const [isEndTimeValid, setIsEndTimeValid] = useState(true);

    const { watch, control, errors, formState, setValue, handleSubmit, trigger } = useForm<CarteForm> ({
        defaultValues: entity,
        resolver: yupResolver(validationFormSchema),
        reValidateMode: 'onChange',
        mode: "onChange",
    });

    const values = watch();

    useEffect(() => {
        return () => {
            setEntity(EMPTY_CARTE);
        };
    }, []);

    //handle event add new notif
    useCommunicationEvent(
        {
            executeCallback: () => {
                carteService.request.Save.refreshState(0);
                operations.post(true);
            }
        }, carteService.request.Save.isSuccess, carteService.request.Save.isFail
    )

    //handle event update notif
    useCommunicationEvent(
        {
            successCallback: () => {
                carteService.updateModel(carteService.request.Update.data)
                operations.post(true);
            },
            afterPost: () => carteService.request.Update.refreshState(entity.id)
        },
        carteService.request.Update.isSuccess && entity.id > 0,
        carteService.request.Update.isFail && entity.id > 0
    )

    //handle event publish notif
    useCommunicationEvent(
        {
            successCallback: () => {
                carteService.updateModel(carteService.request.Publish.data)
                operations.post(true);
            },
            afterPost: () => carteService.request.Publish.refreshState(entity.id)
        },
        carteService.request.Publish.isSuccess,
        carteService.request.Publish.isFail
    )

    //handle event expire notif
    useCommunicationEvent(
        {
            successCallback: () => {
                carteService.updateModel(carteService.request.Expire.data)
                operations.post(true);
            },
            afterPost: () => carteService.request.Expire.refreshState(entity.id)
        },
        carteService.request.Expire.isSuccess,
        carteService.request.Expire.isFail
    )

    //handle event delete notif
    useCommunicationEvent(
        {
            successCallback: () => {
                carteService.removeElement(entity.id)
                operations.post(true);
            },
            afterPost: () => carteService.request.Delete.refreshState(entity.id)
        }, carteService.request.Delete.isSuccess, carteService.request.Delete.isFail
    )

    const isValidated = () => {
        
        return isStartDateValid && isEndDateValid && isStartTimeValid && isEndTimeValid && values.iconUrl &&
                values.titleFR && values.titleFR.length > 0 && values.titleEN && values.titleEN.length > 0;
    }

    const onClickSend = (requestType) => {
        
        if (operations?.pre)
            operations.pre();

        const model = carteService.mapToModel({ ...entity }, values, requestType);
        model.id === 0 ? carteService.request.Save.process(model) :
        requestType === TYPE_REQUEST_PUBLISH ? carteService.request.Publish.process(model) : carteService.request.Update.process(model);
    };

    const onClickDelete = () => {
        if (operations?.pre)
            operations.pre();

        carteService.request.Delete.process(entity.id)
    };

    const validateStartDate = (date) => {
        const startDateValid = validateDate(moment(date).format('YYYY-MM-DD'));
        setIsStartDateValid(startDateValid);
        return startDateValid;
    }

    const validateStartTime = (dt) => {
        const startTimeValid = dt.isSameOrAfter(moment(), 'minute');
        setIsStartTimeValid(startTimeValid);
        return startTimeValid;
    }

    const validateEndDate = (date, currentStartDate) => {
        currentStartDate = isNow ? moment() : currentStartDate;
        const endDateValid = moment(currentStartDate).isSameOrBefore(moment(date), 'day');
        setIsEndDateValid(endDateValid);
        return endDateValid;
    }

    const validateEndTime = (date, currentStartDate) => {
        currentStartDate = isNow ? moment() : currentStartDate;
        const isValid = moment(currentStartDate).isBefore(moment(date), 'minute');
        setIsEndTimeValid(isValid);
        return isValid;
    }

    const launchValidationsDates = async () => {
        await trigger('activationDate');
        await trigger('activationTime');
        await trigger('expirationDate');
        await trigger('expirationTime');
    }

    const updateNow = async (isActive) => {
        const currentDate = moment();
        setValue("activationDate", currentDate);
        setValue("activationTime", moment(currentDate).add(1, 'minutes').format("HH:mm"));

        validateStartDate(currentDate);
        validateStartTime(currentDate);
        validateEndDate(entity.expirationDate, currentDate);
        validateEndTime(entity.expirationDate, currentDate);

        await launchValidationsDates();

        setIsNow(isActive);
        setHasBeenModified(true);
    }

    return (
        <CommunicationContainer>
            <CommunicationFormHeader data={entity} isNew={entity.id === 0} />

            <Form
                onSubmit={handleSubmit(() => {
                    onClickSend(TYPE_REQUEST_SAVE);
                })}
            >
                <FormRow style={{ marginBottom: 0 }}>
                    <Col xs={12} style={{ padding: 0 }}>
                        <strong>Sélectionnez une date et heure de début *</strong>
                    </Col>
                </FormRow>
                <FormRow>
                    <Col xl={6} md={12} style={{paddingLeft:'0px', paddingRight:'5px'}}>
                        <Controller
                        control={control}
                            name="activationDate"
                            value={values.activationDate}
                        render={({ onChange, value }) => {
                            return (
                                <GenericDate
                                    cssClass={"js-start-date"}
                                    isRequired
                                    label="Début"
                                    minDate={new Date()}
                                    date={moment(value).format(DATE_FORMAT)}
                                    onDateChange={async (evt) => {
                                        setHasBeenModified(true);
                                        onChange(evt);
                                        await launchValidationsDates();
                                    }}
                                    width={'80'}
                                    canUserEdit={true}
                                    isDisabled={isNow}
                                />
                            );
                        }}
                        />
                        {errors.activationDate && <ErrorMessage>{errors.activationDate.message}</ErrorMessage>}
                    </Col>
                    <Col xl={6} md={12} style={{  paddingRight: '0px' }}>
                        <Controller
                            control={control}
                            name="activationTime"
                            value={values.activationTime}
                            render={({ onChange, value }, { invalid }) => {
                                return (
                                    <GenericTime
                                        cssClass={"js-start-date"}
                                        isRequired
                                        label="Heure"
                                        time={value}
                                        onTimeChange={async (evt) => {
                                            setHasBeenModified(true);
                                            onChange(evt);
                                            await launchValidationsDates();
                                        }}
                                        canUserEdit={true}
                                        isDisabled={isNow}
                                    />
                                );
                            }}
                        />
                        {errors.activationTime && <ErrorMessage>{errors.activationTime.message}</ErrorMessage>}
                    </Col>
                </FormRow>

                <FormRow>
                    <Col style={{ paddingLeft: 0 }}>
                        <strong>Ou</strong>
                    </Col>
                </FormRow>
                
                <FormRow style={{ marginTop: '5px' }}>
                    <Col style={{ paddingLeft: 0 }}>
                        <Form.Switch
                            id={'startDateSwitch'}
                            label="Maintenant"
                            style={{ color: 'black' }}
                            checked={isNow}
                            onChange={async () => await updateNow(!isNow)} />
                    </Col>
                </FormRow>
                <FormRow style={{ marginBottom: 0 }}>
                    <Col xs={12} style={{ padding: 0 }}>
                        <strong>Sélectionnez une date et heure de fin *</strong>
                    </Col>
                </FormRow>

                <FormRow>
                    <Col xl={6} md={12} style={{ paddingLeft: '0px', paddingRight: '5px' }}>
                        <Controller
                            control={control}
                            name="expirationDate"
                            value={values.expirationDate}
                            render={({ onChange, value }) => {
                                return (
                                    <GenericDate
                                        cssClass={"js-start-date"}
                                        isRequired
                                        label="Fin"
                                        minDate={new Date()}
                                        date={moment(value).format(DATE_FORMAT)}
                                        onDateChange={async (evt) => {
                                            setHasBeenModified(true);
                                            onChange(evt);
                                            await launchValidationsDates();
                                        }}
                                        width={'80'}
                                        canUserEdit={true}
                                    />
                                );
                            }}
                        />
                        {errors.expirationDate && <ErrorMessage>{errors.expirationDate.message}</ErrorMessage>}
                    </Col>
                    <Col xl={6} md={12} style={{ paddingRight: '0px' }}>
                        <Controller
                            control={control}
                            name="expirationTime"
                            value={values.expirationTime}
                            render={({ onChange, value }, { invalid }) => {
                                return (
                                    <GenericTime
                                        cssClass={"js-start-date"}
                                        isRequired
                                        label="Heure"
                                        time={value}
                                        onTimeChange={async (evt) => {
                                            setHasBeenModified(true);
                                            onChange(evt);
                                            await launchValidationsDates();
                                        }}
                                        canUserEdit={true}
                                    />
                                );
                            }}
                        />
                        {errors.expirationTime && <ErrorMessage>{errors.expirationTime.message}</ErrorMessage>}
                    </Col>
                </FormRow>

                <FormRow style={{ marginBottom: 0 }}>
                    <strong>Sélectionnez une icône *</strong>
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="iconUrl"
                        value={values.iconUrl}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <IconList
                                    iconUrl={value}
                                    onChange={(evt) => {
                                        setHasBeenModified(true);
                                        onChange(evt.url);
                                    }}
                                />
                            );
                        }}
                    />
                </FormRow>

                <FormRow style={{ marginBottom: 0 }}>
                    <strong>Message français</strong>
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="titleFR"
                        value={values.titleFR}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <GenericInput
                                    isRequired
                                    label="Titre (Français)"
                                    maxLength={150}
                                    feedback="Veuillez écrire un titre"
                                    value={value}
                                    onChange={(evt) => {
                                        setHasBeenModified(true);
                                        onChange(evt);
                                    }}
                                />
                            );
                        }}
                    />
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="longTextFR"
                        value={values.longTextFR}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <GenericArea
                                    isRequired
                                    label="Texte long"
                                    maxLength={150}
                                    feedback="Veuillez écrire un text long en français"
                                    value={value}
                                    onChange={(evt) => {
                                        setHasBeenModified(true);
                                        onChange(evt);
                                    }}
                                />
                            );
                        }}
                    />
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="actionUrlFR"
                        value={values.actionUrlFR}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <GenericInput
                                    label="URL (Français)"
                                    maxLength={512}
                                    placeholder="URL"
                                    feedback="Veuillez écrire un URL"
                                    value={value}
                                    onChange={(evt) => {
                                        setHasBeenModified(true);
                                        onChange(evt);
                                    }}
                                />
                            );
                        }}
                    />
                </FormRow>

                <FormRow style={{ marginBottom: 0 }}>
                    <strong>Message anglais</strong>
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="titleEN"
                        value={values.titleEN}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <GenericInput
                                    isRequired
                                    label="Titre (Anglais)"
                                    maxLength={150}
                                    feedback="Veuillez écrire un titre"
                                    value={value}
                                    onChange={(evt) => {
                                        setHasBeenModified(true);
                                        onChange(evt);
                                    }}
                                />
                            );
                        }}
                    />
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="longTextEN"
                        value={values.longTextEN}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <GenericArea
                                    isRequired
                                    label="Texte long"
                                    maxLength={150}
                                    feedback="Veuillez écrire un text long en anglais"
                                    value={value}
                                    onChange={(evt) => {
                                        setHasBeenModified(true);
                                        onChange(evt);
                                    }}
                                />
                            );
                        }}
                    />
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="actionUrlEN"
                        value={values.actionUrlEN}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <GenericInput
                                    label="URL (Anglais)"
                                    maxLength={512}
                                    placeholder="URL"
                                    feedback="Veuillez écrire un URL"
                                    value={value}
                                    onChange={(evt) => {
                                        setHasBeenModified(true);
                                        onChange(evt);
                                    }}
                                />
                            );
                        }}
                    />
                </FormRow>
                <FormRow>
                    <small className="text-muted">*Champs obligatoires</small>
                </FormRow>
                <FormRow>
                    <RoleHelper roleCodenameAllowed={ROLE_CODENAME_EDITOR}>
                        <Col style={{alignItems:'center', justifyContent:'center', display:'flex'}}>
                            <Dropdown as={ButtonGroup} drop="up">
                                <Button type="submit" disabled={entity.id > 0 ? !hasBeenModified : !formState.isValid}>
                                    <span><FontAwesomeIcon icon={faSave} /> Enregistrer</span>
                                </Button>
                                <CommunicationFormActionButtons
                                    onClickSend={onClickSend}
                                    onClickDelete={onClickDelete}
                                    isFormFilled={formState.isValid}
                                    isValidated={isValidated}
                                    isLoading={requestStatus === requestStatusList.REQUEST_PENDING}
                                    itemExists={entity.id !== 0}
                                    hideApprove={entity.createdBy ? entity.createdBy.role === roles[ROLE_CODENAME_ADMIN] : false}
                                    hidePublish={entity.status === 16 || entity.status === 32}
                                    hideDelete={(entity.status === 16 && !carteService.isCarteAwaitingPublication(entity)) || entity.status === 32}
                                    hasBeenModified={hasBeenModified || entity.status === status[CODENAME_STATUS_CREATED]}
                                />
                            </Dropdown>
                        </Col>
                    </RoleHelper>
                </FormRow>
            </Form>

        </CommunicationContainer>
    );
};

export default CarteFormEditable;
