import React, { useState, useContext, useEffect} from 'react';
import { StatsCard, StatsContainer } from "../global/StatsComponents";
import { AGENCIES_EXO, AGENCY_LABEL_EXO } from "../../common/helper";
import { KEEN_DATA_AGENCY_CODE } from "../keen/keenConstants";
import { getCountRoutesShown } from "../keen/keenQueries";
import { useKeenQuery } from "../keen/keenHelper";
import { Card, Row, Col } from "react-bootstrap";
import ChronoBadge from "../../common/ChronoBadge";
import ChronoContext from "../../../context/ChronoContext";
import { LoadingIndicator } from "../../LoadingIndicator";
import DiscreteColorLegend from 'react-vis/dist/legends/discrete-color-legend';
import { TitleCard, ITEMS } from "../../home/cards/BaseCardHome";
import { getSimplifiedAgencyLabels } from "../../common/helper";
import { IKeenData } from './StatsRoutesShown';


const translateKeenData = (dataList) => {
    if (dataList) {
        return dataList.map((d) => ({
            x: d[KEEN_DATA_AGENCY_CODE],
            y: d.result,
        }));
    }

    return [];
};

const StatsAgencyShown = ({onClickSelectBar, onClickUnselectBar, uniqueness, isLoading, isSelected, keenData, keenData2}) => {
    const staticData = useContext(ChronoContext).staticData;
    const userAgencies = staticData.user.agencies;

    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        if (keenData.length > 0 && keenData2.length > 0) setIsDataLoaded(true)
    }, [keenData, keenData2])

    const getColor = (item) => {
        return userAgencies.length > 0 ? userAgencies.find(x => x.label === item.x).color : 'black';
    }

    const onClickCard = (item) => {
        if (isSelected) 
            onClickUnselectBar(item);
        else
            onClickSelectBar(item);

        return;
    }

    if (isLoading) {
        return <LoadingIndicator />
    }

    return (
        <StatsContainer>
            <StatsCard>
                {
                    !isDataLoaded ? <LoadingIndicator style={{marginTop: -200}} /> :
                        keenData.length > 0 ?
                        keenData.map((item: IKeenData, i) =><Row>
                            
                                <Col>
                                <Card key={i} onClick={() => onClickCard(item)} style={{cursor: 'pointer', margin: 'auto'}}>
                                    <Card.Header style={{fontSize: 16, width: '100%'}}>
                                        Nombre de consultations { uniqueness && 'uniques'}
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col className="align-self-center">
                                                <ChronoBadge color={getColor(item)} code={item.x} />
                                            </Col>
                                            <Col className="align-self-center" style={{fontSize: 40}}>
                                                {item.y}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                </Col>
                                <Col>
                            <Card key={i} onClick={() => onClickCard(item)} style={{cursor: 'pointer', margin: 'auto'}}>
                                    <Card.Header style={{fontSize: 16, width: '100%'}}>
                                        Nombre de consultations totales { uniqueness && 'uniques'}
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col className="align-self-center">
                                                <ChronoBadge color="#00A0A5" code={'ARTM'} />
                                            </Col>
                                            <Col className="align-self-center" style={{fontSize: 40}}>
                                                {keenData2?.map(i => (i as IKeenData).y).reduce((a, b) => a + b)}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                </Col>
                                </Row>
                            )
                            : <h3 style={{margin: 'auto'}}>Aucun résultat</h3>
                }
            </StatsCard>
        </StatsContainer>
    )
}

export default StatsAgencyShown;
