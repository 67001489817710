/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checkLoginStatus } from "./loginActions";
import { LoadingIndicator } from "../LoadingIndicator";

const ensureLogin = BaseComponent =>
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(
            ({history, location, isLoginPending, isLoginSuccess, checkLoginStatus}) => {
                useEffect(() => {
                    checkLoginStatus()
                }, []);
                
                if (isLoginPending) {
                    return <LoadingIndicator />;
                } else if (isLoginSuccess) {
                    return <BaseComponent />
                } else {
                    if (location.pathname !== '/500') {
                        history.push('/500')
                    }
                    return null;
                }
            }
        )
    );

const mapStateToProps = (state) => ({
    isLoginPending: state.login.isLoginPending,
    isLoginSuccess: state.login.isLoginSuccess,
});

const mapDispatchToProps = dispatch => {
    return {
        checkLoginStatus: () => checkLoginStatus(dispatch)
    }
};

export default ensureLogin;

