import React from 'react';
import { InputGroup, FormControl, Form } from 'react-bootstrap';

interface IGenericArea {
    label?: string;
    value: string;
    placeHolder?: string;
    maxLength?: number;
    rows?: number;
    isDisabled?: boolean;
    isRequired?: boolean;
    onChange?: (ev: any) => void;
    onKeyPress?: (ev: any) => void;
    type?: string;
    invalid?: string;
    style?: any;
    format?: string;
    feedback?: string;
    cssClass?: string;
    canUserEdit?: boolean;
}
const GenericArea: React.FC<IGenericArea> = ({ 
    label, value, onChange, isDisabled = undefined, isRequired = undefined, maxLength = undefined,
    placeHolder = label, rows = 2, feedback = undefined, style = undefined, canUserEdit = true, cssClass =''
}) => {
    const requiredText = isRequired ? ' *' : '';
    
    return (
        <InputGroup>
            {
                label ?
                    <InputGroup.Prepend style={style ? style : {minWidth: 130}}>
                        <InputGroup.Text style={style ? style : {minWidth: 130}}>{label + requiredText}</InputGroup.Text>
                    </InputGroup.Prepend> : null
            }
            <FormControl style={{ resize: 'none' }} aria-label="Default" as="textarea" type="text" disabled={isDisabled || !canUserEdit} value={value}
                onChange={onChange} required={isRequired} maxLength={Number(maxLength)} rows={rows}
                placeholder={placeHolder + requiredText} className={cssClass} />

                
            <InputGroup.Append>
                <InputGroup.Text>
                    <small className="form-text text-muted">{value ? value.length : 0} / {maxLength}</small>
                </InputGroup.Text>
            </InputGroup.Append>
            {
                feedback 
                ? <Form.Control.Feedback type="invalid" >{feedback}</Form.Control.Feedback>
                : null
            }
        </InputGroup>
    );
};

export default GenericArea;
