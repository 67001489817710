import React, { Fragment, FC, ReactElement, useState, useEffect } from "react";
import { AlertFilter } from './components/AlertFilter'
import AlertList from './components/AlertList'
import { Card, Col, Button } from 'react-bootstrap';
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import SvgIcon from "../../common/SvgIcon";
import { useAlertService } from "../../../service/communication/useAlertService";
import AlertBaseContainer from "./components/AlertBaseContainer";
import CommunicationFormSlider from "../CommunicationFormSlider";

const AlertPage: FC = (): ReactElement => {

    const alertService = useAlertService();
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [entity, setEntity] = useState<any>({});
    const [hideGridLoader, setHideGridLoader] = useState<boolean>(false);


    useEffect(() => {
        return () => {
            alertService.cleanCache();
        }
    }, []);

    return (
        <Fragment>
            <TopBar title="Pertubations">
                <Col>
                    <Button onClick={() => {
                        setEntity(alertService.getNewAlert());
                        setHideGridLoader(true);
                        setOpenForm(true);
                    }}
                        style={{ float: 'right' }}
                        disabled={openForm}>
                        Nouvelle alerte
                        <SvgIcon icon="plus" />
                    </Button>
                </Col>
            </TopBar>
            <ContentBelowTopBar>
                <AlertFilter />
                <Card className='generic-card'>
                    <AlertList
                        onClickRow={(item, index) => {
                            setEntity(item);
                            setHideGridLoader(true);
                            setOpenForm(true);
                        }}
                        hideGridLoader={hideGridLoader}
                    />
                </Card>

                <CommunicationFormSlider open={openForm} handleClose={() => {
                    setOpenForm(false);
                    setHideGridLoader(false);
                }}>
                    <AlertBaseContainer entity={entity} />
                </CommunicationFormSlider>

            </ContentBelowTopBar>

        </Fragment>
    )
}

export default AlertPage