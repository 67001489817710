/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import { TopBar, ContentBelowTopBar, Calendar } from "../../../lib/ui/index";
import { LoadingIndicatorInApp } from "../../LoadingIndicator";
import { CalendarFilter } from "./calendarFilter";
import useUI from "../../../lib/hooks/useUI";
import useResources from "../../../lib/hooks/useResources";
import addUIItem from "../../../redux/actions/addUIItem";
import * as keys from "../../../redux/keys/index";
import moment from "moment";
import { useDispatch } from 'react-redux';
import CalendarMessagePopup from './message/calendarMessagePopup'
import { REQUEST_SUCCEEDED, REQUEST_FAILED } from "../../../redux/sagas/requestStatus";
import * as calendarService from "../../../service/calendarService";
import styled from "styled-components";
import { Badge } from 'reactstrap';
import addResource from "../../../redux/actions/addResource";
import ChronoContext from "../../../context/ChronoContext";


const CustomBadge = styled(Badge)`
    margin: 0px 0px;
    text-align:center;
    font-size:12px;
`;

const BottomChild = ({ eventData, currentDate }) => {
    const eventList = eventData && eventData.length > 0 ? eventData.find(x => x.dt === moment(currentDate).format('YYYY-MM-DD')).data.filter(x => x.display === false) : [];

    return (
        eventList && eventList.length > 0 &&
        <div className='calendar-day-bottom-container-parent'>
            <div  className='calendar-day-bottom-container'>
                +{eventList.length}
            </div>
        </div>)
}

const CalendarPage = () => {
    const staticData = useContext(ChronoContext).staticData;
    const agencies = staticData.user.agencies;

    const status = useUI(keys.CALENDAR_FILTER_STATUS);
    const statusLoading = useUI(keys.CALENDAR_MESSAGE + '_STATUS');

    const childrenStatus = useUI(keys.CALENDAR_FILTER_STATUS + 'CHILDREN');
    const messages: any = useResources(keys.CALENDAR_MESSAGE + '_FILTERED');

    const [month, setMonth] = useState(moment().month());
    const [yearValue, setYearValue] = useState(moment().year());
    const [display, setDisplay] = useState('none');

    const [showModal, setShowModal] = useState(false);
    const [calendarDate, setCalendarDate] = useState(moment().format('YYYY-MM-DD'));
    const [eventList, setEventList] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        setDisplay(childrenStatus === 1? 'block' :'none')
    }, [childrenStatus]);

    useEffect(() => {
        if (messages && messages.length === 0 && (statusLoading === REQUEST_SUCCEEDED || statusLoading === REQUEST_FAILED)) {
            dispatch(addUIItem(1, keys.CALENDAR_FILTER_STATUS + 'CHILDREN'));
        }
    }, [status]);

    useEffect(() => {
        dispatch(addUIItem(1, keys.CALENDAR_FILTER_STATUS));
    }, [messages]);

    useEffect(() => {
        return () => {
            dispatch(addUIItem(undefined, keys.CALENDAR_FILTER_STATUS));
            dispatch(addUIItem(undefined, keys.CALENDAR_FILTER_STATUS + 'CHILDREN'));
            dispatch(addUIItem(undefined, keys.CALENDAR_MESSAGE + '_STATUS'));
            dispatch(addResource([], keys.CALENDAR_MESSAGE));
        };
    }, []);


    const eventRenderer = (eventData, currentDate, firstDateOfWeek, lastDateOfWeek, noWeek, nbWeek) => {
        const eventList = eventData && eventData.length > 0 ? eventData.find(x => x.dt === moment(currentDate).format('YYYY-MM-DD')).data.filter(x => x.display === true) : [];
        calendarService.checkAllChildrenLoaded(currentDate, nbWeek, noWeek, lastDateOfWeek, status, dispatch);
        return (
            eventList && eventList.length > 0 &&
            <div>
                {
                    eventList.sortAscending("position").map((message, i) => {
                        let width = moment(lastDateOfWeek).isSameOrAfter(moment(message.endDate).format("YYYY-MM-DD")) ? ((moment.duration(moment(moment(message.endDate).format("YYYY-MM-DD")).diff(moment(currentDate))).asDays() + 1) * 100 - 3).toString() + '%' : ((moment.duration(moment(lastDateOfWeek).diff(moment(currentDate))).asDays() + 1) * 100 - 3).toString() + '%';
                        const dayColor = calendarService.getCalendarDayFrontColor(message);
                        const badge = calendarService.getBadge(message, agencies);
                        const marginTop = message.position === 1 ? '7px' : '3px';
                        return (
                            <div key={'calendar_Page_' + i} style={{ marginTop: marginTop}} >
                                {
                                    message.alreadyShown === undefined || message.alreadyShown === false && 
                                    <Button className={`week-${noWeek}-${i} calendar-day-button-container mt-0`} style={{ width: width, borderLeftColor: dayColor }}>
                                        <Row className='calendar-day-button-event'>
                                            <Col xl={12} lg={12} className='calendar-day-message-container calendar-day-event'>
                                                <div id={'badge_' + i} className='calendar-container-badge'>
                                                    <CustomBadge style={{ color: badge?.color, backgroundColor: badge?.backgroundColor }}>
                                                    {badge?.label }
                                                </CustomBadge>
                                            </div>
                                                    <div className='calendar-text-message'>{calendarService.getLengthData(message.title, width)}</div>
                                                    </Col>
                                        </Row>
                                    </Button>
                                }
                                {
                                    message.alreadyShown === true && message.display === true &&
                                    <div className={`week-${noWeek}-${i} calendar-day-button-container-empty mt-0`} ></div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    return (
        <Fragment>
            <TopBar title="Calendrier" col={4}>
                <CalendarFilter onChange={(obj) => {
                    setMonth(obj.month);
                    setYearValue(obj.year);
                }}/>
            </TopBar>
            <ContentBelowTopBar>
                <CalendarMessagePopup key={"popupCalendarMessage"} id={"popupCalendarMessage"} showModal={showModal} messageList={eventList} currentDate={calendarDate} hideShowModal={() => {
                    setShowModal(false);
                }} />

                <div className='calendar-pd-top-15 mb-3'>
                    {
                        status === 1 &&
                        <Calendar style={{ display: display }} month={month} year={yearValue} showHeader={false} id={'Calendar1'} events={calendarService.getFormatedMessages(messages)} findFormatedEvents={calendarService.findFormatedEvents} getFormatedEventList={calendarService.getFormatedEventList} eventrenderer={eventRenderer} onClickcalendarDay={(calendarDate, data) => {
                            setEventList(data);
                            setCalendarDate(calendarDate);
                            setShowModal(true);
                        }} >
                            <BottomChild eventData={[]} currentDate={moment().format('YYYY-MM-DD')} />
                        </Calendar>
                    }
                    {
                        (status === 0 || display !== 'block') &&
                        <div className='calendar-pd-top-30'>
                            <LoadingIndicatorInApp />
                        </div>
                    }
              
                </div>
            </ContentBelowTopBar>
        </Fragment>
    )
}

export default CalendarPage