/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment,  useState } from 'react';
import { Row, Col, Card, Button, Form, Collapse } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faFilter, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { InputDate } from '../../lib/ui/index'
import styled from 'styled-components';
import { getDropdownListUserEmail, getChronoUserList } from "../../redux/actions/user/requestChronoUser";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as keys from "../../redux/keys/index";
import AutoComplete from '../common/autocomplete';
import { Dropdown } from "../../lib/ui";
import moment from 'moment';


const FormRow = styled(Row)`
    margin-top: 30px;
    margin-bottom: 20px;
`

const ErrorMessage = styled.p`
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;

export const STATUS_OPTIONS  =[
    { label: '', value: '' },
    { label: 'Oui', value: 1 },
    { label: 'Non', value: 0 }
];

const validationSchema = yup.object().shape({
    endDate: yup
        .string()
        .nullable()
        .test(
            "is-greater",
            "La date de fin ne peut être antérieure",
            function (value) {
                if (value === null || value === '') {
                    return true;
                }
                
                const { startDate } = this.parent;
                return moment(value).isSameOrAfter(moment(startDate).format('YYYY-MM-DD')) || (startDate === undefined || startDate === null || startDate === '');
            }),
});


export const ChronoUserFilter = () => {

    const [collapse, setCollapse] = useState(false);


    const formDefaultConfig = {
        defaultValues: {
            startDate: "",
            endDate: "",
            email: "",
            status: "",
            emailSubscribed:''
        },
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    };

    const { handleSubmit, watch, errors, control, setValue } = useForm(formDefaultConfig);
    const values = watch();

    const dispatch = useDispatch();


    const fetchData = () => {
        dispatch(getChronoUserList(keys.CHRONO_USERR_LIST, {
            startDate: (values.startDate != null && values.startDate != '') ? moment(values.startDate).utc().format('YYYY-MM-DD') : '',
            endDate: (values.endDate != null && values.endDate != '') ? moment(values.endDate).utc().format("YYYY-MM-DD 23:59:59") : '',
            email: values.email,
            isEmailSubscribed: (values.emailSubscribed === "1" ? true :
                                values.emailSubscribed === "0" ? false : ''),
            isActive: (values.status === "1" ? true :
                        values.status === "0" ? false : ''),
            isWaitingConfirmation: values.status === "2" ? true : ''
            }));
    }

    return (
        <Fragment>
            {
                <div className='config-mt-15' >

                    <Card>
                        <Card.Header onClick={() => { setCollapse(!collapse); }}>
                            <span className='header-filter-config'>
                                <FontAwesomeIcon icon={faFilter} /> Filtres
                        </span>

                            <Button variant="light" className='btn-filter-collapse' onClick={() => { setCollapse(!collapse); }}>
                                <FontAwesomeIcon icon={collapse ? faPlus : faMinus} />
                            </Button>

                        </Card.Header>
                        <Collapse in={!collapse}>
                            <Card.Body style={{ paddingBottom: '10px' }}>
                                <Form data-testid="frmUserFilter"
                                    onSubmit={handleSubmit(() => {
                                        fetchData();
                                    })}
                                >
                                    <FormRow className='config-mt-10'>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={4}  >
                                            <Controller
                                                control={control}
                                                name="email"
                                                value={values.email}
                                                render={({ onChange, value }) => {
                                                    return (
                                                        <AutoComplete value={value}
                                                            label="Courriel"
                                                            onChange={onChange}
                                                            style={{ width: '100px' }}
                                                            id='chronoUserFilter_Courriel_AutoComplete'
                                                            getDropdownListItems={getDropdownListUserEmail}
                                                            />
                                                    );
                                                }}
                                            />
                                        </Col>

                                        <Col xs={12} sm={12} md={12} lg={12} xl={5} >

                                            <Controller
                                                control={control}
                                                name="startDate"
                                                value={values.startDate}
                                                render={({ onChange, value }, { invalid }) => {
                                                    return (
                                                        <InputDate
                                                            label="Date de création entre"
                                                            date={value}
                                                            onDateChange={onChange}
                                                            isClearable={true}

                                                        />
                                                    );
                                                }}
                                            />
                                        </Col>

                                        <Col xl={3} md={12}>

                                            <Controller 
                                                control={control}
                                                name="endDate"
                                                value={values.endDate}
                                                render={({ onChange, value }, { invalid }) => {
                                                    return (
                                                        <InputDate
                                                            label="et"
                                                            date={value}
                                                            onDateChange={onChange}
                                                            isClearable={true}
                                                        />
                                                    );
                                                }}
                                            />
                                            {errors.endDate && <ErrorMessage>{errors.endDate.message}</ErrorMessage>}
                                        </Col>
                                    </FormRow>

                                    <FormRow style={{ marginTop: 0 }}>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                                            <Controller
                                                control={control}
                                                name="emailSubscribed"
                                                value={values.emailSubscribed}
                                                render={({ onChange, value }) => {
                                                    return (
                                                        <Dropdown
                                                            mode="select"
                                                            label="Accepte d'être contacté"
                                                            labelWidth={100}
                                                            defaultValue={""}
                                                            selectedValue={value}
                                                            options={
                                                                 [
                                                                    ...STATUS_OPTIONS,
                                                                ]
                                                            }
                                                            onChange={(e) => {
                                                                setValue("emailSubscribed", e.target.selectedOptions[0].value)
                                                            }
                                                                
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                           
                                        </Col>

                                        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                                            <Controller
                                                control={control}
                                                name="status"
                                                value={values.status}
                                                render={({ onChange, value }) => {
                                                    return (
                                                        <Dropdown
                                                            mode="select"
                                                            label="Compte actif"
                                                            labelWidth={100}
                                                            defaultValue={""}
                                                            selectedValue={value}
                                                            options={
                                                                [
                                                                    ...STATUS_OPTIONS,
                                                                    { label: "En attente de confirmation de mail", value: 2 },
                                                                ]
                                                            }
                                                            onChange={(e) => {
                                                                setValue("status", e.target.selectedOptions[0].value)
                                                            }
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                         
                                        </Col>

                                        <Col xl={2} lg={12} md={12} className='pl-0' style={{ marginLeft: '10px' }}>
                                            <Button type="submit">
                                                <FontAwesomeIcon icon={faSearch} /> Rechercher
                                            </Button>
                                        </Col>
                                    </FormRow>


                                </Form>
                            </Card.Body>
                        </Collapse>
                    </Card>
                </div>

            }
        </Fragment>
    );

}

export default ChronoUserFilter;
