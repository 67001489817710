import { SAVE_MESSAGE, GET_CALENDAR_MESSAGES } from "../types/types";

export const saveMessage = (requestName, queryParams) => ({
    type: SAVE_MESSAGE,
    payload: {
        requestName,
        queryParams
    }
});

export const getCalendarMessages = (requestName, queryParams) => ({
    type: GET_CALENDAR_MESSAGES,
    payload: {
        requestName,
        queryParams
    }
});


