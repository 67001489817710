import React, { Fragment } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { FormControl, InputGroup, Form } from "react-bootstrap";

interface IDropdownCheckboxes {
    label?: string;
    style?: any;
    labelWidth?: string;
    isRequired?: boolean;
    optionData: Array<any>;
    defaultLabel?: string;
    valueList?: Array<any>;
    onChange: (ev: any, item: any) => any;
    getOptionValue?: any;
    labelButton?: string;
    labelNone?: string;
    numberOptions?: any;
    hideSearch?: boolean;
    cssContainerClass?: string;
    cssClass?: string;
}
const DropdownCheckboxes: React.FC<IDropdownCheckboxes> = ({
    label = "",
    style = {},
    labelWidth = "",
    isRequired = false,
    optionData = [],
    defaultLabel = "",
    valueList = [],
    onChange,
    getOptionValue = undefined,
    labelButton = undefined,
    labelNone = "Aucun",
    numberOptions = null,
    hideSearch = false,
    cssContainerClass = "",
    cssClass = "",
}) => {

    return (
        <InputGroup className={cssContainerClass}>
            {label && label.length > 0 && (
                <InputGroup.Prepend style={style ? style : { minWidth: labelWidth ? labelWidth : 0 }}>
                    <InputGroup.Text style={style ? style : { minWidth: labelWidth ? labelWidth : 0 }}>
                        {label}
                        {isRequired ? " *" : ""}
                    </InputGroup.Text>
                </InputGroup.Prepend>
            )}
            <ReactMultiSelectCheckboxes
                key={getOptionValue}
                className={cssClass}
                hideSearch={hideSearch}
                style={{ width: "100%" }}
                options={optionData}
                closeMenuOnSelect={false}
                onChange={(event, item) => onChange(event, item)}
                placeholderButtonLabel={defaultLabel}
                getDropdownButtonLabel={(item) => {
                    if (item.value.length === 0) return labelNone;
                    else if (item.value.length === 1) return item.value[0].label;
                    else if (item.value.length >= (numberOptions == null ? optionData.length : numberOptions))
                        return defaultLabel;
                    else return item.value.length + " " + labelButton;
                }}
                // placeholder={defaultLabel}
                value={valueList}
                rightAligned={true}

            />
        </InputGroup>
    );
};

export default DropdownCheckboxes;
