import React, { useEffect } from 'react';
import styled from "styled-components";
import CheckTiny from "../../images/CheckTiny.png";
import { useChronoApi } from "../../auth/apiEffects";
import { LoadingIndicatorInApp } from "../LoadingIndicator";
import { Col } from "react-bootstrap";

const IconListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const IconContainer = styled.div`
    margin: 2px;
`;

export const CustomIcon = styled.div<{ isReadOnly?: boolean; isChecked: boolean }>`
    height: 70px;
    width: 70px;
    margin: 5px;
    padding: 5px;
    border-radius: 6px;
    text-align: center;
    background-color: ${props => (props.theme.colors.lightGray)};
    border: 2px solid ${props => props.isReadOnly || props.isChecked ?
    props.theme.colors.secondary :
    props.theme.colors.lightGray};
    
    &:hover {
        border: 2px solid ${props => props.isReadOnly || props.isChecked ?
    props.theme.colors.secondary :
    props.theme.colors.darkGray};
        cursor: pointer;
    }
`;

const CustomImg = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

const IconCheck = styled.img`
    background-color: ${props => (props.theme.colors.secondary)};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    float: right;
    margin-right: -4px;
    margin-top: -20px;
`;

interface IChronoIcon {
    isChecked?: boolean;
    onChange?: (ev: any) => void;
    url: string;
    name?: string;
    isReadOnly?: boolean;
}
export const ChronoIcon: React.FC<IChronoIcon> = ({isChecked, onChange, url, name, isReadOnly}) => {
    return (
        <CustomIcon style={{ margin: 'auto'}} isReadOnly={isReadOnly} isChecked={!!isChecked} onClick={onChange}>
            <CustomImg src={url} alt={name} />
        </CustomIcon>
    );
};


const IconList = ({iconUrl, onChange} : { iconUrl: string, onChange: (ev: any, flag?: boolean) => void}) => {
    const {data: iconsData, isLoading} = useChronoApi('/references/icons');
    useEffect(() => {
        iconsData.forEach((item) => {
            if (item.url === iconUrl) {
                onChange(item);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iconUrl, iconsData]);
    
    if (isLoading) {
        return <Col style={{margin: '50px 0px'}}><LoadingIndicatorInApp size={50} /></Col>;
    }

    return <IconListContainer>
        {iconsData.map((item) => {
                const isChecked = item.url === iconUrl;

                return (
                    <IconContainer key={item.url}>
                        <ChronoIcon isChecked={isChecked} name={item.name} url={item.url} onChange={() => onChange(item, true)} />

                        {isChecked ? <IconCheck src={CheckTiny} alt="checked" /> : null}
                    </IconContainer>
                )
            }
        )}
    </IconListContainer>
};

export default IconList;
