import React, { FC, ReactElement, Fragment, useContext, useEffect, useState} from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { ThemeContext } from "styled-components";
import moment from 'moment';

export const CustomDatePicker = styled(DatePicker)`
    height: 100%;
    padding-left:10px !important;
    padding-top:0px !important;
    border: none;   
    width: inherit;
    cursor: pointer;
    background-color: ${props => props.disabled ? props.theme.colors.invalidInput : null};
`;

export const FormDatePicker:FC<any> = ({ disabled, minDate, maxDate, isRequired, onDateChange, onInputChange, date, invalid, validated,  canUserEdit = true, isClearable = false, cssClass='', format = "YYYY-MM-DD", title}:any): ReactElement => {
    const theme = useContext(ThemeContext);
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        if (date == null || date === '')
            setSelectedDate(new Date());
        else
            setSelectedDate(new Date(moment(date).year(), moment(date).month(), moment(date).date()));
    }, [date]);


    const invalidStyling = invalid ? { border: '1px solid #dc3545', padding: 0, backgroundColor: '' } : validated && !invalid ? { border: '1px solid #28a745', padding: 0, backgroundColor: '' } : { padding: 0, backgroundColor:'' }
    if (!canUserEdit && theme & theme.colors) invalidStyling.backgroundColor = theme.colors.invalidInput;

    return (
    <Form.Control as="div" style={invalidStyling}>
            <CustomDatePicker
            disabled={disabled || !canUserEdit}
            required={isRequired}
            onChange={onDateChange as any}
            onChangeRaw={onInputChange}
                value={(date && date != null) ? moment(date).format(format ? format : 'YYYY-MM-DD') : ''}
                className={cssClass}
            maxDate={maxDate} 
            minDate={minDate}
                openToDate={(date != null && date !== "") ? new Date(moment(date).toDate()) : new Date(moment().toDate())}
                selected={selectedDate}
                isClearable={isClearable}
                title={title}
            popperModifiers={[{
                name: "preventOverflow",
                enabled: true
            }]}
        />
    </Form.Control>
    );
};

const InputDate: FC<any> = ({ date=undefined, label= '', disabled=undefined, minDate=undefined, maxDate=undefined, isRequired=undefined, onDateChange=undefined, onInputChange=undefined, width = 'auto', isInputGroup = true, invalid=undefined, validated=undefined, canUserEdit=undefined, isClearable=undefined, cssClass = '', format = "YYYY-MM-DD", title='' }: any): ReactElement => {
    const requiredLabel = isRequired ? ' *' : '';

    const input = (
        <Fragment>
            <InputGroup.Prepend>
                <InputGroup.Text style={{width: width}}>{label + requiredLabel}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormDatePicker
                disabled={disabled}
                isRequired={isRequired}
                onDateChange={onDateChange}
                onInputChange={onInputChange}
                date={date}
                invalid={invalid}
                validated={validated}
                minDate={minDate}
                maxDate={maxDate}
                canUserEdit={canUserEdit}
                isClearable={isClearable}
                cssClass={cssClass}
                format={format}
                title={title}
                />
        </Fragment>
    );
    
    if (isInputGroup) {
        return (
            <InputGroup>
                {input}
            </InputGroup>
        );
    } else {
        return input;
    }
};

export default InputDate;
