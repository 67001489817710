import * as yup from "yup";
import moment from 'moment';
import { DATE_FORMAT } from "../../CommunicationUtils";
import { getFormattedDate } from "../../../common/helper";

//validations
export const validationFormSchema = yup.object({
    activationDate: yup.date().required("La date de début ne peut être vide"),
    activationTime: yup.string().required("L'heure de début ne peut être vide")
        .test("activationTime-is-greater", "L'heure de début ne peut pas être antérieure", function (value) {
        const { activationDate } = this.parent;
        return moment(getFormattedDate(activationDate, DATE_FORMAT) + " " + value).isAfter(moment());
    }),
    expirationDate: yup.date().required("La date de fin ne peut être vide")
        .test("is-endDate-greater-than-now", "La date de fin ne peut être antérieure", function (value) {
            return moment(value).isSameOrAfter(moment(), 'day')
        })
        .test("is-endDate-greater-than-startDate", "La date de fin ne peut être antérieure", function (value) {
            const { activationDate, activationTime, expirationTime } = this.parent;
            const start = moment(getFormattedDate(activationDate, DATE_FORMAT) + " " + activationTime);
            const end = moment(getFormattedDate(value, DATE_FORMAT) + " " + expirationTime)
            return moment(start).isBefore(moment(end));
        }),
    expirationTime: yup.string().required("L'heure de fin ne peut être vide"),

    iconUrl: yup.string().required("Le texte ne peut être vide"),
    titleFR: yup.string().required("Le texte ne peut être vide"),
    longTextFR: yup.string().required("Le texte ne peut être vide"),
    titleEN: yup.string().required("Le texte ne peut être vide"),
    longTextEN: yup.string().required("Le texte ne peut être vide"),
    urlFR: yup.string().url("L'URL est mal formé."),
    urlEN: yup.string().url("L'URL est mal formé."),
});
