/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import { ContentBelowTopBar, TopBar } from '../common/ContentNavigation';
import { Row, Col, Tabs, Tab, InputGroup, FormControl } from "react-bootstrap";
import ChronoCard from "../common/ChronoCard";
import GenericDate from "../common/generic/GenericDate";
import styled from 'styled-components';
import moment from 'moment';
import HistoricValidation from './HistoricValidation';
import ClientWallet from './ClientWallet';
import AllOrders from './AllOrders';
import SelectEmail from '../common/generic/select/SelectEmail';
import { useIsMountedRef } from "../common/helper";
import { ToastProvider } from 'react-toast-notifications'

export const Header = styled.div`
    font-size: 16px;
    font-weight: bold;
    padding: 20px 0px 20px 0px;
    text-align: left;
    padding-left: 10px;
`

export const ErrorMessage = styled.p`
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
`

const FormRow = styled(Row)`
    margin-top: 30px;
    margin-bottom: 20px;
`

const FormTab = styled(Tab)`
    margin-top: 20px;
`

const BilletterieCompte = () => {

    const [startDate, setStartDate] = useState(moment().subtract(3, 'month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [email, setEmail] = useState('');
    const [loadData, setLoadData] = useState(false);

    const [validated, setValidated] = useState(false);
    const [searchParams, setSearchParams] = useState('');
    const isMountedRef = useIsMountedRef();

    function formatEndDate(dtFin) {
        if (dtFin != null && dtFin !== "")
            return moment(dtFin).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').format('YYYY-MM-DD HH:mm:ss');
        else
            return dtFin
    }

    function execSearch(startDateParam, endDateParam) {
        const validSearchParams = `userEmail=${email}&startDate=${startDateParam}&endDate=${formatEndDate(endDateParam)}`;
        setSearchParams(validSearchParams);

        setValidated(true);
        setLoadData(true);
    }

    const onInputStartChange = event => {
        var inputValue = event.target.value
        if (inputValue === "") {
            setStartDate("");
            execSearch("", endDate);
        }
    };

    const onInputEndChange = event => {
        var inputValue = event.target.value
        if (inputValue === "") {
            setEndDate("");
            execSearch(startDate, "");
        }
    };

    const onStartDateChange = date => {
        var formatDate = "";

        if (date != null && date !== "") {
            formatDate = moment(date).format('YYYY-MM-DD');
        }

        setStartDate(formatDate);
        execSearch(formatDate, endDate);
    }

    const onEndDateChange = date => {
        var formatDate = "";

        if (date != null && date !== "") {
            formatDate = moment(date).format('YYYY-MM-DD');
        }

        setEndDate(formatDate);
        execSearch(startDate, formatDate);
    }

    useEffect(() => {
        if (isMountedRef.current) {
            const validSearchParams = `userEmail=${email}&startDate=${startDate}&endDate=${formatEndDate(endDate)}`;
            setSearchParams(validSearchParams);

            setValidated(true);
        }

    }, [isMountedRef]);

    const formatOptions = evt => {

        const formattedOptions: { id: number; label: string }[] = [];
        if (Array.isArray(evt)) {
            evt.map((item, index) => formattedOptions.push({ "id": index, "label": item }));
            return formattedOptions;
        }
        return [];
    }

    const onEmailSelect = evt => {
        if (evt.length > 0) {

            setEmail(evt[0].label)
            const validSearchParams = `userEmail=${evt[0].label}&startDate=${startDate}&endDate=${formatEndDate(endDate)}`;
            setSearchParams(validSearchParams);

            setValidated(true);
            setLoadData(true);
        }
    }

    return (
        <Fragment>
            <TopBar title="Compte client" />
            <ContentBelowTopBar>
                <ChronoCard>
                    <FormRow style={{ marginTop: 10 }}>
                        <Col xl={6} md={12}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Courriel</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl as="div" className="custom-select" style={{ padding: 0 }}>
                                    <SelectEmail getOptions={formatOptions} onEmailSelect={onEmailSelect} />
                                </FormControl>
                            </InputGroup>
                        </Col>

                        <Col xl={3} md={12} style={{ paddingLeft: 0 }}>
                            <GenericDate label="Début"
                                date={startDate}
                                onDateChange={onStartDateChange}
                                onInputChange={onInputStartChange}
                                width={80}
                                isClearable={true}
                            />
                        </Col>
                        <Col xl={3} md={12} style={{ paddingLeft: 0 }}>
                            <GenericDate label="Fin"
                                date={endDate}
                                onDateChange={onEndDateChange}
                                onInputChange={onInputEndChange}
                                width={80}
                                isClearable={true}
                            />
                            {
                                (endDate != null && endDate !== "" && startDate != null && startDate !== "") ?
                                    (moment(endDate).isBefore(moment(startDate)) ? <ErrorMessage>La date de fin ne peut pas être avant la date de début</ErrorMessage> : null)
                                    : null
                            }
                        </Col>
                    </FormRow>
                    <Tabs defaultActiveKey={1} className="chronotab" id="uncontrolled-tab-example" style={{ padding: 10, borderBottom: !searchParams ? '1px solid #dee2e6' : null }}>
                        <FormTab eventKey={1} title="Activations" style={{ marginTop: 30 }}>
                            {loadData && <HistoricValidation searchParams={searchParams} validated={validated} />}
                        </FormTab>
                        <FormTab eventKey={2} title="Titre utilisables" style={{ marginTop: 30 }}>
                            {loadData && <ToastProvider><ClientWallet searchParams={searchParams} validated={validated} /></ToastProvider>}
                        </FormTab>
                        <FormTab eventKey={3} title="Commandes" style={{ marginTop: 30 }}>
                            {loadData && <ToastProvider><AllOrders searchParams={searchParams} validated={validated} /></ToastProvider>}
                        </FormTab>
                    </Tabs>
                </ChronoCard>
            </ContentBelowTopBar>
        </Fragment>
    )
}

export default BilletterieCompte;