import styled from "styled-components";
import React from "react";

const BadgeContainer = styled.div<{ opacity?: number; size?: number; }>`
    background: ${props => (props.color)};
    opacity: ${props => (props.opacity)};
    border-radius: ${props => (props.size)}px;
    height: ${props => (props.size)}px;
    width:  ${props => (props.size)}px;
`;

const BadgeLabel = styled.div<{ opacity?: number; size?: number; textColor?: string; textSize?: string }>`
    height:  ${props => (props.size)}px;
    line-height:  ${props => (props.size)}px;
    width: ${props => (props.size)}px;
    color: ${props => (props.textColor)};
    font-size: ${props => (props.textSize)};
    font-weight: bold;
    text-align: center;
    margin: auto;
`;

const ChronoBadge = ({color, code, size = 70, textColor = 'white', textSize = '0.9em', opacity = 1}) => {
    return (
        <BadgeContainer size={size} color={color} opacity={opacity}>
            <BadgeLabel size={size} textColor={textColor} textSize={textSize}>{code}</BadgeLabel>
        </BadgeContainer>
    )
};

export default ChronoBadge;