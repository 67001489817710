import React from 'react';
import { GenericTableRow } from "./GenericTable";

const GenericTableHeadSingleRow = ({children, ...props}) => {
    return (
        <thead {...props}>
        <GenericTableRow>
            {children}
        </GenericTableRow>
        </thead>
    );
};

export default GenericTableHeadSingleRow;
