import { NavItem } from "reactstrap";
import React from "react";
import styled from "styled-components";
import ChronoImage from "../../images/iconChrono@3x.png";
import { getEnvironment } from "../../components/common/helper";
import { Badge } from 'reactstrap';


export const ChronoTitle = styled.span`
    font-size: 1.4em;
    margin-top: 4px;
    color: ${props => (props.theme.colors.lightGray)};
`;

export const LogoContainer = styled.div`
    margin: 20px 0px 20px 0px;
    display: flex;
    flex-wrap: wrap;
`;

export const LogoIcon = styled.img`
    height: 40px;
    width: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 22px;
    margin-right: 15px;
`;

const CustomBadge = styled(Badge)`
    margin: 0px 5px;
`;


const HeaderNavMenu = () => {
    return (
        <NavItem>
            <LogoContainer>
                <LogoIcon src={ChronoImage} />
                <ChronoTitle>Chrono
                {
                        getEnvironment() !== 'PROD' &&
                        <CustomBadge style={{fontSize:'12px'}}>{getEnvironment()}</CustomBadge>
                }
                </ChronoTitle>
            </LogoContainer>
        </NavItem>
    );
};

export default HeaderNavMenu;