/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { Dropdown } from "../../../../../lib/ui";
import ChronoContext from "../../../../../context/ChronoContext";
import { synchronizeStepsValues } from '../../../../../service'

const createOptions = (organizations) => {
    return organizations.map((organization) => {
        return { label: organization.label, value: organization.label };
    });
};


const AgencyDropdownStep = (props) => {
    const { stepsValues, setStepsValues, value, onChange, name } = props;
    const chronoContext = useContext(ChronoContext);
    const organizations = chronoContext ? chronoContext.staticData.user.agencies : null;
    const [options, setOptions] = useState(null);

    useEffect(() => {
        if (organizations) {
            setOptions(createOptions(organizations));
        }
    }, []);

    useEffect(() => {
        setStepsValues(synchronizeStepsValues(stepsValues));
    }, [stepsValues[0]]);

    return (
        <div className="d-flex px-3 py-2 h-100">
            {options && <Dropdown mode="box" name={name} options={options} selectedValues={value} onChange={onChange} />}
        </div>
    );
};

export default AgencyDropdownStep;
