import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { RouteComponentProps } from 'react-router-dom';

type ILinkButton = RouteComponentProps & {
    to: string,
    onClick: (ev: any) => void,
    staticContext: any
}

const LinkButton: React.FC<ILinkButton> = ({history, to, onClick, staticContext, ...otherProps}) => {
    return (
        <Button
            {...otherProps}
            onClick={(event) => {
                onClick && onClick(event);
                
                if (to.includes('http')) {
                    window.open(to, '_blank');
                } else {
                    history.push(to);
                }
            }}
        />
    )
};


export default withRouter(LinkButton)