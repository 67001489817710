/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { TitleCard, ITEMS } from "../../home/cards/BaseCardHome";
import ChronoXYPlot from "../../common/ChronoXYPlot";
import { AGENCIES_EXO, AGENCY_LABEL_EXO, TYPES_TRANPORT_STM } from "../../common/helper";
import { StatsCard, StatsContainer } from "../global/StatsComponents";
import { KEEN_DATA_ROUTE_SHORT_NAME } from "../keen/keenConstants";
import { getCountStopsShown } from "../keen/keenQueries";
import { useKeenQuery } from "../keen/keenHelper";
import DiscreteColorLegend from 'react-vis/dist/legends/discrete-color-legend';
import { LoadingIndicator } from "../../LoadingIndicator";
import { FormControl, InputGroup, Row, Col } from "react-bootstrap";
import { sendGetRequest } from "../../../auth/apiEffects";
import { IKeenData } from './StatsRoutesShown';

interface IKeenResult {
    keenData: IKeenData[];
    keenError: string;
}
const translateKeenData = (dataList) => {
    if (dataList) {
        return dataList
            .map((d) => ({
                x: d[KEEN_DATA_ROUTE_SHORT_NAME],
                y: d.result
            }))
            .sort((a, b) => parseInt(a.x, 10) - parseInt(b.x, 10));
    }

    return [];
};

const useKeenResult = (keenClient, filterAgency, currentDates, uniqueness): IKeenResult => {
    let filterAgencyEnhanced = filterAgency;
    if (filterAgency === AGENCY_LABEL_EXO) {
        filterAgencyEnhanced = AGENCIES_EXO;
    }
   
    const keenParams = getCountStopsShown(currentDates, filterAgencyEnhanced, uniqueness);

    return useKeenQuery(keenClient, keenParams, translateKeenData, [filterAgencyEnhanced, currentDates, uniqueness]);
};

const StatsStopShown = ({keenClient, filterAgency, onClickSelectBar, onClickUnselectBar, currentDates, uniqueness}) => {
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isRoutesLoading, setIsRoutesLoading] = useState(true);

    const {keenData} = useKeenResult(keenClient, filterAgency, currentDates, uniqueness);
    const [routes, setRoutes] = useState<any>([]);
    const [routeType, setRouteType] = useState("0");
    const [routeData, setRouteData] = useState<any>([]);
    const [legendsMetro, setLegendsMetro] = useState([]);

    const getLegend = (data) => {
        let results = [];

        if (filterAgency === "STM" && routeType === '1' && data && data.length > 0)
            results = data.map((p) => {
                return { color: p.color };
            });

        setLegendsMetro(results);
          
    }

    let filterData = () => {
        let data = keenData;
  
        if (routes && routes.length > 0 && filterAgency === "STM") {
            console.log(routeType)
            let routeIds = routes.filter((x: {type?: string }) => (x.type || '').toString() === routeType || (routeType === '2' && (x.type || '').toString() !== '1') || routeType === '0').map(x => x.routeId);
            data = keenData.filter((d) => {
                return routeIds.indexOf(d.x) > -1;
            });
            
            if (routeType === '1') {
                data = data.map((p) => {
                    return { ...p, color: routes.find((q: {routeId: number}) => q.routeId === p.x).backgroundColor};
                });
       
                getLegend(data);
            }
           
        }
    
            setRouteData([...data]);
    }

    useEffect(() => {
        setIsDataLoaded(false);

        if (keenData.length > 0)
            filterData();
            
        setIsDataLoaded(true);

    }, [keenData, routeType])

    useEffect(() => {

        setIsRoutesLoading(true);
        async function fetchData() {
            const data = await sendGetRequest(`/references/${filterAgency}/routes/`);
            setRoutes(data);
        }

        if (filterAgency === 'STM')
            fetchData();

        setIsRoutesLoading(false);

    }, [filterAgency]);


    const onRouteTypeSelect = evt => {
        setRouteType(evt.target.value)
    }

    const getCsvData = (dataList) => {
        let results: {Organisation: string; Route: string; "Nombre de clicks": string }[] = [];
        dataList.forEach(row => {
            results.push(
                {
                    Organisation: filterAgency,
                    Route: row.x,
                    "Nombre de clicks": row.y
                });
        });

        return results;
    }

    return (
        <StatsContainer>
            <StatsCard>
                <TitleCard>Affichage des lignes</TitleCard>
                <Row style={{paddingBottom:'15px'}}>
                    <Col>
                        {
                            (!legendsMetro || (legendsMetro && legendsMetro.length === 0) || routeType !== '1' || filterAgency !== "STM") &&
                            <DiscreteColorLegend height={60} width={100} items={ITEMS} style={{ marginBottom: -10 }} />
                        }
                        {
                            legendsMetro && legendsMetro.length > 0 && routeType === '1' && filterAgency === "STM" &&
                            <div className="rv-discrete-color-legend-item">
                                {legendsMetro.map((legend: {color: string}) => <svg key={legend.color} className="rv-discrete-color-legend-item__color" height="2" width="14" style={{ marginRight: '5px' }}><path className="rv-discrete-color-legend-item__color__path" d="M 0, 1 L 14, 1" style={{ stroke: legend.color }}></path></svg>)}

                                <span className="rv-discrete-color-legend-item__title" style={{ fontSize: '12px' }}>Nb vues</span>
                            </div>

                        }
                    </Col>
                    {
                        filterAgency === "STM" &&
                        <Col>
                            {<InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Moyens de transport</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl as="select" value={routeType} onChange={onRouteTypeSelect}>
                                    {
                                        TYPES_TRANPORT_STM &&
                                        TYPES_TRANPORT_STM.map(item => <option key={item.id} value={item.id}>{item.label}</option>)
                                    }
                                </FormControl>
                            </InputGroup>}

                        </Col>

                    }
                    
                </Row>

                {
                    !isDataLoaded || isRoutesLoading ? <LoadingIndicator style={{ marginTop: -200 }} /> :
                        routeData.length > 0 ? <ChronoXYPlot hasMultipleBar={false} showExport={true} data={routeData} csvData={getCsvData(routeData)} csvFileName={'statsRoutesClick'} isAxisYFixed={true} margin={{ left: 50, bottom: 50 }} onClickSelectBar={onClickSelectBar} onClickUnselectBar={onClickUnselectBar} tickLabelAngle={routeData.length > 25 ? -60 : 0} /> 
                            : <h3 style={{ margin: 'auto' }}>Aucun résultat</h3>
                }
            </StatsCard>
        </StatsContainer>
    );
};

export default StatsStopShown;
