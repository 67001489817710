import React, { useContext, FC, ReactElement } from "react";
import styled, { ThemeContext } from "styled-components";
import { Badge } from "react-bootstrap";
import ChronoContext from "../../../../context/ChronoContext";
import { useStatus } from "../../../../lib/hooks/useStatus";
import { useNotificationService } from "../../../../service/communication/useNotificationService";
import { NotificationStatusProps } from "../../../../types/components/notificationComponent";

export const CustomBadge = styled(Badge)`
  margin: 2px 0px;
  padding: 5px 12px !important;
  color: white;
  display: block;
  width: 100px;
  white-space: normal;
  word-wrap: break-word;
`;

const NotificationStatusBadge: FC<NotificationStatusProps> = ({ notification }: NotificationStatusProps): ReactElement => {
  const notificationService = useNotificationService();
    const objNotificationStatus = notificationService.getNotificationStatus(notification, useStatus(ChronoContext), useContext(ThemeContext));

  return (
    <CustomBadge style={{ backgroundColor: objNotificationStatus?.color }}>
      <span style={{ wordBreak: "break-word" }}>{objNotificationStatus?.message}</span>
    </CustomBadge>
  );
};

export default NotificationStatusBadge;
