import React, { useContext } from "react";
import GenericSelect from "../GenericSelect";
import ChronoContext from "../../../../context/ChronoContext";

const SelectAgencies = (props) => {
    const staticData = useContext(ChronoContext).staticData;

    return (
        <GenericSelect title="Organisation" {...props}>
            <option key="emptyChoice" value="" />
            {
                staticData.user.agencies.map((agency) =>
                    <option key={agency.label} value={agency.label}>{agency.label}</option>
                )
            }
        </GenericSelect>
    );
};

export default SelectAgencies;
