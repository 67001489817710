import { sendGetRequest } from '../auth/apiEffects'
import { StatUserConnectedViewModel } from '../types/viewmodels/StatUserConnectedViewModel'

export const getStatForConnectedUserList = async (queryParams): Promise<false | StatUserConnectedViewModel[] | undefined> => {
    try {

        const searchQueryParams = {
            startDate: queryParams.startDate,
            endDate: queryParams.endDate
        }

        const searchQueryParamString = new URLSearchParams(searchQueryParams).toString()
        const response = await sendGetRequest<StatUserConnectedViewModel[]>(`/statistics/userConnected?${searchQueryParamString}`)
        return response

    } catch (e) {
        console.log(e)
        return undefined;
    }
}
