import React, { useEffect, useState } from "react";
import { useChronoApi } from "../../../auth/apiEffects";
import { StatsCard, StatsContainer } from "../global/StatsComponents";
import { TitleCard } from "../../home/cards/BaseCardHome";
import { Col, Container, InputGroup, Row } from "react-bootstrap";

import SliderHours from "../../home/SliderHours";
import { FormDatePicker } from "../../common/generic/GenericDate";
import { getFormattedDate } from "../../common/helper";
import { DATE_FORMAT } from "../../communication/CommunicationUtils";
import moment from "moment";
import styled from "styled-components";
import { getStatisticsDateParams } from "../../../auth/fetchUrlHelper";
import MyHeatMap, { HEAT_MAP_DESTINATION, HEAT_MAP_ORIGIN } from "./MyHeatMap";


export const CustomCol = styled(Col)`
    padding: 20px;
    text-align: center;
`;

const StatsHeatMap = () => {
    const [startDate, setStartDate] = useState(moment().subtract(1, "month").format(DATE_FORMAT));
    const [endDate, setEndDate] = useState(moment().format(DATE_FORMAT));
    const [currentHours, setCurrentHours] = useState<any>({});

    const [locationStatsFiltered, setLocationStatsFiltered] = useState([]);
    const statsParams = getStatisticsDateParams(moment(startDate, DATE_FORMAT), moment(endDate, DATE_FORMAT));

    const { data: locationStats } = useChronoApi("/statistics/latlong" + statsParams);

    const onStartDateChange = (date) => {
        setStartDate(getFormattedDate(date, DATE_FORMAT));
    };
    const onEndDateChange = (date) => {
        setEndDate(getFormattedDate(date, DATE_FORMAT));
    };

    useEffect(() => {
        const filteredStats = locationStats.filter((stat) => {
            const searchDate = moment(stat.searchDate);
            const beginDate = moment(stat.searchDate).hours((currentHours as any)?.startHour);
            const endDate = moment(stat.searchDate).hours((currentHours as any)?.endHour);

            return searchDate.isBetween(beginDate, endDate);
        });

        setLocationStatsFiltered(filteredStats);
    }, [locationStats, currentHours]);

    return (
        <>
            <StatsContainer>
                <StatsCard style={{ minHeight: "143px" }}>
                    <Row className="justify-content-xl-around">
                        <Col className="mb-3">Sélectionnez une période</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12} md={3}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Début</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormDatePicker onDateChange={onStartDateChange} date={startDate} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={3}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Fin</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormDatePicker onDateChange={onEndDateChange} date={endDate} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="justify-content-xl-around">
                        <Col className="mt-3">Sélectionnez une plage horaire</Col>
                    </Row>
                    <Row className="justify-content-xl-around">
                        <CustomCol xs={10}>
                            <SliderHours onNewHours={setCurrentHours} />
                        </CustomCol>
                    </Row>
                </StatsCard>
            </StatsContainer>
            <StatsContainer>
                <StatsCard>
                    <TitleCard>Heatmap des planifications</TitleCard>

                    <Container></Container>

                    <Container>
                        <Row>
                            <Col>
                                <MyHeatMap locations={locationStatsFiltered} heatMapType={HEAT_MAP_ORIGIN} />
                            </Col>
                            <Col>
                                <MyHeatMap locations={locationStatsFiltered} heatMapType={HEAT_MAP_DESTINATION} />
                            </Col>
                        </Row>
                    </Container>
                </StatsCard>
            </StatsContainer>
        </>
    );
};

export default StatsHeatMap;
