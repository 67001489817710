import React, { Fragment, useState, useEffect, useCallback } from "react";
import { ContentBelowTopBar, TopBar } from "../common/ContentNavigation";
import { Row, Col, Button } from "react-bootstrap";
import ChronoCard from "../common/ChronoCard";
import AllReport from "./AllReport";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import GenericDate from "../common/generic/GenericDate";
import styled from "styled-components";

const ErrorMessage = styled.p`
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;

const FormRow = styled(Row)`
  margin-top: 30px;
  margin-bottom: 20px;
`;

const BilletterieRapport = () => {
  const [validated, setValidated] = useState(true);
  const [searchParams, setSearchParams] = useState("");
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [showReport, setShowReport] = useState(false);

  const getData = useCallback(() => {
    const valide = !moment(endDate).isBefore(moment(startDate));
    setValidated(valide);

    if (valide) {
      const validSearchParams = `dtDebut=${startDate}&dtFin=${moment(endDate)
        .add(23, "hours")
        .add(59, "minutes")
        .add(59, "seconds")
        .format("YYYY-MM-DD HH:mm:ss")}`;
      setSearchParams(validSearchParams);
      setShowReport(true);
    }
  }, [endDate, startDate]);

  /*function getData() {
        const valide = !moment(endDate).isBefore(moment(startDate));
        setValidated(valide);

        if (valide) {
            const validSearchParams = `dtDebut=${startDate}&dtFin=${moment(endDate).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').format('YYYY-MM-DD HH:mm:ss')}`;
            setSearchParams(validSearchParams);
            setShowReport(true);
        }
    } */

  useEffect(() => {
    getData();
  }, [getData]);

  const onSearch = () => {
    getData();
  };

  const onStartDateChange = (date) => {
    const formatDate = moment(date).format("YYYY-MM-DD");
    setStartDate(formatDate);
  };

  const onEndDateChange = (date) => {
    const formatDate = moment(date).format("YYYY-MM-DD");
    setEndDate(formatDate);
  };

  return (
    <Fragment>
      <TopBar title="Rapport" />
      <ContentBelowTopBar>
        <ChronoCard>
          <FormRow style={{ marginTop: 10 }}>
            <Col xl={3} md={12} style={{ paddingLeft: 0 }}>
              <GenericDate
                isRequired
                label="Début"
                date={startDate}
                onDateChange={onStartDateChange}
                width={80}
              />
            </Col>
            <Col xl={3} md={12} style={{ paddingLeft: 0 }}>
              <GenericDate
                isRequired
                label="Fin"
                date={endDate}
                onDateChange={onEndDateChange}
                width={80}
              />
              {!validated ? (
                <ErrorMessage>
                  La date de fin ne peut pas être avant la date de début
                </ErrorMessage>
              ) : null}
            </Col>

            <Col xl={3} md={12} style={{ paddingLeft: 0 }}>
              <Button onClick={onSearch}>
                <FontAwesomeIcon icon={faSearch} /> Rechercher
              </Button>
            </Col>
          </FormRow>
        </ChronoCard>
        {showReport && (
          <AllReport searchParams={searchParams} validated={validated} />
        )}
      </ContentBelowTopBar>
    </Fragment>
  );
};

export default BilletterieRapport;
