import { sendPostRequest } from '../../auth/apiEffects'
import { CarteViewModel } from '../../types/viewmodels/carteViewModels'

export const saveCarte = async (queryParams): Promise<false | CarteViewModel | undefined> => {
    try {
        const savedCarteId = await sendPostRequest<CarteViewModel>("/cartes/" + queryParams.carteId + queryParams.publishOrNot, queryParams.queryData);
        return savedCarteId

    } catch (e) {
        console.log(e);
        return undefined;
    }
}