import styled from "styled-components";
import React from "react";

export const CenteredContainer = styled.div`
    display: table;
    height: 100px;
    width: 100%;
    text-align: center;
`;

export const CenteredItem = styled.span`
    display: table-cell;
    vertical-align: middle;
    text-align: center;
`;

export const CenteredItemVerticalOnly = styled.span`
    display: table-cell;
    vertical-align: middle;
    text-align: left;
`;

const CenteredComponent = ({children, verticalOnly = false}) => {
    if (verticalOnly) {
        return (
            <CenteredContainer>
                <CenteredItemVerticalOnly>
                    {children}
                </CenteredItemVerticalOnly>
            </CenteredContainer>
        );
    } else {
        return (
            <CenteredContainer>
                <CenteredItem>
                    {children}
                </CenteredItem>
            </CenteredContainer>
        );
    }
};

export default CenteredComponent;