/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, FC } from "react";
import { Col, Row, Form, Button, Dropdown, ButtonGroup } from "react-bootstrap";
import GenericDate from "../../../common/generic/GenericDate";
import GenericArea from "../../../common/generic/GenericArea";
import GenericInput from "../../../common/generic/GenericInput";
import CommunicationFormHeader from "../../CommunicationFormHeader";
import { getFormattedDate } from "../../../common/helper";
import styled from "styled-components";
import { DATE_FORMAT } from "../../CommunicationUtils";
import CommunicationFormActionButtons, { TYPE_REQUEST_PUBLISH, TYPE_REQUEST_SAVE } from "../../CommunicationFormActionButtons";
import { CommunicationContainer } from "../../CommunicationContainer";
import RoleHelper, { ROLE_CODENAME_EDITOR } from "../../../common/RoleHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import GenericTime from "../../../common/generic/GenericTime";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import NotificationStatusBadge from "../components/NotificationStatusBadge";
import { useNotificationService } from "../../../../service/communication/useNotificationService";
import { NotificationForm} from "../../../../types/forms/notificationForms";
import { NotificationFormBaseProps} from "../../../../types/components/notificationComponent";
import { validationFormSchema } from "./validators/saveValidator";
import { useCommunicationEvent } from "../../communicationEvent";

const FormRow = styled(Row)`
    margin-top: 20px;
    margin-bottom: 20px;
`;

const InfoMessage = styled.p`
  margin-top: 0.25rem;
  font-size: 80%;
  color: #000;
  display
`;

const ErrorMessage = styled.p`
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  display
`;

const NotificationForm_: FC<NotificationFormBaseProps> = ({ entity, operations } : NotificationFormBaseProps): ReactElement => {

    const notificationService = useNotificationService();

    const { watch, control, errors, formState, handleSubmit } = useForm<NotificationForm>({
        defaultValues: entity,
        resolver: yupResolver(validationFormSchema),
        reValidateMode: 'onChange',
        mode: "onChange",
    });
    const values = watch();

    //handle event add new notif
    useCommunicationEvent(
         {
            ...operations,
            executeCallback: () => {
                notificationService.request.Save.refreshState(0);
            },
            successCallback: () => {
                if (operations?.post)
                    operations?.post(true)
            },
        }, notificationService.request.Save.isSuccess, notificationService.request.Save.isFail
    )

    //handle event update notif
    useCommunicationEvent(
    {
        ...operations,
            successCallback: () => {
                notificationService.updateModel(notificationService.request.Update.data);
                if (operations?.post)
                    operations?.post(true)
            },
        afterPost: () => notificationService.request.Update.refreshState(entity.id)
    },
    notificationService.request.Update.isSuccess && entity.id > 0,
    notificationService.request.Update.isFail && entity.id > 0
    )

    //handle event delete notif
   useCommunicationEvent(
        {
            ...operations,
           successCallback: () => {
               notificationService.removeElement(entity.id);
               if (operations?.post)
                   operations?.post(true)
           },
            afterPost: () => notificationService.request.Delete.refreshState(entity.id)
        }, notificationService.request.Delete.isSuccess, notificationService.request.Delete.isFail
    )

    //handle event publish notif
    useCommunicationEvent(
       {
            ...operations,
            successCallback: () => {
                notificationService.updateModel(notificationService.request.Publish.data);
                if (operations?.post)
                    operations?.post(true)
            },
            afterPost: () => notificationService.request.Publish.refreshState(entity.id)
        }, notificationService.request.Publish.isSuccess, notificationService.request.Publish.isFail 
    )

    const onClickSend = (requestType: number): void => {
        if (operations?.pre)
            operations.pre();

        const model = notificationService.mapToModel({ ...entity }, values, requestType);
        model.id === 0 ? notificationService.request.Save.process(model) :
            requestType === TYPE_REQUEST_PUBLISH ? notificationService.request.Publish.process(model) : notificationService.request.Update.process(model);
    };

    const onClickDelete = (): void => {
        if (operations?.pre)
            operations.pre();

        notificationService.request.Delete.process(entity.id)
    };

    return (
        <CommunicationContainer>
            <CommunicationFormHeader
                data={entity}
                isNew={entity.id===0}
                statusBadge={<NotificationStatusBadge notification={entity} />}
            />
            <Form
                onSubmit={handleSubmit(async (data) => {
                    onClickSend(TYPE_REQUEST_SAVE);
                })}
            >
                <div>
                    <FormRow style={{ marginBottom: 0 }}>
                        <Col xs={12} style={{ padding: 0 }}>
                            <strong>Sélectionnez une date et heure d'envoi *</strong>
                        </Col>
                    </FormRow>
                    <FormRow style={{ marginTop: 10, marginBottom: 10 }}>
                        <Col xl={6} md={12} style={{ paddingLeft: 0 }}>
                            <Controller
                                control={control}
                                name="startDate"
                                value={values.startDate}
                                render={({ onChange, value }) => {
                                    return (
                                        <GenericDate
                                            cssClass={"js-start-date"}
                                            isRequired
                                            label="Début"
                                            minDate={new Date()}
                                            date={getFormattedDate(value, DATE_FORMAT)}
                                            onDateChange={onChange}
                                            width={'80'}
                                        />
                                    );
                                }}
                            />
                        </Col>

                        <Col xl={6} md={12} style={{ paddingRight: 0 }}>
                            <Controller
                                control={control}
                                name="startTime"
                                value={moment(values.startTime).format("HH:mm")}
                                render={({ onChange, value }) => {
                                    return (
                                        <GenericTime
                                            cssClass={"js-start-time"}
                                            isRequired
                                            label="Heure"
                                            time={value}
                                            onTimeChange={onChange}
                                        />
                                    );
                                }}
                            />
                        </Col>
                        {errors.startDate && <ErrorMessage>{errors.startDate.message}</ErrorMessage>}
                        {errors.startTime && <ErrorMessage>{errors.startTime.message}</ErrorMessage>}
                        <InfoMessage>
                            Il peut y avoir jusqu'à +/- 5 minutes de décalage par rapport à l'heure paramètrée et l'envoi des
                            notifications.
                        </InfoMessage>
                    </FormRow>
                </div>
                <FormRow style={{ marginBottom: 0 }}>
                    <strong>Message français</strong>
                </FormRow>
                <FormRow style={{ marginTop: 10 }}>
                    <Controller
                        control={control}
                        name="titleFR"
                        value={values.titleFR}
                        render={({ onChange, value }) => {
                            return (
                                <GenericInput
                                    cssClass={"js-titre-fr"}
                                    style={{ width: 140 }}
                                    label="Titre (FR)"
                                    isRequired
                                    value={value}
                                    onChange={onChange}
                                    feedback="Veuillez écrire un titre en français"
                                    maxLength={50}
                                    placeholder="Veuillez écrire un titre en français"
                                />
                            );
                        }}
                    />
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="descriptionFR"
                        value={values.descriptionFR}
                        render={({ onChange, value }) => {
                            return (
                                <GenericArea
                                    cssClass={"js-texte-long-fr"}
                                    style={{ width: 140 }}
                                    isRequired
                                    label="Message (FR)"
                                    value={value}
                                    maxLength={150}
                                    feedback="Veuillez écrire un message long en français"
                                    placeHolder="Veuillez écrire un message en français"
                                    onChange={onChange}
                                />
                            );
                        }}
                    />
                </FormRow>

                <FormRow style={{ marginBottom: 0 }}>
                    <strong>Message anglais</strong>
                </FormRow>
                <FormRow style={{ marginTop: 10 }}>
                    <Controller
                        control={control}
                        name="titleEN"
                        value={values.titleEN}
                        render={({ onChange, value }) => {
                            return (
                                <GenericInput
                                    cssClass={"js-titre-en"}
                                    style={{ width: 140 }}
                                    label="Titre (EN)"
                                    value={value}
                                    isRequired
                                    onChange={onChange}
                                    feedback="Veuillez écrire un titre en anglais"
                                    maxLength={50}
                                    placeholder="Veuillez écrire un titre en anglais"
                                />
                            );
                        }}
                    />
                </FormRow>

                <FormRow>
                    <Controller
                        control={control}
                        name="descriptionEN"
                        value={values.descriptionEN}
                        rules={{ required: true, max: 150 }}
                        render={({ onChange, value }) => {
                            return (
                                <GenericArea
                                    cssClass={"js-texte-long-en"}
                                    style={{ width: 140 }}
                                    isRequired
                                    label="Message (EN)"
                                    value={value}
                                    maxLength={150}
                                    feedback="Veuillez écrire un message long en anglais"
                                    placeHolder="Veuillez écrire un message en anglais"
                                    onChange={onChange}
                                />
                            );
                        }}
                    />
                </FormRow>
                <FormRow>
                    <RoleHelper roleCodenameAllowed={ROLE_CODENAME_EDITOR}>
                        <Col style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <Dropdown as={ButtonGroup} drop="up">
                                <Button type="submit" disabled={!formState.isValid || !formState.isDirty}>
                                    <span>
                                        <FontAwesomeIcon icon={faSave} /> Enregistrer
                                    </span>
                                </Button>
                                <CommunicationFormActionButtons
                                    onClickSend={onClickSend}
                                    onClickDelete={onClickDelete}
                                    isFormFilled={formState.isValid}
                                    isValidated={formState.isValid}
                                    itemExists={entity.id !== 0}
                                    hideApprove={true}
                                    hasBeenModified={formState.isDirty}
                                />
                            </Dropdown>
                        </Col>
                    </RoleHelper>
                </FormRow>
            </Form>
        </CommunicationContainer>
    );
};

export default NotificationForm_;
