export const CODENAME_STATUS_ALL = "all";
export const CODENAME_STATUS_CREATED = "Created";
export const CODENAME_STATUS_APPROVED = "Approved";
export const CODENAME_STATUS_PUBLISHED = "Published";
export const CODENAME_STATUS_REFUSED = "Refused";

export const CODENAME_STATUS_AWAITING_ACTIVATION = "Awaiting Activation";
export const CODENAME_STATUS_ACTIVE = "Active";
export const CODENAME_STATUS_EXPIRED = "Expired";

export const CODENAME_STATUS_APPROVED_REFUSED = "ApprobationRefused";
export const CODENAME_STATUS_PUBLISHED_REFUSED = "PublicationRefused";

export const LABEL_STATUS_ALL = "Tous les statuts";
export const LABEL_STATUS_NONE = "Aucun statut";

export const LABEL_STATUS_CREATED = "En" + String.fromCharCode(160) + "attente d'approbation";
export const LABEL_STATUS_APPROVED = "Approuvé";
export const LABEL_STATUS_PUBLISHED = "Publié";
export const LABEL_STATUS_REFUSED = "Refusé";

export const LABEL_STATUS_AWAITING_ACTIVATION = "En attente d'activation";
export const LABEL_STATUS_ACTIVE = "Active";
export const LABEL_STATUS_EXPIRED = "Expirée";

export const LABEL_STATUS_APPROVED_REFUSED = "Approbation refusée";
export const LABEL_STATUS_PUBLISHED_REFUSED = "Publication refusée";

export const LIST_STATUS = [
    {
        codename: CODENAME_STATUS_ALL,
        label: LABEL_STATUS_ALL,
    },
    {
        codename: CODENAME_STATUS_CREATED,
        label: LABEL_STATUS_CREATED,
    },
    {
        codename: CODENAME_STATUS_APPROVED,
        label: LABEL_STATUS_APPROVED,
    },
    {
        codename: CODENAME_STATUS_AWAITING_ACTIVATION,
        label: LABEL_STATUS_AWAITING_ACTIVATION,
    },
    {
        codename: CODENAME_STATUS_ACTIVE,
        label: LABEL_STATUS_ACTIVE,
    },
    {
        codename: CODENAME_STATUS_EXPIRED,
        label: LABEL_STATUS_EXPIRED,
    },
    {
        codename: CODENAME_STATUS_APPROVED_REFUSED,
        label: LABEL_STATUS_APPROVED_REFUSED,
    },
    {
        codename: CODENAME_STATUS_PUBLISHED_REFUSED,
        label: LABEL_STATUS_PUBLISHED_REFUSED,
    }
];
