import React, { FC, ReactElement, useState } from "react";
import moment from "moment";
import { Dropdown, DropdownButton, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const DropdownCustom:FC<any> = ({ onChange, title, options, id, style=undefined, cssClass }:any):ReactElement => {

    return (
        <DropdownButton id={'dropBtn' + id} variant="outline-secondary" style={style} className={cssClass} drop="down"
            title={title}
            onSelect={(k, e) => {
                if (onChange && k!==null)
                    onChange(parseInt(k));
            }}>

            {
                options.map((m, i) => {

                    return (<Dropdown.Item key={m.label + "_" + id + i} eventKey={m.value}>{m.label}</Dropdown.Item>)
                })
            }

        </DropdownButton>
    )
}

const MOIS = [
    { label: 'Janvier', value: 0 },
    { label: 'Février', value: 1 },
    { label: 'Mars', value: 2 },
    { label: 'Avril', value: 3 },
    { label: 'Mai', value: 4 },
    { label: 'Juin', value: 5 },
    { label: 'Juillet', value: 6 },
    { label: 'Août', value: 7 },
    { label: 'Septembre', value: 8 },
    { label: 'Octobre', value: 9 },
    { label: 'Novembre', value: 10 },
    { label: 'Décembre', value: 11 }

];

const getYears = () => {
    let years = new Array(5);
    for (var i = 0; i < years.length; i++) {
        var y = moment().year() - i;
        years[i] = { label: y.toString(), value: y };
    }
    return years;
}

export const CalendarSelector:FC<any> = ({ id, onChange, month, year }:any): ReactElement => {
    const [disabledNextButton, setDisabledNextButton] = useState(false);
    const [disabledPreviousButton, setDisabledPreviousButton] = useState(false);
    const [monthValue, setMonthValue] = useState(month ? month : 1);
    const [monthName, setMonthName] = useState(MOIS[month ? month : 1].label);
    const [yearValue, setYearValue] = useState(year ? year.toString() : moment().year().toString());

    return (
        <div>
            <div className='calendar-selector-display calendar-pd-left-5'>
                <Button disabled={disabledPreviousButton} variant="outline-secondary" className='calendar-selector-button-left' onClick={() => {
                    let m = monthValue === 0 ? 11 : (monthValue - 1);
                    let y = monthValue === 0 ? parseInt(yearValue) - 1 : parseInt(yearValue);
                    let yearsValue = getYears().map(x => x.value);

                    setDisabledPreviousButton(m === 0 && y === Math.min(...yearsValue));
                    setDisabledNextButton(false);

                    setMonthValue(m);
                    setMonthName(MOIS[m].label);
                    setYearValue(y.toString());

                    if (onChange)
                        onChange({month: m, year: y })
                }}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </Button>
            </div>
            <div className='calendar-selector-display calendar-selector-margin'>
                <DropdownCustom cssClass='calendarDropdownSelector calendarDropdownSelectorMonth' title={monthName} id={'dropdownCalendarSelectorMonth_' + id} options={MOIS} onChange={(value) => {
                    let yearsValue = getYears().map(x => x.value);
                    setDisabledPreviousButton(value === 0 && parseInt(yearValue) === Math.min(...yearsValue));
                    setDisabledNextButton(value === 11 && parseInt(yearValue) === Math.max(...yearsValue));
                    setMonthValue(value);
                    setMonthName(MOIS[value].label);
                    if (onChange)
                        onChange({ month: value, year: parseInt(yearValue) })
                }} />
            </div>

            <div className='calendar-selector-display calendar-selector-margin'>

                <DropdownCustom id={'dropdownCalendarSelectorYear_' + id} cssClass='calendarDropdownSelector' title={yearValue} options={getYears()} onChange={(value) => {
                    let yearsValue = getYears().map(x => x.value);
                    setDisabledPreviousButton(monthValue === 0 && parseInt(value) === Math.min(...yearsValue));
                    setDisabledNextButton(monthValue === 11 && parseInt(value) === Math.max(...yearsValue));

                    setYearValue(value.toString());

                    if (onChange)
                        onChange({ month: monthValue, year: parseInt(value) })
                }} />
            </div>
            <div className='calendar-selector-display'>
                <Button disabled={disabledNextButton} variant="outline-secondary" className='calendar-selector-button-right' onClick={() => {
                    let m = monthValue === 11 ? 0 : (monthValue + 1);
                    let y = monthValue === 11 ? parseInt(yearValue) + 1 : parseInt(yearValue);
                    let yearsValue = getYears().map(x => x.value);
                    setDisabledNextButton(m === 11 && y === Math.max(...yearsValue));
                    setDisabledPreviousButton(false);
                    setMonthValue(m);
                    setMonthName(MOIS[m].label);
                    setYearValue(y.toString());

                    if (onChange)
                        onChange({ month: m, year: y })
                }}>
                    <FontAwesomeIcon icon={faAngleRight} />
                </Button>
            </div>
        </div>
    )
}

export default CalendarSelector;
