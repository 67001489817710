/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useContext } from "react";
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import { useKeenClient, useKeenQuery } from "../keen/keenHelper";
import { LoadingIndicator } from "../../LoadingIndicator";
import StatsRoutesShown from "./StatsRoutesShown";
import StatsStopShown from "./StatsStopsShown";
import StatsSchedulesShown from "./StatsSchedulesShown";
import StatsAgencyShown from "./StatsAgencyShown";
import StatsConfig from "./StatsConfig";
import { Col, Modal, Button } from "react-bootstrap";
import ChronoContext from "../../../context/ChronoContext";
import AgenciesDropDown from "../global/AgenciesDropDown";
import { AGENCIES_EXO, AGENCY_LABEL_EXO, getSimplifiedAgencyLabels } from "../../common/helper";
import NoData from "../../common/generic/noData/NoData";
import moment from "moment";
import { getCountRoutesShown } from "../keen/keenQueries";
import { KEEN_DATA_AGENCY_CODE } from "../keen/keenConstants";

const maxDate = new Date();
maxDate.setHours(0);
maxDate.setMinutes(0);
maxDate.setSeconds(0);

const minDate = moment().subtract(1, "month");
minDate.set({ hour: 0, minute: 0 });
/** @deprecated replace by the centralize component ErrorModal */
const errorModal = (show, setShow) => {
    const handleClose = () => setShow(false);

    return (
        <div>
            <Modal show={show} onHide={handleClose} style={{ marginTop: 100 }}>
                <Modal.Header closeButton>
                    <Modal.Title>Erreur</Modal.Title>
                </Modal.Header>
                <Modal.Body>Une erreur est survenue. Veuillez contacter un administrateur de la console.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const translateKeenData = (dataList) => {
    if (dataList) {
        return dataList.map((d) => ({
            x: d[KEEN_DATA_AGENCY_CODE],
            y: d.result,
        }));
    }

    return [];
};


const useKeenResult = (keenClient, filterAgency, currentDates , uniqueness, userAgencies) => {
    let filterAgencyEnhanced = filterAgency;
    if (filterAgency === AGENCY_LABEL_EXO) {
        filterAgencyEnhanced = AGENCIES_EXO;
    }

    const keenParams = getCountRoutesShown(currentDates, filterAgencyEnhanced, uniqueness);
    const keenParamsStats = getCountRoutesShown(currentDates, [...userAgencies, ...AGENCIES_EXO], uniqueness);

  
    
    const agencyResult = useKeenQuery(keenClient, keenParams, translateKeenData, [filterAgencyEnhanced, currentDates, uniqueness]);
    const combinedAgencyResult = useKeenQuery(keenClient, keenParamsStats, translateKeenData, [currentDates, uniqueness]);

    return { agencyResult, combinedAgencyResult  }
};

const StatisticsOptcPage = (props) => {
    const staticData = useContext(ChronoContext).staticData;
    const userAgencies = staticData.user.agencies;
    const { keenClient, isLoading } = useKeenClient();
    const [agencyCode, setAgencyCode] = useState<any>();
    const [route, setRoute] = useState<any>();
    const [currentDates, setCurrentDates] = useState({ startDate: minDate, endDate: maxDate });
    const [uniqueness, setUniqueness] = useState(false);
    const [agencyLabels, setAgencyLabels] = useState<any>([]);
    const [currentAgencyLabel, setCurrentAgencyLabel] = useState<any>("");
    const [isAgencySelected, setIsAgencySelected] = useState(false);
    const {agencyResult, combinedAgencyResult} = useKeenResult(keenClient, currentAgencyLabel, currentDates, uniqueness, getSimplifiedAgencyLabels(userAgencies)); 
    const [show, setShow] = useState(false);

    const isExoSelected = currentAgencyLabel === "EXO";

    function checkAgencySelection() {
        if (isExoSelected !== undefined && !isExoSelected) selectAgency(currentAgencyLabel);
    }

    useEffect(() => {
        const agencyLabels = getSimplifiedAgencyLabels(userAgencies);
        setAgencyLabels(agencyLabels);
        setCurrentAgencyLabel(agencyLabels[0]);
    }, [userAgencies]);

    useEffect(() => {
        if (!keenClient && !isLoading) {
            setShow(true);
        }
    }, [keenClient, isLoading]);

    useEffect(() => {
        setAgencyCode(false);
        setRoute(false);
        setIsAgencySelected(false);
        checkAgencySelection();
    }, [uniqueness, currentDates, currentAgencyLabel]);

    const onClickSelectAgency = (event) => {
        selectAgency(event.x);
    };
    const onClickUnselectAgency = () => {
        setAgencyCode(false);
        setRoute(false);
        setIsAgencySelected(false);
    };
    const onClickSelectRoute = (event) => {
        setRoute(event.x);
    };
    const onClickUnselectRoute = () => {
        setRoute(false);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    function selectAgency(agency) {
        setAgencyCode(agency);
        setRoute(false);
        setIsAgencySelected(true);
    }

    const showStops = agencyCode;
    const showSchedulesOrDirections = showStops && route;

    return (
        <Fragment>
            <TopBar title={`Statistiques ${currentAgencyLabel ? currentAgencyLabel : ""}`}>
                <Col />
                {agencyLabels.length > 1 ? (
                    <AgenciesDropDown
                        currentAgencyLabel={currentAgencyLabel}
                        setCurrentAgencyLabel={setCurrentAgencyLabel}
                        agencies={agencyLabels}
                    />
                ) : agencyLabels.length ? (
                    <div style={{ marginRight: 30, fontSize: 20 }}>{agencyLabels[0]}</div>
                ) : null}
            </TopBar>
            <ContentBelowTopBar>
                {errorModal(show, setShow)}
                {agencyLabels.length === 0 ? (
                    <NoData {...props} />
                ) : (
                    <div>
                        <StatsConfig
                            setCurrentDates={setCurrentDates}
                            minDate={currentDates.startDate}
                            maxDate={currentDates.endDate}
                            setUniqueness={setUniqueness}
                            uniqueness={uniqueness}
                        />

                        {isExoSelected ? (
                            <StatsRoutesShown
                                filterAgency={currentAgencyLabel}
                                onClickSelectBar={onClickSelectAgency}
                                onClickUnselectBar={onClickUnselectAgency}
                                uniqueness={uniqueness}
                                currentDates={currentDates}
                                keenData={agencyResult.keenData}
                                keenData2={combinedAgencyResult.keenData}
                            />                           
                        ) : (
                            <StatsAgencyShown
                                onClickSelectBar={onClickSelectAgency}
                                onClickUnselectBar={onClickUnselectAgency}
                                uniqueness={uniqueness}
                                isLoading={isLoading}
                                isSelected={isAgencySelected}
                                keenData={agencyResult.keenData}
                                keenData2={combinedAgencyResult.keenData}
                            />
                        )}
                        {showStops && (
                            <StatsStopShown
                                keenClient={keenClient}
                                filterAgency={agencyCode}
                                onClickSelectBar={onClickSelectRoute}
                                onClickUnselectBar={onClickUnselectRoute}
                                uniqueness={uniqueness}
                                currentDates={currentDates}
                            />
                        )}
                        {showSchedulesOrDirections && (
                            <StatsSchedulesShown
                                keenClient={keenClient}
                                filterAgency={agencyCode}
                                filterRoute={route}
                                uniqueness={uniqueness}
                                currentDates={currentDates}
                            />
                        )}
                    </div>
                )}
            </ContentBelowTopBar>
        </Fragment>
    );
};

export default StatisticsOptcPage;
