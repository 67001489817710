import React, { useState, useEffect, useContext } from 'react';
import { TitleCard } from "../../home/cards/BaseCardHome";
import ChronoXYPlot from "../../common/ChronoXYPlot";
import { AGENCIES_EXO, getSimplifiedAgencyLabels } from "../../common/helper";
import { StatsCard, StatsContainer } from "../global/StatsComponents";
import { sendGetRequest } from "../../../auth/apiEffects";
import DiscreteColorLegend from 'react-vis/dist/legends/discrete-color-legend';
import { LoadingIndicator } from "../../LoadingIndicator";
import moment from 'moment';
import { Card, Row, Col } from "react-bootstrap";
import ChronoBadge from "../../common/ChronoBadge";
import ChronoContext from 'context/ChronoContext';

const translateData = (dataList) => {
    if (dataList) {
        return dataList.map((d) => ({
            x: d.organization,
            y: d.amountFavorite,
        }));
    }

    return [];
};

const StatsAgencyShown = ({ onClickSelectBar, onClickUnselectBar, currentDates, isUserHavingFavoriteMode}) => {
    const staticData = useContext(ChronoContext).staticData;
    const userAgencies = staticData.user.agencies;
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const initFavoris = {
        organisation:'',
        routeID:'',
        routeNomCourt:'',
        arretID:'',
        arretCode:'',
        direction:''
    };

    const [favorisList, setFavorisList] = useState<any>(initFavoris);
    const [favorisListAllAgency, setFavorisListAllAgency] = useState<any>([]);
    const [translatedFavorisList, setTranslatedFavorisList] = useState<any[]>();
    const title = (isUserHavingFavoriteMode ? 'Utilisateurs ayant un favoris' : 'Ajouts de favoris') + ' par agence';
    const ITEMS = isUserHavingFavoriteMode ? ['Actifs'] : ['Nb favoris']

    useEffect(() => {
        setIsDataLoaded(false);

        async function fetchData() {
            const favorisList = await sendGetRequest(`/statistics/favoriteAmountByOrg?organizationCodeList=${AGENCIES_EXO.toString()}
                                                 &startDate=${moment(currentDates.startDate).format("YYYY-MM-DD HH:mm")}
                                                 &endDate=${moment(currentDates.endDate).format("YYYY-MM-DD HH:mm")}
                                                 &userHavingFavorite=${isUserHavingFavoriteMode}`
            );

            const agencyLabels = getSimplifiedAgencyLabels(userAgencies);
            const favorisListAllAgency = await sendGetRequest(`/statistics/favoriteAmountByOrg?organizationCodeList=${agencyLabels.join(',')},${AGENCIES_EXO.join(',')}
                                                 &startDate=${moment(currentDates.startDate).format("YYYY-MM-DD HH:mm")}
                                                 &endDate=${moment(currentDates.endDate).format("YYYY-MM-DD HH:mm")}
                                                 &userHavingFavorite=${isUserHavingFavoriteMode}`
            );

            setFavorisList(favorisList);
            setFavorisListAllAgency(favorisListAllAgency)
            setIsDataLoaded(true)
        }
        fetchData();
    }, [currentDates, isUserHavingFavoriteMode]);

    useEffect(() => {
        if (favorisList.length > 0) {
            setTranslatedFavorisList(translateData(favorisList));
        }
    }, [favorisList])

    const getCsvData = (dataList) => {
        let results: { Organisation: string; 'Nombre de favoris': string }[] = [];
        dataList.forEach(row => {
            results.push(
                {
                    Organisation: row.organization,
                    'Nombre de favoris': row.amountFavorite
                });
        });

        return results;
    }

    const calculateConsulationCount = (dataList) => {
        let cc = 0;
        dataList.forEach(row => {
            cc += row.amountFavorite;
        });
        return cc;
    }


    return (
        <>
      <StatsContainer>
            <StatsCard>
                <Row>
                    <Col>
                    <Card  style={{cursor: 'pointer', margin: 'auto'}}>
                           <Card.Header style={{fontSize: 16, width: '100%'}}>
                          {isUserHavingFavoriteMode ? `Nombre d'utilisateurs actif ayant un favori` : `Nombre d'arrêts favoris ajoutés`}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                <Col className="align-self-center" >
                                                <ChronoBadge color={'#000'} code={'EXO'} />
                                            </Col>
                                    <Col className="align-self-center"  style={{fontSize: 40}}>
                                       {(isDataLoaded && translatedFavorisList?.length) ? calculateConsulationCount(favorisList) : 0}
                                    </Col>
                                </Row>
                            </Card.Body>
                           </Card>
                    </Col>

                    <Col>
                            <Card style={{cursor: 'pointer', margin: 'auto'}}>
                                <Card.Header style={{fontSize: 16, width: '100%'}}>
                                {isUserHavingFavoriteMode ? `Nombre totale d'utilisateurs actif ayant un favori` : `Totale de nombre d'arrêts favoris ajoutés`}
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col className="align-self-center">
                                            <ChronoBadge color="#00A0A5" code="ARTM" />
                                        </Col>
                                        <Col className="align-self-center" style={{fontSize: 40}}>
                                        {isDataLoaded ? favorisListAllAgency.map(i => i.amountFavorite).reduce((a, b) => a + b): 0}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            </Col>
                </Row>
               
                    
            </StatsCard>
        </StatsContainer>
        <StatsContainer>
            <StatsCard>
                <TitleCard>{title}</TitleCard>

                <DiscreteColorLegend height={60} width={100} items={ITEMS} style={{marginBottom: -10}} />
                {
                    !isDataLoaded ? <LoadingIndicator style={{marginTop: -200}} /> :
                        (translatedFavorisList as any)?.length > 0 ?
                            <ChronoXYPlot data={translatedFavorisList} csvData={getCsvData(favorisList)} csvFileName={'statsAgency'} isAxisYFixed={true} showExport={true} onClickSelectBar={onClickSelectBar} onClickUnselectBar={onClickUnselectBar} tickLabelAngle={(translatedFavorisList as any)?.length > 25 ? -60 : 0}/>
                        : <h3 style={{margin: 'auto'}}>Aucun résultat</h3>
                }
            </StatsCard>
        </StatsContainer>
        </>
        
    );
};

export default StatsAgencyShown;
