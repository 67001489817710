import React, { useCallback, useState } from 'react';
import { faExternalLinkAlt, faRocket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs } from '@fortawesome/free-solid-svg-icons'
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { ROUTE_ADMIN_GTFS_LOGS } from "../../../routes";
import { useInput, useInputCheckBox } from "../../common/formEffects";
import { sendPostRequest } from "../../../auth/apiEffects";
import styled from "styled-components";
import AlertAfterPost from "../../common/AlertAfterPost";
import LinkButton from "../../common/LinkButton";
import SelectAgencies from "../../common/generic/select/SelectAgencies";

const RebuildGtfsContainer = styled.div`
    padding: 20px 40px;
`;

const GtfsLogsContainer = styled.div`
    height: 40px;
    margin-top: 40px;
`;

const RebuildGTFS = () => {
    const [isStatusOk, setIsStatusOk] = useState(undefined);
    const [isSending, setIsSending] = useState(false);
    const bindAgencyCode = useInput('');
    const bindImportId = useInput(0);
    const bindIsDeleteData = useInputCheckBox(false);

    const sendRequestUpdateConfig = useCallback(async () => {
        if (isSending) return;

        setIsSending(true);
        const status = await sendPostRequest('/admin/gtfs/rebuild', {
            agencyCode: bindAgencyCode.value,
            importId: bindImportId.value,
            supprimerDonneesExistantes: bindIsDeleteData.checked
        });
        setIsSending(false);
        setIsStatusOk(status);

    }, [isSending, bindAgencyCode.value, bindImportId.value, bindIsDeleteData.checked]);

    return (
        <RebuildGtfsContainer>
            <Row>
                <Col>
                    <Alert variant="warning">
                        <h4>Avertissement</h4>
                        <p>
                            En cochant la case "Supprimer les données existantes", les données GTFS de l'organisation choisie seront entièrement supprimées avant d'importer l'import
                            spécifié.
                            Selon la quantité de données à importer, l'opération peut durer plusieurs minutes.
                        </p>
                    </Alert>
                    
                    <SelectAgencies isRequired {...bindAgencyCode} />
                    <br />

                    <Form.Group controlId="isDeleteData">
                        <Form.Check custom type="checkbox" label="Supprimer les données existantes"
                            onChange={bindIsDeleteData.onChange}
                            checked={bindIsDeleteData.checked} />
                    </Form.Group>
                    <br />

                    <Form.Group>
                        Identifiant de l'import
                        <Form.Control type="number" value={bindImportId.value} onChange={bindImportId.onChange} />
                        <small>L'identifiant doit correspondre à un import de l'outil d'offre de service.</small>
                    </Form.Group>
                    <br />
                    
                    <Button onClick={sendRequestUpdateConfig}>
                        <FontAwesomeIcon icon={faRocket} /> Lancer l'exécution
                    </Button>
                    <br />
                    <br />
                    
                    <AlertAfterPost isStatusOk={isStatusOk} />
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title as="h5">Import d'offres de service</Card.Title>
                            <Card.Text>
                                Afin de sélectionner le bon identifiant à importer, il est nécessaire
                                de se référer à l'outil d'import d'offres de service.
                            </Card.Text>
                            <LinkButton size="sm" to="https://outilos.exo.quebec" variant="secondary">
                                <FontAwesomeIcon icon={faExternalLinkAlt} /> Ouvrir
                            </LinkButton>
                        </Card.Body>
                    </Card>
                    <br />

                    <GtfsLogsContainer>
                        <LinkButton to={ROUTE_ADMIN_GTFS_LOGS}>
                            <FontAwesomeIcon icon={faCogs} /> Afficher les logs GTFS
                        </LinkButton>
                    </GtfsLogsContainer>
                </Col>
            </Row>
        </RebuildGtfsContainer>
    );
};

export default RebuildGTFS;
