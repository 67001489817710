import { GenericRequest } from "../../types/viewmodels/GenericRequest";
import { Modes } from "./modes";

export const requestDefinitions: GenericRequest = {
    getMessages: {
        url: () => "/messages",
        method: "post",
        storeKey: () => "getMessages",
        // requestName: () => "getMessages",
        mode: Modes.LATEST,
    },
    getMessageById: {
        url: (props) => {
            if (!props.alertId) return "";
            return `/messages/${props.id}`;
        },
        method: "get",
        mode: Modes.EVERY,
        storeKey: (props) => {
            if (props.id === undefined) return "";
            return `getMessageById${props.id}`;
        },
        // requestName: (props) => {
        //     if (props.id === undefined) return "";
        //     return `getMessageById${props.id}`;
        // },
    },
    saveMessage: {
        url: (props) => {
            if (props.id === undefined) return "";
            return `/messages/${props.id}`;
        },
        method: "post",
        storeKey: () => "saveMessage",
        // requestName: () => "saveMessage",
        mode: Modes.LATEST,
    },
    approveMessage: {
        url: (props) => {
            if (props.id === undefined) return "";
            return `/messages/${props.id}/approve`;
        },
        method: "post",
        // storeKey: () => "getMessages",
        storeKey: (props) => {
            if (props.id === undefined) return "";
            return `approveMessage${props.id}`;
        },
        mode: Modes.EVERY,
    },
    publishMessage: {
        url: (props) => {
            if (props.id === undefined) return "";
            return `/messages/${props.id}/publish`;
        },
        method: "post",
        // storeKey: () => "saveMessage",
        storeKey: (props) => {
            console.log(props)
            if (props.id === undefined) return "";
            return `publishMessage${props.id}`;
        },
        mode: Modes.EVERY,
    },
    refuseMessage: {
        url: (props) => {
            if (props.id === undefined) return "";
            return `/messages/${props.id}/refuse`;
        },
        method: "post",
        // storeKey: () => "getMessages",
        storeKey: (props) => {
            if (props.id === undefined) return "";
            return `refuseMessage${props.id}`;
        },
        mode: Modes.EVERY,
    },
    expireMessage: {
        url: () => {
            return `/messages/expire`;
        },
        method: "post",
        // storeKey: () => "getMessages",
        storeKey: (props) => {
            console.log(props, 'expire')
            if (props.id === undefined) return "";
            return `expireMessage${props.id}`;
        },
        mode: Modes.EVERY,
    },
    deleteMessageById: {
        url: (props) => {
            if (props.id === undefined) return "";
            return `/messages/${props.id}`;
        },
        method: "delete",
        mode: Modes.EVERY,
        // storeKey: () => "getMessages",
        storeKey: (props) => {
            if (props.id === undefined) return "";
            return `deleteMessageById${props.id}`;
        },
    },
    getAlerts: {
        url: () => "/alert/list",
        method: "post",
        storeKey: () => "getAlerts",
        mode: Modes.EVERY,
    },
    getAlertsById: {
        url: (props) => {
            if (!props || !props.alertId) return "";
            return `/alert/${props.alertId}`;
        },
        method: "get",
        mode: Modes.EVERY,
        storeKey: (props) => {
            if (!props || !props.alertId) return "";
            return `getAlertsById${props.alertId}`;
        },
    },
    deleteAlertById: {
        url: (props) => {
            if (!props || !props.alertId) return "";
            return `/alert/${props.alertId}`;
        },
        method: "delete",
        mode: Modes.EVERY,
        storeKey: (props) => {
            if (!props || !props.alertId) return "";
            return `deleteAlertById${props.alertId}${props.keySuffix ? props.keySuffix : ''}`;
        },
    },
    insertAlert: {
        url: () => "/alert",
        method: "post",
        storeKey: () => "insertAlert",
        mode: Modes.EVERY,
    },
    updateAlert: {
        url: () => "/alert",
        method: "put",
        storeKey: (props) => {
            if (!props || !props.alertId) return "";
            return `updateAlert${props.alertId}`;
        },
        mode: Modes.EVERY,
    },
    getNotifications: {
        url: () => "/notifications",
        method: "post",
        storeKey: () => "getNotifications",
        mode: Modes.EVERY,
    },
    getNotificationById: {
        url: () => "/notifications",
        method: "get",
        mode: Modes.EVERY,
        storeKey: () => "getNotificationById",
    },
    deleteNotificationById: {
        url: (props) => {
            if (props.id === undefined) return "";
            return `/notifications/${props.id}`;
        },
        method: "delete",
        mode: Modes.EVERY,
        storeKey: (props) => {
            if (props.id === undefined) return "";
            return `deleteNotificationById${props.id}${props.keySuffix ? props.keySuffix : ''}`;
        },
    },
    saveNotification: {
        url: (props) => {
            if (!props || props.id === undefined) return "";
            return `/notifications/${props.id}`;
        },
        method: "post",
        storeKey: (props) => {
            if (!props || props.id === undefined) return "";
            return `saveNotification${props.id}`;
        },
        mode: Modes.EVERY,
    },
    publishNotification: {
        url: (props) => {
            if (!props || props.id === undefined) return "";
            return `/notifications/${props.id}/publish`;
        },
        method: "post",
        storeKey: (props) => {
            if (!props || props.id === undefined) return "";
            return `publishNotification${props.id}`;
        },
        mode: Modes.EVERY,
    },
    unPublishNotification: {
        url: (props) => {
            if (props.id === undefined) return "";
            return `/notifications/${props.id}/unpublish`;
        },
        method: "post",
        storeKey: (props) => {
            if (props.id === undefined) return "";
            return `unPublishNotification${props.id}`;
        },
        mode: Modes.EVERY,
    },
    getStatus: {
        url: () => "/admin/comunication/status",
        method: "get",
        storeKey: () => "comFilterStatus",
        // requestName: () => "comFilterStatus",
        mode: Modes.EVERY,
    },
    getUserOrganizations: {
        url: () => "/users/agencies",
        method: "get",
        storeKey: () => "comFilterOrganization",
        // requestName: () => "comFilterOrganization",
        mode: Modes.EVERY,
    },
    carteListGet: {
        url: () => "/cartes",
        method: "post",
        storeKey: () => "carteListGet",
        mode: Modes.EVERY,
    },
    carteGetById: {
        url: (props) => {
            if (!props.carteId) return "";
            return `/cartes/${props.carteId}`;
        },
        method: "get",
        mode: Modes.EVERY,
        storeKey: () => "carteGetById",
    },
    carteDeleteById: {
        url: (props) => {
            if (!props.carteId) return "";
            return `/cartes/${props.carteId}`;
        },
        method: "delete",
        mode: Modes.EVERY,
        storeKey: (props) => {
            if (props === undefined || props.carteId === undefined) return "";
            return `carteDeleteById${props.carteId}`;
        },
    },
    carteUpsert: {
        url: (props) => {
            if (props === undefined || props.carteId === undefined) return "";
            return `/cartes/${props.carteId}`;
        },
        method: "post",
        mode: Modes.EVERY,
        storeKey: (props) => {
            if (props === undefined || props.carteId === undefined) return "";
            return `carteUpsert${props.carteId}`;
        },
    },
    cartePublish: {
        url: (props) => {
            if (props === undefined || props.carteId === undefined) return "";
            return `/cartes/${props.carteId}/publish`;
        },
        method: "post",
        mode: Modes.EVERY,
        storeKey: (props) => {
            if (props === undefined || props.carteId === undefined) return "";
            return `cartePublish${props.carteId}`;
        },
    },
    carteExpire: {
        url: (props) => {
            if (!props.carteId) return "";
            return `/cartes/${props.carteId}/expire`;
        },
        method: "post",
        mode: Modes.EVERY,
        storeKey: (props) => {
            if (props === undefined || props.carteId === undefined) return "";
            return `carteExpire${props.carteId}`;
        },
    },
};
