import { SET_LOGIN_PENDING, SET_LOGIN_SUCCESS, SET_LOGIN_ERROR } from '../../components/login/loginActions'

const initState = {
    isLoginSuccess: false,
    isLoginPending: true,
    token: '',
    username: '',
    loginError: false
};

const loginReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_LOGIN_PENDING:
            state = {
                ...state,
                isLoginPending: true
            };
            break;
        case SET_LOGIN_SUCCESS:
            state = {
                ...state,
                isLoginSuccess: true,
                isLoginPending: false,
                username: action.username,
                token: action.token
            };
            break;
        case SET_LOGIN_ERROR:
            state = {
                ...state,
                loginError: true,
                isLoginPending: false
            };
            break;
        default:
    }
    return state;
};

export default loginReducer;