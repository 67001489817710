import { useContext } from "react";
import ChronoContext from "../../context/ChronoContext";
import RoleHelper, {
    ROLE_CODENAME_EDITOR,
    ROLE_CODENAME_APPROBATOR,
    ROLE_CODENAME_USER_ARTM,
    isRoleAllowed,
    ROLE_CODENAME_USER,
} from "../../components/common/RoleHelper";

export const useRole = () => {
    const chronoContext = useContext(ChronoContext);
    const { staticData } = chronoContext;
    const roleUser = staticData.roles[ROLE_CODENAME_USER]
    const roleAdmin = staticData.roles[ROLE_CODENAME_EDITOR];
    const roleApprobator = staticData.roles[ROLE_CODENAME_APPROBATOR];
    const roleUserARTM = staticData.roles[ROLE_CODENAME_USER_ARTM];
    const roleCurrentUser = staticData.user.profile.role;

    const hasRoleUserARTM = roleCurrentUser === roleUserARTM;
    const hasRoleApprobator = roleCurrentUser === roleApprobator;
    const canUserEdit = isRoleAllowed(roleCurrentUser, roleAdmin) || hasRoleUserARTM;

    return { roleUser, roleAdmin, roleApprobator, hasRoleUserARTM, hasRoleApprobator, canUserEdit, roleCurrentUser };
};
