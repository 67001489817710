import React, { Fragment, useEffect, useState } from 'react';
import GenericTableCell from "../common/generic/table/cell/GenericTableCell";
import GenericTable, { GenericTableRow } from "../common/generic/table/GenericTable";
import GenericTableBody from "../common/generic/table/GenericTableBody";
import GenericTableHeadSingleRow from "../common/generic/table/GenericTableHeadSingleRow";
import { sendGetRequest, sendPostRequest, useChronoApi } from "../../auth/apiEffects";
import { LoadingIndicator } from "../LoadingIndicator";
import { Row, Col, Button } from 'react-bootstrap';
import { Header } from './BilletterieCompte';
import { convertDateToEasterTime } from "../common/helper";
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications'
import { set } from 'lodash';
import { isCompositeComponent } from 'react-dom/test-utils';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';

const FormRow = styled(Row)`
    margin-top: 5px;
`

interface IFormattedTicketsDetail {
    activationDate: string;
    validAmountInSecond: number;
    ticketUniqueNumber: string;
    organization: string;
    ticketName: string;
    amountPassageLeft: string;
    creationDate: string;
    expirationDate: string;
    validationUniqueNumber: string;
    status: string;
    utilisateurTitreId: number
    isCancelling: boolean;
}

export interface IFormattedTickets {
    dateCommande: string;
    details: IFormattedTicketsDetail[];
}
const ClientWallet = ({ searchParams, validated }) => {
    const [isLoading, setLoading] = useState(false);
    const [formattedTickets, setFormattedTickets] = useState<any>();

    useEffect(() => {
        fetchData()
    }, [searchParams])

    const fetchData = async () => {
        const temp: IFormattedTickets[] = [];
        const result = await sendGetRequest<any>(`/titre/historique?${searchParams}`);
        const tickets = result.filter(ticket => ticket.amountPassageLeft > 0).map(ticket => {
            return {
                ...ticket,
                isCancelling: false,
            }
        })
        if (tickets.length > 0) {
            const onlyDates = tickets.map(ticket => convertDateToEasterTime(ticket.creationDate, 'YYYY-MM-DD'))
            const unique = onlyDates.filter(uniqueDates);

            unique.forEach(date => temp.push({
                dateCommande: date,
                details: tickets.filter(ticket => convertDateToEasterTime(ticket.creationDate, 'YYYY-MM-DD') === date
                )
            }))
        }
        console.log(temp);
        setFormattedTickets(temp);
    }

    const [toastMessageType, setToastMessageType] = useState('');
    const [isCancelling, setIsCancelling] = useState(false);
    const { addToast } = useToasts();

    const getMessagesToast = () => {
        return {
            success: { text: `Vos commentaires ont  bien été enregistrés.`, type: 'success' },
            error: { text: `Une erreur s'est produite.`, type: 'error' }
        }
    }

    const displayToast = () => {
        if (toastMessageType === null) return;
        const content = getMessagesToast();
        const mess = content[toastMessageType] !== undefined ? content[toastMessageType] : content.error;
        return addToast(mess.text, {
            appearance: mess.type,
            autoDismiss: true,
        })
    }


    useEffect(() => {
        displayToast();
    }, [toastMessageType])

    const uniqueDates = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }

    const cancelTicket = async (utilisateurTitreId) => {
        try {
            const updateTicketStatus = formattedTickets.map(tickets => {
                return {
                    ...tickets,
                    details: tickets.details.map(ticket => {
                        if (ticket.utilisateurTitreId === utilisateurTitreId) {
                            ticket.isCancelling = true;
                        }
                        return ticket;
                    })
                }
            })
            console.log(updateTicketStatus);
            setFormattedTickets(updateTicketStatus);
            await sendPostRequest('/titre/annuler/' + utilisateurTitreId);
            setToastMessageType('success');
        }
        catch (err) {
            setToastMessageType('error');
            setIsCancelling(false);
        } finally {
            fetchData();
        }
    }

    return (
        <Fragment>
            {
                formattedTickets && formattedTickets.length > 0 &&
                <div style={{ padding: "0px 10px 0px 10px" }}>
                    {
                        formattedTickets.sort((a, b) => new Date(b.dateCommande).valueOf() - new Date(a.dateCommande).valueOf()).map((item, i) => {
                            return (
                                <div key={i}>
                                    <FormRow>
                                        <Header>{item.dateCommande}</Header>
                                    </FormRow>
                                    <FormRow>
                                        <GenericTable style={{ marginBottom: '0px', width: '100%' }}>
                                            <GenericTableHeadSingleRow style={{ backgroundColor: '#DCDCDC' }}>
                                                <th>Organisation</th>
                                                <th>Nom du titre</th>
                                                <th>Identifiant titre</th>
                                                <th>Passages restants</th>
                                                <th>Date et heure achat</th>
                                                <th>Durée validité</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </GenericTableHeadSingleRow>
                                            <GenericTableBody key={i + '_inside'} style={{ borderTop: 'none' }}>
                                                {
                                                    item.details.sort((a, b) => new Date(b.activationDate).valueOf() - new Date(a.activationDate).valueOf()).map((ticket, j) => {
                                                        const totalValidTime = ticket.validAmountInSecond / 60 / 60;
                                                        let displayUnits = 'minutes';
                                                        if (totalValidTime === 1) displayUnits = 'heure';
                                                        if (totalValidTime > 1) displayUnits = 'heures';

                                                        return (
                                                            <Fragment key={j}>
                                                                <GenericTableRow key={ticket.ticketUniqueNumber}>
                                                                    <GenericTableCell>{ticket.organization}</GenericTableCell>
                                                                    <GenericTableCell>{ticket.ticketName}</GenericTableCell>
                                                                    <GenericTableCell>{ticket.ticketUniqueNumber}</GenericTableCell>
                                                                    <GenericTableCell>{ticket.amountPassageLeft}</GenericTableCell>
                                                                    <GenericTableCell>{convertDateToEasterTime(ticket.creationDate, 'YYYY-MM-DD HH:mm')}</GenericTableCell>
                                                                    <GenericTableCell>{totalValidTime}&nbsp;{displayUnits}</GenericTableCell>
                                                                    <GenericTableCell>{ticket.status ? "Annulé" : "Utilisable"}</GenericTableCell>
                                                                    <GenericTableCell>
                                                                        <Button onClick={async () => {
                                                                            setIsCancelling(true);
                                                                            await cancelTicket(ticket.utilisateurTitreId);
                                                                            setIsCancelling(false);
                                                                        }} disabled={ticket.isCancelling}>Annuler
                                                                        </Button></GenericTableCell>
                                                                </GenericTableRow>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </GenericTableBody>
                                        </GenericTable>
                                    </FormRow>
                                </div>
                            )
                        })
                    }
                </div>
            }
            {
                (!formattedTickets || formattedTickets.length === 0) && validated &&
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <h3>Aucun résultat</h3>
                    </Col>
                </Row>
            }
        </Fragment>
    )
}

export default ClientWallet;