import { saveState } from './helper'
import {
    ADD_REQUEST_STATUS
} from '../actions/types/types';

const initialState = {};

export default (state = initialState, action) => {

    switch (action.type) {

        case ADD_REQUEST_STATUS:
            const { key, value } = action.payload;
            const requests = state;
            return saveState(requests, key, value);

        default:
            return state;
    }
}