import React, { FC, ReactElement}  from "react";
import styled from "styled-components";
import { Badge } from "react-bootstrap";





export const CustomBadge = styled(Badge)`
    margin: 2px 0px;
    padding: 5px 12px !important;
    color: white;
    display:block;
    width:100px;
    white-space: normal;
    word-wrap:break-word;
`;

const StatusBadge: FC<any> = ({ color, label }:any): ReactElement => {

    return <CustomBadge style={{backgroundColor: color}}><span style={{wordBreak: 'break-word'}}>{label}</span></CustomBadge>
};

export default StatusBadge;