import React from "react";
import { StatsCard, StatsContainer } from "../global/StatsComponents";
import { Col, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import GenericDate from "../../common/generic/GenericDate";
import { getFormattedDate, getMergedDate } from "../../common/helper";
import {
  DATE_FORMAT,
  TIME_FORMAT,
} from "../../communication/CommunicationUtils";
import GenericTime from "../../common/generic/GenericTime";
import moment from "moment";

export const CustomCol = styled(Col)`
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
`;

const StatsConfig = ({
  setCurrentDates,
  minDate,
  maxDate,
  setUniqueness,
  uniqueness,
}) => {
  const onStartDateChange = (date) => {
    const inputDate = moment(date);
    const currentDate = moment(minDate);

    setCurrentDates({
      startDate: getMergedDate(inputDate, currentDate),
      endDate: maxDate,
    });
  };
  const onStartTimeChange = (event) => {
    const currentDate = moment(minDate);
    const inputTime = event.target.value.split(":");

    setCurrentDates({
      startDate: getMergedDate(currentDate, inputTime),
      endDate: maxDate,
    });
  };

  const onEndDateChange = (date) => {
    const inputDate = moment(date);
    const currentDate = moment(maxDate);

    setCurrentDates({
      startDate: minDate,
      endDate: getMergedDate(inputDate, currentDate),
    });
  };
  const onEndTimeChange = (event) => {
    const currentDate = moment(maxDate);
    const inputTime = event.target.value.split(":");

    setCurrentDates({
      startDate: minDate,
      endDate: getMergedDate(currentDate, inputTime),
    });
  };

  return (
    <StatsContainer>
      <StatsCard>
        <Row style={{ padding: "15px 30px" }}>
          <CustomCol md={12} lg={12} xl={12}>
            <Form.Switch
              style={{ padding: "10px 0px" }}
              id="uniqueDisplay"
              label="Consultations par utilisateur unique"
              checked={uniqueness}
              onChange={() => setUniqueness(!uniqueness)}
            />
          </CustomCol>

          <CustomCol md={12} lg={6}>
            <GenericDate
              isRequired
              label="Début"
              date={getFormattedDate(minDate, DATE_FORMAT)}
              onDateChange={onStartDateChange}
              width={80}
            />
          </CustomCol>
          <CustomCol md={12} lg={6}>
            <GenericTime
              isRequired
              label="Heure"
              time={getFormattedDate(minDate, TIME_FORMAT)}
              onTimeChange={onStartTimeChange}
            />
          </CustomCol>
          <CustomCol md={12} lg={6}>
            <GenericDate
              isRequired
              label="Fin"
              date={getFormattedDate(maxDate, DATE_FORMAT)}
              onDateChange={onEndDateChange}
              width={80}
            />
          </CustomCol>
          <CustomCol md={12} lg={6}>
            <GenericTime
              isRequired
              label="Heure"
              time={getFormattedDate(maxDate, TIME_FORMAT)}
              onTimeChange={onEndTimeChange}
            />
          </CustomCol>
        </Row>
      </StatsCard>
    </StatsContainer>
  );
};

export default StatsConfig;
