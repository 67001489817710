import { useState, useEffect } from "react";
import { REQUEST_PENDING, REQUEST_FAILED, REQUEST_SUCCEEDED } from "../../redux/sagas/requestStatus";
import { useResources } from "./useResources";
import { useRequests } from "./useRequests";
import { useDispatch } from "react-redux";
import { getLatest, getEvery, postEvery, postLatest, putEvery, deleteEvery } from "../actions";
import { requestDefinitions, Modes } from "service/definitions";

export const useApiRequest = (requestName) => {
    const [resourceName, setResourceName] = useState(requestName);
    const [toogleChange, setToogleChange] = useState(false);
    const [query, setQuery] = useState<any>(undefined);

    const dispatch = useDispatch()
    const data = useResources(resourceName);
    const status = useRequests(resourceName);

    useEffect(() => {
        if (!query) return;
        const mode = query.request.mode ? query.request.mode : Modes.LATEST;
        dispatch(
            apiRequest[query.request.method][mode](
                query.request.method === "get"
                    ? { requestDefinition: query.request, searchQueryParams: query.params, extendedUrl: query.request.extendedUrl, extendedStore: query.request.extendedStore }
                    : { requestDefinition: query.request, body: query.params, extendedUrl: query.request.extendedUrl, extendedStore: query.request.extendedStore }
            )
        );
    }, [toogleChange])

    const apiRequest = {
        get: {
            LATEST: getLatest,
            EVERY: getEvery,
        },
        post: {
            LATEST: postLatest,
            EVERY: postEvery,
        },
        put: {
            EVERY: putEvery,
        },
        delete: {
            EVERY: deleteEvery,
        },
    };

    const process = (params, key?: any, keyStore?: any) => {
        if (!requestName) return;
        let request = requestDefinitions[requestName];

        request.extendedStore = keyStore;
        request.extendedUrl = key;
        console.log(keyStore)
        setResourceName(request.storeKey ? request.storeKey(keyStore) : requestName);
        setQuery({request: request, params:params});
        setToogleChange(!toogleChange);
      
    };

    const setResource = (keyStore) => {
        let request = requestDefinitions[requestName];
        setResourceName(request.storeKey ? request.storeKey(keyStore) : requestName);
    };

    return {
        result: data,
        isLoading: status.requests === REQUEST_PENDING,
        isSuccess: status.requests === REQUEST_SUCCEEDED,
        isFail: status.requests === REQUEST_FAILED,
        setResource: setResource,
        getResource: resourceName,
        actions: { process: process },
    };
};
