import * as yup from "yup";
import moment from 'moment';
import { DATE_FORMAT } from "../../../CommunicationUtils";
import { getFormattedDate } from "../../../../common/helper";

export const validationFormSchema = yup.object({
    startDate: yup.date().required().min(moment().format(DATE_FORMAT), "La date de début ne peut pas être antérieure"),
    startTime: yup
        .string()
        .required("L'heure de début ne peut être vide")
        .test("is-greater", "L'heure de début ne peut pas être antérieure", function (value) {
            const { startDate } = this.parent;
            return moment(getFormattedDate(startDate, DATE_FORMAT) + " " + value).isAfter(moment());
        }),
    titleFR: yup.string().required().max(50),
    descriptionFR: yup.string().required().max(150),
    titleEN: yup.string().required().max(50),
    descriptionEN: yup.string().required().max(150),
});
