/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as keys from "../../../../../redux/keys";
import { getReferenceStops } from "../../../../../redux/actions/references/requestStops";
import useResources from "../../../../../lib/hooks/useResources";
import useUI from "../../../../../lib/hooks/useUI";
import * as requestStatus from "../../../../../redux/sagas/requestStatus";
import { Dropdown } from "../../../../../lib/ui";
import { LoadingIndicator } from "../../../../LoadingIndicator";

const createOptions = (stopsPerRouteId) => {
    let stops: any[] = [];
    stopsPerRouteId.forEach((stopPerRouteId) => {
        stops.push({
            label: stopPerRouteId.label,
            value: "",
            disabled: true,
            filterOn: stopPerRouteId.label,
        });
        stopPerRouteId.options.forEach((option) => {
            stops.push({
                label: `${option.routeId} - ${option.code} - ${option.name} (${option.direction})`,
                value: `{"organizationCode": "${option.organizationCode}", "routeId": "${option.routeId}", "stopCode": "${option.code}", "stopId": "${option.stopId}", "stopLabel": "${option.routeId} - ${option.code} - ${option.name} (${option.direction})"}`,
                filterOn: stopPerRouteId.label,
            });
        });
    });

    return stops;
};

const createRoutesByOrganizationCode = (values) => {
    let routesByOrganizationCode: any[] = [];
    values.forEach((route, index) => {
        const obj = JSON.parse(route);
        const routesByOrganizationCodeIndex = routesByOrganizationCode.findIndex(
            (element) => route.OrganizationCode === element.OrganizationCode
        );
        if (routesByOrganizationCodeIndex > -1) {
            routesByOrganizationCode.push({
                ...routesByOrganizationCode,
                RouteGtfsIdList: [...routesByOrganizationCode[routesByOrganizationCodeIndex], obj.routeId],
            });
        } else {
            routesByOrganizationCode.push({
                OrganizationCode: obj.organizationCode,
                RouteGtfsIdList: [obj.routeId],
            });
        }
    });

    return routesByOrganizationCode;
};

const StopDropdownStep = (props) => {
    const { stepsValues, value, onChange, name } = props;
    const dispatch = useDispatch();

    const stopsPerRouteId = useResources(keys.STOP_LIST);
    const stopRequestStatus = useUI(keys.STOP_REQUEST_STATUS);
    const [options, setOptions] = useState<any[]>([]);

    useEffect(() => {
        if (stopsPerRouteId) {
            setOptions(createOptions(stopsPerRouteId));
        }
    }, [stopsPerRouteId]);

    useEffect(() => {
        setOptions([]);

        const routesByOrganizationCode = createRoutesByOrganizationCode(stepsValues[1]);
        dispatch(getReferenceStops(routesByOrganizationCode));
    }, [stepsValues[0], stepsValues[1]]);

    return (
        <>
            {stopRequestStatus === requestStatus.REQUEST_PENDING && (
               <LoadingIndicator size={24} height={"inherit"} />
            )}
            {stopRequestStatus === requestStatus.REQUEST_SUCCEEDED && options && (
                <Dropdown mode="list" name={name} options={options} selectedValues={value} onChange={onChange} filterOn={true} />
            )}
        </>
    );
};

export default StopDropdownStep;
