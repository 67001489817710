import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { TOP_BAR_SIZE } from "../common/ContentNavigation";
import { MEDIA_SCREEN_LG } from "../navMenu/NavMenu";

//padding: 1.5% 5% !important;
/* @media (min-width: ${MEDIA_SCREEN_LG}px) {
    overflow-y: scroll;
} */
export const ColForm = styled(Col)`
    background-color: white;
    box-shadow: 0 0 32px 0 rgba(4, 11, 35, 0.08);
    
    
    height: auto;
    overflow-y: hidden;
  
`;
//       overflow-y: auto;
export const ColList = styled(Col)`
    padding: 0 !important;
    
    height: auto;
    overflow-y: hidden;
    @media (min-width: ${MEDIA_SCREEN_LG}px) {
        height: calc(100vh - ${TOP_BAR_SIZE}px);
 
    }
`;

/* @media (min-width: ${MEDIA_SCREEN_LG}px) {
    height: calc(100vh - ${TOP_BAR_SIZE}px);
} */
export const ColListWithoutOverFlow = styled(Col)`
    padding: 0 !important;
    
    height: auto;
    overflow-y: hidden;
`;


export const ContainerSummary = styled(Container)`
    margin: 0;
    background-color: white;
    border-bottom: 1px solid ${props => (props.theme.colors.mediumGray)};
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.08);
    word-break: break-all;
    
    &:hover {
        background-color: ${props => (props.theme.colors.lightGray)};
    }
    
    &[data-selected="true"] {
        background-color: ${props => (props.theme.colors.secondaryDark)};
        color: white;
    }
`;

export const RowDashed = styled(Row)`
    margin: 30px 8% 30px 8%  !important;
    padding: 1% 2% !important;
    justify-content: space-around;
    border: 2px dashed ${props => props.theme.colors.mediumGray};
`;

export const SiblingRowDashed = styled(Row)`
    margin-left: 7%;
    margin-right: 7%;
`;

export const ColDashed = styled(Col)`
    background-color: white;
    margin: 10px;
    padding: 10px 20px;
    justify-content: space-around;
    border: 2px dashed ${props => props.theme.colors.mediumGray};
`;

export const BoxHeaderDashed = styled.p`
    font-weight: bold;
    color: ${props => props.theme.colors.mediumGray};
    font-size: 1em; 
    margin: 0px;
`;

export const BoxTextDashed = styled.div`
    font-weight: bold;
    color: ${props => props.theme.colors.text};
    font-size: 0.9em;
    margin: 0;
    word-break: break-all;
`;

