import { FormControl, InputGroup, Form } from "react-bootstrap";
import React, { FC, ReactElement} from "react";

const DropdownSelect:FC<any> = (props):ReactElement => {
    const {
        label,
        labelWidth,
        selectedValue,
        options,
        placeholder,
        defaultValue,
        onChange,
        isRequired,
        isDisabled,
        style,
        errorMessage,
        invalid,
        cssClass = "",
        styleInput,
    } = props;

    return (
        <>
            <InputGroup>
                {label && label.length > 0 && (
                    <InputGroup.Prepend style={style ? style : { minWidth: labelWidth ? labelWidth : 130 }}>
                        <InputGroup.Text style={style ? style : { minWidth: labelWidth ? labelWidth : 130 }}>
                            {label}
                            {isRequired ? " *" : ""}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                )}
                <FormControl
                    as="select"
                    className={cssClass}
                    disabled={isDisabled}
                    required={isRequired}
                    value={selectedValue}
                    onChange={onChange}
                    style={invalid ? { border: "1px solid #dc3545" } : styleInput}
                >
                    {placeholder && (
                        <option key="emptyChoice" value="" disabled>
                            {placeholder}
                        </option>
                    )}
                    {options &&
                        options.map((option) => {
                            return (
                                <option key={option.label} value={option.value}>
                                    {option.label}
                                </option>
                            );
                        })}
                </FormControl>
                {errorMessage ? <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback> : null}
            </InputGroup>
        </>
    );
};

export default DropdownSelect;
