import React from 'react';
import { Table } from "react-bootstrap";
import styled from "styled-components";

export const GENERIC_TABLE_HEADER_SIZE = 100;

export const CustomTr = styled.tr`
    //height: ${GENERIC_TABLE_HEADER_SIZE}px;
    
    & > th {
        vertical-align: middle !important;
    }
`;

export const GenericTableRow = ({children, ...props}) => {
    return <CustomTr {...props}>{children}</CustomTr>;
};

// TODO: need to add style properly instead of any
const GenericTable: React.FC<{className? : string; style?: any}> = ({ children, className }) => {
    return (
        <Table bordered responsive striped hover className={`text-center ${className}`}>
            { children }
        </Table>
    );
};

export default GenericTable;
