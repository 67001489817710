/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, FC, ReactElement } from "react";
import { Row, Col, Card, Button, Form, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFilter, faPlus, faMinus, faRedo } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller } from "react-hook-form";
import DropdownCheckboxes from "../../DropdownCheckboxes";
import { InputDate, ErrorMessage, Dropdown } from "../../../../lib/ui";
import { MessageFilterProps } from "../../../../types/components/MessageComponent";
import { CommunicationStatus } from "../../../../types/entities/communicationEntities";
import { useComService } from "../../../../service/communication/comService";
import { useMessageService } from "../../../../service/communication/useMessageService";
import { yupResolver } from "@hookform/resolvers/yup";
import type { MessageFilterForm } from "../../../../types/forms/MessageForms";
import { messageFilterFormValidator } from "../validators/messageFilterFormValidator";
import GenericDate from "components/common/generic/GenericDate";
import moment from "moment";

export const MessageFilter: FC<MessageFilterProps> = (): ReactElement => {
    const [collapse, setCollapse] = useState<boolean>(false);
    const [statusList, setStatusList] = useState<Array<CommunicationStatus>>([]);
    const [orgList, setOrgList] = useState<Array<any>>([]);

    const communicationService = useComService();
    const messageService = useMessageService();

    const { handleSubmit, watch, control, setValue, errors, formState, reset, setError, getValues } = useForm<MessageFilterForm>({
        defaultValues: messageService.Filter.defaultValues,
        reValidateMode: "onChange",
        resolver: yupResolver(messageFilterFormValidator),
        mode: "onChange",
    });
    const values = watch();

    useEffect(()=> {
        if(values.startDate && values.endDate) {
            if (moment(values.startDate).isBefore(values.endDate)) {
                setError('startDate', { message: '' });
            }

            if (moment(values.endDate).isAfter(values.startDate)) {
                setError('endDate', { message: '' });
            }
        }
    }, [values.startDate, values.endDate])

    const fetchData = (reset = false): void => {
        messageService.request.GetAll.process(
            messageService.mapToParam( reset ? messageService.Filter.defaultValues : getValues(), reset ? communicationService.getOptionsStatus() : statusList, reset ? communicationService.getOptionsOrg() : orgList, communicationService.getOptionsOrg())
        );

    };

    useEffect(() => {
        communicationService.request.getStatus.actions.process({ communicationType: 1 });
        communicationService.request.getOrganization.actions.process("");
    }, []);

    useEffect(() => {
        if (communicationService.request.getStatus.isSuccess) {
            setStatusList(communicationService.getOptionsStatus())
            setOrgList(communicationService.getOptionsOrg())
            fetchData(true);
        }
    }, [communicationService.request.getStatus.isSuccess]);


    const COLLECTIONS_OPTIONS = [
        { label: "Date de création", value: "2" },
        { label: "Date de début", value: "0" },
        { label: "Date de fin", value: "1" },
    ];

    return (
        <Fragment>
            <div className="config-mt-15">
                <Card className="card-alert-border">
                    <Card.Header
                        onClick={() => {
                            setCollapse(!collapse);
                        }}
                    >
                        <span className="header-filter-config">
                            <FontAwesomeIcon icon={faFilter} /> Filtres
                        </span>

                        <Button onClick={async (ev) => {
                            ev.stopPropagation()
                            reset(messageService.Filter.defaultValues);


                            messageService.cleanCache();

                            setStatusList(communicationService.getOptionsStatus())
                            setOrgList(communicationService.getOptionsOrg())

                            fetchData(true);
                        }} className="ml-4">
                            <FontAwesomeIcon icon={faRedo} /> Réinitialiser
                        </Button>


                        <Button
                            variant="light"
                            className="btn-filter-collapse"
                            onClick={() => {
                                setCollapse(!collapse);
                            }}
                        >
                            <FontAwesomeIcon icon={collapse ? faPlus : faMinus} />
                        </Button>
                    </Card.Header>
                    <Collapse in={collapse}>
                        <Card.Body className="card-alert-body">
                            <Form
                                onSubmit={handleSubmit(() => {
                                    messageService.cleanCache();
                                    fetchData();
                                })}
                            >
                                <Row className="config-mt-10 form-row-alert">
                                    <Col xl={5} lg={10} md={11} className={`p-0 ml-xl-3 ml-lg-4 ml-md-3 mt-lg-0  mt-md-4`}>
                                        <Controller
                                            control={control}
                                            name="filterByDateType"
                                            render={({ onChange, value }, { invalid }) => {
                                                return (
                                                    <Dropdown
                                                        mode="select"
                                                        label={"Filtrer par"}
                                                        options={COLLECTIONS_OPTIONS}
                                                        onChange={onChange}
                                                        selectedValue={value}
                                                        defaultValue={"creationAt"}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>

                                    <Col xl={3} lg={5} md={11} className={`p-0 ml-xl-3 ml-lg-4 ml-md-3 mt-lg-0  mt-md-4`}>
                                        <Controller
                                            control={control}
                                            name="startDate"
                                            value={values.startDate}
                                            render={({ onChange, value }, { invalid }) => {
                                                return (
                                                    <GenericDate
                                                        label="Du"
                                                        date={value}
                                                        onDateChange={onChange}
                                                        width={"80"}
                                                        cssClass="alert-input-date"
                                                        isRequired={true}
                                                    //format={messageService.Constantes.DISPLAY_DATE_FORMAT}
                                                    // title="date de debut"
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.startDate && <ErrorMessage>{errors.startDate.message}</ErrorMessage>}
                                    </Col>

                                    <Col xl={3} lg={5} md={11} className={`p-0 ml-xl-3 ml-lg-4 ml-md-3 mt-lg-0  mt-md-4`}>
                                        <Controller
                                            control={control}
                                            name="endDate"
                                            value={values.endDate}
                                            render={({ onChange, value }, { invalid }) => {
                                                return (
                                                    <GenericDate
                                                        label="Au"
                                                        date={value}
                                                        onDateChange={onChange}
                                                        width={"80"}
                                                        cssClass="alert-input-date"
                                                        isRequired={true}
                                                    //format={messageService.Constantes.DISPLAY_DATE_FORMAT}
                                                    //title="date de fin"
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.endDate && <ErrorMessage>{errors.endDate.message}</ErrorMessage>}
                                    </Col>
                                </Row>

                                <Row className="config-mt-10 form-row-alert">
                                    <Col
                                        xl={12}
                                        md={12}
                                        className={`p-0 pt-1 mt-xl-0 mt-lg-4 ml-xl-3 ml-lg-3 ml-md-3  pr-0 mr-0 mt-md-4`}
                                    >
                                        <div className={`mr-xl-0 mr-lg-0 mr-md-0 card-display-inline`}>
                                            <DropdownCheckboxes
                                                valueList={statusList}
                                                onChange={(value, evt) => {
                                                    communicationService.handleDropdownFilterChange(
                                                        value,
                                                        evt,
                                                        setStatusList,
                                                        communicationService.getOptionsStatus()
                                                    );
                                                }}
                                                cssContainerClass={"com-dropdown-checkbox-container"}
                                                cssClass={"com-dropdown-checkbox"}
                                                hideSearch={true}
                                                defaultLabel={"Tous les statuts"}
                                                label={"Statut"}
                                                labelButton={"choisis"}
                                                optionData={communicationService.getOptionsStatus()}
                                                labelNone={"Tous les statuts"}
                                                numberOptions={
                                                    communicationService.request.StatusGet.result.data &&
                                                        communicationService.request.StatusGet.result.data.length >= 1
                                                        ? communicationService.getOptionsStatus().length - 1
                                                        : null
                                                }
                                                getOptionValue={(option) => option.value}
                                            />
                                        </div>
                                        <div className={`ml-xl-3 ml-lg-3 ml-md-3 mr-xl-3 mr-lg-3 mr-md-3 card-display-inline`}>
                                            <DropdownCheckboxes
                                                valueList={orgList}
                                                onChange={(value, evt) => {
                                                    communicationService.handleDropdownFilterChange(
                                                        value,
                                                        evt,
                                                        setOrgList,
                                                        communicationService.getOptionsOrg()
                                                    );
                                                }}
                                                cssContainerClass={"com-dropdown-checkbox-container"}
                                                cssClass={"com-dropdown-checkbox"}
                                                hideSearch={true}
                                                defaultLabel={"Toutes les organisations"}
                                                label={"Organisation"}
                                                labelButton={"choisis"}
                                                optionData={communicationService.getOptionsOrg()}
                                                labelNone={"Toutes les organisations"}
                                                numberOptions={
                                                    communicationService.request.OrgGet.result.data &&
                                                        communicationService.request.OrgGet.result.data.length >= 1
                                                        ? communicationService.getOptionsOrg().length - 1
                                                        : null
                                                }
                                                getOptionValue={(option) => option.value}
                                            />
                                        </div>
                                        <div className="card-display-inline">
                                            <Button type="submit">
                                                <FontAwesomeIcon icon={faSearch} /> Rechercher
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Collapse>
                </Card>
            </div>
        </Fragment>
    );
};
