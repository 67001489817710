import { createGlobalStyle } from 'styled-components';

export const SCROLLBAR_WIDTH = 13;

export default createGlobalStyle`
    * { padding: 0; margin: 0; }
    
    html * {
        font-family: Nunito, sans-serif !important;
        //color: ${props => (props.theme.colors.text)}
    }
    html, body {
        min-height: 100% !important;
        height: 100%;
        background-color: ${props => props.theme.colors.lightGray} !important;
        overflow: hidden;
    }
    a, a:hover {
        text-decoration: none !important;
    }
    
    #root {
      height: 100%;
    }
    
    .contentWebPage {
        margin-left: 250px;
        min-height: 100% !important;
        height: 100%;
    }
    @media screen and (max-width: 700px) {
        .contentWebPage {
            margin-left: 0px;
        }
    }
    
    .react-datepicker-wrapper {
        width: 100%;
        height: 100%;
    }

    .react-datepicker__input-container {
        height: 100%;
    }

    .react-datepicker__close-icon::after {
        background-color: #495057;
    }

    .react-datepicker-popper {
        z-index: 9999 !important;
    }

    *::-webkit-scrollbar {
        width: ${SCROLLBAR_WIDTH}px;
    }
    *::-webkit-scrollbar-track {
        background: ${props => (props.theme.colors.lightGray)};
    }
    *::-webkit-scrollbar-thumb {
        background: ${props => (props.theme.colors.secondary)};
    }
    *::-webkit-scrollbar-thumb:hover {
        background: ${props => (props.theme.colors.darkGray)};
    }
    
    .activeNavItem {
        color: ${props => (props.theme.colors.primary)};
        background-color: ${props => (props.theme.colors.lightGray)};
        opacity: 1;
    }
    .activeNavItem:hover {
        color: ${props => (props.theme.colors.primary)};
        background-color: ${props => (props.theme.colors.lightGray)};
        cursor: default;
    }
    
    .adminCardHeader {
        color: white;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
        
    #transparentDropDown {
        background-color: transparent;
        border-color: transparent;
        color: black;
        margin: 20px 0px;
        height: 40px;
    }
    #transparentDropDown:hover {
        background-color: ${props => (props.theme.colors.secondary)};
        border-color: transparent;
        color: white;
    }
    #transparentDropDown:focus {
        box-shadow: 0 0 0 0.2rem ${props => (props.theme.colors.secondary)};
    }
    #transparentDropDown
    #transparentDropDown .btn-primary:not(:disabled):not(.disabled):active,
    #transparentDropDown .btn-primary:not(:disabled):not(.disabled).active,
    #transparentDropDown .show > .btn-primary.dropdown-toggle {
        background-color: transparent;
        border-color: transparent;
        color: black;
    }
    
    .btn-primary.dropdown-toggle::after {
        margin: -0.1em 0.7em !important;
    }
    
    .table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
        background-color: ${props => (props.theme.colors.secondaryDark)};
        color: white;
    }
    
    .custom-select {
        display: block !important;
        box-sizing: border-box !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        background-color: #fff !important;
        
        border-radius: 5px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        
        color: ${props => (props.theme.colors.darkGray)} !important;
        // border: 1px solid ${props => (props.theme.colors.mediumGray)} !important;
    }
    
    .custom-select:focus {
        color: white !important;
        box-shadow: 0 0 0 0.2rem ${props => (props.theme.colors.secondary)} !important;
        background: ${props => (props.theme.colors.secondaryDark)} !important;
    }
    
    .custom-select:hover {
        color: black !important;
        box-shadow: 0 0 0 0.2rem ${props => (props.theme.colors.secondary)} !important;
        background: white !important;
    }
    
    .custom-select:disabled {
        color: #495057 !important;
        box-shadow: 0 0 0 0.01rem ${props => (props.theme.colors.mediumGray)} !important;
        background: ${props => (props.theme.colors.lightGray)} !important;
    }

    .custom-select form-control {
        background-image: none !important;
    }

    .form-control {
        background-image: none !important;
        box-shadow: none !important;
    }

    .css-yk16xz-control {
        border: 0 !important;
    }

    .css-1r4vtzz {
        width: 100% !important;
    }

    .css-48ayfv {
        width: 100% !important;
    }

    #fixedYAxis .rv-xy-plot__axis__tick__text {
        transform: translate(-10px, 0);
    }
`;