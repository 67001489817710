import { useEffect } from "react";


export const useCommunicationEvent = (operations, success, fail) => {
   
    useEffect(() => {
        if (success === true || fail === true) {
            if (operations?.pre)
                operations.pre();

            if (operations?.executeCallback)
                operations?.executeCallback();

            if (success === true && operations?.successCallback)
                operations?.successCallback();

            if (fail === true && operations?.failCallback)
                operations?.failCallback();

            if (operations?.post)
                operations.post();

            if (operations?.afterPost)
                operations.afterPost();
        }
    }, [success, fail]);

};
