import React from 'react'

export const initialState = {
    roles: {},
    status: {},
    user: {
        agencies: [],
    },
};

export default React.createContext(initialState);