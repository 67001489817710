import { useState, useEffect } from 'react';
import env from '../env'
import { fetchWithProvider } from './authProvider';
import { Options } from '../types/entities/authEntities'

export const useChronoApi = (url, initData = [], forceRefresh = false, showLoading = false): any => {
    const [data, setData] = useState<any>(initData);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>(false);
    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(true);


  useEffect(() => {
    const fetchData = async () => {
      if (showLoading)
        setIsDataLoaded(false);

      if (!forceRefresh) {
        setLoading(true);
      }
      setError(false);

      try {
        if (url) {
          let apiCallUrl = env.baseApiUrl + url
            const response = await fetchWithProvider(fetch, apiCallUrl, {});
          if (response.ok) {
            const data = await getResponseData(response, apiCallUrl);
            console.log('fetch RECEIVED:', apiCallUrl, data);

            setData(data);
          } else {
            if (response.statusText) {
              console.error('useChronoApi, statusText error', response.statusText);
            } else {
              console.error('useChronoApi, error', await response.text());
            }

            setError(new Error(response.statusText));
          }
        }
      } catch (error) {
        setError(error);
        console.error(url, initData);
        console.error('useChronoApi error:', error);
      }
      setIsDataLoaded(true);
      setLoading(false);
    };

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, forceRefresh]);

  return { data, isLoading, error, isDataLoaded };
};

const getResponseData = async (response, url?) => {
  let data;
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    data = await response.json();
  } else {
    data = await response.text();
  }

    return data;
};

const sendRequest = async <T>(method, url, params = {}, hasBody = true): Promise<T | undefined | false>  => {
    try {
        let options: Options = {
                                 method,
                                 headers: {'Content-Type': 'application/json'}
                                };
    if (hasBody === true && params)
        options.body = JSON.stringify(params);

      console.log('request SENT:', method, url, params);
        const response = await fetchWithProvider(fetch, url, options);

    if (response.ok) {
        const responseData = await getResponseData(response);
        console.log('response RECEIVED:', responseData);
        return responseData as Promise<T | undefined>;
    } else {
      if (response.statusText) { 
          console.error('sendRequest, statusText ERROR', method, response.statusText);
          throw "error"
      } else {
          console.error('sendRequest, ERROR:', method, await response.text());
          throw "error"
      }
      return false;
    }
  } catch (error) {
    console.error(method, url, params);
    console.error('sendRequest error:', error);
    throw error;
  }
};

export const sendPostRequest = async <T>(url, params?:any): Promise<T | undefined | false> => {
  url = env.baseApiUrl + url
  return await sendRequest<T>('POST', url, params)
};

export const sendPutRequest = async<T>(url, params?:any): Promise<T | undefined | false> => {
  url = env.baseApiUrl + url
  return await sendRequest<T>('PUT', url, params)
};

export const sendDeleteRequest = async <T>(url, params?:any): Promise<T | undefined | false> => {
  url = env.baseApiUrl + url
  return await sendRequest<T>('DELETE', url, params)
};

export const sendGetRequest = async <T>(url): Promise<T | undefined | false> => {
  url = env.baseApiUrl + url
  return await sendRequest<T>('GET', url, {}, false)
};

