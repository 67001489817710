import { GET_APPLICATION_LOG_DETAILS_REQUESTED, GET_DROPDOWNLIST_ITEMS_LOG_DETAILS } from "../types/types";

export const getApplicationLogDetails = (requestName, queryParams) => ({
    type: GET_APPLICATION_LOG_DETAILS_REQUESTED,
    payload: {
        requestName,
        queryParams
    }
});

export const getDropdownListItems = (requestName, queryParams) => ({
    type: GET_DROPDOWNLIST_ITEMS_LOG_DETAILS,
    payload: {
        requestName,
        queryParams
    }
});

