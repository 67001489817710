import React from "react";

declare global {
    interface Array<T> {
        sortAscending(propertyName: string): Array<T>;
    }
}

Array.prototype.sortAscending = function (propertyName) {
    let res: any[] = [];

    if (this && this.length > 0)
        return res.concat(this).sort((a, b) => { return a[propertyName] - b[propertyName] });

    return res;
};

