import React from "react";
import GenericSelect from "../GenericSelect";
import { LIST_ROLES } from "../../RoleHelper";

const SelectRoles = (props) => {
    return (
        <GenericSelect title="Rôle d'accès" {...props}>
            {
                LIST_ROLES.map((role) =>
                    <option key={role.codename} value={role.codename}>{role.label}</option>
                )
            }
        </GenericSelect>
    );
};

export default SelectRoles;
