/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import GenericInput from '../../common/generic/GenericInput';
import { Modal, Button, Col } from 'react-bootstrap';
import { faTrashAlt, faTimes, faSave, faSpinner, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ContentBelowTopBar, TopBar } from '../../common/ContentNavigation';
import { sendDeleteRequest, sendPostRequest, sendGetRequest } from "../../../auth/apiEffects";
import { useInput } from "../../common/formEffects";
import CenteredComponent from "../../common/CenteredComponent";
import { LoadingIndicator } from "../../LoadingIndicator";
import { showPopupConfirm } from "../../common/Popups";
import GenericCell from "../../common/generic/table/cell/GenericCell";
import GenericTable, { GenericTableRow } from "../../common/generic/table/GenericTable";
import GenericTableBody from "../../common/generic/table/GenericTableBody";
import GenericTableHeadSingleRow from "../../common/generic/table/GenericTableHeadSingleRow";
import SvgIcon from "../../common/SvgIcon";


const MobileConfigPage = () => {
    const [isModalShown, setIsModalShown] = useState(false);
    const [mobileConfigs, setMobileConfigs] = useState(undefined);
    const [originalConfigs, setOriginalConfigs] = useState([]);

    const [isLoadingConfig, setIsLoadingConfig] = useState(true);

    const bindCode = useInput('');
    const bindValue = useInput('');
    const bindDescription = useInput('');

    const bindType = useInput('APP');


    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        setIsLoadingConfig(true);
        async function fetchData() {
            await loadConfigs();
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (!mobileConfigs) return;
        setIsLoadingConfig(false);
    }, [mobileConfigs]);

    const loadConfigs = async () => {
        const results = await sendGetRequest(`/admin/appconfigs`);
        const formatedResults = results ? results.map((obj) => {
            return { ...obj, isEdit: false, isSending: false };
        }) : [];
        setMobileConfigs([...formatedResults]);
        setOriginalConfigs(formatedResults.map((config) => {
                            return { ...config };
                            }));
    }

    const onEdit = (code) => {
        const configList = [...mobileConfigs];
        const config = configList.find(x => x.code === code);
        if (config)
            config.isEdit = true;

        setMobileConfigs(configList);
    }

    const onCancel = (code) => {

        const configList = [...mobileConfigs];
        const config = configList.find(x => x.code === code);
        const originalConfig = originalConfigs.find(x => x.code === code);

        if (config && originalConfig) {
            config.code = originalConfig.code;
            config.description = originalConfig.description;
            config.value = originalConfig.value;
            config.isEdit = false;
        }

        setMobileConfigs(configList);
    }

    const onFieldChange = (propertyName, value, code) => {
        const configList = [...mobileConfigs];
        const config = configList.find(x => x.code === code);

        if (config)
            config[propertyName] = value;

        setMobileConfigs(configList);
    }

    const setSendigConfig = (config, isSending) => {
        const configList = [...mobileConfigs];
        let configItem = configList.find(x => x.code === config.code);

        if (!configItem)
            return;
        configItem = config;
        configItem.isSending = isSending;
        setMobileConfigs(configList);
    }

    const sendRequestUpdateConfig = async (config) => {
        if (isSending) return;
        setIsModalShown(false);

        if (!isModalShown)
            setSendigConfig(config, true);

        setIsSending(true);
        var isGtfs = bindType.value === 'GTFS' ? true : config.isGtfs;
        var url = isGtfs ? '/admin/gtfsconfigs' : '/admin/appconfigs';

        let conf = {
            configId: config.configId,
            isGtfs: isGtfs,
            code: config.code ? config.code : bindCode.value,
            value: config.value ? config.value : bindValue.value,
            createdBy: config.createdBy,
            updatedBy: config.updatedBy
        }

        if (!isGtfs)
            conf.description = config.description ? config.description : bindDescription.value;

        await sendPostRequest(url, conf);
        await loadConfigs();
        setIsSending(false);

        bindType.reset();
        bindCode.reset();
        bindValue.reset();
        bindDescription.reset();

    };

    const sendRequestDeleteConfig = useCallback(async (config) => {
        if (isSending) return;

        setIsSending(true);
        var url = config.isGtfs ? '/admin/gtfsconfigs/' + config.configId : '/admin/appconfigs/' + config.code;
        const statusOk = await sendDeleteRequest(url);

        if (statusOk)
            await loadConfigs();

        setIsSending(false);

    }, [isSending]);


    if (isLoadingConfig) {
        return <LoadingIndicator />
    }

   

    return (
        <Fragment>
            <TopBar title="Configuration">
                <Col />
                <Col xs="auto">
                    <Button style={{margin: '0 10px 0 0'}} onClick={() => setIsModalShown(true)}>
                        Nouvelle configuration <SvgIcon icon="plus" />
                    </Button>
                </Col>
            </TopBar>
            <ContentBelowTopBar style={{paddingLeft: 0, paddingRight: 0}}>
                <CenteredComponent>
                    <b>
                        Veuillez noter qu'un délai de quelques minutes est nécessaire
                        pour l'ajout/modification/suppression d'une configuration.
                    </b>
                </CenteredComponent>

                <Modal show={isModalShown} onHide={() => setIsModalShown(false)} backdrop='static'>
                    <Modal.Header closeButton>
                        <Modal.Title>Nouvelle configuration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <GenericInput isRequired placeholder="Clé" maxLength="200" {...bindCode} />
                        <GenericInput isRequired placeholder="Valeur" maxLength="500" {...bindValue} />
                        <GenericInput isRequired placeholder="Description" maxLength="100" {...bindDescription} />

                        <small className="text-muted">*Champs obligatoires</small>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setIsModalShown(false)}>
                            Fermer
                        </Button>
                        <Button disabled={bindCode.value.length === 0 || bindValue.value.length === 0 || bindDescription.value.length === 0}
                                onClick={sendRequestUpdateConfig}>
                            Enregistrer
                        </Button>
                    </Modal.Footer>
                </Modal>

                <GenericTable>
                    <GenericTableHeadSingleRow className='config-page-table-header'>
                        <th className='config-page-table-cell'>Clé</th>
                        <th className='config-page-table-cell'>Descriptif</th>
                        <th className='config-page-table-cell'>Valeur</th>
                        <th className='config-page-table-last-cell'>Action</th>
                    </GenericTableHeadSingleRow>
                    <GenericTableBody>
                        {
                            mobileConfigs && mobileConfigs.map(config => {

                            return (
                                <GenericTableRow key={config.code}>
                                    <td className='config-page-table-cell'>{config.code}</td>

                                    <td  className='config-page-table-cell'><GenericCell maxSize={100} disabled={config.isSending} invalid={!config.description || config.description.trim().length === 0} isEdit={config.isEdit} value={config.description} rowId={config.code}
                                        onChange={(value, code) => {
                                            onFieldChange('description', value, code);
                                        }} />
                                    </td>
                                <td className='config-page-table-cell'><GenericCell maxSize={500} disabled={config.isSending } isEdit={config.isEdit} value={config.value} rowId={config.code}
                                        onChange={(value, code) => {
                                            onFieldChange('value', value, code);
                                    }} />
                                    </td>
                                    {
                                        !config.isEdit && !config.isSending &&
                                            <td className='config-page-table-last-cell'>
                                            <Button variant="outline-primary" className='mr-1'
                                                onClick={() => {
                                                    onEdit(config.code)
                                                }}><FontAwesomeIcon
                                                icon={faPencilAlt} />
                                            </Button>
                                            <Button variant="outline-danger"
                                                onClick={() =>
                                                    showPopupConfirm({
                                                        message: 'Voulez-vous vraiment supprimer cette configuration?',
                                                        onClickYes: () => sendRequestDeleteConfig(config),
                                                    })
                                                }
                                                title="Supprimer">
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                        </td>
                                    }
                                    {
                                        config.isEdit && !config.isSending &&
                                        <td>
                                            <Button variant="outline-danger" title="Annuler"
                                                onClick={() => {
                                                    onCancel(config.code);
                                                }}
                                                className='mr-1'>
                                                <FontAwesomeIcon icon={faTimes} />
                                            </Button>
                                            <Button variant="outline-primary" title="Enregistrer" disabled={!config.description || config.description.trim().length === 0} onClick={async () => {
                                                await sendRequestUpdateConfig(config)
                                            }}><FontAwesomeIcon
                                                icon={faSave} />
                                            </Button>
                                        </td>
                                    }
                                    {
                                        config.isSending &&
                                        <td>
                                            <Button variant="outline-secondary" title="Sauvegarde en cours"><FontAwesomeIcon
                                                icon={faSpinner} spin={true}/>
                                            </Button>
                                        </td>
                                    }
                                </GenericTableRow>
                            )
                        })
                    }
                    </GenericTableBody>
                </GenericTable>
            </ContentBelowTopBar>
        </Fragment>
    );
};

export default MobileConfigPage;
