import React, { Fragment, useCallback, useState } from 'react';
import { faCloudDownloadAlt, faRocket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sendPostRequest, useChronoApi } from "../../../auth/apiEffects";
import { Alert, Form, Button, Card, Col, Row, Table, Spinner } from "react-bootstrap";
import { useInput, useInputCheckBox } from "../../common/formEffects";
import CenteredComponent from "../../common/CenteredComponent";
import AlertAfterPost from "../../common/AlertAfterPost";

const PublishGTFS = () => {
    return (
        <Row>
            <Col xs={12} lg={6}>
                <Card>
                    <Card.Body>
                        <Card.Title>Publications actives</Card.Title>
                        <br />
                        <ActivePublications />
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} lg={6}>
                <Card>
                    <Card.Body>
                        <Card.Title>Reconstruire et notifier</Card.Title>
                        <br />
                        <RebuildGTFS />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
};

const ActivePublications = () => {
    const {data: activeGtfsPublication, isLoading: isLoadingGtfsPublication} = useChronoApi('/admin/gtfs/active');

    return (
        <Table>
            <thead>
                <tr>
                    <th>Version du modèle</th>
                    <th>Version du fichier</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {
                isLoadingGtfsPublication ? <tr><td colSpan="3"><CenteredComponent><Spinner animation="border" /></CenteredComponent></td></tr> : null
            }
            {
                activeGtfsPublication.map((item, index) =>
                    <tr key={index}>
                        <th style={{paddingLeft:'25px'}}>{item.versionModele}</th>
                        <td style={{ paddingLeft: '25px' }}>{item.versionFichier}</td>
                        <td>
                            <Button
                                variant="secondary"
                                href={item.urlFichier}
                            >
                                <FontAwesomeIcon icon={faCloudDownloadAlt} />
                                &nbsp;Télécharger
                            </Button>
                        </td>
                    </tr>
                )
            }
            </tbody>
        </Table>
    );
};

const RebuildGTFS = () => {
    const [isStatusOk, setIsStatusOk] = useState(undefined);
    const bindIsRebuiltDatabase = useInputCheckBox(false);
    const bindTimeBeforePush = useInput(1);

    const sendRequestPublishGtfs = useCallback(async () => {
        const status = await sendPostRequest('/admin/gtfs/publish', {
            isRebuildDatabase: bindIsRebuiltDatabase.checked,
            timeBeforePush: bindTimeBeforePush.value,
        });
        setIsStatusOk(status);
    }, [bindIsRebuiltDatabase, bindTimeBeforePush]);

    return (
        <Fragment>
            <Card.Text>
                La reconstruction permet de générer une nouvelle version de la base Realm GTFS. Un push
                sera ensuite envoyé à tous les utilisateurs,
                pour télécharger la dernière version des horaires.
            </Card.Text>

            <Form.Group controlId="isRebuiltDatabase">
                <Form.Check custom type="checkbox" label="Reconstruire la base Realm"
                            onChange={bindIsRebuiltDatabase.onChange}
                            checked={bindIsRebuiltDatabase.checked} />
            </Form.Group>
            <Form.Group>
                Délai avant l'envoi du push (heures)
                <Form.Control type="number" value={bindTimeBeforePush.value} onChange={bindTimeBeforePush.onChange}  min="0" max="24" />
            </Form.Group>
            {
                bindTimeBeforePush.value && bindTimeBeforePush.value === 0 &&
                <Alert variant="warning">
                    <h5>Avertissement</h5>
                    <p>
                        La base de donnée Realm ne peut pas être générée instantanément, veuillez prendre en compte que le délai sera de minimum de 30 minutes.
                    </p>
                </Alert>

            }
            <Button onClick={sendRequestPublishGtfs}>
                <FontAwesomeIcon icon={faRocket} />
                &nbsp;Lancer l'exécution
            </Button>
            <br />
            <br />
            <AlertAfterPost isStatusOk={isStatusOk} />
        </Fragment>
    );
};


export default PublishGTFS;
