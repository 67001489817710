/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TitleCard, ITEMS } from "../../home/cards/BaseCardHome";
import ChronoXYPlot from "../../common/ChronoXYPlot";
import { AGENCIES_EXO, AGENCY_LABEL_EXO, getTextWidth } from "../../common/helper";
import { GraphsCard, StatsContainer } from "../global/StatsComponents";
import { KEEN_DATA_STOP_CODE } from "../keen/keenConstants";
import { getCountSchedulesShown } from "../keen/keenQueries";
import { executeKennQuery } from "../keen/keenHelper";
import { sendGetRequest } from "../../../auth/apiEffects";
import { getStopsUrl } from "../../../auth/fetchUrlHelper";
import DiscreteColorLegend from 'react-vis/dist/legends/discrete-color-legend';
import { FormControl, InputGroup, Row, Col } from "react-bootstrap";
import { LoadingIndicatorInApp } from "../../LoadingIndicator";


const translateKeenData = (dataList) => {
    if (dataList) {
        return dataList
            .map((d) => ({
                x: d[KEEN_DATA_STOP_CODE],
                y: d.result
            }));
    }

    return [];
};

const DIRECTIONS_ROUTES = [{ directionId: 0, directionName: 'Direction 0' },
                           { directionId: 1, directionName: 'Direction 1' }]

const StatsStopShown = ({ keenClient, filterAgency, filterRoute, currentDates, uniqueness }) => {

    const [enhancedData, setEnhancedData] = useState<any>([]);
    const [direction, setDirection] = useState<any>('');
    const [isLoading, setIsLoading] = useState(false);
    const [isStateChange, setIsStateChange] = useState(false);

    const [keenData, setKeenData] = useState<any>([]);
    const [keenDataDirections, setKeenDataDirections] = useState<any>([]);
    const [stops, setStops] = useState<any>([]);


    const getKennStops = async () => {
        let filterAgencyEnhanced = filterAgency;
        if (filterAgency === AGENCY_LABEL_EXO) {
            filterAgencyEnhanced = AGENCIES_EXO;
        }
        const keenParams = getCountSchedulesShown(currentDates, filterAgencyEnhanced, filterRoute, direction, uniqueness);
        return await executeKennQuery(keenClient, keenParams, translateKeenData, '');
    };

    function adjustDirections  (data)  {
        const directions: { directionId: number | string; directionName: string[]; }[] = [];

        for (let i = 0; i <= 1; i++) {
            let directionId = i;
            const directionNames = data.filter((x) => x.directionId === directionId);
            if (directionNames && directionNames.length > 0)
                directions.push({ directionId: directionId, directionName: directionNames.map((x) => x.directionName).join(" / ") });
        } 

        return directions;
    }

    useEffect(() => {
        if (!isStateChange)
            return;

        setIsLoading(true);
        async function fetchData() {
            const keenStops = await getKennStops();
            const keenDirections = await sendGetRequest(`/references/${filterAgency}/routes/${filterRoute}/directions`);
            const dataStops = await sendGetRequest(getStopsUrl(filterAgency, filterRoute));

            setStops(dataStops);
            setKeenData(keenStops);
            setKeenDataDirections(keenDirections && (keenDirections as any)?.length > 0 ? adjustDirections(keenDirections) : DIRECTIONS_ROUTES);

            setIsLoading(false);
            setIsStateChange(false)
        }
        fetchData();
        
    }, [isStateChange]);

    useEffect(() => {
        setDirection('');
        setIsStateChange(true);

    }, [filterAgency, filterRoute, currentDates, uniqueness]);

    useEffect(() => {
        setIsStateChange(true);

    }, [direction]);

    useEffect(() => {
        
        let fullStopList: { x: string; y: number }[] = [];

        if (isLoading)
            return;

        if (keenData && keenData.length > 0) {
            const enhancedData = keenData.map(data => {
                const stopCode = data.x;
                const stop = stops.find(stop => stop.code === stopCode);
                return { x: stop ? stop.name : 'Stop code: ' + stopCode, y: data.y, id: stop ? stop.code : null }
            });

            stops.map(stop => {

            enhancedData.map(item => {
                if (item.id ===  stop.code) return fullStopList.push({x: stop.name, y: item.y})
                return fullStopList.push({x: stop.name, y: 0})
            })
                  
                return {x: stop.name ? stop.name : 'Stop code 1', y: 1}
            })

            setEnhancedData(fullStopList);
        }
        
    }, [keenData, stops, keenDataDirections, isLoading]);

    const marginBottom = getTextWidth('abcdefghijklmnopqrstuvwxyz', '13px arial');

    const onDirectionSelect = evt => {
        setDirection(evt.target.value)
    }

    const getCsvData = (dataList) => {
        let results: { Organisation: string; Route: string; 'Arrêt': number | string; "Nombre de clicks": number | string; }[] = [];
        dataList.forEach(row => {
            results.push(
                {
                    Organisation: filterAgency,
                    Route: filterRoute,
                    'Arrêt': row.x,
                    "Nombre de clicks": row.y
                });
        });

        return results;
    }

    return (
        <StatsContainer>
            <GraphsCard>
                <Row>
                    
                    <div style={{textAlign:'center', width:'100%', paddingBottom:'15px'}}>
                        <TitleCard>Affichage des arrêts</TitleCard>
                    </div>

                </Row>
                <Row style={{paddingBottom:'15px'}}>
                    <Col>
                        <DiscreteColorLegend height={60} width={100} items={ITEMS} style={{ marginBottom: -10 }} orientation={'vertical'} />
                    </Col>
                    <Col>
                        {<InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Direction</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl as="select" value={direction} onChange={onDirectionSelect}>
                                <option key="emptyChoice" value="" />
                                {
                                    keenDataDirections &&
                                    keenDataDirections.map(item => <option key={item.directionId} value={item.directionId}>{item.directionName}</option>)
                                }
                            </FormControl>
                        </InputGroup>}

                    </Col>
                    
                </Row>
                {
                    isLoading &&
                    <LoadingIndicatorInApp style={{paddingTop:'15px'}} />
                }
                {
                    !isLoading && keenData.length === 0 &&
                    <h4 className="text-center" style={{ padding: 150 }}>Aucune données à afficher</h4>
                }
                { 
                    !isLoading && keenData.length > 0 &&
                    <ChronoXYPlot data={enhancedData} csvData={getCsvData(enhancedData)} csvFileName={'statsStopClick'} showExport={true} isAxisYFixed={true} tickLabelAngle={-60}
                        margin={{ bottom: marginBottom }}
                        height={430}
                    />
                }
            </GraphsCard>
        </StatsContainer>
    );
};

export default StatsStopShown;