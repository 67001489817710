/* eslint-disable react-hooks/exhaustive-deps */
import {
    FlexibleWidthXYPlot,
    Hint,
    HorizontalGridLines,
    VerticalBarSeries,
    VerticalGridLines,
    LineMarkSeries,
    XAxis,
    YAxis,
    Crosshair
} from "react-vis";
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { ThemeContext } from "styled-components";
import { useIsMountedRef } from "./helper";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { getCsvData, createDownloadLink } from "../common/helper";




const ChronoXYPlot: React.FC<any> = ({ data, onClickSelectBar, onClickUnselectBar = onClickSelectBar, defaultScrollXPositionEnd = false, tickLabelAngle = 0, margin, height = 300, contentHeight = '100%', width, isLine, colorStroke, id, isAxisYFixed = false, hasMultipleBar = false, overwriteDefaultColor = false, avoidResizing = false, showExport = false, highestYValue = -1, widthAxisY, marginAxisY, renderCrossHairText, csvData = [], csvFileName='exportCsv' }) => {
    const theme = useContext(ThemeContext);
    const [hoveredCell, setHoveredCell] = useState<{x?: any; y?: any} | boolean>(false);
    const [clickedCell, setClickedCell] = useState<{x?: any; y?: any} | boolean>(false);
    const [enhancedData, setEnhancedData] = useState(data);
    const isMountedRef = useIsMountedRef();
    const [points, setPoints] = useState<Array<{x?: Number; y?: Number }>>([] as any);

    const dataLength = isLine || hasMultipleBar ? enhancedData[0].data.length : enhancedData.map(item => item.x).filter((value, index, self) => self.indexOf(value) === index).length

    const [graphWidth, setGraphWidth] = useState(width ? width : (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - 400));
    const extraLength = dataLength > 280 ? (dataLength * 60) : dataLength > 200 ? (dataLength * 40) : dataLength > 140 ? (dataLength * 30) : dataLength > 50 ? (dataLength * 25) : dataLength > 25 ? 600 : 0;
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    const highestValue = highestYValue !== -1 ? highestYValue : getHighestYValue(data)

    let stillMounted = { value: false }
    useEffect(() => {
        stillMounted.value = true
        return () => { stillMounted.value = false }
        // eslint-disable-next-line 
    }, [stillMounted.value])

    if (!isLine && !hasMultipleBar)
        enhancedData.sort(function (a, b) {
            return (a.x.toLowerCase() > b.x.toLowerCase()) ? 1 : ((b.x.toLowerCase() > a.x.toLowerCase()) ? -1 : 0);
        });
    else
    {
        if (enhancedData && enhancedData.length > 0)
            enhancedData.map(d => d.data.sort(function (a, b) { return (a.x.toLowerCase() > b.x.toLowerCase()) ? 1 : ((b.x.toLowerCase() > a.x.toLowerCase()) ? -1 : 0); }) );
    }

    function getHighestYValue(data) {
        if (data === undefined || data.length <= 0)
            return -1;

        if (data[0].data === undefined) {
            return Math.max.apply(Math, data.map(function (element) { return element.y; }))
        } else {
            return getHighestYValue(data[0].data);
        }
    }

    function getDataWithColor(donnees) {
        const dataWithColor = donnees.map(cell => {
            let color = cell.color && cell.color.length > 0 ? cell.color :  theme.colors.secondary;
            if (cell.x === (clickedCell as any)?.x) {
                if (cell.x === (hoveredCell as any)?.x) {
                    color = theme.colors.primaryDark;
                } else {
                    color = theme.colors.primary;
                }
            } else if (cell.x === (hoveredCell as any)?.x) {
                color = theme.colors.secondaryDark;
            }

            return {
                ...cell,
                color,
            };
        });

        return dataWithColor;
    }
    
    useEffect(() => {
        if (isMountedRef.current && !overwriteDefaultColor) {
            var dataWithColor: any = [];
            if (!isLine)
                dataWithColor = getDataWithColor(data);
            else {
                data.forEach(d => {
                    dataWithColor.push(
                        {
                            data: getDataWithColor(d.data),
                            color: d.color
                        });
                });
            }
            setEnhancedData(dataWithColor);
        }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, clickedCell, hoveredCell, isMountedRef]);

    useEffect(() => {
        if (isMountedRef.current) {
            setHoveredCell(false);
            setClickedCell(false);
        }
    }, [data, isMountedRef]);

    const displayWindowSize = () => {
        if (avoidResizing)
            return;

        if (vw < 1100) {
            setGraphWidth(700);
            return;
        }

        if (vw >= 1100) {
            setGraphWidth(vw - 400)
            return;
        }
        setGraphWidth(vw - 400)
    }

    useEffect(() => {
        displayWindowSize();
        onScroll(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(defaultScrollXPositionEnd === true) {
            chronoXYPlotGraphic.current.scrollLeft = chronoXYPlotGraphic.current.scrollWidth
        }
    }, [])

    window.addEventListener("resize", displayWindowSize);

    const onClickCell = (cell) => {
        if (onClickSelectBar) {
            if (cell.x === (clickedCell as any)?.x) {
                setClickedCell(false);
                onClickUnselectBar(cell)
            } else {
                onClickSelectBar(cell);
                setClickedCell(cell);
            }
           
        }
    };

    const myFormatter = value => {
        if (value.length > 16 ) {
            value = value.split('');
            value.splice(16, value.length - 16, '...').join("");
        }

        return (
            <tspan>
                <tspan x="0" dy="5">{value}</tspan>
            </tspan>
        );
    }

    const showCrosshair = (donnees) => {
        var result = donnees.reduce(function (a, b) {
            return {
                y: a.y + b.y
            };
        })
        return result && result.y ? result.y > 0 : false
    }

    const getFormattedCsvData = (dataList) => {
        let results = dataList;
        if (isLine) {
            results = [];
            dataList.forEach((d) => {
                results = results.concat(d);
            });
        }
        return results;
    }

    const exportCsvData = () => {
        const dataList = csvData && csvData.length > 0 ? csvData
            : enhancedData && enhancedData.length > 0 ? getFormattedCsvData(enhancedData)
            : [];
        const csvDataToExport = getCsvData(dataList);
        createDownloadLink(csvDataToExport.replaceAll("#", ''), csvFileName);
    }

    const onNearestX = (datapoint, indexPoint, indexSries) => {
        if (indexSries > 0)
            return;

        let dataPoints: any = [];
        enhancedData.forEach((d) => {
            dataPoints.push({ ...d.data[indexPoint], series: indexSries })
        });

        setPoints(dataPoints);
    }

    const onScroll = (param, timer = 0) => {

        if (!isAxisYFixed)
            return;

        let divScroll = document.getElementsByClassName(param ? 'js-scroll-graphic-' + param : 'js-scroll-graphic');
        let divAxis = document.getElementsByClassName(param ? 'js-scroll-axis-' + param : 'js-scroll-axis');
        let existingAxis = document.getElementsByClassName(param ? 'js-axis-y-' + param : 'js-axis-y');
        let newAxis = document.getElementsByClassName(param ? 'js-svg-' + param : 'js-svg');

        // If the amount of graph found in the page doesn't match all the axis found, then some axis isn't done loading so we wait and try again.
        // After 30 seconds, we do not try anymore as there's something wrong
        if ((divScroll.length !== divAxis.length || divScroll.length !== existingAxis.length || divScroll.length !== newAxis.length) && timer < 30000)
            setTimeout(function () {
                onScroll(param, timer + 100)
            }, 100);

        if (divScroll && divAxis && existingAxis && newAxis) {
            for (var i = 0; i < divScroll.length; i++) {
                var scrollLeft = divScroll[i].scrollLeft;
                (divAxis[i] as HTMLElement).style.left = scrollLeft + "px";
                newAxis[i].append(existingAxis[i]);
                (newAxis[i] as HTMLElement).style.height = height - 80 + "px" ;
            }
        }
    }

    const chronoXYPlotGraphic: any = useRef()



    return (
        <Fragment>
            {
                showExport === true &&
                <div className='chronoXYPlot-tool-div'>
                    <Button onClick={exportCsvData} className='chronoXYPlot-tool-button'>
                        <span>
                            <FontAwesomeIcon icon={faFileCsv} /> Exporter
                    </span>
                    </Button>
                </div>
            }
            <div className={id ? 'js-scroll-graphic-' + id : 'js-scroll-graphic'} ref={chronoXYPlotGraphic} onScroll={() => onScroll(id)} style={{ height: contentHeight, width: '100%', overflowY: 'hidden', overflowX: 'visible', marginBottom: 20, scrollMarginLeft: 0, position:'relative' }}>
            <Row>
                {
                    isAxisYFixed &&
                    <Col className={id ? 'js-scroll-axis-' + id : 'js-scroll-axis'} xl={2} md={1} sm={1} xs={1} style={{ position: 'absolute', left: '0px', padding: '0px', margin: '0px', zIndex: 1001, width: widthAxisY ? widthAxisY : '50px', height: '320px', backgroundColor: 'white' }}>
                        <svg id={"fixedYAxis"} className={id ? 'js-svg-' + id : 'js-svg'} style={{ width: widthAxisY ? widthAxisY : '50px', height: '330px', margin: marginAxisY ? marginAxisY : '0px', padding: '0px', overflow: 'hidden', left: '0px' }}>
                        </svg>
                    </Col>
                }

                <Col xl={isAxisYFixed ? 11 : 12} md={isAxisYFixed ? 11 : 12} sm={isAxisYFixed ? 11 : 12} xs={isAxisYFixed ? 11 : 12} style={{zIndex: 1}}>
                    <FlexibleWidthXYPlot xType="ordinal" yDomain={highestValue > 0 ? [0, highestValue] : [0, 5]} height={height} margin={margin} color={theme.colors.secondary} width={graphWidth + extraLength} onMouseLeave={() => setPoints([])}>
                        <VerticalGridLines />
                        <HorizontalGridLines />
                        <XAxis tickLabelAngle={tickLabelAngle}  
                            tickFormat={myFormatter}
                        />

                        <YAxis hideLine={isAxisYFixed} className={id ? 'js-axis-y-' + id : 'js-axis-y'} left={5}  />
                        {
                            isLine &&
                            enhancedData.map((stat, i) => {

                                return (
                                    <LineMarkSeries
                                        size={1}
                                        key={i}
                                        stroke={stat.color}
                                        data={stat.data}
                                        fill={'transparent'}
                                        onNearestX={(p, { index }) => onNearestX(p, index, i)}
                                    />
                                    );
                            })

                        }
                        {
                            !isLine && !hasMultipleBar &&
                            <VerticalBarSeries data={enhancedData}
                                colorType="literal"
                                onValueClick={onClickCell}
                                onValueMouseOver={v => setHoveredCell(v)}
                                onValueMouseOut={_ => setHoveredCell(false)} />
                        }
                        {
                            !isLine && hasMultipleBar &&
                            enhancedData.map((stat, i) => {
                                return (
                                    <VerticalBarSeries data={stat.data}
                                        color={stat.color}
                                        colorType="literal"
                                        onValueClick={onClickCell}
                                        onValueMouseOver={v => setHoveredCell(v)}
                                        onValueMouseOut={_ => setHoveredCell(false)} />
                                );
                            })
                        }
                
                        {hoveredCell && !isLine && (
                            <Hint value={hoveredCell}>
                                <div className="rv-hint__content"
                                    style={{background: theme.colors.secondaryDark, margin: 10, padding: 15}}>

                                    <h6 style={{margin: 0}}>{`${(hoveredCell as any)?.x}: ${(hoveredCell as any)?.y}`}</h6>
                                </div>
                            </Hint>
                        )}

                        {
                            points && points.length > 0 && showCrosshair(points) &&
                            <Crosshair style={{ line: { backgroundColor: 'violet', width:'3px' } }} values={points}>
                                <div className="rv-hint__content"
                                    style={{ background: theme.colors.secondaryDark, margin: 10, padding: 15 }}>

                                    {
                                        renderCrossHairText &&
                                        <div>
                                            {renderCrossHairText(points)}
                                        </div>
                                    }
                                    {
                                        !renderCrossHairText &&
                                        points.map((point, i) => {

                                            return (
                                                <h6 key={i} style={{ margin: 0 }}>{`${point.x}: ${point.y}`}</h6>
                                            );
                                        })
                                    }


                                </div>
                            </Crosshair>
                        }

                    </FlexibleWidthXYPlot>
                </Col>
            </Row>
            </div>
            </Fragment>
    );
};

export default ChronoXYPlot;