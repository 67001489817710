import React, { FC, ReactElement, useRef, useState } from "react";
import { MessageFilter } from "./components/MessageFilter";
import { MessageList } from "./components/MessageList";
import { Card, Col, Button } from "react-bootstrap";
import SvgIcon from "../../common/SvgIcon";
import { ContentBelowTopBar, TopBar } from "lib/ui";
import CommunicationFormSlider from "../CommunicationFormSlider";
import type { CommunicationFormSliderRef } from "../../../types/components/CommunicationFormSlider";
import type { MessageDetail as MessageDetailType, MessageForm as MessageFormType } from "../../../types/forms/MessageForms";
import { EMPTY_MESSAGE } from "./MessageConstants";
import { MessageBaseContainer } from "./components/MessageBaseContainer";

export const MessagePage: FC = (): ReactElement => {
    const sliderRef = useRef<CommunicationFormSliderRef>();
    const [isNewMessage, setIsNewMessage] = useState<boolean>(false);
    const [isMessageEditable, setIsMessageEditable] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [entity, setEntity] = useState<MessageFormType | MessageDetailType>(EMPTY_MESSAGE);
    const [isMessageCopy, setIsMessageCopy] = useState<boolean>(false);
    const [isSliderLoading, setIsSliderLoading] = useState<boolean>(false);

    return (
        <>
            <TopBar title="Messages">
                <Col>
                    <Button
                        onClick={() => {
                            setIsNewMessage(true);
                            setIsOpen(!isOpen);
                        }}
                        style={{ float: "right" }}
                    >
                        Nouveau message
                        <SvgIcon icon="plus" />
                    </Button>
                </Col>
            </TopBar>
            <ContentBelowTopBar>
                <MessageFilter />
                <Card className="generic-card">
                    <MessageList
                        onClickRowAction={() => {
                            setIsOpen(!isOpen);
                        }}
                        setIsSliderLoading={setIsSliderLoading}
                        setEntity={setEntity}
                        setIsMessageEditable={setIsMessageEditable}
                    />
                </Card>

                <CommunicationFormSlider
                    ref={sliderRef}
                    open={isOpen}
                    handleClose={() => {
                        setIsOpen(!isOpen);
                        setEntity(EMPTY_MESSAGE);
                        setIsNewMessage(false);
                        setIsMessageCopy(false);
                        setIsMessageEditable(false);
                    }}
                >
                    {entity && (
                        <MessageBaseContainer
                            entity={entity}
                            setEntity={setEntity}
                            isNewMessage={isNewMessage}
                            setIsMessageEditable={setIsMessageEditable}
                            isMessageEditable={isMessageEditable}
                            isMessageCopy={isMessageCopy}
                            setIsMessageCopy={setIsMessageCopy}
                            isSliderLoading={isSliderLoading}
                        />
                    )}
                </CommunicationFormSlider>
            </ContentBelowTopBar>
        </>
    );
};
