import React, { Fragment, useState } from "react";
import ConfigurationFilter from './ConfigurationFilter'
import ConfigurationGrid from './ConfigurationGrid'
import { Card, Col, Button } from 'react-bootstrap';
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import SvgIcon from "../../common/SvgIcon";
import PopupMobileconfig from './PopupMobileconfig'

const ConfigurationPage = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <Fragment>
            <TopBar title="Créer / éditer les logs applicatifs">
                <Col>
                    <Button onClick={() => {
                        setShowModal(true);
                    }}
                        style={{ float: 'right' }}>
                        Créer logtype&nbsp;
                        <SvgIcon icon="plus" />
                    </Button>
                </Col>
            </TopBar>
            <ContentBelowTopBar>
                <PopupMobileconfig id="CreateLogMobile" showModal={showModal} rowIndex={-1} data={undefined} showFooter={true} hideShowModal={() => { setShowModal(false) }} />
                <ConfigurationFilter/>
                <Card className='generic-card'>
                    <ConfigurationGrid />
                </Card>
            </ContentBelowTopBar>
        </Fragment>
    )
}

export default ConfigurationPage