/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, FC, ReactElement } from 'react';
import { Row, Col, Card, Button, Form, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faFilter, faPlus, faMinus, faRedo } from '@fortawesome/free-solid-svg-icons'
import { useForm, Controller } from "react-hook-form";
import DropdownCheckboxes from "../../DropdownCheckboxes";
import { InputDate, ErrorMessage, Dropdown } from "../../../../lib/ui";
import { AlertFilterForm } from "../../../../types/forms/alertForms";
import { AlertFilterProps } from "../../../../types/components/alertComponent";
import { CommunicationStatus } from "../../../../types/entities/communicationEntities";
import { useComService } from "../../../../service/communication/comService";
import { useAlertService } from "../../../../service/communication/useAlertService";
import { yupResolver } from '@hookform/resolvers/yup';
import { validationGetParamSchema } from './validators/getValidator';
import { FILTER_DATE_OPTIONS } from '../AlertConstants';
import GenericDate from 'components/common/generic/GenericDate';
import moment from 'moment';

export const AlertFilter: FC<AlertFilterProps> = (): ReactElement => {
    const [collapse, setCollapse] = useState<boolean>(false);
    const [statusList, setStatusList] = useState<Array<CommunicationStatus>>([]);
    const [orgList, setOrgList] = useState<Array<any>>([]);

    const service = useComService();
    const alertService = useAlertService();

    const { handleSubmit, watch, control, setValue, errors, reset, setError, getValues } = useForm<AlertFilterForm>({
        defaultValues: alertService.Filter.defaultValues,
        reValidateMode: 'onChange',
        resolver: yupResolver(validationGetParamSchema),
        mode: "onChange",
    });
    const values = watch();
    useEffect(()=> {
        if(values.startDate && values.endDate) {
            if (moment(values.startDate).isBefore(values.endDate)) {
                setError('startDate', { message: '' });
            }

            if (moment(values.endDate).isAfter(values.startDate)) {
                setError('endDate', { message: '' });
            }
        }
    }, [values.startDate, values.endDate])

    const fetchData = (reset = false): void => {
        alertService.request.GetAll.process(alertService.mapToParam(reset ? alertService.Filter.defaultValues : getValues(), reset ? service.getOptionsStatus() : statusList, reset ? service.getOptionsOrg() : orgList, service.getOptionsOrg()));
    }

    useEffect(() => {
        service.request.StatusGet.actions.process({ communicationType: 3 })
        service.request.OrgGet.actions.process('');
    }, []);

    useEffect(() => {
        if (service.request.getStatus.isSuccess) {
            setStatusList(service.getOptionsStatus())
            setOrgList(service.getOptionsOrg())
            fetchData(true);
        }
    }, [service.request.getStatus.isSuccess]);

    return (
        <Fragment>
            <div className='config-mt-15'>

                <Card className='card-alert-border'>
                    <Card.Header onClick={() => { setCollapse(!collapse); }}>
                        <span className='header-filter-config'>
                            <FontAwesomeIcon icon={faFilter} /> Filtres
                        </span>

                        <Button onClick={(ev) => {
                            ev.stopPropagation()
                            reset(alertService.Filter.defaultValues);
                            alertService.cleanCache();
                            setStatusList(service.getOptionsStatus());
                            setOrgList(service.getOptionsOrg());

                            fetchData(true);
                        }} className="ml-4">
                            <FontAwesomeIcon icon={faRedo} /> Réinitialiser
                        </Button>

                        <Button variant="light" className='btn-filter-collapse' onClick={() => { setCollapse(!collapse); }}>
                            <FontAwesomeIcon icon={!collapse ? faPlus : faMinus} />
                        </Button>
                    </Card.Header>
                    <Collapse in={collapse}>
                        <Card.Body className='card-alert-body'>
                            <Form
                                onSubmit={handleSubmit(() => {
                                    alertService.cleanCache();
                                    fetchData();
                                })}
                            >
                                <Row className='config-mt-10 form-row-alert'>

                                    <Col xl={5} lg={10} md={11} className={`p-0 ml-xl-3 ml-lg-4 ml-md-3 mt-lg-0  mt-md-4`}>
                                        <Controller
                                            control={control}
                                            name="filterByDateType"
                                            render={({ onChange, value }, { invalid }) => {
                                                return (
                                                    <Dropdown
                                                        mode="select"
                                                        label={"Filtrer par"}
                                                        options={FILTER_DATE_OPTIONS}
                                                        onChange={onChange}
                                                        selectedValue={value}
                                                        defaultValue={alertService.Filter.defaultValues.filterByDateType}
                                                    />
                                                );
                                            }}
                                        />
                                    </Col>

                                    <Col xl={3} lg={5} md={11} className={`p-0 ml-xl-3 ml-lg-4 ml-md-3 mt-lg-0  mt-md-4`}>
                                        <Controller
                                            control={control}
                                            name="startDate"
                                            value={values.startDate}
                                            render={({ onChange, value }, { invalid }) => {
                                                return (
                                                    <GenericDate
                                                        label="Du"
                                                        date={value}
                                                        onDateChange={onChange}
                                                        width={'80'}
                                                        cssClass='alert-input-date'
                                                        isRequired
                                                    // format={alertService.Constantes.DISPLAY_DATE_FORMAT}
                                                    // title="date de debut"
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.startDate && <ErrorMessage>{errors.startDate.message}</ErrorMessage>}
                                    </Col>

                                    <Col xl={3} lg={5} md={11} className={`p-0 ml-xl-3 ml-lg-4 ml-md-3 mt-lg-0  mt-md-4`}>
                                        <Controller
                                            control={control}
                                            name="endDate"
                                            value={values.endDate}
                                            render={({ onChange, value }, { invalid }) => {
                                                return (
                                                    <GenericDate
                                                        label="Au"
                                                        date={value}
                                                        onDateChange={onChange}
                                                        width={'80'}
                                                        cssClass='alert-input-date'
                                                        isRequired
                                                    //format={alertService.Constantes.DISPLAY_DATE_FORMAT}
                                                    //title="date de fin"
                                                    />
                                                );
                                            }}
                                        />
                                        {errors.endDate && <ErrorMessage>{errors.endDate.message}</ErrorMessage>}
                                    </Col>
                                </Row>

                                <Row className='config-mt-10 form-row-alert'>
                                    <Col xl={12} md={12} className={`p-0 pt-1 mt-xl-0 mt-lg-4 ml-xl-3 ml-lg-3 ml-md-3  pr-0 mr-0 mt-md-4`}>
                                        <div className={`mr-xl-0 mr-lg-0 mr-md-0 card-display-inline`}>
                                            <DropdownCheckboxes
                                                valueList={statusList}
                                                onChange={(value, evt) => {
                                                    service.handleDropdownFilterChange(value, evt, setStatusList, service.getOptionsStatus());
                                                }}
                                                cssContainerClass={'com-dropdown-checkbox-container'}
                                                cssClass={'com-dropdown-checkbox'}
                                                hideSearch={true}
                                                defaultLabel={'Tous les statuts'}
                                                label={'Statut'}
                                                labelButton={"choisis"}
                                                optionData={service.getOptionsStatus()}
                                                labelNone={"Tous les statuts"}
                                                numberOptions={
                                                    service.request.StatusGet.result.data && service.request.StatusGet.result.data.length >= 1
                                                        ? service.getOptionsStatus().length - 1
                                                        : null
                                                }
                                                getOptionValue={(option) => option.value}
                                            />
                                        </div>
                                        <div className={`ml-xl-3 ml-lg-3 ml-md-3 mr-xl-3 mr-lg-3 mr-md-3 card-display-inline`}>
                                            <DropdownCheckboxes
                                                valueList={orgList}
                                                onChange={(value, evt) => {
                                                    service.handleDropdownFilterChange(value, evt, setOrgList, service.getOptionsOrg());
                                                }}
                                                cssContainerClass={'com-dropdown-checkbox-container'}
                                                cssClass={'com-dropdown-checkbox'}
                                                hideSearch={true}
                                                defaultLabel={'Toutes les organisations'}
                                                label={'Organisation'}
                                                labelButton={"choisis"}
                                                optionData={service.getOptionsOrg()}
                                                labelNone={'Toutes les organisations'}
                                                numberOptions={
                                                    service.request.OrgGet.result.data && service.request.OrgGet.result.data.length >= 1
                                                        ? service.getOptionsOrg().length - 1
                                                        : null
                                                }
                                                getOptionValue={(option) => option.value}
                                            />
                                        </div>
                                        <div className='card-display-inline'>
                                            <Button type="submit">
                                                <FontAwesomeIcon icon={faSearch} /> Rechercher
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Collapse>
                </Card>
            </div>
        </Fragment>
    );
}

export default AlertFilter;
