/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { ContentBelowTopBar, TopBar } from "../common/ContentNavigation";
import ChronoCard from "../common/ChronoCard";
import styled from "styled-components";
import ChronoUserList from "./ChronoUserList";
import ChronoUserFilter from "./ChronoUserFilter";
import { useDispatch } from 'react-redux';
import addUIItem from "../../redux/actions/addUIItem";
import addResource from "../../redux/actions/addResource";

import * as keys from "../../redux/keys/index";


export const ErrorMessage = styled.p`
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;

const ChronoUsersPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(
                addUIItem(
                    undefined,
                    keys.CHRONO_USERR_LIST + '_STATUS'
                )
            );

            dispatch(addResource([], keys.CHRONO_USERR_LIST));
        };
    }, []);
  return (
    <Fragment>
      <TopBar title="Utilisateurs Chrono" />
          <ContentBelowTopBar>
              <ChronoUserFilter />
              <ChronoCard className='generic-card'>
                 <ChronoUserList />
              </ChronoCard>
      </ContentBelowTopBar>
    </Fragment>
  );
};

export default ChronoUsersPage;
