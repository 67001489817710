import React, {FC, ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { CalendarDay } from "./calendarDay";
import { CalendarSelector } from "./calendarSelector";

export const Calendar: FC<any> = ({ children, id, month, year, showHeader, events, eventrenderer, style, handleCalendarDayClick, getFormatedEventList, onClickcalendarDay, findFormatedEvents }:any):ReactElement => {

    const [dates, setDates] = useState<any[]>([]);
    const [monthValue, setMonthValue] = useState<number>(month ? month : 1);
    const [yearValue, setYearValue] = useState<string>(year ? year.toString() : moment().year().toString());

    useEffect(() => {
        let year = parseInt(yearValue)
        let startDate = moment([year, monthValue])

        let firstDay = moment(startDate).startOf('month')
        let endDay = moment(startDate).endOf('month')

        let monthRange = enumerateDaysBetweenDates(firstDay, endDay)
        let weeks: number[] = [];
        monthRange.forEach((monthDate) => {
            if (!weeks.includes(moment(monthDate).week()))
                weeks.push(moment(monthDate).week())
            }
        )

        let calendar: any[] = [];
        weeks.forEach((w) => {
            let firstWeekDay = moment(startDate).week(w).day(1);
            let lastWeekDay = moment(startDate).week(w).day(7);

            if (moment(firstWeekDay).month() === monthValue || moment(lastWeekDay).month() === monthValue) {
                let weekRange = enumerateDaysBetweenDates(firstWeekDay, lastWeekDay);
                calendar.push(weekRange)
            }
        });
        setDates(calendar);

    }, [monthValue, yearValue])

    useEffect(() => {
        setMonthValue(month);
        setYearValue(year.toString());
    }, [month, year])


    const enumerateDaysBetweenDates = (startDate, endDate) => {
        var dates : any[] = [];

        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');

        dates.push(startDate.clone().toDate())
        while (currDate.add(1, 'days').diff(lastDate) < 0) {
            dates.push(currDate.clone().toDate());
        }
        dates.push(endDate.clone().toDate())
        return dates;
    };

    return (
        <div style={style}>
            {
                showHeader === true &&
                <div className='calendar-pd-15'>
                    <CalendarSelector id='calendarSelector_generic' key='calendarSelector_generic' month={monthValue} year={yearValue} onChange={(obj) =>
                    {
                        setMonthValue(obj.monthValue);
                        setYearValue(obj.year);
                    }} />
                </div>

            }
            <div>
                <table id={"tblCalendar_" + id} className="text-center calendar-width-100 calendar-container">
                    <thead className='calendar-thead'>
                        <tr>
                            <th key={'Calendar_Lundi_' + id} className='calendar-width-14'>Lundi</th>
                            <th key={'Calendar_Mardi_' + id} className='calendar-width-14'>Mardi</th>
                            <th key={'Calendar_Mercredi_' + id} className='calendar-width-14'>Mercredi</th>
                            <th key={'Calendar_Jeudi_' + id} className='calendar-width-14'>Jeudi</th>
                            <th key={'Calendar_Vendredi_' + id} className='calendar-width-14'>Vendredi</th>
                            <th key={'Calendar_Samedi_' + id} className='calendar-width-14'>Samedi</th>
                            <th key={'Calendar_Dimanche_' + id} className='calendar-width-14'>Dimanche</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dates && dates.length > 0 &&
                            dates.map((week, j) => {
                                let formatedEvents = [];
                                if (getFormatedEventList)
                                    formatedEvents = getFormatedEventList(events, week[0], week[6]);


                                return (
                                    <tr key={'tr_' + j + id} style={{ width: '100%' }}>
                                        {
                                            week.map((weekDate, i) => {

                                                return (
                                                    <td key={'CalendarDay_td_' + i + id} id={'CalendarDay_td_' + i + id} onClick={() => {
                                                        if (onClickcalendarDay)
                                                            onClickcalendarDay(weekDate, findFormatedEvents ? findFormatedEvents(weekDate, formatedEvents) : formatedEvents)
                                                    }}>
                                                        <CalendarDay children={children} key={'CalendarDay_' + i + id} month={monthValue} currentDate={weekDate} events={getFormatedEventList ? formatedEvents : events} eventrenderer={eventrenderer} firstDateOfWeek={week[0]} lastDateOfWeek={week[6]} noWeek={j} nbWeek={dates.length} handleClick={handleCalendarDayClick}  />
                                                    </td>
                                                )
                                            })

                                            }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Calendar;
