import { put, takeLatest, take } from 'redux-saga/effects'
import { authProvider, fetchWithProvider, getUserInfo } from '../../auth/authProvider';
import { channel } from 'redux-saga'
import env from '../../env'
import { SET_LOGIN_PENDING, SET_LOGIN_SUCCESS, SET_LOGIN_ERROR } from './loginActions'


const setLoginPending = () => {
    return {
        type: SET_LOGIN_PENDING,
    };
};

const setLoginSuccess = (username, token) => {
    return {
        type: SET_LOGIN_SUCCESS,
        username: username,
        token: token
    };
};

const setLoginError = () => {
    return {
        type: SET_LOGIN_ERROR
    };
};

const checkUserExistsInDatabase = async (user) => {
    try {
        const response = await fetchWithProvider(fetch, env.baseApiUrl + '/users/');
        if (response.ok) {
            const userResponse = await response.json();

            if (userResponse.isDeleted) {
                return false;
            }
        } else {
            if (response.statusText) {
                console.log('Login check, response NOT OK, statusText: ', response.statusText);
            } else {
                console.log('Login check, response NOT OK: ', await response.text());
            }

            return false;
        }
    } catch (error) {
        console.log('Login check, error:', error);
        return false;
    }

    return true;
};

const authChannel = channel();

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchUser() {
    try {
        yield put(setLoginPending());
        const user = getUserInfo();

        if (user) {
            authProvider.getIdToken().then(async (token) => {

                if (!token) {
                    console.log('No token!');
                    authChannel.put(setLoginError());
                    return;
                }

                const userExists = await checkUserExistsInDatabase(user);
                if (userExists){
                    console.log('success');
                    authChannel.put(setLoginSuccess(user.name, user.token));
                } else {
                    console.log('error');
                    authChannel.put(setLoginError());
                }

            }).catch((error) => {
                console.log(error)
                if (error) {
                    console.log('authentication provider error occurred: ' + error);
                    authChannel.put(setLoginError());
                    return;
                }

            });
        } else {
            authProvider.login();
        }
    } catch (e) {
        yield put(setLoginError());
    }
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* loginSaga() {
    yield takeLatest("USER_FETCH_REQUESTED", fetchUser);
    while (true) {
        const action = yield take(authChannel)
        yield put(action)
    }
}

export default loginSaga;