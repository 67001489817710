import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../actions/types/types";
import * as consoleApi from "../../../api";
import addResource from "../../actions/addResource";
import addUIItem from "../../actions/addUIItem";
import * as status from "../requestStatus";
import * as keys from "../../keys";

export function* watchRoutes() {
  yield takeLatest(types.GET_REFERENCE_ROUTE_REQUESTED, doRoutes);
}

export function* doRoutes(action) {
  const { queryParams } = action.payload;

  try {
    yield put(addUIItem(status.REQUEST_PENDING, keys.ROUTE_REQUEST_STATUS));
    const routes = yield call(
      consoleApi.getReferenceRoutes,
      queryParams
    );

    if (!routes) {
      throw new Error("Unable to get route references logs.");
    }
    yield put(addResource(routes, keys.ROUTE_LIST));
    yield put(addUIItem(status.REQUEST_SUCCEEDED, keys.ROUTE_REQUEST_STATUS));
  } catch (e) {
    yield put(addUIItem(status.REQUEST_FAILED, keys.ROUTE_REQUEST_STATUS));
  }
}
