import React from 'react';
import styled from "styled-components";
import { Button } from 'react-bootstrap';
import { authProvider, authRequest } from '../../auth/authProvider';

const Container = styled.div`
    background-color: #e9ecef;
    height: 100%;
    padding: 4em;
`;

const CustomTitle = styled.h1`
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
`;

const UnauthorizedPage = ({error}) => {
    return (
        <Container>
            <CustomTitle>Console Chrono Mobile</CustomTitle>
            <h3>Vous devez être connecté pour accéder à la console web.</h3>
            <hr />
            <p>
                Veuillez utiliser les identifiants de votre organisation au moment de la connexion.<br />
                {error && <b>Erreur: {error}</b>}
            </p>

            <Button className="btn-lg" onClick={() => {
                authProvider.loginRedirect(authRequest);
            }}>Se connecter</Button>
        </Container>
    );
};

export default UnauthorizedPage;
