import React, { Fragment } from "react";
import ApplicationLogDetailHeader from "./ApplicationLogDetailHeader";
import ApplicationLogDetailFilter from "./ApplicationLogDetailFilter";
import ApplicationLogDetailsGrid from "./ApplicationLogDetailsGrid";
import { Card } from "react-bootstrap";

const ApplicationLogDetailsPage = () => {
  return (
    <Fragment>
        <Card className="mt-3 p-3">
          <ApplicationLogDetailHeader />
        </Card>

        <ApplicationLogDetailFilter />
          <div style={{ paddingTop: '10px', marginBottom: 20 }}>
              <ApplicationLogDetailsGrid />
        </div>
    </Fragment>
  );
};

export default ApplicationLogDetailsPage;
