/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, Button, Form, Collapse } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faFilter, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import { getConfigurationLogs, getMobileConfigurationListItems } from "../../../redux/actions/applicationLogs/requestConfigurationLogs";
import useUI from "../../../lib/hooks/useUI";
import { useForm, Controller } from "react-hook-form";
import * as constantesConfig from "../../../redux/keys/applicationLogs/configurationLogs";
import { Dropdown } from "../../../lib/ui";
import { OS_OPTIONS, STATUS_OPTIONS } from "../constantes";
import  AutoComplete  from '../../common/autocomplete';


const FormRow = styled(Row)`
    margin-top: 30px;
    margin-bottom: 20px;
`

export const FilterConfiguration = () => {

    const [loaded, setLoaded] = useState(true);
    const [collapse, setCollapse] = useState(false);

    const formDefaultConfig = {
        defaultValues: {
            os: '',
            logType: '',
            active: ''
        },
        reValidateMode: 'onChange',
        mode: "onChange",
    };

    const { handleSubmit, watch, control } = useForm(formDefaultConfig);
    const values = watch();

    const dispatch = useDispatch();
    const refresh = useUI(constantesConfig.CONFIGURATION_APP_LOGS_REFRESH);

    useEffect(() => {
        if (loaded === true)
            fetchData();
        setLoaded(false);
    }, [loaded]);

    useEffect(() => {
        setLoaded(refresh);
    }, [refresh]);

    const fetchData = () => {
        dispatch(getConfigurationLogs(constantesConfig.CONFIGURATION_APP_LOGS, {
            OS: values.os,
            LogType: values.logType,
            Active: values.active
        }));
    }

    return (
        <Fragment>
            {
                <div className='config-mt-15'>

                    <Card style={{borderRadius:'10px'}}>
                        <Card.Header onClick={() => { setCollapse(!collapse); }}>
                            <span className='header-filter-config'>
                                <FontAwesomeIcon icon={faFilter} /> Filtres
                            </span>

                            <Button variant="light" className='btn-filter-collapse' onClick={() => { setCollapse(!collapse); }}>
                                <FontAwesomeIcon icon={collapse ? faPlus : faMinus} />
                            </Button>
                        </Card.Header>
                        <Collapse in={!collapse}>
                            <Card.Body style={{paddingBottom:'5px', paddingTop:'10px', paddingLeft:'50px'}}>
                                <Form data-testid="frmConfigLogFilter"
                                    onSubmit={handleSubmit(() => {
                                        fetchData();
                                    })}
                                >
                                    <FormRow classname='config-mt-10'>
                                        <Col xl={3} md={12} style={{ paddingLeft: 0 }}>
                                            <Controller
                                                control={control}
                                                name="os"
                                                value={values.os}
                                                render={({ onChange, value }) => {
                                                    return (
                                                        <Dropdown
                                                            mode="select"
                                                            label="OS"
                                                            options={[{ label: 'Tous', value: '' }].concat(OS_OPTIONS)}
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Col>

                                        <Col xl={3} md={12} style={{ paddingLeft: 0 }}>

                                            <Controller
                                                control={control}
                                                name="active"
                                                value={values.active}
                                                render={({ onChange, value }) => {
                                                    return (
                                                        <Dropdown
                                                            mode="select"
                                                            label="Etat du log"
                                                            options={STATUS_OPTIONS}
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    );
                                                }}
                                            />
                                        </Col>

                                        <Col xl={4} md={12} style={{ paddingLeft: 0 }}>

                                        <Controller
                                            control={control}
                                            name="logType"
                                            value={values.logType}
                                            render={({ onChange, value }) => {
                                                return (
                                                    <AutoComplete value={value}
                                                        label="ID"
                                                        onChange={onChange}
                                                        propertyname={'LogType'}
                                                        style={{ width: '50px' }}
                                                        id='configurationFilter_AutoComplete'
                                                        getDropdownListItems={getMobileConfigurationListItems}
                                                    />
                                                );
                                            }}
                                        />
                                        </Col>
                                        <Col xl={2} md={12} style={{ paddingLeft: 0 }}>
                                            <div style={{ textAlign: 'left' }}>
                                                <Button type="submit">
                                                    <FontAwesomeIcon icon={faSearch} /> Rechercher
                                                </Button>
                                            </div>
                                        </Col>
                                    </FormRow>
                                </Form>
                            </Card.Body>
                        </Collapse>
                    </Card>
                </div>

            }
        </Fragment>
    );

}

export default FilterConfiguration;
