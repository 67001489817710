import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { LoadingIndicator } from "../../LoadingIndicator";
import { sendPutRequest, useChronoApi } from "../../../auth/apiEffects";
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import GenericTableCell from "../../common/generic/table/cell/GenericTableCell";
import GenericTableCellSwitch from "../../common/generic/table/cell/GenericTableCellSwitch";
import GenericTable, { GenericTableRow } from "../../common/generic/table/GenericTable";
import GenericTableHeadSingleRow from "../../common/generic/table/GenericTableHeadSingleRow";
import GenericTableBody from "../../common/generic/table/GenericTableBody";
import { showPopupConfirm } from "../../common/Popups";
import { Spinner } from "react-bootstrap";

const RealtimePage = () => {
    const {data: realtimeAgencyStatesFromServer, isLoading} = useChronoApi('/realtime/agency/state');

    const [updatedAgencyStates, setUpdatedAgencyStates] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [targetedAgency, setTargetedAgency] = useState(undefined);

    useEffect(() => {
        setUpdatedAgencyStates(realtimeAgencyStatesFromServer);
    }, [realtimeAgencyStatesFromServer]);

    const sendRequestSwitchRealtime = useCallback(async ({code, isActive}) => {
        setIsSending(true)
        const updatedState = updatedAgencyStates.map(agencyState => {
            if (agencyState.code === code) {
                return {
                    ...agencyState,
                    isActive: !isActive,
                };
            } else {
                return agencyState;
            }
        });
        setUpdatedAgencyStates(updatedState);

        await sendPutRequest(`/realtime/agency/${code}/state/${!isActive}`);
        setIsSending(false)
    }, [updatedAgencyStates]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Fragment>
            <TopBar title="Temps réel" />
            <ContentBelowTopBar style={{paddingLeft: 0, paddingRight: 0}}>

                <GenericTable>
                    <GenericTableHeadSingleRow>
                        <th>Code agence</th>
                        <th>Global</th>
                        <th>Perturbations</th>
                        <th>Horaires</th>
                        <th>Positionnements</th>
                        <th>Switch</th>
                    </GenericTableHeadSingleRow>
                    <GenericTableBody>
                        {
                            updatedAgencyStates.map(agencyState => {
                                return (
                                    <GenericTableRow key={agencyState.code}>
                                        <GenericTableCell>{agencyState.code}</GenericTableCell>
                                        <GenericTableCell>{agencyState.isActive}</GenericTableCell>
                                        <GenericTableCell valueType='nullableBool'>{agencyState.supportAlertes}</GenericTableCell>
                                        <GenericTableCell valueType='nullableBool'>{agencyState.supportMajHoraires}</GenericTableCell>
                                        <GenericTableCell valueType='nullableBool'>{agencyState.supportPositionnements}</GenericTableCell>
                                        {   
                                            isSending && agencyState.code === targetedAgency
                                                ?<td>
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner> 
                                                </td>
                                            :   <GenericTableCellSwitch id={agencyState.code} isActive={agencyState.isActive}
                                                                        onSwitch={() => {
                                                                            setTargetedAgency(agencyState.code)
                                                                            return showPopupConfirm({
                                                                                message: 'Voulez-vous vraiment switcher le temps réel de cette agence ?',
                                                                                onClickYes: () => sendRequestSwitchRealtime({
                                                                                    code: agencyState.code,
                                                                                    isActive: agencyState.isActive
                                                                                }),
                                                                            })
                                                                        }
                                                                    } />
                                        }
                                    </GenericTableRow>
                                )
                            })
                        }
                    </GenericTableBody>
                </GenericTable>

            </ContentBelowTopBar>
        </Fragment>
    );
};

export default RealtimePage;
