/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import ChronoXYPlot from "../../common/ChronoXYPlot";
import { StatsCard, StatsContainer } from "../global/StatsComponents";
import { LoadingIndicatorInApp } from "../../LoadingIndicator";
import moment from "moment";
import styled from "styled-components";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import SelectRoutes from "../../common/generic/select/SelectRoutes";
import { IKeenData } from "../optc/StatsRoutesShown";

interface ICSVData {
  Organisation: string;
  Type: string;
  Date: string | number;
  'Pourcentage de succès': string | number;
}

interface ITranslateData {
  data: { x: string; y: number}[]; color: string }
export const FormRow = styled(Row)`
  margin-top: 15px;
  margin-bottom: 10px;
`;

const FormTab = styled(Tab)`
  margin-top: 20px;
`;

const RealTimeGraph = (props) => {
    const [statsPosition, setStatsPosition] = useState<ITranslateData[]>([]);
    const [statsTrip, setStatsTrip] = useState<ITranslateData[]>([]);
    const [statsAlert, setStatsAlert] = useState<ITranslateData[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const [statsPositionCsv, setStatsPositionCsv] = useState<ICSVData[]>([]);
    const [statsTripCsv, setStatsTripCsv] = useState<ICSVData[]>([]);
    const [statsAlertCsv, setStatsAlertCsv] = useState<ICSVData[]>([]);

  useEffect(() => {
    loadData(
      props.routes && props.routes.length > 0 ? props.routes[0].routeId : ""
    );
  }, [props.routes, props.data, props.agencyCode]);

  const AddMissingdates = (donnees) => {
    const endDate = moment().format("YYYY-MM-DD");
    const startDate = moment(endDate).add(-7, "days").format("YYYY-MM-DD");

    for (var m = moment(startDate); m.isBefore(endDate); m.add(1, "days")) {
      let day = m;
      var findings = donnees.filter(
        (x) =>
          moment(x.logDate).format("YYYY-MM-DD") ===
          moment(day).format("YYYY-MM-DD")
      );
      if (findings && findings.length > 0) continue;

      donnees.push({
        logDate: moment(m).format("YYYY-MM-DD"),
        percentageRequestSuccessful: 0,
        percentageRealtimeHasDataActiveServiceSuccessful: 0,
      });
    }
  };

  const TranslateData = (
    dataList,
    realTimeType,
    isReception = false,
    routeId = null,
    addMisssingdate = false
  ) => {
    var donnees: ITranslateData[] = [];

    if (dataList && dataList.length > 0) {
      var filteredData =
        props.routes && props.routes.length > 0
          ? dataList.filter(
              (x) =>
                x.realtimeType === realTimeType &&
                x.organization === props.agencyCode &&
                x.routeGtfsId === routeId
            )
          : dataList.filter(
              (x) =>
                x.realtimeType === realTimeType &&
                x.organization === props.agencyCode
            );
      if (filteredData && filteredData.length > 0) {
        if (addMisssingdate) AddMissingdates(filteredData);

        var result = filteredData.reduce(function (h, obj) {
          h[moment(obj.logDate).format("YYYY-MM-DD")] = (
            h[obj.date] || []
          ).concat(obj);
          return h;
        }, {});

        donnees.push({
          data: Object.keys(result).map((key) => {
            var res = {
              x: moment(key).format("YYYY-MM-DD"),
              y: result[key].reduce(
                (a, b) =>
                  a +
                  ((isReception === true
                    ? b.percentageRealtimeHasDataActiveServiceSuccessful
                    : b.percentageRequestSuccessful) || 0),
                0
              ) as number,
            };
            return res;
          }),
          color: "blue",
        });
      }
    }
    return donnees;
    };

    const getData = (routeId, type) => {
        const data = TranslateData(
            props.data,
            type,
            props.isReception,
            routeId,
            props.routes && props.routes.length > 0
        );
        return {
            data: data,
            csvData: data && data.length > 0 ? data[0].data.map(row => {
                return {
                    Organisation: props.agencyCode,
                    Type: type,
                    'Date': row.x,
                    'Pourcentage de succès': row.y
                }
            }) : []
        };
    }

    const updatePositionData = (routeId) => {
        const positionData: { data: ITranslateData[]; csvData: ICSVData[] } = getData(routeId, 'Position');
        setStatsPosition(positionData.data);
        setStatsPositionCsv(positionData.csvData);
    }

    const updateTripData = (routeId) => {
        const tripData: { data: ITranslateData[]; csvData: ICSVData[] } = getData(routeId, 'Trip');
        setStatsTrip(tripData.data);
        setStatsTripCsv(tripData.csvData);
    }

    const updateAlertData = (routeId) => {
        const alertData: { data: ITranslateData[]; csvData: ICSVData[] } = getData(routeId, 'Alert');
        setStatsAlert(alertData.data);
        setStatsAlertCsv(alertData.csvData);
    }

  const loadData = (routeId) => {
    setIsLoading(true);

      if (props.data && props.data.length > 0) {
          updatePositionData(routeId);
          updateTripData(routeId);
          updateAlertData(routeId);
    }

    setIsLoading(false);
  };

  const onRouteIdChange = (event) => {
    loadData(event.target.value);
  };

  return (
    <Fragment>
      {
        <React.Fragment>
          <StatsContainer>
            <StatsCard style={{ minHeight: "150px", marginTop: "5px" }}>
              <FormRow style={{ textAlign: "right", marginTop: "0px" }}>
                <Col lg={6}>
                  <h5
                    style={{
                      textAlign: "left",
                      color: "black",
                      paddingTop:
                        props.routes && props.routes.length > 0
                          ? "10px"
                          : "0px",
                    }}
                  >
                    {props.title}
                  </h5>
                </Col>
                {props.routes &&
                  props.routes.length > 0 &&
                  !(isLoading || props.isLoadingData) && (
                    <Col lg={6}>
                      <SelectRoutes
                        data={props.routes}
                        onChange={onRouteIdChange}
                        hideAllValue={true}
                        agencyCode={props.agencyCode}
                      />
                    </Col>
                  )}
              </FormRow>
              {(isLoading || props.isLoadingData) && <LoadingIndicatorInApp />}

              {!(isLoading || props.isLoadingData) && (
                <Tabs
                  defaultActiveKey={1}
                  className="successCallTab"
                  id="uncontrolled-tab-example"
                  style={{ padding: 10 }}
                >
                  <FormTab
                    eventKey={1}
                    title="Position"
                    style={{ marginTop: 30 }}
                  >
                    {statsPosition && statsPosition.length > 0 && (
                      <ChronoXYPlot
                        id={"gphSuccessCallPosition"}
                        isLine={true}
                        isAxisYFixed={true}
                        showExport={true}
                        height={250}
                        data={statsPosition}
                        csvData={statsPositionCsv}
                        csvFileName={'realtimePositions'}
                        tickLabelAngle={-70}
                        margin={{ left: 50, bottom: 90 }}
                        highestYValue={100}
                        defaultScrollXPositionEnd={true}
                      />
                    )}
                    {!statsPosition ||
                      (statsPosition.length === 0 && (
                        <Row>
                          <Col
                            sm={12}
                            style={{ textAlign: "center", paddingTop: 10 }}
                          >
                            <h3>Aucun résultat</h3>
                          </Col>
                        </Row>
                      ))}
                  </FormTab>
                  <FormTab
                    eventKey={2}
                    title="Voyage"
                    style={{ marginTop: 30 }}
                  >
                    {statsTrip && statsTrip.length > 0 && (
                      <ChronoXYPlot
                        id={"gphSuccessCallTrip"}
                        isLine={true}
                        isAxisYFixed={true}
                        csvData={statsTripCsv}
                        csvFileName={'realtimeTrips'}
                        showExport={true}
                        height={250}
                        data={statsTrip}
                        tickLabelAngle={-70}
                        margin={{ left: 50, bottom: 90 }}
                        overwriteDefaultColor={true}
                        avoidResizing={true}
                        highestYValue={100}
                      />
                    )}
                    {!statsTrip ||
                      (statsTrip.length === 0 && (
                        <Row>
                          <Col
                            sm={12}
                            style={{ textAlign: "center", paddingTop: 10 }}
                          >
                            <h3>Aucun résultat</h3>
                          </Col>
                        </Row>
                      ))}
                  </FormTab>
                  <FormTab
                    eventKey={3}
                    title="Alerte"
                    style={{ marginTop: 30 }}
                  >
                    {statsAlert && statsAlert.length > 0 && (
                      <ChronoXYPlot
                        id={"gphSuccessCallAlert"}
                        isLine={true}
                        isAxisYFixed={true}
                        showExport={true}
                        height={250}
                        data={statsAlert}
                        csvData={statsAlertCsv}
                        csvFileName={'realtimeAlerts'}
                        tickLabelAngle={-70}
                        margin={{ left: 50, bottom: 90 }}
                        overwriteDefaultColor={true}
                        avoidResizing={true}
                        highestYValue={100}
                      />
                    )}
                    {!statsAlert ||
                      (statsAlert.length === 0 && (
                        <Row>
                          <Col
                            sm={12}
                            style={{ textAlign: "center", paddingTop: 10 }}
                          >
                            <h3>Aucun résultat</h3>
                          </Col>
                        </Row>
                      ))}
                  </FormTab>
                </Tabs>
              )}
            </StatsCard>
          </StatsContainer>
        </React.Fragment>
      }
    </Fragment>
  );
};

export default RealTimeGraph;
