import React from 'react';

const GenericTextArea = ({ isEdit, value, rowId, rows, cols, onChange, changeParameter, invalid = false, maxSize = 512, disabled }) => {
  
    const onChangeValue = (event) => {
        if (onChange)
            onChange(event.target.value, rowId, changeParameter)
    }

    return (
        <div>
            {
                isEdit && disabled === false? <textarea autoFocus rows={rows} cols={cols ? cols : "60"} disabled={disabled ? true : undefined} style={{ paddingLeft: '5px', paddingRight: '5px', width: '100%', borderRadius: '5px', borderColor: !invalid ? 'lightgray' : 'red' }} value={value ? value : ''} maxLength={maxSize} onChange={onChangeValue} /> : <div className='limited-text' title={value}>{value}</div>
            }
        </div>
    )
}
    
        
export default GenericTextArea;