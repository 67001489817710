import React, { useContext } from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import moment from 'moment';
import { ThemeContext } from "styled-components";

const GenericTime = ({ time, label, isRequired, isDisabled = false, onTimeChange, canUserEdit = true, invalid = undefined, validated = undefined, cssClass = '' }) => {
    const theme = useContext(ThemeContext);
    const requiredLabel = isRequired ? ' *' : '';
    const timeFormatted = moment(time, "HH:mm:ss").format("HH:mm");

    let givenStyle = {color: 'black', backgroundColor: ''}
    
    const invalidStyling = invalid ? { border: '1px solid #dc3545' } : validated && !invalid ? { border: '1px solid #28a745' } : undefined

    if (!canUserEdit) givenStyle.backgroundColor = theme.colors.invalidInput

    return (
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>{label + requiredLabel}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                style={givenStyle ? invalidStyling : undefined}
                type="time"
                disabled={isDisabled || !canUserEdit}
                required={isRequired}
                onChange={onTimeChange}
                value={timeFormatted}
                className={cssClass}
            />
        </InputGroup>
    );
};

export default GenericTime;
