import { Dropdown, DropdownButton } from "react-bootstrap";
import React from "react";
import styled from "styled-components";

const HeaderText = styled.p`
    color: black;
    height: 40px;
    margin: 20px 70px 20px 0px;
    font-size: 1em;
    font-weight: 400;
    display: flex;
    align-items: center;
`;


const AgenciesDropDown: React.FC<any> = ({currentAgencyLabel, setCurrentAgencyLabel, agencies, id, cssClass, alwaysShownDropDown = false}) => {
    if (agencies.length === 0 && !alwaysShownDropDown) {
        return <HeaderText>Aucune agence rattachée à ce compte</HeaderText>
    } else if (agencies.length === 1 && !alwaysShownDropDown) {
        return <div style={{marginRight: 40}}>{agencies[0]}</div>
    } else {
        return (
            <DropdownButton style={{ margin: '0 10px' }} className={cssClass} id={id ? id : "transparentDropDown"} drop="down"
                title={currentAgencyLabel ? currentAgencyLabel : ''}
                onSelect={(e) => {
                                setCurrentAgencyLabel(e);
                            }}>
                {
                    agencies.map((agency, i) =>
                    <Dropdown.Item key={agency + "_" + i} eventKey={agency}>{agency}</Dropdown.Item>
                )}
            </DropdownButton>
        )
    }
};

export default AgenciesDropDown;