import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Badge } from "react-bootstrap";
import {
    CODENAME_STATUS_APPROVED,
    CODENAME_STATUS_CREATED,
    CODENAME_STATUS_APPROVED_REFUSED,
    CODENAME_STATUS_PUBLISHED_REFUSED,
    LABEL_STATUS_APPROVED,
    LABEL_STATUS_CREATED,
    LABEL_STATUS_AWAITING_ACTIVATION,
    LABEL_STATUS_ACTIVE,
    LABEL_STATUS_EXPIRED,
    LABEL_STATUS_APPROVED_REFUSED,
    LABEL_STATUS_PUBLISHED_REFUSED
} from "../../statusUtils";
import ChronoContext from "../../context/ChronoContext";
import moment from 'moment';

import { useStatus } from '../../lib/hooks'

interface IStatusBadge {
    status?: number;
    data?: any;
    statusLabel?: string;
}

export const CustomBadge = styled(Badge)`
    margin: 2px 0px;
    padding: 5px 12px !important;
    color: white;
    display:block;
    width:100px;
    white-space: normal;
    word-wrap:break-word;
`;

const updateStatus = (data) => {
    if (!data) return '';
    const endDate = data.expirationDate ? data.expirationDate : data.endDate ? data.endDate : data.end;
    const startDate = data.activationDate ? data.activationDate : data.startDate ? data.startDate : data.start;
    if (moment(endDate).isBefore(moment())) return LABEL_STATUS_EXPIRED;
    if (moment(startDate).isAfter(moment())) return LABEL_STATUS_AWAITING_ACTIVATION;
    return LABEL_STATUS_ACTIVE;
}
/** @deprecated use StatusBadge component and UI lib */
const StatusBadge: React.FC<IStatusBadge> = ({ status=undefined, data= undefined, statusLabel = undefined }) => {
 
    const statuses = useStatus(ChronoContext);

    const theme = useContext(ThemeContext);
    let color;
    let message;

    if (status === statuses[CODENAME_STATUS_CREATED] || status === 0) {
        color = theme.colors.messageError;
        message = statusLabel ? statusLabel : LABEL_STATUS_CREATED;
    } else if (status === statuses[CODENAME_STATUS_APPROVED] || status === 8) {
        color = theme.colors.messageWarning;
        message = statusLabel ? statusLabel : LABEL_STATUS_APPROVED;
    }
    else if (status === statuses[CODENAME_STATUS_APPROVED_REFUSED] || status === 128) {
        color = theme.colors.messageError;
        message = statusLabel ? statusLabel : LABEL_STATUS_APPROVED_REFUSED;
    }
    else if (status === statuses[CODENAME_STATUS_PUBLISHED_REFUSED] || status === 256) {
        color = theme.colors.messageError;
        message = statusLabel ? statusLabel : LABEL_STATUS_PUBLISHED_REFUSED;
    }else {
        color = theme.colors.messageInfo;
        message = statusLabel ? statusLabel : updateStatus(data);
    }
    return <CustomBadge style={{backgroundColor: color}}><span style={{wordBreak: 'break-word'}}>{message}</span></CustomBadge>
};

export default StatusBadge;