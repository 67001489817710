/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import ApplicationLogsTopBar from "./ApplicationLogsTopBar";
import ApplicationLogsGrid from "./ApplicationLogsGrid";
import { ContentContainer } from "../../../lib/ui";
import ApplicationLogsFilters from "./ApplicationLogsFilters";
import useUI from "../../../lib/hooks/useUI";
import ApplicationLogDetailsPage from "./ApplicationLogDetails/ApplicationLogDetailsPage";
import addUIItem from "../../../redux/actions/addUIItem";
import { useDispatch } from "react-redux";
import * as keys from "../../../redux/keys";

const ApplicationLogsPage = () => {
    const elementSelected = useUI(keys.SELECTED_APPLICATION_LOG_INDEX) as { showDetails: boolean; };

    const dispatch = useDispatch();
  
    useEffect(() => {
        return () => {
            dispatch(
                addUIItem(
                    {
                        selectedIndex: -1,
                        showDetails: false,
                    },
                    keys.SELECTED_APPLICATION_LOG_INDEX
                )
            );

            dispatch(addUIItem(undefined, keys.LOG_SUMMARY_PARAMS));
        };
    }, []);

    return (
        <Fragment>
            <ApplicationLogsTopBar />
            <ContentContainer>
                {(!elementSelected || (elementSelected && !elementSelected.showDetails)) && (
                    <>
                        <ApplicationLogsFilters />
                        <ApplicationLogsGrid />
                    </>
                )}

                {elementSelected && elementSelected.showDetails === true && <ApplicationLogDetailsPage />}
            </ContentContainer>
        </Fragment>
    );
};

export default ApplicationLogsPage;
