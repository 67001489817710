import React, { FC, ReactElement} from 'react';
import { Container } from 'react-bootstrap';
import styled from "styled-components";
import { MEDIA_SCREEN_MENU_SWITCH_SIZE } from "../../global";
import ToastStack from "../../toast/ToastStack/ToastStack";

export const TOP_BAR_SIZE = 80;

const ContentContainerStyled = styled(Container)`
    height: calc(100vh - ${props => props.barheight ? props.barheight : TOP_BAR_SIZE}px);
    max-width: 100% !important;
    position: relative;
    top: ${props => props.barheight ? props.barheight : TOP_BAR_SIZE}px;
    overflow-y: auto;
    
    @media screen and (max-width: ${MEDIA_SCREEN_MENU_SWITCH_SIZE}px) {
       height: auto;
       top: 0;
    }
`;


const ContentContainer:FC<any> = ({children, barheight}:any):ReactElement => {
    
  return (
      <ContentContainerStyled barheight={barheight}>
          <ToastStack />
          { children }
      </ContentContainerStyled>
  )
};

export default ContentContainer