/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, InputGroup, Card } from 'react-bootstrap';
import styled from "styled-components";
import { Dropdown } from "../../../lib/ui";
import { useForm, Controller } from "react-hook-form";
import { Input } from '../../../lib/ui/index'
import { useDispatch } from 'react-redux';
import { saveConfigurationLog, getMobileConfigurationListItems } from "../../../redux/actions/applicationLogs/requestConfigurationLogs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { OS_OPTIONS, CRITICALITY_OPTIONS, UNIT_TIME } from "../constantes";
import * as constantesConfig from "../../../redux/keys/applicationLogs/configurationLogs";
import addUIItem from "../../../redux/actions/addUIItem";
import addResource from "../../../redux/actions/addResource";
import AutoComplete from '../../common/autocomplete';

const ErrorMessage = styled.p`
    margin-top: 0.1rem;
    padding:0px;
    font-size: 100%;
    color: #dc3545;
    margin-bottom: 10px;
`
const FormRow = styled(Row)`
    margin-top: 10px;
    margin-bottom: 10px;
`

const validationSchema = yup.object().shape({
    category: yup.string().required("Saisir une valeur pour la categorie").max(50, 'Le nombre de caracteres pour la categorie ne doit pas depasser 50'),
    criticality: yup.string().required("Selectionner une valeur pour le niveau de criticite").max(20, 'Le nombre de caracteres pour la criticite ne doit pas depasser 20'),
    os: yup.string().required("Selectionner une valeur pour l'OS").max(25, 'Le nombre de caracteres pour la criticite ne doit pas depasser 25'),
    logType: yup.string().required("Saisir une valeur pour le log type").max(50, 'Le nombre de caracteres pour la criticite ne doit pas depasser 50'),
    occurence: yup.string().test("","La valeur de l'occurence doit etre comprise entre 1 et 1000", function (value) {
        return (value.length > 0 && value > 0  && value <= 1000) || value.length === 0;
    }),
    frequencyErrors: yup.string().test("","La valeur de la periode d'analyse doit etre comprise entre 1 et 1000", function (value) {
        return (value.length > 0 && value > 0 && value <= 1000) || value.length === 0;
    }),
    frequency: yup.string().test("","La valeur de la frequence d'envoi doit etre comprise entre 1 et 1000", function (value) {
        return (value.length > 0 && value > 0 && value <= 1000) || value.length === 0;
    })
}); 


const ConfigurationForm = ({ data, isSaving, cancelSaving, id }) => {

    const [error, setError] = useState(undefined);


    const formDefaultConfig = {
        defaultValues: {
            logMobileConfigurationId: data && data.logMobileConfigurationId ? data.logMobileConfigurationId : 0,
            category: data && data.category ? data.category : '',
            criticality: data && data.criticality ? data.criticality : 'WARNING',
            notification: data && data.notification ? data.notification : false,
            occurence: data && data.occurence != undefined ? data.occurence : '',
            frequency: data && data.frequency ? data.frequency : '',
            frequencyErrors: data && data.frequencyErrors ? data.frequencyErrors : '',
            unitFrequency: data && data.unitFrequency ? data.unitFrequency : 'Minutes',
            unitFrequencyErrors: data && data.unitFrequencyErrors ? data.unitFrequencyErrors : 'Minutes',
            os: data && data.os ? data.os : 'ANDROID',
            logType: data && data.logType ? data.logType : '',
            active: data && data.active != undefined ? data.active : true 
        },
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    };

    const {  watch, control, setValue } = useForm(formDefaultConfig);
    const values = watch();

    const dispatch = useDispatch();

    const onChangeNotification = (evt) => {
        setValue("notification", evt.target.checked);
    }

    const onChangeActive = (evt) => {
        setValue("active", evt.target.checked);
    }

    useEffect(() => {
        if (isSaving === true) {
            setError(undefined);

            validationSchema.validate(values).then(function () {
                   dispatch(addResource({}, constantesConfig.CONFIGURATION_APP_TO_SAVE + id));
                   dispatch(addUIItem(false, constantesConfig.CONFIGURATION_APP_LOGS_REFRESH));
                   dispatch(addUIItem('', constantesConfig.CONFIGURATION_APP_TO_SAVE + '_STATUS' + id));

                   saveData();

                }).catch(function (ex) {
                    var err = { 
                        [ex.path]: {
                            'message': ex.message
                        }
                    };
               
                    setError(err);
                    if (cancelSaving)
                        cancelSaving();
                });
        }
    }, [isSaving]);

    const saveData = () => {
            dispatch(saveConfigurationLog(constantesConfig.CONFIGURATION_APP_TO_SAVE, {
                logMobileConfigurationId: data && data.logMobileConfigurationId ? data.logMobileConfigurationId : 0,
                category: values.category,
                criticality: values.criticality,
                notification: values.notification,
                occurence: values.occurence,
                frequency: values.frequency,
                frequencyErrors: values.frequencyErrors,
                unitFrequency: values.unitFrequency,
                unitFrequencyErrors: values.unitFrequencyErrors,
                os: values.os,
                logType: values.logType,
                active: values.active,
                lastDateNotification: data && data.lastDateNotification ? data && data.lastDateNotification : '',
                id: id
            }));
    }

    return (
        <Fragment>
            <Row>
                <Col >
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>

                            <Controller
                                control={control}
                                name="logType"
                                value={values.logType}
                                render={({ onChange, value }) => {
                                    return (
                                        <Input
                                            label="ID (logtype)"
                                            value={value}
                                            onChange={onChange}
                                            maxLength="50"
                                            placeholder="Saisir un log type"
                                            isRequired={true}
                                            data_testid="configLogType"
                                        />

                                    );
                                }}
                            />
                            {error && error.logType && <ErrorMessage>{error.logType.message}</ErrorMessage>}
                        </Col>
                    </Row>

                    <FormRow>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>

                            <Controller
                                control={control}
                                name="os"
                                value={values.os}
                                render={({ onChange, value }) => {
                                    return (
                                        <Dropdown
                                            mode="select"
                                            label="OS"
                                            isRequired={true}
                                            options={OS_OPTIONS}
                                            onChange={onChange}
                                            selectedValue={value}
                                        />
                                    );
                                }}
                            />
                            {error && error.os && <ErrorMessage>{error.os.message}</ErrorMessage>}
                        </Col>
                    </FormRow>

                    <FormRow>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>

                            <Controller
                                control={control}
                                name="criticality"
                                value={values.criticality}
                                render={({ onChange, value }) => {
                                    return (
                                        <Dropdown
                                            mode="select"
                                            isRequired={true}
                                            label="Criticité"
                                            options={CRITICALITY_OPTIONS}
                                            onChange={onChange}
                                            selectedValue={value}
                                        />
                                    );
                                }}
                            />
                            {error && error.criticality && <ErrorMessage>{error.criticality.message}</ErrorMessage>}
                        </Col>
                    </FormRow>

                    <FormRow>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>

                            <Controller
                                control={control}
                                name="category"
                                value={values.category}
                                render={({ onChange, value }) => {
                                    return (
                                        <AutoComplete value={value}
                                            label="Catégorie"
                                            onChange={onChange}
                                            propertyname={'Category'}
                                            style={{ width: '130px' }}
                                            placeholder="Saisir une categorie"
                                            maxLength="50"
                                            isRequired={true}
                                            id='configurationFormCat_AutoComplete'
                                            getDropdownListItems={getMobileConfigurationListItems}
                                        />
                                    );
                                }}
                            />
                            {error && error.category && <ErrorMessage>{error.category.message}</ErrorMessage>}
                        </Col>
                    </FormRow>

                    <FormRow>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <Controller
                                control={control}
                                name="active"
                                value={values.active}
                                render={({ onChange, value }) => {
                                    return (

                                        <Input type='checkbox'
                                            label="Active"
                                            value={value}
                                            checked={value}
                                            onChange={(evt) => { onChangeActive(evt) }}
                                            styleInput={{ flex: 'none', width: '15px', marginLeft: '15px' }}
                                        />
                                    );
                                }}
                            />
                        </Col>
                    </FormRow>

                    <FormRow>
                        <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <Controller
                                control={control}
                                name="notification"
                                value={values.notification}
                                render={({ onChange, value }) => {
                                    return (

                                        <Input type='checkbox'
                                            label="Notification"
                                            value={value}
                                            onChange={(evt) => { onChangeNotification(evt)}}
                                            styleInput={{flex:'none', width:'15px', marginLeft:'15px'}}
                                        />
                                    );
                                }}
                            />
                        </Col>
                    </FormRow>

                    <FormRow>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Card style={{
                                margin: '10px 0px',
                                padding: '10px',
                                width: '100%',
                                borderRadius: '20px'
                            }}>
                                <Row>
                                    <Col sm={12} lg={12} xl={12}>
                                        <Row>
                                            <Col sm={12} md={12} xl={12}>
                                                <div style={{ display: 'inline-block', width: '22%', paddingRight:'10px' }}>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text >Condition d'envoi</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                </div>
                                                <div style={{display:'inline-block', width:'15%'}}>
                                                    <Controller
                                                        control={control}
                                                        name="occurence"
                                                        value={values.occurence}
                                                        render={({ onChange, value }) => {
                                                            return (
                                                    
                                                                <Input type="number"
                                                                    min="1"
                                                                    max="1000"
                                                                    label=""
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    styleInput={{height:'38px'}}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ display: 'inline-block', width: '35%' }}>
                                                    <Controller
                                                        control={control}
                                                        name="frequencyErrors"
                                                        value={values.frequencyErrors}
                                                        render={({ onChange, value }) => {
                                                            return (

                                                                <Input type="number"
                                                                    min="1"
                                                                    max="1000"
                                                                    label="logs recus en"
                                                                    placeholder=''
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    styleInput={{ height: '38px' }}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ display: 'inline-block', width: '28%' }}>

                                                    <Controller
                                                        control={control}
                                                        name="unitFrequencyErrors"
                                                        value={values.unitFrequencyErrors}
                                                        render={({ onChange, value }) => {
                                                            return (
                                                                <Dropdown
                                                                    mode="select"
                                                                    label=""
                                                                    options={UNIT_TIME}
                                                                    styleInput={{ height: '38px', marginLeft: '10px' }}
                                                                    onChange={onChange}
                                                                    selectedValue={value}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                   
                                                </div>
                                            </Col>
                                        </Row>
                                        {error && error.occurence && <ErrorMessage>{error.occurence.message}</ErrorMessage>}
                                        {error && error.frequencyErrors && <ErrorMessage>{error.frequencyErrors.message}</ErrorMessage>}
                                    </Col>
                                </Row>

                                <FormRow style={{marginBottom:'5px'}}>
                                    <Col sm={12} lg={12} xl={12}>
                                        <Row>
                                            <Col sm={12} md={12} xl={12}>
                                                <div style={{ display: 'inline-block', width: '22%', paddingRight: '10px' }}>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text >Frequence d'envoi</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                </div>
                                                <div style={{ display: 'inline-block', width: '45%', paddingLeft: '10px' }}>
                                                    <Controller
                                                        control={control}
                                                        name="frequency"
                                                        value={values.frequency}
                                                        render={({ onChange, value }) => {
                                                            return (

                                                                <Input type="number"
                                                                    min="1"
                                                                    max="1000"
                                                                    label="Tous les"
                                                                    placeholder=""
                                                                    value={value}
                                                                    onChange={onChange}
                                                                    style={{ width: '100px' }}
                                                                    styleInput={{ height: '38px' }}
                                                                />
                                                            );
                                                        }}
                                                    />

                                                </div>
                                                
                                                <div style={{ display: 'inline-block', width: '33%' }}>

                                                    <Controller
                                                        control={control}
                                                        name="unitFrequency"
                                                        value={values.unitFrequency}
                                                        render={({ onChange, value }) => {
                                                            return (
                                                                <Dropdown
                                                                    mode="select"
                                                                    label=""
                                                                    options={UNIT_TIME}
                                                                    styleInput={{ height: '38px', marginLeft: '10px' }}
                                                                    onChange={onChange}
                                                                    selectedValue={value}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </FormRow>
                                {error && error.frequency && <ErrorMessage>{error.frequency.message}</ErrorMessage>}
                            </Card>
                            </Col>
                    </FormRow>
                </Col>
            </Row>
        </Fragment>
    );
};

export default ConfigurationForm;
