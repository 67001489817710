import React from "react";
import {  Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

const CardStatisticUserConnectedLegend = ({ color, text, total, avg }) => {

    return (
        <div>
            <Row>
                <Col sm={12} md={12} lg={12} xl={12}>
                    <div style={{ display: 'inline-block', margin: 0, padding: 0}}>
                        <FontAwesomeIcon icon={faCircle} style={{color:color}}/>
                    </div>
                    <div style={{ display: 'inline-block', fontSize: '14px', color: 'gray', marginLeft:'3px' }}>
                        {text}
                    </div>
                </Col>
              
            </Row>
            <Row>
                <Col sm={12} md={12} lg={12} xl={12}>
                    <div style={{ display: 'inline-block', margin:0, padding:0}}>
                        <FontAwesomeIcon icon={faCircle} style={{ color: 'white' }} />
                    </div>
                    <div style={{ display: 'inline-block', fontSize: '18px', color: 'black', marginLeft: '3px' }}>
                        {total / 1000 > 1 ? Math.floor(total / 1000) + 'K' : total}
                    </div>
                </Col>

            </Row>

            <Row>
                <Col sm={12} md={12} lg={12} xl={12}>
                    <div style={{ display: 'inline-block', margin: 0, padding: 0 }}>
                        <FontAwesomeIcon icon={avg > 0 ? faArrowUp : faArrowDown} style={{ color: (avg > 0 ? 'green' : 'red') }} />
                    </div>
                    <div style={{ display: 'inline-block', fontSize: '16px', color: (avg > 0 ? 'green' : 'red'), fontWeight: 'bold', marginLeft: '3px' }}>
                        {Math.abs(avg) + '%'}
                    </div>
                </Col>

            </Row>

        </div>
    )
};

export default CardStatisticUserConnectedLegend;