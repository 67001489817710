import * as  React from 'react';
import { LoadingIndicatorInApp } from "../../../LoadingIndicator";
import { Row, Col, Button } from 'react-bootstrap';
import Grid from "../../../../lib/ui/Grid/Grid";
import { convertDateToEasterTime } from "../../../common/helper";
import { NotificationListProps} from "../../../../types/components/notificationComponent";
import { useNotificationService } from "../../../../service/communication/useNotificationService";
import StatusBadge from "../../../common/StatusBadge";
import NotificationButtonGrid from "./NotificationButtonGrid";
import CommunicationContainer from '../../CommunicationContainer';

export const NotificationList: React.FC<NotificationListProps> = ({ onClickRow, hideGridLoader }: NotificationListProps): React.ReactElement => {

    const notificationService = useNotificationService();

    const [reinitializeGrid, setInitializeGrid] = React.useState<boolean>(false)
    const [refresh, setRefresh] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (!notificationService.request.GetAll.isLoading)
            setInitializeGrid(true);
    }, [notificationService.request.GetAll.isLoading]);

    const columns = [
        {
            Header: "#",
            accessor: "id",
            Cell: props => <span>{props.value}</span>
        },
        {
            Header: "Statut",
            accessor: "filteredStatusLabel",
            Cell: props => <div className='badge-container'><StatusBadge status={props.data[props.row.index].filteredStatus} statusLabel={props.value} /></div>
        },
        {
            Header: "Début",
            accessor: "startDate",
            Cell: props => <span>{props.value ? convertDateToEasterTime(props.value, 'YYYY-MM-DD') : ''}</span>
        },
        {
            Header: "Titre",
            accessor: "titleFR",
            Cell: props => <span title={props.value}>{props.value}</span>
        },
        {
            Header: "Description",
            accessor: "descriptionFR",
            Cell: props => <span  title={props.value}>{props.value}</span>
        },
        {
            Header: "Actions",
            accessor: "actions",
            Cell: props => <NotificationButtonGrid key={'btnAction' + props.data[props.row.index].id} data={props.data[props.row.index]} onRefresh={() => setRefresh(!refresh)} />
        },

    ];

    if (notificationService.request.GetAll.isLoading && !hideGridLoader) 
        return <LoadingIndicatorInApp />;

    return (
        <React.Fragment>
            {
                notificationService.request.GetAll.data && notificationService.request.GetAll.data.length > 0 &&
                <CommunicationContainer useStyleContainer={true} >
                    <Grid cssClass={"communication-list"} resetGrid={reinitializeGrid} resetCallback={() => setInitializeGrid(false)} columns={columns} data={notificationService.request.GetAll.data} enableSelectedRow={true} onClickRow={onClickRow} onSelectItem={() => {
                        document.body.click();
                    }} />
                </CommunicationContainer>
            }
            {
                (!notificationService.request.GetAll.data || notificationService.request.GetAll.data.length === 0) &&
                <Row>
                    <Col className='text-center'>
                        <h3>Aucun résultat</h3>
                    </Col>
                </Row>
            }

        </React.Fragment>
    )
}

export default NotificationList;