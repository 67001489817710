import { GET_MOBILE_CONFIG_LOG_REQUESTED, SAVE_MOBILE_CONFIG_LOG, GET_MOBILE_CONFIGURATION_LIST_ITEMS, GET_MOBILE_CONFIG_LOG_TYPE } from "../types/types";

export const getConfigurationLogs = (requestName, queryParams) => ({
    type: GET_MOBILE_CONFIG_LOG_REQUESTED,
    payload: {
        requestName,
        queryParams
    }
});


export const saveConfigurationLog = (requestName, queryParams) => ({
    type: SAVE_MOBILE_CONFIG_LOG,
    payload: {
        requestName,
        queryParams
    }
});

export const getMobileConfigurationListItems = (requestName, queryParams) => ({
    type: GET_MOBILE_CONFIGURATION_LIST_ITEMS, // GET_DROPDOWNLIST_ITEMS_LOG_CONFIG a voir avec harmonisation
    payload: {
        requestName,
        queryParams
    }
});



export const getMobileConfigurationLogType = (requestName, queryParams) => ({
    type: GET_MOBILE_CONFIG_LOG_TYPE,
    payload: {
        requestName,
        queryParams
    }
});

