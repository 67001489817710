/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { sendGetRequest } from "../../auth/apiEffects";
import { LoadingIndicator } from "../LoadingIndicator";
import CardStatistics from "./cards/CardStatistics";
import CardListMessages from "./cards/CardListMessages";
// import { ContentBelowTopBar, TopBar } from "../common/ContentNavigation";
import { ContentBelowTopBar, TopBar } from "lib/ui";
import { isRoleAllowed, ROLE_CODENAME_USER } from "../common/RoleHelper";
import ChronoContext from "../../context/ChronoContext";
import Alert from "react-bootstrap/Alert";
import CardStatisticForUserConnected from "./cards/CardStatisticForUserConnected";
import "../../lib/proptotype/prototype.ts";
import { useComService } from "service/communication/comService";
import { useMessageService } from "service/communication/useMessageService";
import { GtfsConfigViewModel } from "../../types/viewmodels/GtfsConfigViewModel";

const HomeContainer = styled(Row)`
    padding: 20px 40px;
`;

const HomePage = () => {
    const staticData = useContext(ChronoContext).staticData;
    const userProfile = staticData.user.profile;
    const roleUser = staticData.user.profile.role;
    const minRole = staticData.roles[ROLE_CODENAME_USER];
    const [allowed, setAllowed] = useState(0);
    const [isGtfsActive, setIsGtfsActive] = useState(true);

    const communicationService = useComService();
    const messageService = useMessageService();

    useEffect(() => {
        async function fetchData() {
            await loadData();
        }

        fetchData();
    }, []);

    const fetchData = (): void => {
        messageService.request.GetAll.process(messageService.mapToParam({}, [], [], communicationService.getOptionsOrg()));
    };

    useEffect(() => {
        communicationService.request.getStatus.actions.process({ communicationType: 1 });
        communicationService.request.getOrganization.actions.process("");
        fetchData();
    }, []);

    const loadData = async () => {
        const results = await sendGetRequest<GtfsConfigViewModel[]>(`/admin/gtfsconfigs`);
        var activatedConfigs: any[] = [];

        if (results)
            activatedConfigs = results.filter((config) => {
                return config.code === "DEACTIVATED_IMPORT";
            });

        setIsGtfsActive(activatedConfigs && activatedConfigs.length > 0 ? activatedConfigs[0].value === "false" : true);
    };

    useEffect(() => {
        if (!userProfile.email) {
            setAllowed(0);
            return;
        }

        var isOK = isRoleAllowed(roleUser, minRole);
        setAllowed(isOK ? 1 : 2);
    }, [roleUser, userProfile]);

    if (messageService.request.GetAll.isLoading || allowed === 0) {
        return <LoadingIndicator />;
    }

    if (allowed === 1 && messageService.request.GetAll.data) {
        return (
            <Fragment>
                <TopBar title="Tableau de bord" />
                <ContentBelowTopBar>
                    <HomeContainer>
                        {!isGtfsActive && (
                            <Col lg={12} xl={12} xs={12} sm={12}>
                                <Alert variant="danger">
                                    <span>La consommation des GTFS est d&eacute;sactiv&eacute;e</span>
                                </Alert>
                            </Col>
                        )}

                        <Col lg={12} xl={6}>
                            <CardStatistics />
                        </Col>
                        <Col lg={12} xl={6}>
                            <CardListMessages messages={messageService.request.GetAll.data} />
                        </Col>
                        <Col lg={12} xl={6}>
                            <CardStatisticForUserConnected />
                        </Col>
                    </HomeContainer>
                </ContentBelowTopBar>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <div style={{ padding: 70, textAlign: "center" }}>
                    <h1> Console Chrono </h1>
                </div>
            </Fragment>
        );
    }
};

export default HomePage;
