import * as  React from 'react';
import { LoadingIndicatorInApp } from "../../../LoadingIndicator";
import { Row, Col, Dropdown, Button, ButtonGroup } from 'react-bootstrap';
import Grid from "../../../../lib/ui/Grid/Grid";
import { convertDateToEasterTime } from "../../../common/helper";
import { CarteListProps } from "../../../../types/components/carteComponent";
import { useCarteService } from "../../../../service/communication/carteService";
import CommunicationGridContainer from '../../CommunicationGridContainer';
import StatusBadge from "../../../common/StatusBadge";
import CarteButtonGrid from "./CarteButtonGrid";

export const CarteList: React.FC<CarteListProps> = ({ onClickRowAction, setIsSliderLoading, setEntity }: CarteListProps): React.ReactElement => {
    const carteService = useCarteService();

    const [reinitializeGrid, setInitializeGrid] = React.useState<boolean>(false)
    const [refresh, setRefresh] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (!carteService.request.GetAll.isLoading)
            setInitializeGrid(true);
    }, [carteService.request.GetAll.isLoading]);

    const columns = [
        {
            Header: "",
            accessor: "iconUrl",
            Cell: props => <div>< img height={55} src={props.value} /></div >
        },
        {
            Header: "Statut",
            accessor: "filteredStatusLabel",
            Cell: props => <div className='badge-container'><StatusBadge status={props.data[props.row.index].filteredStatus} statusLabel={props.value} /></div>
        },
        {
            Header: "Identifiant",
            accessor: "id",
            Cell: props => <span>{props.value}</span>
        },
        {
            Header: "Début",
            accessor: "activationDate",
            Cell: props => <span>{props.value ? convertDateToEasterTime(props.value, 'YYYY-MM-DD HH:mm') : ''}</span>
        },
        {
            Header: "Fin",
            accessor: "expirationDate",
            Cell: props => <span>{props.value ? convertDateToEasterTime(props.value, 'YYYY-MM-DD HH:mm') : ''}</span>
        },
        {
            Header: "TexteFr",
            accessor: "longTextFR",
        },
        {
            Header: "TexteFr",
            accessor: "longTextEN",
        },
        {
            Header: "Actions",
            accessor: "actions",
            Cell: props => <CarteButtonGrid key={'btnAction' + props.data[props.row.index].id} data={props.data[props.row.index]} onRefresh={() => setRefresh(!refresh)}/>
        }
    ];

    if (carteService.request.GetAll.isLoading && (!carteService.request.GetAll.data || carteService.request.GetAll.data?.length === 0)) {
        return <LoadingIndicatorInApp />;
    }

    return (
        <React.Fragment>
            {
                carteService.request.GetAll.data && carteService.request.GetAll.data.length > 0 &&
                <CommunicationGridContainer
                     useStyleContainer={true}
                >
                    <Grid
                        cssClass={"communication-list"}
                        resetGrid={reinitializeGrid}
                        resetCallback={() => setInitializeGrid(false)}
                        columns={columns} data={carteService.request.GetAll.data}
                        enableSelectedRow={true}
                        onClickRow={async (item) => {
                            onClickRowAction();
                            setIsSliderLoading(true);

                            const model = carteService.mapToForm(item);
                            setEntity(model);

                            setIsSliderLoading(false);
                        }}
                        onSelectItem={() => { document.body.click(); }}
                    />
                </CommunicationGridContainer>
            }
            {
                (!carteService.request.GetAll.data || carteService.request.GetAll.data.length === 0) &&
                <Row>
                    <Col className='text-center'>
                        <h3>Aucun résultat</h3>
                    </Col>
                </Row>
            }

        </React.Fragment>
    )
}

export default CarteList;