/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Col, FormControl } from 'react-bootstrap';
import { CalendarSelector } from "../../../lib/ui/index";
import DropdownCheckboxes from "../DropdownCheckboxes";
import ChronoContext from "../../../context/ChronoContext";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle, faStream } from '@fortawesome/free-solid-svg-icons';
import useUI from "../../../lib/hooks/useUI";
import useResources from "../../../lib/hooks/useResources";
import addUIItem from "../../../redux/actions/addUIItem";
import * as requestStatus from "../../../redux/sagas/requestStatus";
import * as keys from "../../../redux/keys/index";
import { useDispatch } from 'react-redux';
import * as calendarService from "../../../service/calendarService";
import * as miscService from "../../../service/miscService";

import {
    LABEL_STATUS_ALL,
    LABEL_STATUS_NONE,
    LIST_STATUS,
} from "../../../statusUtils";

import {
    LABEL_AGENCY_ALL,
    LABEL_AGENCY_NONE,
} from "../CommunicationUtils";

import moment from "moment";


const CustomFormControl = styled(FormControl)`
 padding: 0px;
 display: inline-table;
 max-width: 230px;
 min-width: 180px;
 background-color: transparent;
 border-color: transparent;
 color: black;
 margin: 10px 0px;
 border-radius: 30px !important;
 text-align: start;
`;

export const CalendarFilter = ({ onChange}) => {

    const dropdownOptionData = LIST_STATUS.map((x) => {
        return { label: x.label, value: x.codename };
    });

    const chronContext = useContext(ChronoContext);
    const staticData = chronContext ? chronContext.staticData : null;
    const agencies = staticData != null ? staticData.user.agencies : "";
    const statusMessage = staticData != null ? staticData.status : "";

    const [statusList, setStatusList] = useState(dropdownOptionData);
    const [month, setMonth] = useState(moment().month());
    const [year, setYear] = useState(moment().year().toString());
    const [orgFilterList, setOrgFilterList] = useState<Array<any>>([]);

    const dispatch = useDispatch();
    const messages = useResources(keys.CALENDAR_MESSAGE);   
    const status = useUI(keys.CALENDAR_MESSAGE + '_STATUS');


    useEffect(() => {
        setOrgFilterList(miscService.getOptionsFilterOrganizationList(agencies))
    }, [agencies]);

    useEffect(() => {
        dispatch(addUIItem(0, keys.CALENDAR_FILTER_STATUS));
        dispatch(addUIItem(0, keys.CALENDAR_FILTER_STATUS + 'CHILDREN'));

        calendarService.filterData(status, agencies, messages, statusList, statusMessage, orgFilterList, dispatch);
    }, [statusList, orgFilterList]);

    useEffect(() => {
        dispatch(addUIItem(0, keys.CALENDAR_FILTER_STATUS));
        dispatch(addUIItem(0, keys.CALENDAR_FILTER_STATUS + 'CHILDREN'));

        calendarService.loadData(year, month, dispatch, onChange);
    }, [month, year]);

    useEffect(() => {
        if (status === requestStatus.REQUEST_SUCCEEDED || status === requestStatus.REQUEST_FAILED) {
            calendarService.filterData(status, agencies, messages, statusList, statusMessage, orgFilterList, dispatch);
        }
    }, [status]);

    const onStatusChange = (value, evt) => {
        setStatusList(miscService.getStatusList(evt, dropdownOptionData, value));
    };

    const onOrganizationFilterChange = (value, evt) => {
        var optionList = miscService.getOptionsFilterOrganizationList(agencies);
        setOrgFilterList(miscService.getOrgList(evt, optionList, value));
    };

    return (
        <Fragment>

            <Col className='container-calendar-filter-dropdown'>
                <div className='calendar-pd-5 container-calendar-filter'>
                    <CalendarSelector key='calendarSelector_Filter' id='calendarSelector_Filter' month={month} year={year} onChange={(obj) => {
                        setMonth(obj.month);
                        setYear(obj.year);
                    }} />
                </div>

                <div className='calendar-filter-dropdown'>
                    <CustomFormControl
                        as="div"
                        id="selectMultiStatus"
                    >
                        <div className='calendar-filter-awesome'><FontAwesomeIcon icon={faExclamationCircle} /></div>
                        <div className='calendar-filter-display'>
                            <DropdownCheckboxes
                            valueList={statusList}
                            onChange={onStatusChange}
                            defaultLabel={LABEL_STATUS_ALL}
                            labelButton={"choisis"}
                            optionData={dropdownOptionData}
                            labelNone={LABEL_STATUS_NONE}
                            numberOptions={
                                dropdownOptionData && dropdownOptionData.length > 1
                                    ? dropdownOptionData.length - 1
                                    : null
                            }
                            getOptionValue={(option) => option.label}
                            />
                        </div>
                    </CustomFormControl>
                </div>

                <div className='calendar-filter-dropdown'>
                    <CustomFormControl
                        as="div"
                        id="selectMultiOrgFilter"
                    >
                        <div className='calendar-filter-awesome'><FontAwesomeIcon icon={faStream} /></div>
                        <div className='calendar-filter-display'>

                            <DropdownCheckboxes
                                valueList={orgFilterList}
                                onChange={onOrganizationFilterChange}
                                defaultLabel={LABEL_AGENCY_ALL}
                                labelButton={"choisies"}
                                optionData={miscService.getOptionsFilterOrganizationList(agencies)}
                                numberOptions={
                                    agencies && agencies.length > 0 ? agencies.length : null
                                }
                                labelNone={LABEL_AGENCY_NONE}
                                getOptionValue={(option) => option.label}
                                />
                        </div>
                    </CustomFormControl>
                </div>
            </Col>
        </Fragment>
    )
}

export default CalendarFilter