import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../actions/types/types";
import * as consoleApi from "../../../api";
import addResource from "../../actions/addResource";
import addUIItem from "../../actions/addUIItem";
import * as status from "../../sagas/requestStatus";
import * as keys from "../../keys";

export function* watchApplicationLogs() {
  yield takeLatest(types.GET_APPLICATION_LOGS_REQUESTED, doApplicationLogs);
}

export function* doApplicationLogs(action) {
  const { queryParams } = action.payload;
  console.log(action.payload)
  try {
   
    yield put(addUIItem(status.REQUEST_PENDING, keys.APPLICATION_LOGS_REQUEST_STATUS));
    const applicationLogs = yield call(
      consoleApi.getApplicationLogs,
      queryParams
    );

    if (!applicationLogs) {
      throw new Error("Unable to get applications logs.");
    }

    yield put(addResource(applicationLogs, keys.APPLICATION_LOGS));
    yield put(addUIItem(status.REQUEST_SUCCEEDED, keys.APPLICATION_LOGS_REQUEST_STATUS));
  } catch (e) {
    yield put(addUIItem(status.REQUEST_FAILED, keys.APPLICATION_LOGS_REQUEST_STATUS));
  }
}
