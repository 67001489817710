import React, { useContext } from 'react';
import styled, { ThemeContext } from "styled-components";
import { CustomBadge } from "../../../StatusBadge";
import { getFormattedDate, getTruncatedText } from "../../../helper";

const GenericTd = styled.td`
    vertical-align: middle !important;
`;

interface IGenericTableCell { 
    maxSize?: number;
    valueType?: string;
    dateFormat?: string;
    trueText?: string;
    falseText?: string;
    style?: any; //TODO: need to check how we can pass this properly
    onClick?: () => void;
    colSpan?: number;
}

const GenericTableCell: React.FC<IGenericTableCell> = ({ 
    children, maxSize = 512, dateFormat, trueText, falseText, ...props }) => {
    const theme = useContext(ThemeContext);
    trueText = trueText ? trueText : 'Active'
    falseText = falseText ? falseText : 'Inactive'

    let content;
    if (typeof children === 'boolean' || props.valueType === 'nullableBool') {
        content = children == null ? <CustomBadge style={{ display: 'inline-grid', backgroundColor: theme.colors.messageError }}>Indisponible</CustomBadge>
            : children ?
                <CustomBadge style={{ display: 'inline-grid', backgroundColor: theme.colors.messageInfo }}>{trueText}</CustomBadge> :
                <CustomBadge style={{ display: 'inline-grid', backgroundColor: theme.colors.messageError }}>{falseText}</CustomBadge>;
    } else if (dateFormat) {
        content = getFormattedDate(children, dateFormat);
    } else if (typeof children === 'string') {
        content = getTruncatedText(children, maxSize);
    } else {
        content = children;
    }

    return <GenericTd {...props}>{content}</GenericTd>;
};

export default GenericTableCell;