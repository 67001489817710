import styled from "styled-components";
import ChronoCard from "../../common/ChronoCard";

export const StatsContainer = styled.div`
    padding: 0px 40px;
`;

export const StatsCard = styled(ChronoCard)`
    padding: 20px;
`;

export const GraphsCard = styled(ChronoCard)`
    padding: 20px;
    height: 600px;
`;
