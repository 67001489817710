import React, { Fragment } from "react";
import { TopBar } from "../../../lib/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import addUIItem from "../../../redux/actions/addUIItem";
import useUI from "../../../lib/hooks/useUI";
import * as keys from "../../../redux/keys/applicationLogs/applicationLogDetails";
import { Col, Button } from "react-bootstrap";

const ApplicationLogsTopBar = () => {
    const dispatch = useDispatch();
    const elementSelected = useUI(keys.SELECTED_APPLICATION_LOG_INDEX) as { showDetails: boolean; id: string; selectedIndex: number; };

    return (
        <Fragment>
            <TopBar
                title={elementSelected && elementSelected.showDetails === true ? `ID (${elementSelected.id})` : "Log applicatifs"}
                data-testId='topbar'
            >
                {elementSelected && elementSelected.showDetails === true && (
                    <Col>
                        <Button
                            onClick={() => {
                                dispatch(
                                    addUIItem(
                                        {
                                            selectedIndex: elementSelected.selectedIndex,
                                            showDetails: false,
                                        },
                                        keys.SELECTED_APPLICATION_LOG_INDEX
                                    )
                                );
                            }}
                            style={{ float: "right" }}
                        >
                            <FontAwesomeIcon icon={faLongArrowAltLeft} />
                        </Button>
                    </Col>
                )}
            </TopBar>
        </Fragment>
    );
};

export default ApplicationLogsTopBar;
