import { combineReducers } from "redux";
import login from "./reducers/loginReducer";
import resources from "./reducers/resources";
import ui from "./reducers/ui";
import requests from "./reducers/requests"

const staticReducers = {
    login,
    resources,
    ui,
    requests
};

const createReducer = () => {
    return combineReducers({
        ...staticReducers,
    });
};

export default createReducer;
