import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import useResources from "../../../../lib/hooks/useResources";
import useUI from "../../../../lib/hooks/useUI";
import * as constantesDetails from "../../../../redux/keys/applicationLogs/applicationLogDetails";
import * as constantesLogs from "../../../../redux/keys/applicationLogs/applicationLogs";

interface IApplicationLogs {
    os: string;
    category: string;
    criticality: string;
    nbLogs: string;
    nbUsers: string;

}
interface IElementSelected {
    selectedIndex: number;
}
export const ApplicationLogDetailHeader = () => {
    const applicationLogs = useResources(constantesLogs.APPLICATION_LOGS) as IApplicationLogs[];
    const elementSelected = useUI(constantesDetails.SELECTED_APPLICATION_LOG_INDEX) as IElementSelected;

    return (
        <Fragment>
            {applicationLogs && applicationLogs.length > 0 && elementSelected && elementSelected.selectedIndex >= 0 ? (
                <div>
                    <Row>
                        <Col xl={2} md={4} sm={6} xs={6}>
                            <div className="header-title-config header-general-config">OS</div>
                        </Col>
                        <Col xl={4} md={8} sm={6} xs={6}>
                            <div className="header-value-config header-general-config" data-testid="detail_OS">
                                {applicationLogs[elementSelected.selectedIndex].os}
                            </div>
                        </Col>
                        <Col xl={2} md={4} sm={6} xs={6}>
                            <div className="header-title-config header-general-config">Catégorie</div>
                        </Col>
                        <Col xl={4} md={8} sm={6} xs={6}>
                            <div data-testid="detail_Category" className="header-value-config header-general-config">
                                {applicationLogs[elementSelected.selectedIndex].category}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={2} md={4} sm={6} xs={6}>
                            <div className="header-title-config header-general-config">Criticité</div>
                        </Col>
                        <Col xl={4} md={8} sm={6} xs={6}>
                            <div data-testid="detail_Criticality" className="header-value-config header-general-config">
                                {applicationLogs[elementSelected.selectedIndex].criticality}
                            </div>
                        </Col>
                        <Col xl={2} md={4} sm={6} xs={6}>
                            <div className="header-title-config header-general-config">Occurence</div>
                        </Col>
                        <Col xl={4} md={8} sm={6} xs={6}>
                            <div data-testid="detail_NbLogs" className="header-value-config header-general-config">
                                {applicationLogs[elementSelected.selectedIndex].nbLogs}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={2} md={4} sm={6} xs={6}>
                            <div className="header-title-config header-general-config">Utilisateurs concernés</div>
                        </Col>
                        <Col xl={4} md={8} sm={6} xs={6}>
                            <div data-testid="detail_NbUsers" className="header-value-config  header-general-config">
                                {applicationLogs[elementSelected.selectedIndex].nbUsers}
                            </div>
                        </Col>
                    </Row>
                </div>
            ): null}
        </Fragment>
    );
};

export default ApplicationLogDetailHeader;
