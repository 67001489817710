import React, { useState } from 'react';
import { LoadingIndicatorInApp } from "../../LoadingIndicator";
import { useChronoApi } from "../../../auth/apiEffects";
import { Col } from "react-bootstrap";
import GoogleMapReact from 'google-map-react';

const montrealLatLong = {lat: 45.5772167, lng: -73.7179935};

export const HEAT_MAP_ORIGIN = "origin";
export const HEAT_MAP_DESTINATION = "destination";

const GRADIENT_ORIGIN = [
    'rgba(0, 255, 255, 0)',
    'rgba(0, 255, 255, 0.1)',
    'rgba(0, 191, 255, 0.2)',
    'rgba(0, 127, 255, 0.3)',
    'rgba(0, 63, 255, 0.4)',
    'rgba(0, 0, 255, 0.5)',
    'rgba(0, 0, 223, 0.6)',
    'rgba(0, 0, 191, 0.7)',
    'rgba(0, 0, 159, 0.8)',
    'rgba(0, 0, 127, 0.9)',
    'rgba(0, 0, 127, 1)',
];

const GRADIENT_DESTINATION = [
    'rgba(170, 0, 0, 0)',
    'rgba(180, 0, 0, 0.1)',
    'rgba(190, 0, 0, 0.2)',
    'rgba(200, 0, 0, 0.3)',
    'rgba(210, 0, 0, 0.4)',
    'rgba(220, 0, 0, 0.5)',
    'rgba(230, 0, 0, 0.6)',
    'rgba(250, 0, 0, 0.7)',
    'rgba(250, 0, 0, 0.8)',
    'rgba(255, 0, 0, 0.9)',
    'rgba(255, 0, 0, 1)',
];

const translateDestinationData = (dataList) => {
    if (dataList) {
        return dataList.map(item => ({
            lat: item.destinationLat,
            lng: item.destinationLong,
        }));
    } else {
        return [];
    }
};

const translateOriginData = (dataList) => {
    if (dataList) {
        return dataList.map(item => ({
            lat: item.originLat,
            lng: item.originLong,
        }));
    } else {
        return [];
    }
};

const MyHeatMap = ({heatMapType, locations}) => {
    const {data: googleMapKey, isLoading} = useChronoApi('/statistics/googleapikey');
    // eslint-disable-next-line no-unused-vars
    const [_, setGMap] = useState<any>(false);
    
    if (isLoading) {
        return <Col style={{margin: '50px 0px'}}><LoadingIndicatorInApp size={50} /></Col>;
    }

    return (
        <div style={{height: '60vh', width: '100%'}}>
            <GoogleMapReact
                ref={gMapRef => setGMap(gMapRef as any)}
                bootstrapURLKeys={{
                    key: googleMapKey,//'AIzaSyC3-Z_63hYKTS4mKzNkkMotGbJVSliZMtE',
                    language: 'fr'
                }}
                defaultCenter={montrealLatLong}
                defaultZoom={11}
                heatmapLibrary
                heatmap={{
                    positions: heatMapType === HEAT_MAP_ORIGIN ? translateOriginData(locations) : translateDestinationData(locations),
                    options: {
                        radius: 40,
                        opacity: 1,
                        // TODO: this prop doesn't exists why it is passed
                       // gradient: heatMapType === HEAT_MAP_ORIGIN ? GRADIENT_ORIGIN as any : GRADIENT_DESTINATION as any,
                    }
                }} />
        </div>
    )
};

export default MyHeatMap;
