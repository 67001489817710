import * as  React from 'react';
import CommunicationActionButtonGrid from "../../CommunicationActionButtonGrid";
import { useNotificationService } from "../../../../service/communication/useNotificationService";
import { useCommunicationEvent } from '../../communicationEvent';
import { NotificationButtonGridProps } from '../../../../types/components/notificationComponent';

const NotificationButtonGrid: React.FC<NotificationButtonGridProps> = ({ data, onRefresh }: NotificationButtonGridProps): React.ReactElement => {
    const notificationService = useNotificationService();
    const keySuffix = 'alertGrid';

    React.useEffect(() => {
        notificationService.request.Publish.changeResourceName({ id: data.id });
        notificationService.request.Delete.changeResourceName({ id: data.id, keySuffix: keySuffix});
    }, []);

    //handle event publish notif
    useCommunicationEvent(
        {
            successCallback: () => notificationService.updateModel(notificationService.request.Publish.data),
            afterPost: () => {
                notificationService.request.Publish.refreshState(data.id);
                if (onRefresh)
                    onRefresh();
            }
        }, notificationService.request.Publish.isSuccess, notificationService.request.Publish.isFail
    )

     //handle event delete notif
    useCommunicationEvent(
        {
            successCallback: () => notificationService.removeElement(data.id),
            afterPost: () => {
                notificationService.request.Delete.refreshState(data.id);
                if (onRefresh)
                    onRefresh();
            }
        }, notificationService.request.Delete.isSuccess, notificationService.request.Delete.isFail
    )

    return (
        <div id={'containerButonGrid' + data.id} className='com-container'>

            <CommunicationActionButtonGrid
                isProcessing={notificationService.request.Publish.isLoading || notificationService.request.Unpublish.isLoading || notificationService.request.Delete.isLoading}
                disabled={notificationService.isNotificationPublished(data)}
                hideApprove={true}
                hidePublish={false}
                hideRefuse={true}
                canPublish={notificationService.canEditNotification(data)}
                onClickSend={(requestType) => {
                    const model = notificationService.mapToModel(data, undefined, requestType);
                    notificationService.request.Publish.process(model);

                }}
                onClickDelete={() => {
                    notificationService.request.Delete.process(data.id, keySuffix);
                }}
                data={data}
            />
        </div>
    )
}

export default NotificationButtonGrid;