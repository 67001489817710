import { all, fork } from 'redux-saga/effects'
import loginSaga from '../components/login/loginSaga'
import { watchApplicationLogs } from './sagas/applicationLogs/applicationLogs'
import { watchApplicationLogDetails } from './sagas/applicationLogs/ApplicationLogDetails'
import { watchApplicationLogConfigurations } from './sagas/applicationLogs/applicationLogsConfigurations'
import { watchMobileConfigLogs } from './sagas/applicationLogs/configurationLog'
import { watchChronouserRequest } from './sagas/chronoUser/chronoUser'
import { watchMobileMessage } from './sagas/communication/message'
import { watchMobileCarte } from './sagas/communication/carte'
import { watchRoutes } from './sagas/references/routes'
import { watchStops} from './sagas/references/stops'
import { watchMobileAlert } from './sagas/communication/alert'
import { watchMobileNotification } from './sagas/communication/notification'
import { watchDashboradStatsRequest } from './sagas/stats/dashboard'
import { watchRequestApi } from './sagas/genericSaga'



export default function* rootSaga() {
    yield all([
        loginSaga(),
        fork(watchApplicationLogs),
        fork(watchApplicationLogDetails),
        fork(watchApplicationLogConfigurations),
        fork(watchMobileConfigLogs),
        fork(watchMobileMessage),
        fork(watchChronouserRequest),
        fork(watchMobileCarte),
        fork(watchRoutes),
        fork(watchStops),
        fork(watchMobileAlert),
        fork(watchMobileNotification),
        fork(watchDashboradStatsRequest),
        fork(watchRequestApi)
    ])
}