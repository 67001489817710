import React, { Fragment, useContext } from 'react';
import { LoadingIndicator } from "../../LoadingIndicator";
import { useChronoApi } from "../../../auth/apiEffects";
import { ContentBelowTopBar, TopBar } from "../../../lib/ui";
import moment from 'moment';
import GenericTable, { GenericTableRow } from "../../common/generic/table/GenericTable";
import GenericTableBody from "../../common/generic/table/GenericTableBody";
import GenericTableCell from "../../common/generic/table/cell/GenericTableCell";
import { ThemeContext } from "styled-components";
import GenericTableHeadSingleRow from "../../common/generic/table/GenericTableHeadSingleRow";

const MonitoringGtfsPage = () => {
    const theme = useContext(ThemeContext);
    const {data: statsGtfs, isLoading} = useChronoApi('/admin/gtfs/stats');

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Fragment>
            <TopBar title="Monitoring GTFS" />
            <ContentBelowTopBar className={`pl-4 pr-4`}>
                <GenericTable className={`mt-5 bg-white`}>
                    <GenericTableHeadSingleRow>
                        <th style={{backgroundColor: theme.colors.mediumGray}}>Code agence</th>
                        <th>GTFS Id</th>
                        <th>Occupancy Id</th>
                        <th>Route</th>
                        <th>Services actifs</th>
                        <th>Voyages</th>
                        <th>Date de fin</th>
                        <th>Jours restants</th>
                    </GenericTableHeadSingleRow>

                    <GenericTableBody>
                        {
                            statsGtfs.map(stat => {
                                const dateFormat = 'DD/MM/YYYY';
                                const daysLeftBeforeExpiry = moment().diff(stat.maxDateService, 'days') * -1;

                                const warningColor = daysLeftBeforeExpiry <= 7 ?
                                    theme.colors.messageError : daysLeftBeforeExpiry <= 15 ?
                                        theme.colors.messageWarning : 'transparent';
                                
                                return (
                                    <GenericTableRow key={stat.organisationCode}
                                                     style={{backgroundColor: warningColor}}>
                                        <GenericTableCell style={{backgroundColor: theme.colors.mediumGray}}><b>{stat.organisationCode}</b></GenericTableCell>
                                        <GenericTableCell>{stat.importId}</GenericTableCell>
                                        <GenericTableCell>{stat.importIdComplementaire ? stat.importIdComplementaire : "Aucun"}</GenericTableCell>
                                        <GenericTableCell>{stat.nbRoutes}</GenericTableCell>
                                        <GenericTableCell>{stat.nbServicesActifs}</GenericTableCell>
                                        <GenericTableCell>{stat.nbVoyages}</GenericTableCell>
                                        <GenericTableCell dateFormat={dateFormat}>{stat.maxDateService}</GenericTableCell>
                                        <GenericTableCell>{daysLeftBeforeExpiry}</GenericTableCell>
                                    </GenericTableRow>
                                );
                            })
                        }
                    </GenericTableBody>
                </GenericTable>
            </ContentBelowTopBar>
        </Fragment>
    );

};

export default MonitoringGtfsPage;
