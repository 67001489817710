import { useSelector } from "react-redux";

const useUI = (key) => {
    const uiVal = useSelector<any>((state) => 
    {
        const ui = state.ui;
        if (ui && ui[key] !== undefined) 
            return ui[key];
    });
    return uiVal;
  
};

export default useUI;