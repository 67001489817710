import React, { useState } from "react";
import { LoadingIndicatorInApp } from "../../../LoadingIndicator";
import { Row, Col } from "react-bootstrap";
import Grid from "../../../../lib/ui/Grid/Grid";
import { convertDateToEasterTime } from "../../../common/helper";
import { MessageListProps } from "../../../../types/components/MessageComponent";
import { useMessageService } from "service/communication/useMessageService";
import { StatusBadge } from "lib/ui";
import { OrganizationBadge } from "lib/ui/badge/organizationBadge";
import { useComService } from "service/communication/comService";
import { MessageActionsButtonGrid } from "./MessageActionsButtonGrid";
import CommunicationGridContainer from "components/communication/CommunicationGridContainer";

export const MessageList: React.FC<MessageListProps> = (props: MessageListProps) => {
    const messageService = useMessageService();
    const communicationService = useComService();
    const [refresh, setRefresh] = useState<boolean>(true);
    const [reinitializeGrid, setInitializeGrid] = useState<boolean>(false);
    const { onClickRowAction } = props;
    const { setEntity, setIsMessageEditable, setIsSliderLoading } = props;

    const columns = [
        {
            Header: "#",
            accessor: "id",
            Cell: (props) => <span>{props.value}</span>,
        },
        {
            Header: "Statut",
            accessor: "filteredStatusLabel",
            Cell: (props) => {
                return (
                    <div className="badge-container">
                        <StatusBadge
                            color={
                                props.data[props.row.index] &&
                                messageService.tool.getStatusBadgeColor(props.data[props.row.index].filteredStatus).color
                            }
                            label={props.value}
                        />
                    </div>
                );
            },
        },
        {
            Header: "Agence",
            accessor: "agencyCodeList",
            Cell: (props) => {
                const badgeModel = communicationService.getOrgBadge(props.value);
                return <OrganizationBadge badgeModel={badgeModel} />;
            },
        },
        {
            Header: "Message FR",
            accessor: "contentFR",
            Cell: (props) => <span>{props.value}</span>,
        },
        {
            Header: "Message EN",
            accessor: "contentEN",
            Cell: (props) => <span>{props.value}</span>,
        },

        {
            Header: "Début",
            accessor: "startDate",
            Cell: (props) => <span>{props.value ? convertDateToEasterTime(props.value, "YYYY-MM-DD") : ""}</span>,
        },
        {
            Header: "Fin",
            accessor: "endDate",
            Cell: (props) => <span>{props.value ? convertDateToEasterTime(props.value, "YYYY-MM-DD") : ""}</span>,
        },

        {
            Header: "Actions",
            accessor: "actions",
            Cell: (props) => (
                <>
                    {props.data[props.row.index] && (
                        <MessageActionsButtonGrid
                            key={"btnAction" + props.data[props.row.index].id}
                            data={props.data[props.row.index]}
                            onRefresh={() => setRefresh(!refresh)}
                        />
                    )}
                </>
            ),
        },
    ];

    if (messageService.request.GetAll.isLoading) {
        return <LoadingIndicatorInApp />;
    }

    return (
        <>
            {messageService.request.GetAll.data && messageService.request.GetAll.data.length > 0 && (
                <CommunicationGridContainer
                    isProcessing={
                        messageService.request.GetAll.isLoading &&
                        messageService.request.GetAll.data &&
                        messageService.request.GetAll.data?.length > 0
                    }
                    useStyleContainer={true}
                >
                    <Grid
                        cssClass={"communication-list"}
                        resetGrid={reinitializeGrid}
                        resetCallback={() => setInitializeGrid(false)}
                        columns={columns}
                        data={messageService.request.GetAll.data}
                        enableSelectedRow={true}
                        columnSizes={[1, 1, 1, 16, 16, 4, 4, 1]}
                        onClickRow={async (item) => {
                            onClickRowAction();
                            setIsSliderLoading(true);
                            if (messageService.canEditMessage(item)) {
                                setIsMessageEditable(true);
                                setEntity(await messageService.mapToForm(item));
                            } else {
                                setEntity(await messageService.mapToDetail(item));
                            }
                            setIsSliderLoading(false);
                        }}
                        onSelectItem={() => {
                            document.body.click();
                        }}
                    />
                </CommunicationGridContainer>
            )}
            {(!messageService.request.GetAll.data || messageService.request.GetAll.data.length === 0) && (
                <Row>
                    <Col className="text-center">
                        <h3>Aucun résultat</h3>
                    </Col>
                </Row>
            )}
        </>
    );
};
