import React, { Fragment, FC, ReactElement, useState, useEffect, useRef } from "react";
import { CarteFilter } from './components/CarteFilter'
import { CarteList } from './components/CarteList'
import { Card, Col, Button } from 'react-bootstrap';
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import SvgIcon from "../../common/SvgIcon";
import { useCarteService } from "../../../service/communication/carteService";
import CarteBaseContainer from "./CarteBaseContainer";
import CommunicationFormSlider from "../CommunicationFormSlider";
import { EMPTY_CARTE } from "./CarteConstants";
import type { CommunicationFormSliderRef } from "../../../types/components/CommunicationFormSlider";

const CartePage: FC = ():ReactElement => {

    const sliderRef = useRef<CommunicationFormSliderRef>();
    const carteService = useCarteService();
    const [entity, setEntity] = useState<any>({});
    const [isSliderLoading, setIsSliderLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            carteService.cleanCache();
        }
    }, []);

    return (
        <Fragment>
            <TopBar title="Cartes">
                <Col>
                    <Button onClick={() => {
                        setEntity(carteService.getNewCarte());
                        setIsOpen(!isOpen);
                    }}
                        style={{ float: 'right' }}
                    >
                        Nouvelle carte
                        <SvgIcon icon="plus" />
                    </Button>
                </Col>
            </TopBar>
            <ContentBelowTopBar>
                <CarteFilter />
                <Card className='generic-card'>
                    <CarteList
                        onClickRowAction={() => {
                            setIsOpen(!isOpen);
                        }}
                        setIsSliderLoading={setIsSliderLoading}
                        setEntity={setEntity}
                    />
                </Card>

                <CommunicationFormSlider
                    ref={sliderRef}
                    open={isOpen}
                    handleClose={() => {
                        setIsOpen(!isOpen);
                        setEntity(EMPTY_CARTE);
                    }}
                >
                    {entity && (
                        <CarteBaseContainer
                            entity={entity}
                            setEntity={setEntity}
                            isSliderLoading={isSliderLoading}
                        />
                    )}
                </CommunicationFormSlider>
            </ContentBelowTopBar>
        </Fragment>
    )
}

export default CartePage

