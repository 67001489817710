import * as yup from "yup";
import moment from 'moment';

//validations
export const validationGetParamSchema = yup.object({
    dateDebut: yup.date().nullable()
        .test("is-null-startDate", "La date de début ne peut être vide si la date de fin n'est pas vide", function (value) {
            const { dateFin } = this.parent;
            return !(value == null && dateFin != null);
        }).test("is-smaller", "La date de fin ne peut être antérieure", function (value) {
            const { dateDebut, dateFin } = this.parent;
            if (value != null && dateDebut != null && dateDebut != null)
                return moment(value).isBefore(dateFin);

            return true;
        }),
    dateFin: yup.date().nullable()
        .test("is-greater", "La date de fin ne peut être antérieure", function (value) {
            const { dateDebut } = this.parent;
            if (value != null && dateDebut != null)
                return moment(value).isAfter(dateDebut);

            return true;
        })
        .test("is-null-endDate", "La date de fin ne peut être vide si la date de début n'est pas vide", function (value) {
            const { dateDebut } = this.parent;
            return !(value == null && dateDebut != null);
        })
});


