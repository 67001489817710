import moment from 'moment';
import momentTime from 'moment-timezone';
import { useEffect, useState, useRef, useCallback } from "react";



export const AGENCY_LABEL_EXO = 'EXO';

export const AGENCIES_EXO = [
    'CITCRC',
    'CITHSL',
    'CITLA',
    'CITLR',
    'CITPI',
    'CITROUS',
    'CITSO',
    'CITSV',
    'CITVR',
    'MRCLASSO',
    'MRCLM',
    'OMITSJU'
];

export const TYPES_TRANPORT_STM = [
    { label: 'Tout', id: '0' }, 
    { label: 'Métro', id: '1' },
    { label: 'Bus', id: '2' }
];

export const getSimplifiedAgencyLabels = (agencies) => {
    const agenciesFiltered = agencies.map(agency => AGENCIES_EXO.includes(agency.label) ? AGENCY_LABEL_EXO : agency.label);
    return [...new Set(agenciesFiltered)] as string[];
};

export const sortAlphaNum = (routes, sortOnAttribute) => {
    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

    if (sortOnAttribute) {
        return routes.sort((a, b) => {
            return collator.compare(a[sortOnAttribute], b[sortOnAttribute])
        });
    } else {
        return routes.sort(collator.compare);
    }
};

export const validateDate = (date) => {
    return moment(date).isSameOrAfter(moment(), 'day')
}

export const validateEndDate = (startDate, endDate) => {
    return moment(startDate).isSameOrBefore(moment(endDate));
}

export const getFormattedDate = (date, dateFormat = 'DD/MM/YYYY') => {
    return moment(date).format(dateFormat);
};

export const getTruncatedText = (sentence, maxSize) => {
    if (!sentence) return '';
    if (sentence.length > maxSize)
        return sentence.slice(0, maxSize) + '...';
    return sentence;
};

export const useWindowSize = () => {
    const isClient = typeof window === 'object';

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
};

export const isValidUrl = (url) => {
    const pattern = new RegExp('^(((http|https)?:\\/\\/)|www\\.)'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(url);
};

export const getTextWidth = (text, font) => {
    // re-use canvas object for better performance
    const canvas = (getTextWidth as any).canvas || ((getTextWidth as any).canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
};

export const getMergedDate = (date, time) => {
    if (Array.isArray(time)) {
        return moment()
            .year(date.year())
            .month(date.month())
            .date(date.date())
            .hours(time[0])
            .minutes(time[1]);
    } else {
        return moment()
            .year(date.year())
            .month(date.month())
            .date(date.date())
            .hours(time.hours())
            .minutes(time.minutes());
    }
};

export const appendHttpsIfNeeded = (url) => {
    return url.startsWith('www') ? 'https://' + url : url;
};

export const  getCsvData= (data) => {
    var csvRow: String[] = [];
    var headers = Object.getOwnPropertyNames(data[0]);

    // headers
    csvRow.push(headers.join(","));

    data.forEach(row => {
        var ligne: String[] = [];
        for (var item = 0; item < headers.length; item++)
            ligne.push(row[headers[item]]);

        csvRow.push(ligne.join(","));
    });

    var csvStr = csvRow.join("\n");
    return csvStr;
}

export const createDownloadLink = (csvStr, filename) => {

    if (csvStr === '') return;

    var link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvStr));
    link.setAttribute("download", filename + ".csv");
    link.click();
}

export const  useIsMountedRef = () => {
    const isMountedRef = useRef(null as any);
    useEffect(() => {
        isMountedRef.current = true;

        return () => {
            isMountedRef.current = false
        };
    });
    return isMountedRef;
}

// export const useStateWithMountCheck = (...args: any[]) => {
//     const isMountedRef = useIsMountedRef();
//      const [state, originalSetState] = useState(...args);

//     const setState = (...args) => {
//         if (isMountedRef.current) {
//      originalSetState(...args);
//         }
//     }

//     return [state, setState];
// }

export const convertDateToEasterTime = (dt, format='') => {
    return momentTime.utc(dt).tz('America/New_York').format(format);
}


export const useStateWithPromise = (initialState) => {
    const [state, setState] = useState(initialState);
    const resolverRef = useRef(null as any);

    useEffect(() => {
        if (resolverRef.current) {
            resolverRef.current(state);
            resolverRef.current = null;
        }
        /**
         * Since a state update could be triggered with the exact same state again,
         * it's not enough to specify state as the only dependency of this useEffect.
         * That's why resolverRef.current is also a dependency, because it will guarantee,
         * that handleSetState was called in previous render
         */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resolverRef.current, state]);

    const handleSetState = useCallback((stateAction) => {
        setState(stateAction);
        return new Promise(resolve => {
            resolverRef.current = resolve;
        });
    }, [setState])

    return [state, handleSetState];
};


export const multiValueContainer = ({ selectProps, data }) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(selected => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
    const val = `${label}${labelSuffix}`;
    return val;
};


export const customStylesMultiValueContainer = {
    valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    })
};

export const customStylesMultiSelect = {

    multiValue: (base, state) => ({
        ...base,
        maxWidth: "300px"
    })
}

export const getEnvironment = () => {
    let host = window.location.hostname;
    let env = 'PROD';
    if (host === "localhost" || host === "127.0.0.1")
        env = 'DEV'
    if (host.toString().toLowerCase().includes("consoletest"))
        env = 'TEST';
    else if (host.toString().toLowerCase().includes("consoleqa"))
        env = 'QA';
    else if (host.toString().toLowerCase().includes("consolepreprod"))
        env = 'PREPROD';

    return env;
}

export const validateEmail = (param) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-_.]+?\.[a-zA-Z0-9-]{2,100}$/.test(param) && !param.toLowerCase().includes("@exo.")) {
            return true;
        }
    return false;
}