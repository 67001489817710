/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { Badge } from "react-bootstrap";
import ChronoContext from "../../../../../context/ChronoContext";
import "../../../../../styles/components/communication/message.scss";

const MessageMultiStepsSummary = (props) => {
    const { stepsValues } = props;
    const chronoContext = useContext(ChronoContext);
    const agencies = chronoContext ? chronoContext.staticData.user.agencies : null;
    const agencyColor = (agency) => {
        const agencyColor = agencies.find((a) => a.label === agency).color;
        const color = {
            backgroundColor: agencyColor,
            color: "#fff",
        };

        return color;
    };

    return (
        <>
            {stepsValues[0] && stepsValues[0].length > 0 && (
                <ul className="mb-4 px-2 messageMultiStepsSummary">
                   {stepsValues[0] && stepsValues[0].length > 0 && (
                        <li className="align-items-center mx-1 py-3 border-bottom">
                            {stepsValues[0].map((agency) => {
                                const badgeColor = agencyColor(agency);
                                return (
                                    <Badge
                                        pill
                                        className="mr-1 px-3 py-2 my-1 badge"
                                        style={{ backgroundColor: badgeColor.backgroundColor, color: badgeColor.color }}
                                    >
                                        {agency}
                                    </Badge>
                                );
                            })}
                        </li>
                    )} 
                     {stepsValues[1] && stepsValues[1].length > 0 && (
                        <li className="align-items-center mx-1 py-3 border-bottom ">
                            {stepsValues[1].map((r) => {
                                const route = JSON.parse(r);
                                return (
                                    <Badge pill className="mr-1 px-3 py-2 my-1 badge routeBadge">
                                        {route.routeLabel}
                                    </Badge>
                                );
                            })}
                        </li>
                    )}
                     {stepsValues[2] && stepsValues[2].length > 0 && (
                        <li className=" align-items-center mx-1 py-3 border-bottom">
                            {stepsValues[2].map((s) => {
                                const stop = JSON.parse(s.replace(/\t/g, ' '));
                                return (
                                    <Badge pill variant="secondary" className="mr-1 px-3 py-2 my-1 badge stopBadge">
                                        {stop.stopLabel}
                                    </Badge>
                                );
                            })}
                        </li>
                    )}
                </ul>
            )}
        </>
    );
};

export default MessageMultiStepsSummary;
