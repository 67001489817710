import React from 'react';

const GenericTableHead = ({children, ...props}) => {
    return (
        <thead {...props}>
        {
            children
        }
        </thead>
    );
};

export default GenericTableHead;
