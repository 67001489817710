import moment from "moment";

export const EMPTY_NOTIFICATION = {
  id: 0,
  titleFR: "",
  descriptionFR: "",
  titleEN: "",
  descriptionEN: "",
  startDate: moment().toDate(),
  startTime: moment().add(10, "minute").format('HH:mm'),
};

export const CODENAME_STATUS_ALL = "all";
export const LABEL_STATUS_ALL = "Tous les statuts";
export const CODENAME_STATUS_CREATED = "Created";
export const LABEL_STATUS_CREATED = "Enregistré";
export const CODENAME_STATUS_PUBLISHED = "Published";
export const LABEL_STATUS_PUBLISHED = "En attente d'envoi";
export const CODENAME_STATUS_SENT = "Sent";
export const LABEL_STATUS_SENT = "Envoyé";
export const LABEL_STATUS_NONE = "Aucun statut";

export const NOTIFICATION_LIST_STATUS = [
  {
    value: CODENAME_STATUS_ALL,
    label: LABEL_STATUS_ALL,
  },
  {
    value: CODENAME_STATUS_CREATED,
    label: LABEL_STATUS_CREATED,
  },
  {
    value: CODENAME_STATUS_PUBLISHED,
    label: LABEL_STATUS_PUBLISHED,
  },
  {
    value: CODENAME_STATUS_SENT,
    label: LABEL_STATUS_SENT,
  },
];

export const FILTER_DATE_OPTIONS = [
    { label: "Date de création", value: "2" },
    { label: "Date de début", value: "0" }
];


// store keys
export const SEARCH_PARAM_KEY = "searchParamsNotif";
export const SELECTED_NOTIF = "SelectedNotif";
export const DELETE_NOTIF_BY_ID = "deleteNotificationById";
export const SAVE_NOTIF = "saveNotification";
export const PUBLISH_NOTIF = "publishNotification";
export const UNPUBLISH_NOTIF = "unPublishNotification";
export const GET_NOTIFS = "getNotifications";
export const GET_NOTIFS_BY_ID = "getNotificationById";
export const NOTIF_LIST_GET = "notificationsGet";

