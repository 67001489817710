import React from "react";
import type { FC, ReactNode } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import ToastStack from "../../toast/ToastStack/ToastStack";
import { MEDIA_SCREEN_MENU_SWITCH_SIZE } from "../../global";

const TOP_BAR_SIZE = 80;

const ContentBelowTopBarStyled = styled(Container)`
    height: calc(100vh - ${(props) => (props.barheight ? props.barheight : TOP_BAR_SIZE)}px);
    max-width: 100% !important;
    position: relative;
    top: ${(props) => (props.barheight ? props.barheight : TOP_BAR_SIZE)}px;
    overflow-y: auto;

    @media screen and (max-width: ${MEDIA_SCREEN_MENU_SWITCH_SIZE}px) {
        height: auto;
        top: 0;
    }
`;
interface ContentBelowTopBarProps {
    barheight?: string;
    className?: string;
    children: ReactNode;
}

export const ContentBelowTopBar: FC<ContentBelowTopBarProps> = (props: ContentBelowTopBarProps) => {
    const { children, barheight, className } = props;
    return (
        <ContentBelowTopBarStyled barheight={barheight} className={`${className}`}>
            <ToastStack />
            {children}
        </ContentBelowTopBarStyled>
    );
};

export default ContentBelowTopBar;
