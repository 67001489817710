import React, { useState, useEffect, ReactNode, FC } from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ErrorMessage = styled.p`
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
`;

interface GenericPopupProps {
    children: ReactNode;
    cssClass?: string;
    headerStyle?: any;
    title: string;
    onClickYes: Function;
    lblYes?: string;
    show: boolean;
    onHide?: Function;
    showFooter?: boolean;
    bodyStyle?: any;
    isSaving?: boolean;
    errorMessage?: string;
}
/** @deprecated use popupmodal in lib/ui 20210409 */
const GenericPopup: FC<GenericPopupProps> = ({
    children,
    cssClass,
    headerStyle,
    title,
    onClickYes,
    lblYes,
    show,
    onHide,
    showFooter = true,
    bodyStyle,
    isSaving = false,
    errorMessage = "",
}) => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        setShowPopup(show);
    }, [show]);

    const onClosePopup = () => {
        if (onHide) {
            onHide();
        }
    };

    const onClickSave = () => {
        if (onClickYes) onClickYes();
    };

    return (
        <div>
            <Modal className={cssClass} show={showPopup} onHide={onClosePopup} centered backdrop="static">
                <Modal.Header style={headerStyle} closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={bodyStyle}>
                    {children}
                    {errorMessage.trim().length > 0 && <ErrorMessage>{errorMessage}</ErrorMessage>}
                </Modal.Body>

                {showFooter === true && (
                    <Modal.Footer>
                        {!isSaving && (
                            <Button variant="primary" onClick={() => onClickSave()}>
                                {lblYes}
                            </Button>
                        )}

                        {isSaving && (
                            <Button disabled>
                                <span>
                                    Sauvegarde en cours... <FontAwesomeIcon icon={faSpinner} spin={true} />
                                </span>
                            </Button>
                        )}

                        <Button variant="secondary" onClick={() => onClosePopup()}>
                            Annuler
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        </div>
    );
};

export default GenericPopup;
