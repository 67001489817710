/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import type { FC } from "react";
import { Container } from "react-bootstrap";
import CommunicationFormHeader from "../../CommunicationFormHeader";
import { BoxHeaderDashed, BoxTextDashed, ColDashed, SiblingRowDashed } from "../../CommunicationStructure";
import ChronoContext from "../../../../context/ChronoContext";
import { getCountClickMessage } from "../../../statistics/keen/keenQueries";
import { useKeenClient, executeKennQuery } from "../../../statistics/keen/keenHelper";
import * as miscService from "../../../../service/miscService";
import moment from "moment";
import { StatusBadge } from "../../../../lib/ui";
import { useMessageService } from "service/communication/useMessageService";
import { useCommunicationEvent } from "components/communication/communicationEvent";
import { useRole } from "lib/hooks/useRole";
import { MessageDetail as MessageDetailType } from "types/forms/MessageForms";
import { MessageDetailProps } from "types/components/MessageComponent";
import * as requestTypeConstante from "../../CommunicationFormActionButtons";

const translateKeenData = (dataList, emptyParams) => {
    if (dataList) {
        return dataList;
    }

    return 0;
};

export const MessageSummary: FC<MessageDetailProps> = (props: MessageDetailProps) => {
    const { keenClient } = useKeenClient();
    const entity = props.entity as MessageDetailType;
    const { setIsMessageCopy, setIsMessageEditable } = props;
    const staticData = useContext(ChronoContext).staticData;
    const agencies = staticData != null ? staticData.user.agencies : "";
    const { roleUser, roleCurrentUser } = useRole();
    const [isSendingData, setIsSendingData] = useState(false);
    const [canStopPublicationCard, setCanStopPublicationCard] = useState(false);
    const [canDeleteCard, setCanDeleteCard] = useState(false);
    const [keenData, setKeenData] = useState<number | string>(0);
    const messageService = useMessageService();
    const { operations } = props;

    const handleCopy = () => {
        setIsMessageCopy(true);
        setIsMessageEditable(false);
    };

    const getKeenMessageConsultationAmount = async () => {
        var keenResult = 0;
        const { messageExternalIdList } = entity;
        if (messageExternalIdList) {
            for (var i = 0; i < messageExternalIdList.length; ++i) {
                const keenParams = getCountClickMessage(messageExternalIdList[i], entity.startDate, entity.endDate);

                var result = await executeKennQuery(keenClient, keenParams, translateKeenData, []);
                keenResult += result;
            }
        }
        return Number.isNaN(keenResult) ? "-" : keenResult;
    };

    useEffect(() => {
        if (!keenClient) return;

        if (entity) {
            var hasUserRole = roleCurrentUser === roleUser;
            setCanStopPublicationCard(
                !hasUserRole && entity && entity.startDate && entity.endDate
                    ? moment().isSameOrAfter(moment(entity.startDate + " " + entity.startTime)) &&
                          moment().isSameOrBefore(moment(entity.endDate + " " + entity.endTime))
                    : false
            );

            setCanDeleteCard(
                entity && entity.startDate ? moment().isBefore(moment(entity.startDate + " " + entity.startTime)) : false
            );
        }

        async function fetchMessageConsultationCount() {
            const keenResult = await getKeenMessageConsultationAmount();
            setKeenData(keenResult);
        }
        fetchMessageConsultationCount();
    }, [entity, keenClient]);

    useCommunicationEvent(
        {
            successCallback: () => {
                messageService.updateModel(messageService.request.Expire.data);
                operations.post(true);
            },
            afterPost: () => messageService.request.Expire.refreshState(entity.id),
        },
        messageService.request.Expire.isSuccess,
        messageService.request.Expire.isFail
    );

    //handle event delete notif
    useCommunicationEvent(
        {
            successCallback: () => {
                messageService.removeElement(entity.id);
                operations.post(true);
            },
            afterPost: () => {
                messageService.request.Delete.refreshState(entity.id);
            },
        },
        messageService.request.Delete.isSuccess,
        messageService.request.Delete.isFail
    );

    const onClickStopPublicationHandle = async () => {
        setIsSendingData(true);
        const model = messageService.mapToModel(entity, requestTypeConstante.TYPE_REQUEST_EXPIRE);
        messageService.request.Expire.process(model);
        setIsSendingData(false);
    };

    const onClickDeleteHandle = (): void => {
        operations.pre();
        messageService.request.Delete.process(entity.id);
    };

    return (
        <Container>
            <CommunicationFormHeader
                isSendingData={isSendingData}
                data={entity}
                agencies={agencies}
                showCopyButton={miscService.CanCopy(staticData.roles, roleCurrentUser, entity)}
                showStopPublication={canStopPublicationCard}
                showDeleteButton={canDeleteCard}
                statusBadge={
                    <StatusBadge
                        color={
                            messageService.tool.getStatusBadgeColor(entity.filteredStatus) &&
                            messageService.tool.getStatusBadgeColor(entity.filteredStatus).color
                        }
                        label={entity.filteredStatusLabel}
                    />
                }
                onClickStopPublication={onClickStopPublicationHandle}
                onClickCopy={handleCopy}
                onClickDelete={onClickDeleteHandle}
                cssIconDelete={'communication-header-delete'}
            />
            <SiblingRowDashed>
                <ColDashed>
                    <BoxHeaderDashed>VISIBILITÉ</BoxHeaderDashed>
                    <BoxTextDashed>{entity.isVisibleInFeedOnly ? "Fil d'actualité" : "Lignes et arrêts choisis"}</BoxTextDashed>
                </ColDashed>
                <ColDashed>
                    <BoxHeaderDashed>DEBUT</BoxHeaderDashed>
                    <BoxTextDashed>{entity.startDate}</BoxTextDashed>
                    <BoxTextDashed>{entity.startTime}</BoxTextDashed>
                </ColDashed>
                <ColDashed>
                    <BoxHeaderDashed>FIN</BoxHeaderDashed>
                    <BoxTextDashed>{entity.endDate}</BoxTextDashed>
                    <BoxTextDashed>{entity.endTime}</BoxTextDashed>
                </ColDashed>
            </SiblingRowDashed>
            <SiblingRowDashed>
                <ColDashed>
                    <BoxHeaderDashed>ORGANISATION</BoxHeaderDashed>
                    {entity.agencyCodeList.map((agencyCode) => (
                        <BoxTextDashed>{agencyCode}</BoxTextDashed>
                    ))}
                </ColDashed>
                <ColDashed>
                    <BoxHeaderDashed>LIGNE</BoxHeaderDashed>
                    <BoxTextDashed>
                        {entity.routeList &&
                            entity.routeList.length > 0 &&
                            entity.routeList.map((route, index) => {
                                const routeObj = JSON.parse(route);
                                return (
                                    <p key={`route_${index}`} style={{ padding: 0, margin: 0, wordBreak: "break-word" }}>
                                        {routeObj.routeLabel}
                                    </p>
                                );
                            })}
                        {!entity.routeList && "Aucune ligne"}
                    </BoxTextDashed>
                </ColDashed>
                <ColDashed>
                    <BoxHeaderDashed>ARRÊT</BoxHeaderDashed>
                    <BoxTextDashed>
                        {entity.arretList &&
                            entity.arretList.length > 0 &&
                            entity.arretList.map((stop, index) => {
                                const stopObj = JSON.parse(stop.replace(/\t/g, ' '));
                                return (
                                    <p key={`stop_${index}`} style={{ padding: 0, margin: 0, wordBreak: "break-word" }}>
                                        {stopObj.stopLabel}
                                    </p>
                                );
                            })}
                        {!entity.arretList && "Aucune arrêt"}
                    </BoxTextDashed>
                </ColDashed>
            </SiblingRowDashed>

            <SiblingRowDashed>
                <ColDashed>
                    <BoxHeaderDashed>entity FRANÇAIS</BoxHeaderDashed>
                    <BoxTextDashed>{entity.contentFR}</BoxTextDashed>
                </ColDashed>
                <ColDashed>
                    <BoxHeaderDashed>entity ANGLAIS</BoxHeaderDashed>
                    <BoxTextDashed>{entity.contentEN}</BoxTextDashed>
                </ColDashed>
            </SiblingRowDashed>

            {(entity.urlFR || entity.urlEN) && (
                <SiblingRowDashed>
                    <ColDashed>
                        <BoxHeaderDashed>URL FRANÇAIS</BoxHeaderDashed>
                        <BoxTextDashed>{entity.urlFR ? entity.urlFR : <strong>-</strong>}</BoxTextDashed>
                    </ColDashed>
                    <ColDashed>
                        <BoxHeaderDashed>URL ANGLAIS</BoxHeaderDashed>
                        <BoxTextDashed>{entity.urlEN ? entity.urlEN : <strong>-</strong>}</BoxTextDashed>
                    </ColDashed>
                </SiblingRowDashed>
            )}

            <SiblingRowDashed>
                <ColDashed>
                    <BoxHeaderDashed>NOMBRE DE CONSULTATIONS</BoxHeaderDashed>
                    <BoxTextDashed>{keenData}</BoxTextDashed>
                </ColDashed>
            </SiblingRowDashed>
        </Container>
    );
};
