/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, FC, ReactElement } from "react";
import { Col, Row, Form, Dropdown, Button, ButtonGroup } from "react-bootstrap";
import GenericDate from "../../../common/generic/GenericDate";
import GenericInput from "../../../common/generic/GenericInput";
import CommunicationFormHeader from "../../CommunicationFormHeader";
import { DATE_FORMAT, communications_toast_text } from "../../CommunicationUtils";
import CommunicationFormActionButtons, { TYPE_REQUEST_PUBLISH, TYPE_REQUEST_SAVE } from "../../CommunicationFormActionButtons";
import { CommunicationContainer } from "../../CommunicationContainer";
import SelectAgencies from "../../../common/generic/select/SelectAgencies";
import SelectRoutes from "../../../common/generic/select/SelectRoutes";
import SelectStops from "../../../common/generic/select/SelectStops";
import SelectGtfsrEffects from "../../../common/generic/select/SelectGtfsrEffects";
import SelectGtfsrCauses from "../../../common/generic/select/SelectGtfsrCauses";
import RoleHelper, { ROLE_CODENAME_EDITOR } from "../../../common/RoleHelper";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import { useAlertService } from "../../../../service/communication/useAlertService";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AlertForm } from "../../../../types/forms/alertForms";
import { AlertFormBaseProps } from "../../../../types/components/alertComponent";
import { validationFormSchema } from "./validators/saveValidator";
import { useCommunicationEvent } from "../../communicationEvent";

const FormRow = styled(Row)`
    margin-top: 20px;
    margin-bottom: 20px;
`

const ErrorMessage = styled.p`
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
`

const AlertForm_: FC<AlertFormBaseProps> = ({ entity, operations }: AlertFormBaseProps): ReactElement => {

    const alertService = useAlertService();

    const { watch, control, errors, formState, handleSubmit, trigger } = useForm<AlertForm>({
        defaultValues: entity,
        resolver: yupResolver(validationFormSchema),
        reValidateMode: 'onChange',
        mode: "onChange",
    });
    const values = watch();

    //handle event add new alert
    useCommunicationEvent(
        {
            ...operations,
            executeCallback: () => alertService.request.Save.refreshState(0),
            successCallback: () => {
                if (operations?.post)
                    operations?.post(true)
            },
        }, alertService.request.Save.isSuccess, alertService.request.Save.isFail
    )

    //handle event update alert
    useCommunicationEvent(
        {
            ...operations,
            successCallback: () => {
                alertService.updateModel(alertService.request.Update.data);
                if (operations?.post)
                    operations?.post(true)
            },
            afterPost: () => alertService.request.Update.refreshState(entity.id)
        },
        alertService.request.Update.isSuccess,
        alertService.request.Update.isFail
    )

    //handle event delete notif
    useCommunicationEvent(
        {
            ...operations,
            successCallback: () => {
                alertService.removeElement(entity.id);
                if (operations?.post)
                    operations?.post(true)
            },
            afterPost: () => alertService.request.Delete.refreshState(entity.id)
        }, alertService.request.Delete.isSuccess, alertService.request.Delete.isFail
    )

    const onClickSend = (requestType: number): void => {
        if (operations?.pre)
            operations.pre();

        const model = alertService.mapToModel(values, requestType, entity);
        entity.id === 0 ? alertService.request.Save.process(model) : alertService.request.Update.process(model);
    };

    const onClickDelete = (): void => {
        if (operations?.pre)
            operations.pre();

        alertService.request.Delete.process(entity.id)
    };

    return (
        <CommunicationContainer>
            <CommunicationFormHeader data={entity} isNew={entity.id === 0} />

            <Form
                onSubmit={handleSubmit(() => {
                    onClickSend(TYPE_REQUEST_SAVE);
                })}
            >
                <FormRow style={{ marginBottom: 0 }}>
                    <Col xs={12} style={{ padding: 0 }}>
                        <strong>Sélectionnez une date de début et une date de fin *</strong>
                    </Col>
                </FormRow>
                <FormRow>
                    <Col xl={6} md={12} style={{ paddingLeft: '0px', paddingRight: '5px' }}>
                        <Controller
                            control={control}
                            name="start"
                            value={values.start}
                            render={({ onChange, value }) => {
                                return (
                                    <GenericDate
                                        cssClass={"js-start-date"}
                                        isRequired
                                        label="Début"
                                        minDate={new Date()}
                                        date={moment(value).format(DATE_FORMAT)}
                                        onDateChange={async (evt) => {
                                            onChange(evt);
                                             await trigger('start');
                                             await trigger('end');
                                        }}
                                        width={'80'}
                                        canUserEdit={true}
                                    />
                                );
                            }}
                        />
                        {errors.start && <ErrorMessage>{errors.start.message}</ErrorMessage>}
                    </Col>
                    <Col xl={6} md={12} style={{ paddingRight: '0px' }}>
                        <Controller
                            control={control}
                            name="end"
                            value={values.end}
                            render={({ onChange, value }, { invalid }) => {
                                return (
                                    <GenericDate
                                        cssClass={"js-start-date"}
                                        isRequired
                                        label="Fin"
                                        minDate={new Date()}
                                        date={moment(value).format(DATE_FORMAT)}
                                        width={'80'}
                                        onDateChange={async (evt) => {
                                            onChange(evt);
                                            await trigger('end');
                                        }}
                                        canUserEdit={true}
                                    />
                                );
                            }}
                        />
                        {errors.end && <ErrorMessage>{errors.end.message}</ErrorMessage>}
                    </Col>
                </FormRow>
                <FormRow style={{ marginBottom: 0 }}>
                    <strong>Cible (utilisateurs ayant pour favoris)</strong>
                </FormRow>

                <FormRow style={{ marginTop: 10 }}>
                    <Controller
                        control={control}
                        name="agencyCode"
                        value={values.agencyCode}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <SelectAgencies
                                    value={value}
                                    onChange={(evt) => {
                                        onChange(evt);
                                    }}
                                    isRequired
                                    feedback='Veuillez choisir une organisation' />
                            );
                        }}
                    />
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="routeId"
                        value={values.routeId}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <SelectRoutes
                                    value={value}
                                    onChange={(evt) => {
                                        onChange(evt);
                                    }}
                                    agencyCode={values.agencyCode} />
                            );
                        }}
                    />
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="stopId"
                        value={values.stopId}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <SelectStops
                                    value={value}
                                    onChange={(evt) => {
                                        onChange(evt);
                                    }}
                                    multi={false}
                                    isDisabled={!values.agencyCode || !values.routeId}
                                    agencyCode={values.agencyCode}
                                    newRouteId={values.routeId} />
                            );
                        }}
                    />
                </FormRow>

                <FormRow>
                    <Col xl={6} md={12} style={{ padding: 0 }}>
                        <Controller
                            control={control}
                            name="cause"
                            value={values.cause}
                            render={({ onChange, value }, { invalid }) => {
                                return (
                                    <SelectGtfsrCauses
                                        key={'GtfsCause_1'}
                                        value={value}
                                        onChange={(evt) => {
                                            onChange(evt);
                                        }}
                                    />
                                );
                            }}
                        />
                    </Col>
                    <Col xl={6} md={12} style={{ padding: 0 }}>
                        <Controller
                            control={control}
                            name="effect"
                            value={values.effect}
                            render={({ onChange, value }, { invalid }) => {
                                return (
                                    <SelectGtfsrEffects
                                        key={'GtfsEffects_0'}
                                        value={value}
                                        onChange={(evt) => {
                                            onChange(evt);
                                        }}
                                    />
                                );
                            }}
                        />
                    </Col>
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="textFR"
                        value={values.textFR}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <GenericInput
                                    isRequired
                                    label="Texte"
                                    maxLength={512}
                                    placeholder="Texte..."
                                    feedback="Veuillez écrire un texte explicatif"
                                    value={value}
                                    onChange={(evt) => {
                                        onChange(evt);
                                    }}
                                />
                            );
                        }}
                    />
                </FormRow>
                <FormRow>
                    <small className="text-muted">*Champs obligatoires</small>
                </FormRow>
                <FormRow>
                    <RoleHelper roleCodenameAllowed={ROLE_CODENAME_EDITOR}>
                        <Col style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <Dropdown as={ButtonGroup} drop="up">
                                <Button type="submit" disabled={!formState.isValid || !formState.isDirty}>


                                    <span><FontAwesomeIcon icon={faSave} /> Enregistrer</span>

                                </Button>
                                <CommunicationFormActionButtons
                                    onClickSend={onClickSend}
                                    onClickDelete={onClickDelete}
                                    isFormFilled={formState.isValid}
                                    itemExists={entity.id !== 0}
                                    hideApprove={true}
                                    hasBeenModified={formState.isDirty}
                                />

                            </Dropdown>
                        </Col>
                    </RoleHelper>
                </FormRow>
            </Form>

        </CommunicationContainer>
    );
};

export default AlertForm_;
