import { useContext, useState, useEffect } from "react";
import { useApiRequest } from "../../redux/hooks/useApiRequest";
import moment from "moment";
import { MessageDetail, MessageForm } from "../../types/forms/MessageForms";
import { MessageViewModel, MessageParamViewModel } from "../../types/viewmodels/MessageViewModel";
import { CommunicationStatus } from "../../types/entities/communicationEntities";
import * as config from "../../components/communication/message/MessageConstants";
import ChronoContext from "../../context/ChronoContext";
import { ThemeContext } from "styled-components";
import { CODENAME_STATUS_PUBLISHED } from "../../statusUtils";
import { useCacheService } from "../../redux/hooks/useCacheService";
import useUI from "../../lib/hooks/useUI";
import { DATE_FORMAT } from "../../components/communication/CommunicationUtils";
import { Categories } from "../../redux/keys/generic/types";
import { Status } from "../../types/enum/Status";
import { getReferenceRoutes, getReferenceStops } from "../../api/references/references";
import { getMergedDate } from "components/common/helper";
import * as requestTypeConstante from "../../components/communication/CommunicationFormActionButtons";
import * as codenameStatusConstante from "../../statusUtils";
import { sendPostRequest } from "auth/apiEffects";
import { RouteMessageViewModel } from "../../types/viewmodels/RouteMessageViewModel";

export const useMessageService = () => {
    //states
    const [isProcessingSave, setIsProcessingSave] = useState(false);
    const [isErrorSave, setIsErrorSave] = useState(false);
    const [isSuccessSave, setIsSuccessSave] = useState(false);
    const [dataSave, setDataSave] = useState<MessageViewModel | undefined>();
    const searchParams = useUI(config.SEARCH_PARAM_KEY);
    const [isNewMessage, setIsNewMessage] = useState(false);

    //hook
    const context = useContext(ChronoContext);
    const status = context.staticData.status;
    const theme = useContext(ThemeContext);
    const requestMessageList = useApiRequest(config.GET_MESSAGES);
    const requestMessageSave = useApiRequest(config.SAVE_MESSAGE);
    const requestMessageGetById = useApiRequest(config.GET_MESSAGE_BY_ID);
    const requestMessageDeleteById = useApiRequest(config.DELETE_MESSAGE_BY_ID);
    const requestMessageApprove = useApiRequest(config.APPROVE_MESSAGE);
    const requestMessagePublish = useApiRequest(config.PUBLISH_MESSAGE);
    const requestMessageRefuse = useApiRequest(config.REFUSE_MESSAGE);
    const requestMessageExpire = useApiRequest(config.EXPIRE_MESSAGE);
    const cacheService = useCacheService();

    // hook for save
    useEffect(() => {
        if (requestMessageSave.isFail === true && isProcessingSave === true) {
            setIsErrorSave(true);
            setIsProcessingSave(false);
        }
    }, [requestMessageSave.isFail]);

    useEffect(() => {
        if (requestMessageSave.isSuccess === true && isProcessingSave === true) {
            requestMessageList.actions.process(searchParams);
        }
    }, [isNewMessage, requestMessageSave.isSuccess]);

    useEffect(() => {
        if (!requestMessageList.isLoading && isProcessingSave === true && requestMessageSave.isSuccess === true) {
            setIsSuccessSave(true);
            setIsProcessingSave(false);
            setDataSave(requestMessageSave.result.data);
        }
    }, [requestMessageList.isLoading]);

    //forms parameters
    const defaultValues = { status: [], startDate: moment().add(-30, "d"), endDate: moment(), filterByDateType: "2" };

    const statusCodeObject = {
        [requestTypeConstante.TYPE_REQUEST_APPROVE]: {
            statusCode: status[codenameStatusConstante.CODENAME_STATUS_APPROVED],
        },
        [requestTypeConstante.TYPE_REQUEST_PUBLISH]: {
            statusCode: status[codenameStatusConstante.CODENAME_STATUS_PUBLISHED],
        },
        [requestTypeConstante.TYPE_REQUEST_REFUSE]: {
            statusCode: status[codenameStatusConstante.CODENAME_STATUS_APPROVED_REFUSED],
        },
        [requestTypeConstante.TYPE_REQUEST_SAVE]: {
            statusCode: status[codenameStatusConstante.CODENAME_STATUS_CREATED],
        },
        [requestTypeConstante.TYPE_REQUEST_EXPIRE]: {
            statusCode: "",
        },
    };
    const _formatRouteList = (routeList) => {
        return routeList.map((route) => {
            const routeObj = JSON.parse(route);
            return {
                ...routeObj,
                agencyCode: routeObj.organizationCode,
            };
        });
    };

    const _formatRouteListOptions = async (agencyCodeList, routeList) => {
        const routes = await getReferenceRoutes({ agencyCodeList });
        const selectedRoutes = routeList.map((messageRoute) => {
            const route = routes ? routes.find(
                (x) => x.routeId === messageRoute.routeId && x.organizationCode === messageRoute.agencyCode
            ): undefined;
            if (route) {
                return `{"organizationCode": "${route.organizationCode}", "routeId": "${route.routeId}", "routeLabel": "${route.shortName} - ${route.longName}", "backgroundColor": "${route.backgroundColor}", "fontColor": "${route.fontColor}"}`;
            }

            return null;
        });
        return selectedRoutes;
    };

    const _formatStopListOptions = async (routeList) => {
        let queryReferenceRouteListParams: { organizationCode: string; routeGtfsIdList: string[] }[] = [];
        routeList.forEach((route) => {
            const queryReferenceRouteListParamsIndex = queryReferenceRouteListParams.findIndex(
                (x) => x.organizationCode === route.agencyCode
            );
            if (queryReferenceRouteListParamsIndex >= 0) {
                queryReferenceRouteListParams[queryReferenceRouteListParamsIndex] = {
                    ...queryReferenceRouteListParams[queryReferenceRouteListParamsIndex],
                    routeGtfsIdList: [
                        ...queryReferenceRouteListParams[queryReferenceRouteListParamsIndex].routeGtfsIdList,
                        route.routeId,
                    ],
                };
            } else {
                queryReferenceRouteListParams.push({
                    organizationCode: route.agencyCode,
                    routeGtfsIdList: [route.routeId],
                });
            }
        });
        const stops = await getReferenceStops(queryReferenceRouteListParams);

        return routeList
            .map((route) => {
                return route.arrets.map((stopItem) => {
                    const stopItemCode = stopItem.split("-")[2];
                    const stopIndex = stops.findIndex((x) => x.organization === route.agencyCode && x.routeId === route.routeId);
                    const stop = stops[stopIndex]?.options.find((x) => x.stopId === stopItemCode);
                    if (!stop) {
                        throw Error("stop is empty not found");
                    }
                    return `{"organizationCode": "${stop.organizationCode}", "routeId": "${stop.routeId}", "stopCode": "${stop.code}", "stopId": "${stop.stopId}", "stopLabel": "${stop.routeId} - ${stop.code} - ${stop.name} (${stop.direction})"}`;
                });
            })
            .flat();
    };

    const _formatStopList = (stopsList) => {
        return stopsList.map((stop) => {
            const stopObj = JSON.parse(stop.replace(/\t/g, ' '));
            return stopObj.organizationCode + "-" + stopObj.routeId + "-" + stopObj.stopId;
        });
    };

    const getStatusBadgeColor = (statusCode) => {
        const statusBadgeOptions = {
            0: {
                color: theme.colors.messageError, //theme.colors.messageError,
            },
            8: {
                color: theme.colors.messageError, //theme.colors.messageError,
            },
            128: {
                color: theme.colors.messageError, //theme.colors.messageError,
            },
            256: {
                color: theme.colors.messageError, //theme.colors.messageError,
            },
        };

        return statusBadgeOptions[statusCode]
            ? statusBadgeOptions[statusCode]
            : { color: theme.colors.messageInfo /*theme.colors.messageInfo*/ };
    };
    //methods
    const mapToForm = async (message): Promise<MessageForm | undefined> => {
        try {
            return {
                ...message,
                startDate: moment(message.startDate).format(DATE_FORMAT),
                startTime: moment(message.startDate).format("HH:mm"),
                endDate: moment(message.endDate).format(DATE_FORMAT),
                endTime: moment(message.endDate).format("HH:mm"),
                agencyCodeList: message.agencyCodeList,
                routeList:
                    message.agencyCodeList &&
                    message.routeList &&
                    (await _formatRouteListOptions(message.agencyCodeList, message.routeList)),
                arretList: message.routeList && (await _formatStopListOptions(message.routeList)),
                isVisibleInFeedOnly: message.isVisibleInFeedOnly,
                contentFR: message.contentFR,
                urlFR: message.urlFR ? message.urlFR : "",
                contentEN: message.contentEN,
                urlEN: message.urlEN ? message.urlEN : "",
                isNow: false,
            };
        } catch (error) {
            return undefined;
        }
    };

    const mapToDetail = async (message): Promise<MessageDetail | undefined> => {
        try {
            return {
                ...message,
                startDate: moment(message.startDate).format(DATE_FORMAT),
                startTime: moment(message.startDate).format("HH:mm"),
                endDate: moment(message.endDate).format(DATE_FORMAT),
                endTime: moment(message.endDate).format("HH:mm"),
                agencyCodeList: message.agencyCodeList,
                routeList:
                    message.agencyCodeList &&
                    message.routeList &&
                    (await _formatRouteListOptions(message.agencyCodeList, message.routeList)),
                arretList: message.routeList && (await _formatStopListOptions(message.routeList)),
                isVisibleInFeedOnly: message.isVisibleInFeedOnly,
                contentFR: message.contentFR,
                urlFR: message.urlFR ? message.urlFR : "",
                contentEN: message.contentEN,
                urlEN: message.urlEN ? message.urlEN : "",
                isNow: false,
            };
        } catch (error) {
            console.log(error);
            return undefined;
        }
    };

    const mapToParam = (
        values: any,
        statusList: Array<CommunicationStatus>,
        orgList: Array<any>,
        userOrgList: Array<any>
    ): MessageParamViewModel => {
        const param = {
            status: statusList ? statusList.filter((x) => x.value != null).map((x) => x.value as unknown as Status) : [],
            filterByDateType: values.filterByDateType,
            agencyCodes:
                orgList && orgList.length > 0
                    ? orgList.filter((x) => x.value != null).map((x) => x.label)
                    : userOrgList?.filter((x) => x.value != null).map((x) => x.label),
            startDate:
                values.startDate && values.startDate != null
                    ? moment(values.startDate).utc().format(DATE_FORMAT)
                    : values.startDate,
            endDate:
                values.endDate && values.endDate != null
                    ? moment(values.endDate)
                          .utc()
                          .format(DATE_FORMAT + " 23:59:59")
                    : values.endDate,
        };

        cacheService.Save(param, config.SEARCH_PARAM_KEY, Categories.UI);
        return param as MessageParamViewModel;
    };

    const getStatusCode = (requestType) => {
        if (requestType) return statusCodeObject[requestType].statusCode;

        return statusCodeObject[requestTypeConstante.TYPE_REQUEST_SAVE].statusCode;
    };

    const _mergedDate = (formDate, formTime) => {
        const convertedDate = moment(formDate);
        const splittedTime = formTime.split(":");
        const convertedTime = convertedDate.set({
            hour: splittedTime[0],
            minute: splittedTime[1],
        });

        return getMergedDate(convertedDate, convertedTime);
    };

    const mapToModel = (formData: MessageForm | MessageDetail, requestType?: number): MessageViewModel => {
        const stripedFormData = { ...formData };
        delete stripedFormData.startTime;
        delete stripedFormData.endTime;
        delete stripedFormData.isNow;

        let fontColor;
        let backgroundColor;

        if ("fontColor" in formData && requestType === requestTypeConstante.TYPE_REQUEST_EXPIRE) {
            fontColor = formData.fontColor || "#FFFFFF";
            backgroundColor = formData.backgroundColor || "#000000";
        } else {
            fontColor = formData.colors.fontColor;
            backgroundColor = formData.colors.backgroundColor;
        }

        const model: MessageViewModel = {
            ...stripedFormData,
            id: formData.id,
            routeList: _formatRouteList(formData.routeList),
            arretList: _formatStopList(formData.arretList),
            startDate: _mergedDate(formData.isNow ? new Date() : formData.startDate, formData.isNow ? moment(new Date()).format("HH:mm") : formData.startTime).toDate(),
            endDate: _mergedDate(formData.endDate, formData.endTime).toDate(),
            agencyCodeList: formData.agencyCodeList,
            fontColor: fontColor,
            backgroundColor: backgroundColor,
            statusCode: getStatusCode(requestType),
            refusedReason: formData.refusedReason ? formData.refusedReason : "",
        };

        return model;
    };

    const mapToModelForUpdate = (formData: MessageDetail, requestType?: number): MessageViewModel => {
        const stripedFormData = { ...formData };

        let fontColor;
        let backgroundColor;

        if ("fontColor" in formData) {
            fontColor = formData.fontColor || "#FFFFFF";
            backgroundColor = formData.backgroundColor || "#000000";
        }

        const model: MessageViewModel = {
            ...stripedFormData,
            id: formData.id,
            routeList: _formatRouteList(formData.routeList),
            arretList: _formatStopList(formData.arretList),
            agencyCodeList: formData.agencyCodeList,
            fontColor: formData.fontColor || "#FFFFFF",
            backgroundColor: formData.backgroundColor || "#000000",
            statusCode: getStatusCode(requestType),
            refusedReason: formData.refusedReason ? formData.refusedReason : "",
        };

        return model;
    };

    const hasOverlappingMessages = async (entity) => {
        const overlappingMessageList = await sendPostRequest<MessageViewModel[]>("/messages/getAllOverlappingMessage", entity);

        if (overlappingMessageList && overlappingMessageList.length > 0) {
            let stopArray: any[] = [];
            let routeArray: any[] = [];
            let agencyCode = "";

            for (let i = 0; i < overlappingMessageList.length; ++i) {
                let overlapMessage = overlappingMessageList[i];

                agencyCode = overlapMessage.agencyCodeList[0];

                const filteredStopList = overlapMessage.arretList.filter(function (e: any) {
                    return stopArray.indexOf(e) < 0;
                });
                stopArray = stopArray.concat(filteredStopList);

                for (let j = 0; j < overlapMessage.routeList.length; ++j) {
                    let route = overlapMessage.routeList[j] as RouteMessageViewModel;

                    if (!routeArray.includes(route.routeId.toString())) {
                        routeArray.push(route.routeId);
                    }
                }
            }

            var messagePart = "l'agence " + agencyCode;

            if (stopArray.length > 0)
                messagePart = (stopArray.length > 1 ? "les arrêts" : "l'arrêt") + " " + stopArray.sort().join("/");
            else if (routeArray.length > 0)
                messagePart = (routeArray.length > 1 ? "les lignes" : "la ligne") + " " + routeArray.sort().join("/");

            return messagePart;
        } else {
            return "";
        }
    };

    const isMessageEditable = (message: MessageViewModel) => {
        return message.statusCode !== status[CODENAME_STATUS_PUBLISHED] || message.id === 0;
    };

    const getNewMessage = (): MessageForm => {
        return config.EMPTY_MESSAGE;
    };

    const clearCacheOperations = (id: any): void => {
        cacheService.Remove(config.EXPIRE_MESSAGE + id, Categories.REQUEST);
        cacheService.Remove(config.PUBLISH_MESSAGE + id, Categories.REQUEST);
        cacheService.Remove(config.APPROVE_MESSAGE + id, Categories.REQUEST);
        cacheService.Remove(config.DELETE_MESSAGE_BY_ID + id, Categories.REQUEST);
        cacheService.Remove(config.SAVE_MESSAGE, Categories.REQUEST);
    };

    const save = (model: MessageViewModel): void => {
        clearCacheOperations(model.id);
        setIsProcessingSave(true);
        setIsNewMessage(model.id === 0);
        requestMessageSave.actions.process(model, { id: model.id }, { id: model.id });
    };

    const updateModel = (model: any): void => {
        if (!model) return;
        clearCacheOperations(model.id);
        const index = requestMessageList.result.data.findIndex((x) => x.id === model.id);
        const newMessageList = [...requestMessageList.result.data];
        newMessageList[index] = { ...model };
        cacheService.Save(newMessageList, config.GET_MESSAGES, Categories.RESOURCES);
    };

    const getById = (messageId: number): void => {
        requestMessageGetById.actions.process("", { id: messageId }, { id: messageId });
    };

    const deleteById = (messageId?: number): void => {
        clearCacheOperations(messageId);
        requestMessageDeleteById.actions.process("", { id: messageId }, { id: messageId });
    };

    const expire = (model: MessageViewModel | MessageDetail): void => {
        clearCacheOperations(model.id);
        requestMessageExpire.actions.process(model, { id: model.id }, { id: model.id });
    };

    const approve = (model: MessageViewModel): void => {
        clearCacheOperations(model.id);
        requestMessageApprove.actions.process(model, { id: model.id }, { id: model.id });
    };

    const publish = (model: MessageViewModel): void => {
        clearCacheOperations(model.id);
        requestMessagePublish.actions.process(model, { id: model.id }, { id: model.id });
    };

    const refuse = (model: MessageViewModel): void => {
        clearCacheOperations(model.id);
        requestMessageRefuse.actions.process(model, { id: model.id }, { id: model.id });
    };

    const removeElement = (id?: number): void => {
        const index = requestMessageList.result.data.findIndex((x) => x.id === id);
        const newMessageList = [...requestMessageList.result.data];
        newMessageList.splice(index, 1);
        cacheService.Save(newMessageList, config.GET_MESSAGES, Categories.RESOURCES);
    };

    const cleanCache = (): void => {
        clearCacheOperations(0);
        cacheService.Remove(config.SEARCH_PARAM_KEY, Categories.UI);
        cacheService.Remove(config.GET_MESSAGES_STORE_KEY, Categories.RESOURCES);
    };

    const canPublish = (model: MessageViewModel): boolean => {
        var dt = moment().format(DATE_FORMAT);
        return moment(model.endDate).isAfter(moment(dt));
    };

    const canEditMessage = (message: MessageViewModel | MessageForm): boolean => {
        if (message.statusCode !== undefined) {
            return message.statusCode < Status.Published;
        }

        return false;
    };

    const isMessagePublished = (message: MessageViewModel): boolean => {
        return !(message.statusCode < status[CODENAME_STATUS_PUBLISHED] || message.id === 0);
    };

    // interfaces
    return {
        request: {
            GetAll: {
                isLoading: requestMessageList.isLoading,
                isSuccess: requestMessageList.isSuccess,
                isFail: requestMessageList.isFail,
                process: requestMessageList.actions.process,
                data: requestMessageList.result.data,
            },
            Save: {
                isLoading: isProcessingSave,
                isSuccess: isSuccessSave,
                isFail: isErrorSave,
                process: save,
                data: dataSave,
                refreshState: (id) => {
                    setIsSuccessSave(false);
                    setIsErrorSave(false);
                    clearCacheOperations(id);
                },
            },
            GetById: {
                isLoading: requestMessageGetById.isLoading,
                isSuccess: requestMessageGetById.isSuccess,
                isFail: requestMessageGetById.isFail,
                process: getById,
                data: requestMessageGetById.result.data,
                changeResourceName: requestMessageGetById.setResource,
            },
            Delete: {
                isLoading: requestMessageDeleteById.isLoading,
                isSuccess: requestMessageDeleteById.isSuccess,
                isFail: requestMessageDeleteById.isFail,
                process: deleteById,
                data: requestMessageDeleteById.result.data,
                changeResourceName: requestMessageDeleteById.setResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                },
            },
            Expire: {
                isLoading: requestMessageExpire.isLoading,
                isSuccess: requestMessageExpire.isSuccess,
                isFail: requestMessageExpire.isFail,
                process: expire,
                data: requestMessageExpire.result.data,
                changeResourceName: requestMessageExpire.setResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                },
            },
            Approve: {
                isLoading: requestMessageApprove.isLoading,
                isSuccess: requestMessageApprove.isSuccess,
                isFail: requestMessageApprove.isFail,
                process: approve,
                data: requestMessageApprove.result.data,
                changeResourceName: requestMessageApprove.setResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                },
            },
            Publish: {
                isLoading: requestMessagePublish.isLoading,
                isSuccess: requestMessagePublish.isSuccess,
                isFail: requestMessagePublish.isFail,
                process: publish,
                data: requestMessagePublish.result.data,
                changeResourceName: requestMessagePublish.setResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                },
            },
            Refuse: {
                isLoading: requestMessageRefuse.isLoading,
                isSuccess: requestMessageRefuse.isSuccess,
                isFail: requestMessageRefuse.isFail,
                process: refuse,
                data: requestMessageRefuse.result.data,
                changeResourceName: requestMessageRefuse.setResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                },
            },
        },
        tool: {
            getStatusBadgeColor: getStatusBadgeColor,
        },
        Filter: {
            defaultValues: defaultValues,
            searchParams: searchParams,
        },
        Constantes: {
            DISPLAY_DATE_FORMAT: DATE_FORMAT,
        },
        mapToForm: mapToForm,
        mapToParam: mapToParam,
        mapToModel: mapToModel,
        mapToModelForUpdate: mapToModelForUpdate,
        isMessageEditable: isMessageEditable,
        getNewMessage: getNewMessage,
        cleanCache: cleanCache,
        updateModel: updateModel,
        removeElement: removeElement,
        canPublish: canPublish,
        canEditMessage: canEditMessage,
        isMessagePublished: isMessagePublished,
        hasOverlappingMessages: hasOverlappingMessages,
        mapToDetail: mapToDetail,
    };
};
