import moment from "moment";

const UNKNOWN_CAUSE = 'UNKNOWN_CAUSE';
const OTHER_CAUSE = 'OTHER_CAUSE';
const TECHNICAL_PROBLEM = 'TECHNICAL_PROBLEM';
const STRIKE = 'STRIKE';
const DEMONSTRATION = 'DEMONSTRATION';
const ACCIDENT = 'ACCIDENT';
const HOLIDAY = 'HOLIDAY';
const WEATHER = 'WEATHER';
const MAINTENANCE = 'MAINTENANCE';
const CONSTRUCTION = 'CONSTRUCTION';
const POLICE_ACTIVITY = 'POLICE_ACTIVITY';
const MEDICAL_EMERGENCY = 'MEDICAL_EMERGENCY';

export const LIST_GTFS_R_CAUSE = [
    { label: UNKNOWN_CAUSE, value: UNKNOWN_CAUSE },
    { label: OTHER_CAUSE, value: OTHER_CAUSE },
    { label: TECHNICAL_PROBLEM, value: TECHNICAL_PROBLEM },
    { label: STRIKE, value: STRIKE },
    { label: DEMONSTRATION, value: DEMONSTRATION },
    { label: ACCIDENT, value: ACCIDENT },
    { label: HOLIDAY, value: HOLIDAY },
    { label: WEATHER, value: WEATHER },
    { label: MAINTENANCE, value: MAINTENANCE },
    { label: CONSTRUCTION, value: CONSTRUCTION },
    { label: POLICE_ACTIVITY, value: POLICE_ACTIVITY },
    { label: MEDICAL_EMERGENCY, value: MEDICAL_EMERGENCY },
];

const NO_SERVICE = 'NO_SERVICE';
const REDUCED_SERVICE = 'REDUCED_SERVICE';
const SIGNIFICANT_DELAYS = 'SIGNIFICANT_DELAYS';
const DETOUR = 'DETOUR';
const ADDITIONAL_SERVICE = 'ADDITIONAL_SERVICE';
const MODIFIED_SERVICE = 'MODIFIED_SERVICE';
const OTHER_EFFECT = 'OTHER_EFFECT';
const UNKNOWN_EFFECT = 'UNKNOWN_EFFECT';
const STOP_MOVED = 'STOP_MOVED';

export const LIST_GTFS_R_EFFECT = [
    { label: NO_SERVICE, value: NO_SERVICE },
    { label: REDUCED_SERVICE, value: REDUCED_SERVICE },
    { label: SIGNIFICANT_DELAYS, value: SIGNIFICANT_DELAYS },
    { label: DETOUR, value: DETOUR },
    { label: ADDITIONAL_SERVICE, value: ADDITIONAL_SERVICE },
    { label: MODIFIED_SERVICE, value: MODIFIED_SERVICE },
    { label: OTHER_EFFECT, value: OTHER_EFFECT },
    { label: UNKNOWN_EFFECT, value: UNKNOWN_EFFECT },
    { label: STOP_MOVED, value: STOP_MOVED },
];

export const EMPTY_ALERT = {
    id: 0,
    start: moment().toDate(),
    end: moment().add(1, 'day').toDate(),
    agencyCode: '',
    routeId: '',
    stopId: '',
    cause: UNKNOWN_CAUSE,
    effect: NO_SERVICE,
    textFR: '',
    textEN: '',
    status: null,
    createdById: null,
    publishedById: null,
    publicationDate: null

};

export const FILTER_DATE_OPTIONS = [
    { label: "Date de création", value: "2" },
    { label: "Date de début", value: "0" },
    { label: "Date de fin", value: "1" },
];


// store keys
export const SEARCH_PARAM_KEY = "searchParamsAlert";
export const SELECTED_ALERT = "SelectedAlert";
export const DELETE_ALERT_BY_ID = "deleteAlertById";
export const UPDATE_ALERT = "updateAlert";
export const INSERT_ALERT = "insertAlert";
export const GET_ALERTS = "getAlerts";
export const GET_ALERTS_BY_ID = "getAlertsById";
export const ALERT_LIST_GET = "alertListGet";
