import { sendPostRequest, sendGetRequest } from '../../auth/apiEffects'
import { MessageViewModel } from '../../types/viewmodels/MessageViewModel'

export const saveMessage = async (queryParams): Promise<false | MessageViewModel | undefined> => {
    try {
        const savedMessageId = await sendPostRequest<MessageViewModel>("/messages/" + queryParams.messageId + queryParams.publishOrApprove, queryParams.queryData);
        return savedMessageId

    } catch (e) {
        console.log(e);
        return undefined;
    }
}

export const getMessages = async (queryParams): Promise<false | MessageViewModel[] | undefined> => {
    try {

        const searchQueryParams = {
            startDate: queryParams.startDate,
            endDate: queryParams.endDate,
            isCalendar:true
        }

        const response = await sendPostRequest<MessageViewModel[]>("/messages/", searchQueryParams);
        return response;

    } catch (e) {
        console.log(e);
        return undefined;
    }
}