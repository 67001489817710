import React, { Fragment, useContext, useEffect, useState } from 'react';
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import StatsHeatMap from "./StatsHeatMap";
import { Col } from "react-bootstrap";
import AgenciesDropDown from "../global/AgenciesDropDown";
import ChronoContext from "../../../context/ChronoContext";
import { getSimplifiedAgencyLabels } from "../../common/helper";

const StatisticsHeatMapPage = () => {
    const staticData = useContext(ChronoContext).staticData;
    const userAgencies = staticData.user.agencies;

    const [agencyLabels, setAgencyLabels] = useState<any>([]);
    const [currentAgencyLabel, setCurrentAgencyLabel] = useState<any>('');

    useEffect(() => {
        const agencyLabels = getSimplifiedAgencyLabels(userAgencies);
        setAgencyLabels(agencyLabels);
        setCurrentAgencyLabel(agencyLabels[0])
    }, [userAgencies]);
    
    return (
        <Fragment>
            <TopBar title={`Planifications ${currentAgencyLabel}`}>
                <Col  />
                <AgenciesDropDown
                    currentAgencyLabel={currentAgencyLabel}
                    setCurrentAgencyLabel={setCurrentAgencyLabel}
                    agencies={agencyLabels}
                />
            </TopBar>
            <ContentBelowTopBar>
                <StatsHeatMap />
            </ContentBelowTopBar>
        </Fragment>
    );
};

export default StatisticsHeatMapPage;
