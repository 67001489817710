import React, { FC, ReactElement } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AlertSummary from "./AlertSummary";
import AlertForm from "./AlertForm";
import { useAlertService } from "../../../../service/communication/useAlertService";
import { ToastProvider } from 'react-toast-notifications';
import { AlertFormBaseProps } from '../../../../types/components/alertComponent';

const AlertBaseContainer: FC<AlertFormBaseProps> = ({ entity, operations }: AlertFormBaseProps) : ReactElement => {
    const alertService = useAlertService();

    if (alertService.isAlertEditable(entity)) {
        return <ToastProvider><AlertForm entity={entity} operations={operations}  /></ToastProvider>;
    } else {
        return <ToastProvider><AlertSummary entity={entity} /></ToastProvider>;
    }
};

export default AlertBaseContainer;
