import React, { useState, useEffect } from "react";
import { useChronoApi } from "../../../../auth/apiEffects";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useIsMountedRef } from "../../helper";

interface IGetOption {
    id: number;
    label: string;
}
interface ISelectEmail {
    getOptions: (ev: any) => IGetOption[];
    onEmailSelect: (ev: any) => void;
    onChange?: (ev: any) => void;
    value?: string;
    searchCharacters?: string;

}
const SelectEmail: React.FC<ISelectEmail> = ({ value, onChange, searchCharacters, getOptions, onEmailSelect }) => {
    const [email, setEmail] = useState('');
    const [options, setOptions] = useState<IGetOption[]>([]);
    const isMountedRef = useIsMountedRef();

    const { data: users, isLoading } = useChronoApi(`/users/matchingChronoUserEmailList/${(email && email.length > 0 ? email : "1")}`)

    const onSelectData = (evt) => {
        console.log("onSelectData", evt)
        onEmailSelect(evt)
    }


    useEffect(() => {
        if (!isLoading && users && users.length > 0 && isMountedRef.current) {
            var opts: IGetOption[] = getOptions(users);
            setOptions(opts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users, isLoading, isMountedRef]);

    const inputChange = (evt) => {
        if (evt && evt.length >= 3) {
            setEmail(evt);
        }
    }

    return (
        <Typeahead
            id="selectEmails"
            onInputChange={inputChange}
            onChange={onChange ? console.log("test") : onSelectData}
            options={options}
            maxResults={50}
            value={value}
        />
    );
};

export default SelectEmail;

