import { sendGetRequest, sendPostRequest } from "../../auth/apiEffects";
import { RouteViewModel } from '../../types/viewmodels/RouteViewModel'

export const getReferenceRoutes = async (queryParams): Promise<false | RouteViewModel[] | undefined> => {
    try {
        const response = await sendGetRequest<RouteViewModel[]>(`/references/routes?agencyCodeList=${queryParams.agencyCodeList}`);
        return response;
    } catch (e) {
        console.log(e);
        return undefined;
    }
};

export const getReferenceStops = async (queryParams): Promise<false | any | undefined> => {
    try {
        const response = await sendPostRequest<any>("/references/routes/arrets", queryParams);

        return response;
    } catch (e) {
        console.log(e);
        return undefined;
    }
};
