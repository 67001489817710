import RoleHelper, { ROLE_CODENAME_ADMIN, ROLE_CODENAME_APPROBATOR, isRoleAllowed,  } from "../common/RoleHelper";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUpload, faTimesCircle, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { showPopupConfirm } from "../common/Popups";
import React, { FC, useContext, useState } from "react";
import GenericPopup from "../common/generic/GenericPopup";
import ChronoContext from "context/ChronoContext";

export const TYPE_REQUEST_SAVE = 1;
export const TYPE_REQUEST_APPROVE = 2;
export const TYPE_REQUEST_PUBLISH = 3;
export const TYPE_REQUEST_REFUSE = 4;
export const TYPE_REQUEST_EXPIRE = 5;

interface CommunicationFormActionButtonsProps {
    onClickSend?: Function;
    onClickDelete?: Function;
    isFormFilled?: boolean;
    isLoading?: boolean;
    itemExists?: boolean;
    hideApprove?: boolean;
    hidePublish?: boolean;
    hideDelete?: boolean;
    isValidated?: Function | boolean;
    allowAccessButton?: any;
    showRefused?: boolean;
    hasBeenModified?: boolean;
    disabled?: boolean;
    cssClass?: string;
}

const CommunicationFormActionButtons: FC<CommunicationFormActionButtonsProps> = ({
    onClickSend,
    onClickDelete,
    isFormFilled,
    isLoading,
    itemExists,
    hideApprove,
    hidePublish,
    hideDelete,
    isValidated,
    allowAccessButton,
    showRefused,
    hasBeenModified,
    disabled = false,
    cssClass,
}: CommunicationFormActionButtonsProps) => {
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const staticData = useContext(ChronoContext).staticData;
    const roleUser = staticData.user.profile.role;
    const rolesArray = staticData.roles;

    const publishButton = !hidePublish && onClickSend && (
        <Dropdown.Item
            disabled={!isFormFilled || isLoading}
            onClick={() => {
                var isFormValid = isValidated && typeof isValidated == "function" ? isValidated() : true;
                if (!isFormValid) return;

                showPopupConfirm({
                    message: "Voulez-vous vraiment publier?",
                    onClickYes: () => {
                        console.log(onClickSend);
                        onClickSend(TYPE_REQUEST_PUBLISH);
                    },
                });
            }}
        >
            <FontAwesomeIcon icon={faUpload} /> Publier
        </Dropdown.Item>
    );

    const approveButton = itemExists && !hideApprove && onClickSend && (
        <Dropdown.Item
            disabled={!isFormFilled || isLoading}
            onClick={() => {
                showPopupConfirm({
                    message: "Voulez-vous vraiment approuver?",
                    onClickYes: () => onClickSend(TYPE_REQUEST_APPROVE),
                });
            }}
        >
            <FontAwesomeIcon icon={faUpload} /> Approuver
        </Dropdown.Item>
    );

    const disabledApproveButton = itemExists && !hideApprove && (
        <Dropdown.Item disabled={!isFormFilled || isLoading}>
            <FontAwesomeIcon icon={faUpload} /> Approuver
        </Dropdown.Item>
    );

    const deleteButton = itemExists && !hideDelete && onClickDelete && (
        <Dropdown.Item
            onClick={() => {
                showPopupConfirm({
                    message: "Voulez-vous vraiment supprimer?",
                    onClickYes: () => onClickDelete(),
                });
            }}
        >
            <FontAwesomeIcon icon={faTrash} /> Supprimer
        </Dropdown.Item>
    );

    const refuseButton = itemExists && showRefused === true && onClickSend && (
        <div>
            <Dropdown.Item
                disabled={!isFormFilled || isLoading}
                onClick={(event) => {
                    setErrorMsg("");
                    setShowModal(true);
                }}
            >
                <FontAwesomeIcon icon={faTimesCircle} /> Refuser
            </Dropdown.Item>
            <GenericPopup
                title="Commentaire"
                lblYes="Envoyer"
                show={showModal}
                onClickYes={() => {
                    setErrorMsg("");
                    if (comment.trim().length === 0) {
                        setErrorMsg("Veuillez saisir un commentaire");
                        return;
                    }

                    setShowModal(false);
                    onClickSend(TYPE_REQUEST_REFUSE, comment);
                }}
                errorMessage={errorMsg}
                onHide={() => setShowModal(false)}
            >
                <textarea
                    autoFocus
                    rows={10}
                    cols={60}
                    style={{
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        width: "100%",
                        borderRadius: "5px",
                        borderColor: "lightgray",
                    }}
                    maxLength={500}
                    onChange={(event) => setComment(event.target.value)}
                />
            </GenericPopup>
        </div>
    );

    return (
        <>
            {(publishButton || approveButton || deleteButton || refuseButton) && (
                <RoleHelper roleCodenameAllowed={ROLE_CODENAME_APPROBATOR} conditionsAreAllowed={() => allowAccessButton}>
                    
                       {(isRoleAllowed(roleUser, rolesArray[ROLE_CODENAME_ADMIN]) || (hasBeenModified && approveButton) || (!hasBeenModified && disabledApproveButton) || (refuseButton)) ? <Dropdown.Toggle disabled={disabled} split id="dropdown-split-basic" className={cssClass}>
                            <FontAwesomeIcon icon={faEllipsisH} />
                        </Dropdown.Toggle> : "-"}
                   

                    <Dropdown.Menu rootCloseEvent="mousedown">
                        {hasBeenModified && approveButton}
                        {!hasBeenModified && disabledApproveButton}
                        {refuseButton}
                        <RoleHelper roleCodenameAllowed={ROLE_CODENAME_ADMIN}>
                            {publishButton}
                            {deleteButton}
                        </RoleHelper>
                    </Dropdown.Menu>
                </RoleHelper>
            )}
        </>
    );
};

export default CommunicationFormActionButtons;
