import React, { useState, FC, ReactElement } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CarteSummary from "./components/CarteSummary";
import CarteFormEditable from "./components/CarteFormEditable";
import { useCarteService } from "../../../service/communication/carteService";
import { CarteBaseContainerProps } from '../../../types/components/carteComponent';
import { ToastProvider } from 'react-toast-notifications';
import { LoadingIndicator } from "components/LoadingIndicator";

const CarteBaseContainer: FC<CarteBaseContainerProps> = ({ entity, setEntity, operations, isSliderLoading }: CarteBaseContainerProps): ReactElement => {
    const [isCarteEdit, setIsCarteEdit] = useState(false);
    const carteService = useCarteService();

    if (isSliderLoading) return <LoadingIndicator />

    if (carteService.isCarteEditable(entity) || isCarteEdit) {
        return <ToastProvider><CarteFormEditable entity={entity} setEntity={setEntity} operations={operations} /></ToastProvider>;
    } else {
        return <ToastProvider><CarteSummary entity={entity} operations={operations} onClickEdit={(val) => setIsCarteEdit(val)} /></ToastProvider>;
    }
};

export default CarteBaseContainer;
