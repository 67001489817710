import React, { useState, useEffect, useContext } from 'react';
import { TitleCard, ITEMS } from "../../home/cards/BaseCardHome";
import ChronoXYPlot from "../../common/ChronoXYPlot";
import { AGENCIES_EXO, AGENCY_LABEL_EXO, getSimplifiedAgencyLabels } from "../../common/helper";
import { StatsCard, StatsContainer } from "../global/StatsComponents";
import { KEEN_DATA_AGENCY_CODE } from "../keen/keenConstants";
import { getCountRoutesShown } from "../keen/keenQueries";
import { useKeenQuery } from "../keen/keenHelper";
import DiscreteColorLegend from 'react-vis/dist/legends/discrete-color-legend';
import { LoadingIndicator } from "../../LoadingIndicator";
import { Card, Row, Col } from "react-bootstrap";
import ChronoBadge from "../../common/ChronoBadge";
import ChronoContext from 'context/ChronoContext';

export interface IKeenData { 
    x: number;
    y: number;
    color: string;

}
interface IUserKeenResult {
    keenData: IKeenData[];
    keenError: Error
}
const translateKeenData = (dataList) => {
    if (dataList) {
        return dataList.map((d) => ({
            x: d[KEEN_DATA_AGENCY_CODE],
            y: d.result,
        }));
    }

    return [];
};

const StatsRoutesShown = ({filterAgency, onClickSelectBar, onClickUnselectBar, currentDates, uniqueness, keenData, keenData2}) => {
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        if (keenData.length > 0) setIsDataLoaded(true)
    }, [keenData])

    const getCsvData = (dataList) => {
        let results: { Organisation: number; "Nombre de clicks": string;}[] = [];
        dataList.forEach(row => {
            results.push(
                {
                    Organisation: row.x,
                    "Nombre de clicks": row.y
                });
        });
        return results;
    }

    const calculateConsulationCount = (dataList) => {
        let cc = 0;
        dataList.forEach(row => {
            cc += row.y;
        });
        return cc;
    }

    return (
        <>
         <StatsContainer>
            <StatsCard>
                <Row>
                    <Col>
                    <Card  style={{cursor: 'pointer', margin: 'auto'}}>
                           <Card.Header style={{fontSize: 16, width: '100%'}}>
                                Nombre de consultations { uniqueness && 'uniques'}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                <Col className="align-self-center">
                                                <ChronoBadge color={'#000'} code={'EXO'} />
                                            </Col>
                                    <Col className="align-self-center" style={{fontSize: 40}}>
                                       {(isDataLoaded && keenData.length) ? calculateConsulationCount(keenData) : 0}
                                    </Col>
                                </Row>
                            </Card.Body>
                           </Card>
                    </Col>
                    <Col>
                            <Card style={{cursor: 'pointer', margin: 'auto'}}>
                                <Card.Header style={{fontSize: 16, width: '100%'}}>
                                Nombre de consultations totales { uniqueness && 'uniques'}
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col className="align-self-center">
                                            <ChronoBadge color="#00A0A5" code="ARTM" />
                                        </Col>
                                        <Col className="align-self-center" style={{fontSize: 40}}>
                                        {(isDataLoaded && keenData2?.length) ? keenData2?.map(i => i.y).reduce((a, b) => a + b): 0}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            </Col>
                </Row>
               
                    
            </StatsCard>
        </StatsContainer>
        <StatsContainer>
            <StatsCard>
                <TitleCard>Affichage des agences</TitleCard>

                <DiscreteColorLegend height={60} width={100} items={ITEMS} style={{marginBottom: -10}} />               
                {
                    !isDataLoaded ? <LoadingIndicator style={{marginTop: -200}} /> :
                        keenData.length > 0 ?
                            <ChronoXYPlot data={keenData} csvData={getCsvData(keenData)} csvFileName={'statsAgencyClick'} showExport={true} isAxisYFixed={true} onClickSelectBar={onClickSelectBar} onClickUnselectBar={onClickUnselectBar} tickLabelAngle={keenData.length > 25 ? -60 : 0}/>
                        : <h3 style={{margin: 'auto'}}>Aucun résultat</h3>
                }
            </StatsCard>
        </StatsContainer>
        </>
    );
};

export default StatsRoutesShown;
