import React, {FC, ReactElement, useContext, useEffect, useState } from 'react';
import { Toast as ToastBootstrap } from "react-bootstrap";
import styled, { ThemeContext } from "styled-components";
import ChronoContext from "../../../../context/ChronoContext";

export const TYPE_TOAST_INFO = 1;
export const TYPE_TOAST_WARNING = 2;
export const TYPE_TOAST_ERROR = 3;


interface IMessage {
    messageColor: string
}

const CustomToast = styled(ToastBootstrap)`
  width: 300px;
  position: fixed;
  right: 0;
  margin-top: 1%;
  margin-right: 2%;
  z-index: 100;
`;

const CustomToastHeader = styled(ToastBootstrap.Header)`
  background: ${props => (
      props.type === TYPE_TOAST_ERROR ? 'rgba(255,0,0,0.6)' :
          props.type === TYPE_TOAST_WARNING ? 'rgba(240,215,0,0.6)' :
            'rgba(0,156,255,0.6)')} !important;
  color: black !important;
`;

const CustomMessage = styled.p<IMessage>`
  color: ${props => (props.messageColor)};
`;

const Toast:FC<any> = ({title = 'Message', message, type = TYPE_TOAST_INFO}:any):ReactElement => {
    const theme = useContext(ThemeContext);
    const context = useContext(ChronoContext);
    const [show, setShow] = useState(true);
    
    useEffect(() => {
        if (!show) {
            context.removeNotification(message);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);
    
    const toggleShow = () => setShow(!show);
    
    let messageColor;
    switch (type) {
        case TYPE_TOAST_INFO:
            messageColor = theme.colors.messageInfo;
            break;
        case TYPE_TOAST_WARNING:
            messageColor = theme.colors.messageWarning;
            break;
        case TYPE_TOAST_ERROR:
            messageColor = theme.colors.messageError;
            break;
        default:
            break;
    }
    
    return (
        <CustomToast show={show} onClose={toggleShow}>
            <CustomToastHeader type={type}>
                <strong className="mr-auto">{title}</strong>
            </CustomToastHeader>
            <ToastBootstrap.Body>
                <CustomMessage messageColor={messageColor}>{message}</CustomMessage>
            </ToastBootstrap.Body>
        </CustomToast>
    );
};

export default Toast;
