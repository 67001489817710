import React, { useContext } from 'react';
import ChronoContext from "../../context/ChronoContext";
import ChronoToast from "./ChronoToast";

const ChronoToastStack = () => {
    const context = useContext(ChronoContext);
    const staticData = context.staticData;
    const notifications = staticData.notifications || [];
    
    return (
        <div>
            { notifications.map(notification => <ChronoToast message={notification.message} type={notification.type} />)}
        </div>
    )
};

export default ChronoToastStack;
