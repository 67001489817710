import { sendGetRequest } from '../auth/apiEffects'
import { DropdownItemViewModel } from '../types/viewmodels/DropdownItemViewModel'

export const getMobileConfigurationListItems = async (queryParams): Promise<false | DropdownItemViewModel[] | undefined> => {
    try {

        const searchQueryParams = {
            property: queryParams.propertyName,
            criteria: queryParams.criteria
        }

        const searchQueryParamString = new URLSearchParams(searchQueryParams).toString()
        const response = await sendGetRequest<DropdownItemViewModel[]>(`/log/configuration/list?${searchQueryParamString}`)
        return response

    } catch (e) {
        console.log(e);
        return undefined;
    }
}

export const getDropdownListItemForLogDetailProperty = async(queryParams): Promise<false | DropdownItemViewModel[] | undefined> => {
    try {

        const searchQueryParams = {
            property: queryParams.propertyName,
            criteria: queryParams.criteria
        }

        const searchQueryParamString = new URLSearchParams(searchQueryParams).toString()
        const response = await sendGetRequest<DropdownItemViewModel[]>(`/log/property/value/list?${searchQueryParamString}`)
        return response

    } catch (e) {
        console.log(e);
        return undefined;
    }
}

export const getDropdownItemForUserEmailList = async(queryParams): Promise<false | DropdownItemViewModel[] | undefined> => {
    try {
        const response = await sendGetRequest<DropdownItemViewModel[]>(`/users/matchingChronoUserEmailList/dropdown/${queryParams.email}`)
        return response

    } catch (e) {
        console.log(e);
        return undefined;
    }
}


