import * as  React from 'react';
import CommunicationActionButtonGrid from "../../CommunicationActionButtonGrid";
import { useCarteService } from "../../../../service/communication/carteService";
import { useCommunicationEvent } from '../../communicationEvent';
import { CarteButtonGridProps } from '../../../../types/components/carteComponent';
import { TYPE_REQUEST_PUBLISH } from "../../CommunicationFormActionButtons";

const CarteButtonGrid: React.FC<CarteButtonGridProps> = ({ data, onRefresh }: CarteButtonGridProps): React.ReactElement => {
    const carteService = useCarteService();

    React.useEffect(() => {
        carteService.request.Save.changeResourceName({ carteId: data.id });
        carteService.request.Publish.changeResourceName({ carteId: data.id });
        carteService.request.Expire.changeResourceName({ carteId: data.id });
        carteService.request.Delete.changeResourceName({ carteId: data.id });
    }, []);


    //handle event publish notif
    useCommunicationEvent(
        {
            successCallback: () => carteService.updateModel(carteService.request.Publish.data),
            afterPost: () => {
                carteService.request.Publish.refreshState(data.id);
                if (onRefresh)
                    onRefresh();
            }
        },
        carteService.request.Publish.isSuccess, carteService.request.Publish.isFail
    )

    //handle event delete notif
    useCommunicationEvent(
        {
            successCallback: () => carteService.removeElement(data.id),
            afterPost: () => {
                carteService.request.Delete.refreshState(data.id);
                if (onRefresh)
                    onRefresh();
            }
        }, carteService.request.Delete.isSuccess, carteService.request.Delete.isFail
    )

    return (
        <div id={'containerButonGrid' + data.id} className='com-container'>

            <CommunicationActionButtonGrid
                isProcessing={carteService.request.Save.isLoading || carteService.request.Delete.isLoading || carteService.request.Publish.isLoading || carteService.request.Expire.isLoading}
                disabled={!carteService.isCarteEditable(data) && !carteService.isCarteAwaitingPublication(data)}
                hideApprove={true}
                hidePublish={carteService.isCarteAwaitingPublication(data)}
                hideRefuse={true}
                canPublish={carteService.canPublish(data)}
                onClickSend={(requestType) => {
                    const model = carteService.mapToModel(data, undefined, requestType);
                    if (requestType === TYPE_REQUEST_PUBLISH) {
                        carteService.request.Publish.process(model);
                    } else {
                        carteService.request.Save.process(model);
                    }

                }}
                onClickDelete={() => {
                    carteService.request.Delete.process(data.id);
                }}
                data={data}
        />
    </div>
    )
}

export default CarteButtonGrid;