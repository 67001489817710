import React from "react";
import { Spinner, SpinnerProps } from "react-bootstrap";
import styled from "styled-components";

const LoadingContainer = styled.div`
    position: relative;

    & > div {
        margin: 0;
        position: absolute;
        top: 40%;
        left: 45%;
    }
`;

// @ Refactorer pour étendre SpinnerProps - problème d'overlap avec size
interface LoadingIndicatorInAppProps {
    size?: number | string;
    animation?: "border" | "grow";
    height?: number | string;
    couleurFond?: string;
    style?: object;
}


export const LoadingIndicatorInApp = ({ size = 50, style = undefined, animation = "border" }: LoadingIndicatorInAppProps) => {
    return (
        <LoadingContainer>
            <Spinner style={{ width: size, height: size, ...style }} animation={animation} />
        </LoadingContainer>
    );
};

// @ Refactorer pour étendre SpinnerProps - problème d'overlap avec size
interface LoadingIndicatorProps {
    size?: number | string;
    animation?: "border" | "grow";
    height?: number | string;
    couleurFond?: string;
    style?: object;
}

export const LoadingIndicator = ({
    size = 150,
    style,
    animation = "border",
    height = window.innerHeight,
    couleurFond = "transparent",
}: LoadingIndicatorProps) => {
    return (
        <LoadingContainer style={{ height: height, backgroundColor: couleurFond }}>
            <Spinner style={{ width: size, height: size, ...style }} animation={animation} />
        </LoadingContainer>
    );
};
