import React from "react";
import GenericSelect from "../GenericSelect";
import { LIST_GTFS_R_CAUSE } from "../../../communication/alert/AlertConstants";

const SelectGtfsrCauses = (props) => {
    return (
        <GenericSelect title="Cause" {...props}>
            {
                LIST_GTFS_R_CAUSE.map((cause: any, i) =>
                    <option key={cause.codename + '_' + i} value={cause.codename}>{cause.label}</option>
                )
            }
        </GenericSelect>
    );
};

export default SelectGtfsrCauses;
