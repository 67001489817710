import { useContext, useState, useEffect } from "react";
import { useApiRequest } from "../../redux/hooks/useApiRequest";
import moment from 'moment';
import { CarteForm } from "../../types/forms/carteForms";
import { CarteViewModel, CarteParamViewModel } from "../../types/viewmodels/carteViewModels";
import { CommunicationStatus } from "../../types/entities/communicationEntities";
import * as config from "../../components/communication/carte/CarteConstants";
import ChronoContext from "../../context/ChronoContext";
import { CODENAME_STATUS_CREATED, CODENAME_STATUS_PUBLISHED } from "../../statusUtils";
import { DATE_FORMAT } from "../../components/communication/CommunicationUtils";
import { TYPE_REQUEST_PUBLISH } from "../../components/communication/CommunicationFormActionButtons";
import useUI from "../../lib/hooks/useUI";
import { useCacheService } from "../../redux/hooks/useCacheService";
import { Categories } from '../../redux/keys/generic/types';
import { Status } from "../../types/enum/Status";

export const useCarteService = () => {

    //states
    const [isProcessingSave, setIsProcessingSave] = useState(false);
    const [isErrorSave, setIsErrorSave] = useState(false);
    const [isSuccessSave, setIsSuccessSave] = useState(false);
    const [dataSave, setDataSave] = useState(null);
    const searchParams = useUI(config.SEARCH_PARAM_KEY);
    const [isNewCarte, setIsNewCarte] = useState(false);

    //hook
    const context = useContext(ChronoContext);
    const requestCarteList = useApiRequest(config.CARTE_LIST_GET);
    const requestCarteUpsert = useApiRequest(config.UPSERT_CARTE);
    const requestCartePublish = useApiRequest(config.PUBLISH_CARTE);
    const requestCarteExpire = useApiRequest(config.EXPIRE_CARTE);
    const requestCarteGetById = useApiRequest(config.GET_CARTE_BY_ID);
    const requestCarteDeleteById = useApiRequest(config.DELETE_CARTE_BY_ID);

    const cacheService = useCacheService();

    useEffect(() => {
        if (requestCarteUpsert.isFail === true && isProcessingSave === true) {
            setIsErrorSave(true);
            setIsProcessingSave(false);
        }
    }, [requestCarteUpsert.isFail])

    useEffect(() => {
        if (requestCarteUpsert.isSuccess === true && isProcessingSave === true) {
            requestCarteList.actions.process(searchParams);

        }
    }, [isNewCarte, requestCarteUpsert.isSuccess])

    useEffect(() => {
        if (!requestCarteList.isLoading && isProcessingSave === true && requestCarteUpsert.isSuccess === true) {
            setIsSuccessSave(true);
            setIsProcessingSave(false);
            setDataSave(requestCarteUpsert.result.data);
        }
    }, [requestCarteList.isLoading])

    //forms parameters
    const defaultValues = { status: [], dateDebut: moment().add(-15, 'd'), dateFin: moment(), filterByDateType: '2' }

    const mapToParam = (values: any, statusList: Array<CommunicationStatus>): CarteParamViewModel => {
        const param = {
            status: statusList ? statusList.filter(x => x.value != null).map(x => x.value as unknown as Status) : [],   
            startDate: values.dateDebut && values.dateDebut != null ? moment(values.dateDebut).utc().format(DATE_FORMAT) : values.dateDebut,
            endDate: values.dateFin && values.dateFin != null ? moment(values.dateFin).utc().format(DATE_FORMAT + ' 23:59:59') : values.dateFin,
            filterByDateType: values.filterByDateType,
        };
        cacheService.Save(param, config.SEARCH_PARAM_KEY, Categories.UI);
        return param as CarteParamViewModel;
    }

    const mapToModel = (model: CarteForm, values?: CarteForm, requestType?: number): CarteViewModel => {
        const status = context.staticData.status;
        let entity = model as CarteViewModel;

        if (values) {
            Object.keys(values).forEach((p) => {
                if (entity.hasOwnProperty(p))
                    entity[p] = values[p];
            });
            entity.activationDate = moment(moment(model.activationDate).format(DATE_FORMAT) + ' ' + model.activationTime).toDate();
            entity.expirationDate = moment(moment(model.expirationDate).format(DATE_FORMAT) + ' ' + model.expirationTime).toDate();
        }
        entity.status = requestType === TYPE_REQUEST_PUBLISH ? status[CODENAME_STATUS_PUBLISHED] : status[CODENAME_STATUS_CREATED];

        delete entity["activationTime"];
        delete entity["expirationTime"];
        delete entity["publicationDate"];
        delete entity["modificationDate"];

        return entity as CarteViewModel;
    }

    const mapToForm = (model: CarteViewModel): CarteForm => {
        return {
            ...model,
            activationTime: moment(model.activationDate).format("HH:mm"),
            expirationTime: moment(model.expirationDate).format("HH:mm")
        } as CarteForm
    }

    const isCarteEditable = (carte: CarteForm) => {
        const status = context.staticData.status;
        return carte.status !== status[CODENAME_STATUS_PUBLISHED] || carte.id === 0;
    }

    const isCarteAwaitingPublication = (carte: CarteForm) => {
        const status = context.staticData.status;
        return carte.status === status[CODENAME_STATUS_PUBLISHED] && moment(carte.activationDate) > moment();
    }

    const getNewCarte = (): CarteForm => {
        return config.EMPTY_CARTE as CarteForm
    }

    const clearCacheOperations = (id: any): void => {
        cacheService.Remove(config.DELETE_CARTE_BY_ID + id, Categories.REQUEST);
        cacheService.Remove(config.UPSERT_CARTE + id, Categories.REQUEST);
        cacheService.Remove(config.PUBLISH_CARTE + id, Categories.REQUEST);
        cacheService.Remove(config.EXPIRE_CARTE + id, Categories.REQUEST);
    }

    const save = (model: CarteViewModel) => {
        clearCacheOperations(model.id);
        if (model.id === 0) {
            setIsNewCarte(model.id === 0);
            setIsProcessingSave(true);
        }

        requestCarteUpsert.actions.process(model, { carteId: model.id }, { carteId: model.id });
    }

    const updateModel = (model: CarteViewModel): void => {
        if (!model)
            return;

        const index = requestCarteList.result.data.findIndex(x => x.id === model.id);
        requestCarteList.result.data[index] = { ...model };
    }

    const publish = (model: CarteViewModel) => {
        clearCacheOperations(model.id);
        requestCartePublish.actions.process(model, { carteId: model.id }, { carteId: model.id })
    }

    const expire = (carteId: number) => {
        clearCacheOperations(carteId);
        requestCarteExpire.actions.process(carteId, { carteId: carteId }, { carteId: carteId })
    }

    const getById = (carteId: number) => {
        requestCarteGetById.actions.process(carteId, { carteId: carteId }, { carteId: carteId });
    }

    const deleteById = (carteId: number) => {
        clearCacheOperations(carteId);
        requestCarteDeleteById.actions.process(carteId, { carteId: carteId }, { carteId: carteId });
    }

    const removeElement = (id: number) => {
        const index = requestCarteList.result.data.findIndex(x => x.id === id);
        if(index>=0)
            requestCarteList.result.data.splice(index, 1);
    }

    const cleanCache = () => {
        clearCacheOperations(0);
        cacheService.Remove(config.SEARCH_PARAM_KEY, "UI");
        cacheService.Remove(config.CARTE_LIST_GET, "resources");
    }

    const canPublish = (model: CarteViewModel): boolean => {
        var dt = moment().format(DATE_FORMAT);
        return moment(model.expirationDate).isAfter(moment(dt));
    }

    return {
        request: {
            GetAll: {
                isLoading: requestCarteList.isLoading,
                isSuccess: requestCarteList.isSuccess,
                isFail: requestCarteList.isFail,
                process: requestCarteList.actions.process,
                data: requestCarteList.result.data
            },
            Save: {
                isLoading: isProcessingSave,
                isSuccess: isSuccessSave,
                isFail: isErrorSave,
                process: save,
                data: dataSave,
                changeResourceName: requestCarteUpsert.setResource,
                getResource: requestCarteUpsert.getResource,
                refreshState: (id) => {
                    setIsSuccessSave(false);
                    setIsErrorSave(false);
                    clearCacheOperations(id);
                }
            },
            Update: {
                isLoading: requestCarteUpsert.isLoading,
                isSuccess: requestCarteUpsert.isSuccess,
                isFail: requestCarteUpsert.isFail,
                process: save,
                data: requestCarteUpsert.result.data,
                changeResourceName: requestCarteUpsert.setResource,
                getResource: requestCarteUpsert.getResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                }
            },
            Publish: {
                isLoading: requestCartePublish.isLoading,
                isSuccess: requestCartePublish.isSuccess,
                isFail: requestCartePublish.isFail,
                process: publish,
                data: requestCartePublish.result.data,
                changeResourceName: requestCartePublish.setResource,
                getResource: requestCartePublish.getResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                }
            },
            Expire: {
                isLoading: requestCarteExpire.isLoading,
                isSuccess: requestCarteExpire.isSuccess,
                isFail: requestCarteExpire.isFail,
                process: expire,
                data: requestCarteExpire.result.data,
                changeResourceName: requestCarteExpire.setResource,
                getResource: requestCarteExpire.getResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                }
            },
            GetById: {
                isLoading: requestCarteGetById.isLoading,
                isSuccess: requestCarteGetById.isSuccess,
                isFail: requestCarteGetById.isFail,
                process: getById,
                data: requestCarteGetById.result.data,
                changeResourceName: requestCarteGetById.setResource,
            },
            Delete: {
                isLoading: requestCarteDeleteById.isLoading,
                isSuccess: requestCarteDeleteById.isSuccess,
                isFail: requestCarteDeleteById.isFail,
                process: deleteById,
                data: requestCarteDeleteById.result.data,
                changeResourceName: requestCarteDeleteById.setResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                }
            }
        },
        Filter: {
            defaultValues: defaultValues,
            searchParams: searchParams
        },
        Constantes: {
            DISPLAY_DATE_FORMAT: DATE_FORMAT
        },
        mapToParam: mapToParam,
        mapToModel: mapToModel,
        mapToForm: mapToForm,
        isCarteEditable: isCarteEditable,
        isCarteAwaitingPublication: isCarteAwaitingPublication,
        getNewCarte: getNewCarte,
        cleanCache: cleanCache,
        updateModel: updateModel,
        removeElement: removeElement,
        canPublish: canPublish
    };
};







