import React from 'react';
import { ContentBelowTopBar } from "./../../ContentNavigation";
import { Card, Button } from 'react-bootstrap';
import ChronoCard from "./../../ChronoCard";
import ChronoImage from "../../../../images/iconChrono@3x.png";
import {ChronoTitle, LogoContainer, LogoIcon} from "../../../navMenu/HeaderNavMenu";

const NoData = (props) => {
    return (
        <div>
            <ContentBelowTopBar>
                <ChronoCard style={{padding: 0, width: '60%', margin: 'auto', marginTop: 30}}>
                    <Card.Header style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 25, paddingBottom: 25}}>
                        <LogoContainer style={{margin: 0}}>
                            <LogoIcon src={ChronoImage} />
                            <ChronoTitle style={{color: 'black'}}>Chrono</ChronoTitle>
                        </LogoContainer>
                    </Card.Header>
                    <Card.Body style={{padding: 30}}>
                        <Card.Title>Aucune agence n'a été rattachée à votre compte</Card.Title>
                        <Card.Text>
                            Veuillez contacter un administrateur de la console pour plus de détails.
                        </Card.Text>
                        <Button variant="primary" onClick={() => props.history.push(`/`)}>Retour vers Tableau de bord</Button>
                    </Card.Body>
                </ChronoCard>
            </ContentBelowTopBar>
        </div> 
    )
}

export default NoData;