/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import GenericTableCell from "../../common/generic/table/cell/GenericTableCell";
import GenericTable, { GenericTableRow } from "../../common/generic/table/GenericTable";
import GenericTableBody from "../../common/generic/table/GenericTableBody";
import GenericTableHeadSingleRow from "../../common/generic/table/GenericTableHeadSingleRow";
import { sendPostRequest, sendGetRequest } from "../../../auth/apiEffects";
import { LoadingIndicatorInApp } from "../../LoadingIndicator";
import { Row, Col, Button } from 'react-bootstrap';
import { faSpinner, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import SelectAchalandageStatus from "../../common/generic/select/SelectAchalandageStatus";
import { AGENCIES_EXO } from "../../common/helper";

export const Header = styled.div`
    font-size: 16px;
    font-weight: bold;
    padding: 5px 0px 15px 5px;
    text-align: left;
    background-color:#EFF1F1;
    height:50px;
`

const FormRow = styled(Row)`
    margin-top: 5px;
`

const MappingStatutAchalandage = ({ searchParams }) => {

    const [statuts, setStatuts] = useState([]);
    const [icones, setIcones] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [show, setShow] = useState(false);
    const [formattedIcones, setFormattedIcones] = useState([]);
    const [ allStatuts, setAllStatuts ] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [isRefresh, setIsRefresh] = useState(false);



    useEffect(() => {
        async function fetchData() {
            if (!isRefresh && isDataLoaded)
                return;

            const resultIcones = await sendGetRequest('/admin/achalandage/icone/liste');
            const resultAllStatuts = await sendGetRequest('/admin/achalandage/statut/liste');
            const formattedResultAllStatuts = resultAllStatuts ? resultAllStatuts.map((obj) => {
                return { ...obj, isDirty: false };
            }) : [];
            setIcones(resultIcones ? resultIcones : []);
            setAllStatuts(formattedResultAllStatuts);
            setIsDataLoaded(true);
            setIsRefresh(false);
        }
        fetchData();
    }, [isRefresh]);


    useEffect(() => {

        if (!isDataLoaded)
            return;

        const criteria = searchParams === 'EXO' ? AGENCIES_EXO[0] : searchParams;

        const filteredStatus = allStatuts.filter(statut => {
            return statut.organizationCode === criteria;
        });

        const resultFormattedIcones = icones.map((icone) => {
            return { value: icone.iconeAchalandageId, label: icone.niveau };
        });

        setFormattedIcones(resultFormattedIcones ? resultFormattedIcones : []);
        setStatuts(filteredStatus);

        setShow(isDataLoaded);
    }, [searchParams, isDataLoaded]);

    const onChange = (valueId, rowId) => {

        const statutList = [...statuts];
        const icone = icones.find(x => x.iconeAchalandageId.toString() === valueId.toString());
        const statut = statutList.find(x => x.statutAchalandageId.toString() === rowId.toString());

        if (statut ) {
            statut.iconeAchalandageId = valueId === '' ? null : parseInt(valueId);
            statut.urlIcone = icone ? icone.urlIcone : '';
            statut.isDirty = true;
        }
        setStatuts(statutList);
    }

    const AjouterAgencesExo = (data) => {
            var organizations = data.map(x => x.organizationCode);
            var orgExo = organizations.filter(org => {
                return AGENCIES_EXO.includes(org);
            });

            if (!orgExo || orgExo.length === 0)
                return data;

            var statutGtfsList = data.filter(statut => {
                return AGENCIES_EXO.includes(statut.organizationCode);
            }).map(x => x.statutGtfsR);

            var results = allStatuts.filter(statut => {
                return statutGtfsList.includes(statut.statutGtfsR) && AGENCIES_EXO.includes(statut.organizationCode);
            });

            results = results.map((statut) => {
                var stat = data.find(x => x.statutGtfsR === statut.statutGtfsR &&  AGENCIES_EXO.includes(x.organizationCode));
                if (stat != null)
                    statut.iconeAchalandageId = stat.iconeAchalandageId;
                return statut;
            });

          return [...new Set([...data, ...results])];
    }

    const onSave = async (event) => {

        var data = allStatuts.filter(statut => {
            return statut.isDirty === true;
        });

        if (!data && data.length === 0)
            return;

        setIsSending(true);

       data = AjouterAgencesExo(data);

        const dataToSend = data.map((d) => {
                        return { iconeAchalandageId: d.iconeAchalandageId, statutAchalandageId: d.statutAchalandageId}
        });

        await sendPostRequest('/admin/achalandage/statut/sauvegarde/liste', dataToSend);

        /*var statutList = allStatuts.map((d) => {
            d.isDirty = false;
            return d;
        }); */
        setIsSending(false);
    }

    const onCancel = async (event) => {

        setShow(false);
        setIsDataLoaded(false);
        setIsRefresh(true);
    }

    if (!show) {
        return <LoadingIndicatorInApp />;
    }

    return (
        <Fragment>
            {
                statuts.length > 0 &&
                <div style={{ padding: "0px 10px 0px 10px" }}>
                    <FormRow>
                        <GenericTable style={{ marginBottom: '0px', width: '100%' }} disabled className="custom-tbody-transparent">
                            <GenericTableHeadSingleRow style={{ backgroundColor: '#DCDCDC' }}>
                                <th style={{ width: '40%' }}>STATUT GTFS</th>
                                <th style={{ width: '30%' }}>STATUT CHRONO</th>
                                <th style={{ width: '30%' }}>REPRESENTATION</th>
                            </GenericTableHeadSingleRow>
                            <GenericTableBody className="custom-tbody-transparent">
                                {

                                    statuts.map((statut, j) => {
                                        return (
                                            <Fragment key={j}>

                                                <GenericTableRow key={statut.statutAchalandageId} className="custom-tr-transparent" >
                                                    <GenericTableCell style={{ backgroundColor: 'transparent', color: 'black', padding:'5px'  }}>{statut.statutGtfsR}</GenericTableCell>
                                                    <GenericTableCell style={{ backgroundColor: 'transparent', padding: '5px' }}>
                                                      
                                                        <SelectAchalandageStatus disabled={isSending} rowId={statut.statutAchalandageId} data={formattedIcones} value={statut.iconeAchalandageId == null ? '' : statut.iconeAchalandageId} onChange={onChange} />
                                                    </GenericTableCell>
                                                    <GenericTableCell style={{ backgroundColor: 'transparent', padding: '5px'}}>
                                                        <img src={statut.urlIcone} alt=""/>
                                                    </GenericTableCell>

                                                </GenericTableRow>
                                            </Fragment>
                                        )
                                    })
                                }

                            </GenericTableBody>
                        </GenericTable>
                    </FormRow>
                    <FormRow>
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            {
                            isSending ? 
                                        <Button disabled>
                                            <span>Patientez... <FontAwesomeIcon icon={faSpinner} spin={true} /></span>
                                        </Button>
                                      :
                                        <Button onClick={onSave}>
                                            <span><FontAwesomeIcon icon={faSave} /> Enregistrer</span>
                                        </Button>
                            }

                            <Button onClick={onCancel} style={{marginLeft:'10px'}}>
                                <span><FontAwesomeIcon icon={faTimes} /> Annuler</span>
                            </Button>

                        </div>
                    </FormRow>
                </div>
            }
            {
                (!statuts || statuts.length === 0) &&
                <Row>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <h3>Aucun résultat</h3>
                    </Col>
                </Row>
            }

        </Fragment>
    )
}

export default MappingStatutAchalandage;