import React, { useState, useEffect } from "react";
import { Card, Row, Col } from 'react-bootstrap';
import BaseCardHome, { TitleCard } from "./BaseCardHome";
import ChronoXYPlot from "../../common/ChronoXYPlot";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import useResources from "../../../lib/hooks/useResources";
import useUI from "../../../lib/hooks/useUI";
import * as keys from "../../../redux/keys";
import { getStatsForConnectedUser } from "../../../redux/actions/dashboard/requestStatsConnectedUser";
import * as requestStatus from "../../../redux/sagas/requestStatus";
import { Dropdown } from "../../../lib/ui";
import { LoadingIndicatorInApp } from "../../LoadingIndicator";
import  CardStatisticUserConnectedLegend from "./cardStatisticUserConnectedLegend";


const getData = (dataList, type) => {
    var data = [];
    if (dataList && dataList.length > 0) {
        // eslint-disable-next-line array-callback-return

        data.push({
            data: dataList.map((element) => {
                var res = {
                    x: moment(element.connectedDate).format("YYYY-MM-DD"),
                    y: type === '30 days' ? element.total30Days :
                        type === '7 days' ? element.total7Days : element.totalDay,
                    type: type === '30 days' ? '30 jours' :
                          type === '7 days' ? '7 jours' : '1 jour',
                };
                return res;
            }),
            color: type === '30 days' ? "blue" :
                   type === '7 days' ? "violet" : 'orange',
        });
    }
    return data;
};


const translateData = (dataList) => {
    var donnees = [];
    if (dataList && dataList.length > 0) {
        donnees = donnees.concat(getData(dataList, '30 days'));
        donnees = donnees.concat(getData(dataList, '7 days'));
        donnees = donnees.concat(getData(dataList, 'day'))
    }
    return donnees.length === 0 ? [{ data: [] }] : donnees;
};

const CrossHairText = (points) => {
    return (
        <div>
            <h6 style={{ margin: 0, paddingBottom:'5px', fontWeight: 'bold', textDecoration: 'underline' }}>{`${points[0].x}`}</h6>
            {
                points.map((point, i) => {

                    return (
                        <div key={i} style={{ margin: 0, fontSize:'14px' }}>{`${point.type}: ${point.y}`}</div>
                    );
                })
            }
        </div>
    )
        
}
        
const COLLECTIONS_OPTIONS = [
    { label: 'Derniers 30 jours', value: 'd' },
    { label: 'Janvier', value: '1' },
    { label: 'Février', value: '2' },
    { label: 'Mars', value: '3' },
    { label: 'Avril', value: '4' },
    { label: 'Mai', value: '5' },
    { label: 'Juin', value: '6' },
    { label: 'Juillet', value: '7' },
    { label: 'Août', value: '8' },
    { label: 'Septembre', value: '9' },
    { label: 'Octobre', value: '10' },
    { label: 'Novembre', value: '11' },
    { label: 'Décembre', value: '12' }
];


const CardStatisticForUserConnected = () => {
    const [value, setValue] = useState('d');

    const dispatch = useDispatch();
    const stats = useResources(keys.STATS_CONNECTED_USER);
    const status = useUI(keys.STATS_CONNECTED_USER + '_STATUS');

    const fetchData = (startdate, endDate) => {
        if (startdate && endDate)

            dispatch(getStatsForConnectedUser(keys.STATS_CONNECTED_USER, {
                startDate: moment(startdate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD 23:59:59")
            }));
    }

    const onChange = (evt) => {
        let startDate = undefined;
        let endDate = undefined;

        setValue(evt.target.value);
        if (evt.target.value === 'd') {
            endDate = moment().subtract(1, 'day');
            startDate = moment(endDate).subtract(30, 'day');
        }
        else if (parseInt(evt.target.value) >= 1 && parseInt(evt.target.value) <= 12) {
            startDate = moment([moment().year(), parseInt(evt.target.value) - 1, 1]);
            endDate = (parseInt(evt.target.value) - 1) < moment().month() ? moment(startDate).endOf('month') : moment().subtract(1, 'day');
        }
        fetchData(startDate, endDate);

    }

    const getOprtions = () => {
        return COLLECTIONS_OPTIONS.filter(x => ((parseInt(x.value) - 1) < moment().month() || ((parseInt(x.value) - 1) === moment().month() && moment().date() > 1) || x.value === 'd'));
    }

    useEffect(() => {
        var dt = moment().subtract(1, 'day');
        fetchData(moment(dt).subtract(30, 'day'), dt);
    }, []);

   
    return (
        <BaseCardHome>
            <Row style={{ padding: '20px 30px' }}>
                <Col sm={12} md={12} lg={12} xl={12}>
                    <Card.Title>
                        <div style={{display:'inline-block'}}>
                            <TitleCard>Utilisateurs Actifs</TitleCard>
                        </div>
                        <div style={{ display: 'inline-block', float: 'right', marginTop: '-5px', borderRadius: "30px !important" }}>
                            <Dropdown
                                mode="select"
                                label=""
                                options={getOprtions()}
                                onChange={onChange}
                                selectedValue={value}
                            />
                        </div>
                    </Card.Title>
                </Col>
            </Row>

            <Row>
                <Col sm={12} md={12} lg={12} xl={10}>
                    {
                        status === requestStatus.REQUEST_PENDING &&
                        <LoadingIndicatorInApp style={{ marginTop: '130px' }}/>
                    }

                    {
                        status != requestStatus.REQUEST_PENDING && stats && stats.length > 0 &&
                        <ChronoXYPlot
                            id={"gphConnectedUsers"}
                            isAxisYFixed={true}
                            height={320}
                            isLine={true}
                            colorStroke={"violet"}
                            data={translateData(stats)}
                            tickLabelAngle={-70}
                            widthAxisY={'65px'}
                            marginAxisY={'0px 0px 0px -10px'}
                            margin={{ left: 70, bottom: 90 }}
                            renderCrossHairText={CrossHairText}
                            defaultScrollXPositionEnd={true}
                        />
                    }

                 
                </Col>
                <Col sm={12} md={12} lg={12} xl={2}>

                    {
                        status != requestStatus.REQUEST_PENDING && stats && stats.length > 0 &&
                        <div>
                            <div style={{padding:0, paddingBottom:'15px'}}>
                                <CardStatisticUserConnectedLegend
                                    color='blue'
                                    text={'30 jours'}
                                    total={stats[0].avg30Days}
                                    avg={stats[0].percentageAvg30Days}

                                        />
                            </div>

                            <div style={{ padding:0, paddingBottom: '15px' }}>
                                <CardStatisticUserConnectedLegend
                                    color='violet'
                                    text={'7 jours'}
                                    total={stats[0].avg7Days}
                                    avg={stats[0].percentageAvg7Days}

                                />
                            </div>

                            <div style={{ padding:0, paddingBottom: '15px' }}>
                                <CardStatisticUserConnectedLegend
                                    color='orange'
                                    text={'1 jour'}
                                    total={stats[0].avgDays}
                                    avg={stats[0].percentageAvgDays}

                                />
                            </div>
                            </div>
                    }

                </Col>
            </Row>

        
        </BaseCardHome>
    )
};

export default CardStatisticForUserConnected;