import { sendGetRequest, sendPutRequest, sendPostRequest } from '../auth/apiEffects'
import { ChronoUserViewModel } from '../types/viewmodels/ChronoUserViewModel'

export const getChronoUserList = async (queryParams): Promise<false | ChronoUserViewModel[] | undefined> => {
    try {

        const searchQueryParams = {
            email: queryParams.email,
            startDate: queryParams.startDate,
            endDate: queryParams.endDate,
            isActive: queryParams.isActive,
            isEmailSubscribed: queryParams.isEmailSubscribed,
            isWaitingConfirmation: queryParams.isWaitingConfirmation
        }

        const searchQueryParamString = new URLSearchParams(searchQueryParams).toString()
        const response = await sendGetRequest<ChronoUserViewModel[]>(`/users/getChronoUsers?${searchQueryParamString}`)
        return response

    } catch (e) {
        console.log(e);
        return undefined;
    }
}

export const activateChronoUser = async (param): Promise<Boolean | undefined> => {
    try {
        const response = await sendPutRequest<Boolean>(`/users/updateChronoUserFlag/${param.userId}`);
        return response

    } catch (e) {
        console.log(e);
        return false;
    }
}

export const resendEmailConfirmationChronoUser = async(param): Promise<Boolean | undefined> => {
    try {
        const response = await sendPostRequest<Boolean>(`/users/resendChronoUserEmailConfirmation/${param.email}`);
        return response

    } catch (e) {
        console.log(e);
        return false;
    }
}
