import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../actions/types/types";
import * as consoleApi from "../../../api";
import addResource from "../../actions/addResource";
import addUIItem from "../../actions/addUIItem";
import * as status from "../../sagas/requestStatus";
import * as keys from "../../keys";

export function* watchApplicationLogConfigurations() {
  yield takeLatest(types.GET_APPLICATION_LOG_CONFIGURATIONS_REQUESTED, doApplicationLogConfiguration);
}

export function* doApplicationLogConfiguration(action) {
  try {
    yield put(addUIItem( status.REQUEST_PENDING, keys.APPLICATION_LOG_CONFIGURATIONS_REQUEST_STATUS));
    const applicationLogConfigurations = yield call(consoleApi.getApplicationLogConfigurations);
    
    if (!applicationLogConfigurations) {
      throw new Error("Unable to get applications logs.");
    }

    yield put(addResource(applicationLogConfigurations, keys.APPLICATION_LOG_CONFIGURATIONS ));
    yield put(addUIItem(status.REQUEST_SUCCEEDED, keys.APPLICATION_LOG_CONFIGURATIONS_REQUEST_STATUS));
  } catch (e) {
    yield put(addUIItem(status.REQUEST_FAILED, keys.APPLICATION_LOG_CONFIGURATIONS_REQUEST_STATUS ));
  }
}
