import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import GenericSelect from "../GenericSelect";
import { useChronoApi } from "../../../../auth/apiEffects";
import { getRoutesUrl } from "../../../../auth/fetchUrlHelper";
import { sortAlphaNum } from "../../helper";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectRoutes = (props) => {
    const { data: routes } = useChronoApi(getRoutesUrl(props.data ? '' : props.agencyCode));
    const [sortedRoutes, setSortedRoutes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
    }, [props.agencyCode]);

    useEffect(() => {
        var donnees = props.data ? props.data : routes;
        donnees = donnees.filter((v, i, a) => a.findIndex(t => (t.routeId === v.routeId)) === i);

        setSortedRoutes(sortAlphaNum(donnees, 'shortName'));
        setIsLoading(false);
    }, [routes, props.data]);

    return (
        <div style={{ width: '100%' }}>
            {
                !isLoading && isLoading === false &&
                <GenericSelect title="Ligne" isDisabled={!props.agencyCode} {...props}>
                    {
                        !props.hideAllValue &&
                        <option key="all" value="" />
                    }
                    {
                        sortedRoutes.map((route: any) =>
                            <option key={route.routeId} value={route.routeId}>{route.shortName} - {route.longName}</option>
                        )}
                </GenericSelect >
            }
            {
                isLoading === true &&
                <InputGroup>
                    <InputGroup.Prepend style={{ minWidth: 130 }}>
                        <InputGroup.Text style={{ minWidth: 130 }}>
                            {"Ligne"}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        as="div"
                        style={{ paddingTop: '10px', paddingLeft: '5px' }}
                    >
                        <FontAwesomeIcon icon={faSpinner} spin={true} />
                    </FormControl>
                </InputGroup>
            }
        </div>
    );
};

export default SelectRoutes;
