import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";

// type TChronoMultiSelect = {
//     allowSelectAll?: boolean;
//     components?: any;
//     options: {label: string; value: string}[];
//     allOption: {label: string; value: string}[];
//     defaultValue: any;
//     onChange: (ev: any) => void;
//     isDisabled?: boolean;
//     isSearchable?: boolean;
//     hasOptionWithGroup?: boolean;
//     closeMenuOnSelect?: boolean;
// }

const ChronoMultiSelect = (props) => {
    const { allowSelectAll, components, options, allOption, onChange, isDisabled,
        isSearchable, hasOptionWithGroup = false, closeMenuOnSelect = true, defaultValue = [] } = props;

    if (allowSelectAll) {
        return (
            <ReactSelect
                isDisabled={isDisabled}
                isSearchable={isSearchable}
                defaultValue={defaultValue}
                style={{ border: 'none' }}
                {...props}
                options={[allOption, ...options]}
                components={components}
                closeMenuOnSelect={closeMenuOnSelect}
                onChange={(selected: Array<{ label: string; value : string }>) => {
                    if (selected === null) {
                        return onChange([]);
                    }

                    if (selected.length > 0 && selected[selected.length - 1].value === allOption.value) {
                        if (hasOptionWithGroup) {
                            var optionList = [];

                            options.forEach(function (optionBloc: any) {
                                optionList = optionList.concat(optionBloc.options);
                            });

                            return onChange(optionList);
                        }
                        else
                            return onChange(options);
                    }
                    return onChange(selected);
                }}
            />
        );
    }

    return <ReactSelect {...props} />;
};

ChronoMultiSelect.propTypes = {
    isDisabled: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isMulti: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool,
    formatGroupLabel: PropTypes.any,
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    }),
    components: PropTypes.shape({
        MultiValue: PropTypes.any,
        Option: PropTypes.any
    }),
    hideSelectedOptions: PropTypes.bool,
    styles: PropTypes.any,
    hasOptionWithGroup: PropTypes.bool,
};

ChronoMultiSelect.defaultProps = {
    allOption: {
        label: "Select all",
        value: "*"
    }
};

export default ChronoMultiSelect;