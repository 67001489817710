import * as yup from "yup";
import moment from 'moment';

//validations
export const validationFormSchema = yup.object({
    start: yup.date().required("La date de début ne peut être vide")
        .test("is-startDate-greater-than-now", "La date de début ne peut être antérieure", function (value) {
            return moment(value).isSameOrAfter(moment(), 'day')
        }),
    end: yup.date().required("La date de fin ne peut être vide")
        .test("is-endDate-greater-than-now", "La date de fin ne peut être antérieure", function (value) {
            return moment(value).isSameOrAfter(moment(), 'day')
        })
        .test("is-endDate-greater-than-startDate", "La date de fin ne peut être antérieure", function (value) {
            const { start } = this.parent;
            return moment(start).isBefore(moment(value));
        }),
    agencyCode: yup.string().required("L'organisation ne peut être vide"),
    textFR: yup.string().required("Le texte ne peut être vide")
});
