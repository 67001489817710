export const OS_OPTIONS = [
    { label: 'Tous les os', value: '' },
    { label: 'Android', value: 'ANDROID' },
    { label: 'iOS', value: 'IOS' }
];

export const STATUS_OPTIONS = [
    { label: 'Tous', value: '' },
    { label: 'Actif', value: true },
    { label: 'Inactif', value: false }
];


export const CRITICALITY_OPTIONS = [
    { label: 'Tous' , value: ''},
    { label: 'Warning', value: 'WARNING' },
    { label: 'Error', value: 'ERROR' },
    { label: 'Critical', value: 'CRITICAL' }
];

export const LOGDATEFILTER_OPTIONS = [
    { label: 'Dernière heure', value: 'LASTHOUR' },
    { label: 'Dernière journée', value: 'LASTDAY' },
    { label: 'Dernière semaine', value: 'LASTWEEK' },
    { label: 'Dernier mois', value: 'LASTMONTH' },
    { label: 'Personnalisée', value: 'CUSTOM' }
];

export const UNIT_TIME = [
    { label: 'Heures', value: 'Heures' },
    { label: 'Jours', value: 'Jours' },
    { label: 'Semaines', value: 'Semaines' },
    { label: 'Mois', value: 'Mois' }
];

