import React, { Fragment, useState, useEffect } from 'react';
import GenericTableCell from "../common/generic/table/cell/GenericTableCell";
import GenericTable, { GenericTableRow } from "../common/generic/table/GenericTable";
import GenericTableBodyWithPagination from "../common/generic/table/GenericTableBodyWithPagination";
import GenericTableHeadSingleRow from "../common/generic/table/GenericTableHeadSingleRow";
import { sendGetRequest } from "../../auth/apiEffects";
import { LoadingIndicator } from "../LoadingIndicator";
import { Row, Col } from 'react-bootstrap';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { getCsvData, createDownloadLink, convertDateToEasterTime } from "../common/helper";

interface IReportGetRequest {
    validations: IValidation[];
    sales: IValidation[];
    rapportVenteId: string;
    nom: string;
    dateCreation?: string;
    isDownloading: boolean;
    dateDebut: string;
    dateFin: string;
}

interface IMapRowValidation {
    dateHeureAchat: string;
    activationDate: string;
    titleFR: string;
    titleEN: string;
    tarif: string;
    quantite: string;
    amount: string;
    nbQuantite: string;
    courriel: string;
}

interface IValidation {
    Date: string;
    Heure: string;
    'Nom titre de transport': string;
    'Tarif': string;
    'Quantité': string;
    'ID du Compte Chrono': string;
}


const AllReport = ({ searchParams, validated }) => {
    const [reports, setReports] = useState<IReportGetRequest[]>([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        setIsLoading(true);
        async function fetchData() {
            const results: IReportGetRequest[] | undefined | false = await sendGetRequest(`/rapport/liste?${searchParams}`);
            const formattedResult = results ? results.map((obj) => {
                return { ...obj, isDownloading: false };
            }) : [];

            setReports(formattedResult);
            setIsLoading(false);
        }
        fetchData();
    }, [searchParams]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    function mapRowValidation(row?: IMapRowValidation)
    {
        return {
            'Date': row ? moment(row.activationDate).format('YYYY-MM-DD') : '',
            'Heure': row ? moment(row.activationDate).format('HH:mm') : '',
            'Nom titre de transport': row ? row.titleFR + ' / ' + row.titleEN : '',
            'Tarif': row ? row.tarif : '',
            'Quantité': row ? row.nbQuantite : '',
            'ID du Compte Chrono': row ? row.courriel : ''
            };
    }

    function mapRowSales(row?: IMapRowValidation) {
        return {
            'Date': row ? moment(row.dateHeureAchat).format('YYYY-MM-DD') : '',
            'Heure': row ? moment(row.dateHeureAchat).format('HH:mm') : '',
            'Nom titre de transport': row ? row.titleFR + ' / ' + row.titleEN : '',
            'Tarif': row ? row.tarif : '',
            'Quantité': row ? row.quantite : '',
            'Montant': row ? row.amount : '',
            'ID du Compte Chrono': row ? row.courriel : ''
        };
    }

    function getDataValidations(data) {
        var validations: IValidation[] = [];
 
        data.forEach(row => {
            validations.push(mapRowValidation(row));
        });

        return validations;
    }

    function getDataSales(data) {
        var sales: IValidation[] = [];
        data.forEach(row => {
            sales.push(mapRowSales(row));
        });

        return sales;
    }

    async function exportCsv(reportId, dtDebut, dtFin) {
        var reportData: IReportGetRequest | undefined | false = await sendGetRequest('/rapport/' + reportId.toString());

        var csvValidations = reportData && reportData.validations && reportData.validations.length > 0 ? getCsvData(getDataValidations(reportData.validations)) : getCsvData([mapRowValidation()]);
        var csvSales = reportData && reportData.sales && reportData.sales.length > 0 ? getCsvData(getDataSales(reportData.sales)) : getCsvData([mapRowSales()]);
        var startDate = moment(dtDebut).format('YYYYMMDD');
        var endDate = moment(dtFin).format('YYYYMMDD');

        createDownloadLink(csvValidations,  "Rapport_activations_" + startDate + "_au_" + endDate );
        createDownloadLink(csvSales, "Rapport_vente_" + startDate + "_au_" + endDate);
    }

    async function onDownload(reportId, dtDebut, dtFin) {
        const reportList = [...reports];
        const report = reportList.find(x => x.rapportVenteId === reportId);
        if (!report)
            return;

        report.isDownloading = true;
        setReports(reportList);

        await exportCsv(reportId, dtDebut, dtFin);

        report.isDownloading = false;
        setReports([...reportList]);
    }


    return (
        <Fragment>
            {
                reports.length > 0 &&
                <GenericTable>
                    <GenericTableHeadSingleRow>
                        <th>Nom rapport</th>
                        <th>Date et heure de génération</th>
                        <th>Fichier</th>
                    </GenericTableHeadSingleRow>
                    {
                       
                        <GenericTableBodyWithPagination dataPerRow={3}>
                            {
                                reports.map(report => {

                                    return (
                                        <GenericTableRow key={report.rapportVenteId}>
                                            <GenericTableCell>{report.nom}</GenericTableCell>
                                            <GenericTableCell>{report.dateCreation ? convertDateToEasterTime(report.dateCreation, 'YYYY-MM-DD HH:mm') : ''}</GenericTableCell>
                                            <GenericTableCell> {report.isDownloading === true ? <span><FontAwesomeIcon icon={faSpinner} spin={true} /></span> : <span onClick={() => onDownload(report.rapportVenteId, report.dateDebut, report.dateFin)} style={{ cursor: 'pointer' }}><FontAwesomeIcon icon={faDownload} /></span>} </GenericTableCell>
                                        </GenericTableRow>
                                    );
                                })
                            }
                        </GenericTableBodyWithPagination>
                        }

                </GenericTable>
            }
            {
                (!reports || reports.length === 0) && validated &&
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <h3>Aucun résultat</h3>
                    </Col>
                </Row>
            }

        </Fragment>
    )
}

export default AllReport;