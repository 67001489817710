import React, { Fragment, useEffect, useContext, useState } from 'react';
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import { Col, Row, Card } from "react-bootstrap";
import AgenciesDropDown from "../../statistics/global/AgenciesDropDown";
import ChronoContext from "../../../context/ChronoContext";
import styled from "styled-components";
import MappingStatutAchalandage from "./MappingStatutAchalandage";
import MappingIconeLegende from "./MappingIconeLegende";
import ChronoCard from "../../common/ChronoCard";
import { getSimplifiedAgencyLabels } from "../../common/helper";


export const Header = styled.div`
    font-size: 16px;
    font-weight: bold;
    padding: 5px 0px 15px 5px;
    text-align: left;
    background-color:#EFF1F1;
    height:50px;
`

const AchalandageConfig = () => {
    const staticData = useContext(ChronoContext).staticData;
    const userAgencies = staticData.user.agencies;

    const [agencies, setAgencies] = useState([]);
    const [currentAgency, setCurrentAgency] = useState('');

    useEffect(() => {
        const formattedAgencies = getSimplifiedAgencyLabels(userAgencies); 
        setAgencies(formattedAgencies);
        setCurrentAgency(formattedAgencies[0]);
    }, [userAgencies]);


    return (
        <Fragment>
            <TopBar title="Configuration de l'achalandage" />
            <ContentBelowTopBar>
                <ChronoCard>
                    <Card>
                        <Card.Title>
                            <Header>
                                <Row>
                                    <Col xl={6} md={6} xs={8}>
                                        <div style={{ marginTop: '10px' }}>
                                            Mapping statuts GTFS vers statuts Chrono
                                        </div>
                                    </Col>
                                    <Col xl={6} md={6} xs={4}>
                                        <div style={{ textAlign: 'right', marginTop: '-2px' }}>
                                            <AgenciesDropDown
                                                cssClass="custom-dropdown-js"
                                                id="ddlAgencies"
                                                currentAgencyLabel={currentAgency}
                                                setCurrentAgencyLabel={setCurrentAgency}
                                                agencies={agencies}
                                                alwaysShownDropDown={true}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Header>
                        </Card.Title>
                        <Card.Body style={{minHeight:'85px'}}>
                            <MappingStatutAchalandage searchParams={currentAgency} />
                        </Card.Body>
                    </Card>

                    <Card style={{marginTop:'10px'}}>
                        <Card.Title>
                            <Header>
                                <Row>
                                    <Col xl={12} md={12} xs={12}>
                                        <div style={{ marginTop: '10px' }}>
                                            Mapping des icônes et de la légende
                                        </div>
                                    </Col>
                                </Row>
                            </Header>
                        </Card.Title>
                        <Card.Body style={{ minHeight: '85px' }}>
                            <MappingIconeLegende/>
                        </Card.Body>
                    </Card>
                </ChronoCard>
            </ContentBelowTopBar>
        </Fragment>
    );
   
};

export default AchalandageConfig;
