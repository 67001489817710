import React, { Fragment, FC, ReactElement, useEffect, useState } from "react";
import { NotificationFilter } from './components/NotificationFilter'
import NotificationList from './components/NotificationList'
import { Card, Col, Button } from 'react-bootstrap';
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import SvgIcon from "../../common/SvgIcon";
import { useNotificationService } from "../../../service/communication/useNotificationService";
import CommunicationFormSlider from "../CommunicationFormSlider";
import NotificationBaseContainer from "./components/NotificationBaseContainer";

const NotificationPage: FC = (): ReactElement => {

    const notificationService = useNotificationService();
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [entity, setEntity] = useState<any>({});
    const [hideGridLoader, setHideGridLoader] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            notificationService.cleanCache();
        }
    }, []);

    return (
        <Fragment>
            <TopBar title="Notifications">
                <Col>
                    <Button onClick={() => {
                        setEntity(notificationService.getNewNotification());
                        setHideGridLoader(true);
                        setOpenForm(true);
                    }}
                        style={{ float: 'right' }}
                        disabled={openForm}>
                        Nouvelle notification
                        <SvgIcon icon="plus" />
                    </Button>
                </Col>
            </TopBar>
            <ContentBelowTopBar>
                <NotificationFilter />
                <Card className='generic-card'>
                    <NotificationList
                        onClickRow={(item, index) => {
                            const model = notificationService.mapToForm(item);
                            setEntity(model);
                            setHideGridLoader(true);
                            setOpenForm(true);
                        }}
                        hideGridLoader={hideGridLoader}
                    />
                </Card>

                <CommunicationFormSlider  open={openForm} handleClose={() => {
                    setHideGridLoader(false);
                    setOpenForm(false);
                }}>
                    <NotificationBaseContainer entity={entity}/>
                </CommunicationFormSlider>
            </ContentBelowTopBar>
        </Fragment>
    )
}

export default NotificationPage