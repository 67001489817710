import React, { Fragment } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

interface IData {
    title?: string;
    yesterday?: string;
    today?: string;
}
interface ICardTicket {
    data?: IData
}
export const CardTicket: React.FC<ICardTicket> = ({ data }) => {
    
    return (
        <Fragment>
            {
                data && 
                <Card style={{
                    margin: '20px 0px',
                    padding: '20px',
                    width: '100%',
                    borderRadius: '20px'
                }}>
                    <div style={{ textAlign: 'center', fontSize: '18px', paddingBottom: '5px' }}>{data.title && data.title !== '' ? data.title : '-'}</div>
                    <div>
                        <Row>
                            <Col xl={6} md={6} sm={6} xs={6}>
                                <div style={{ textAlign: 'center', fontSize: '16px', color: 'gray', paddingBottom: '5px' }}>Hier</div>
                                <div style={{ textAlign: 'center', fontSize: '28px', color: 'black', fontWeight: 'bold' }}>{data.yesterday && data.yesterday !== '' ? data.yesterday : '-'}</div>
                            </Col>
                            <Col xl={6} md={6} sm={6} xs={6}>
                                <div style={{ textAlign: 'center', fontSize: '16px', color: 'gray', paddingBottom: '5px' }}>Aujourd'hui</div>
                                <div style={{ textAlign: 'center', fontSize: '28px', color: 'black', fontWeight: 'bold' }}>{data.today && data.today !== '' ? data.today : '-'}</div>
                            </Col>
                        </Row>
                    </div>
                </Card>
            }

            {
                !data &&
                <Row>
                    <Col style={{ textAlign: 'center', paddingTop: 10 }}>
                        <h3>Aucun résultat</h3>
                    </Col>
                </Row>
            }
        </Fragment>
    );
};

export default CardTicket;
