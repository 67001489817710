/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { PopupModal } from '../../../lib/ui/index'
import ConfigurationForm from './ConfigurationForm'
import * as constantesConfig from "../../../redux/keys/applicationLogs/configurationLogs";
import * as statusRequest from "../../../redux/sagas/requestStatus";
import  useUI  from "../../../lib/hooks/useUI";
import  useResources  from "../../../lib/hooks/useResources";
import addUIItem from "../../../redux/actions/addUIItem";
import { useDispatch } from 'react-redux';


const PopupMobileConfig = ({id, data, showModal, showFooter, hideShowModal, rowIndex }) => {

    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const status = useUI(constantesConfig.CONFIGURATION_APP_TO_SAVE + '_STATUS' + id);
    const element = useResources(constantesConfig.CONFIGURATION_APP_TO_SAVE + id);
    const list = useResources(constantesConfig.CONFIGURATION_APP_LOGS);

    const dispatch = useDispatch();

    useEffect(() => {
        if (status === statusRequest.REQUEST_SUCCEEDED && isSaving && element && element != null && element.logMobileConfigurationId !== undefined) {
            if (rowIndex >= 0)
                list[rowIndex] = { ...element };
            else
                dispatch(addUIItem(true, constantesConfig.CONFIGURATION_APP_LOGS_REFRESH));

            setIsSaving(false);
            hideShowModal();
        }

        if (status === statusRequest.REQUEST_FAILED && isSaving) {
            setErrorMessage("Une erreur est survenue durant l'opération.  Assurez-vous qu'un autre log avec le même ID, le même OS et la même catégorie n'existe pas déjà.")
            setIsSaving(false);
            dispatch(addUIItem('', constantesConfig.CONFIGURATION_APP_TO_SAVE + '_STATUS' + id));
        }

    }, [status, element]);


    return (
        <PopupModal
            id={id}
            title="Créer / éditer"
            lblYes="Sauvegarder"
            bodyStyle={{ minHeight: '80px' }}
            show={showModal}
            showFooter={showFooter}
            isSaving={isSaving}
            cssClass={'js-popup-user'}
            headerStyle={{ width: '800px' }}
            errorMessage={errorMessage}
            onClickYes={() => {
                setErrorMessage('');
                setIsSaving(true);
            }}

            onHide={() => {
                setErrorMessage('');
                hideShowModal();
            }
            }>
            <ConfigurationForm cancelSaving={() => { setIsSaving(false) }} data={data} isSaving={isSaving} id={id}/>
        </PopupModal>
    )
};

export default PopupMobileConfig
