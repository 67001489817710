import React, { Fragment, useState } from "react";
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import { Row, Col, Button } from "react-bootstrap";
import ChronoCard from "../../common/ChronoCard";
import styled from "styled-components";
import StatsTickets from "./StatsTickets";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericDate from "../../common/generic/GenericDate";
import moment from "moment";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { getFormattedDate } from "../../common/helper";
import { DATE_FORMAT } from "../../communication/CommunicationUtils";
import zipcelx from "zipcelx-es5";
import { sendGetRequest } from "../../../auth/apiEffects";

interface IData {
  value: string | number;
  type:  string;
}

interface IReportedData {
  timeRange: string | number;
  ticketSoldAmount:  string;
  activationAmount: number;
}

const ErrorMessage = styled.p`
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;

export const FormRow = styled(Row)`
  margin-top: 30px;
  margin-bottom: 20px;
`;

const BilletterieStatistique: React.FC = () => {
  const [validated, setValidated] = useState(true);
  const dtDebut = moment().subtract(1, "month").format("YYYY-MM-DD");
  const dtFin = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(dtDebut);
  const [endDate, setEndDate] = useState(dtFin);
  const [currentDates, setCurrentDates] = useState({
    startDate: dtDebut,
    endDate: dtFin,
  });
  const [reportDate, setReportDate] = useState(
    getFormattedDate(moment().subtract(1, "days"), DATE_FORMAT)
  );

  const onReportDateChange = (date) => {
    setReportDate(getFormattedDate(moment(date), DATE_FORMAT));
  };

  async function onDownload(): Promise<void> {
    var reportData: IReportedData[] | boolean | undefined = await sendGetRequest(
      "/statistics/ticketStatistic/summaryDownload/" + reportDate
    );

    if (reportData && (reportData as IReportedData[])?.length > 0) {
      var excelData: IData[][] = [];

      var headers = [
        {
          value: "",
          type: "string",
        },
        {
          value: "Ventes",
          type: "string",
        },
        {
          value: "",
          type: "string",
        },
        {
          value: "Activations",
          type: "string",
        },
      ];

      excelData.push(headers);

      (reportData as IReportedData[]).forEach((x) => {
        var row: IData[] = [];
        row.push({
          value: x.timeRange,
          type: "string",
        });
        row.push({
          value: x.ticketSoldAmount,
          type: "number",
        });
        row.push({
          value: x.timeRange,
          type: "string",
        });
        row.push({
          value: x.activationAmount,
          type: "number",
        });

        excelData.push(row);
      });

      var excelConfig = {
        filename: "Rapport_détaillé_du_" + reportDate,
        sheet: {
          data: excelData,
        },
      };

      zipcelx(excelConfig);
    }
  }

  const onStartDateChange = (date) => {
    const formatDate = moment(date).format("YYYY-MM-DD");
    const valide = !moment(endDate).isBefore(moment(formatDate));
    setValidated(valide);
    setStartDate(formatDate);
  };

  const onEndDateChange = (date) => {
    const formatDate = moment(date).format("YYYY-MM-DD");
    const valide = !moment(formatDate).isBefore(moment(startDate));
    setValidated(valide);
    setEndDate(formatDate);
  };

  function getData() {
    const valide = !moment(endDate).isBefore(moment(startDate));
    setValidated(valide);

    if (valide) setCurrentDates({ startDate: startDate, endDate: endDate });
  }

  return (
    <Fragment>
      <TopBar title="Statistiques billetterie en ligne" />
      <ContentBelowTopBar>
        <div>
          <Row className="justify-content-xl-around">
            <Col xl={8} lg={6} md={7}>
              <ChronoCard style={{ minHeight: "143px" }}>
                <FormRow className="m-0 my-auto">
                  <Col
                    xl={5}
                    lg={12}
                    md={12}
                    className="my-auto"
                    style={{ maxWidth: "347px" }}
                  >
                    <GenericDate
                      isRequired
                      label="Début"
                      date={startDate}
                      onDateChange={onStartDateChange}
                      maxDate={moment().toDate()}
                      width={80}
                    />
                  </Col>
                  <Col
                    xl={5}
                    lg={12}
                    md={12}
                    className="my-auto pt-xl-0 pt-lg-3 pt-md-2"
                    style={{ maxWidth: "347px" }}
                  >
                    <GenericDate
                      isRequired
                      label="Fin"
                      date={endDate}
                      onDateChange={onEndDateChange}
                      maxDate={moment().toDate()}
                      width={80}
                    />
                    {!validated ? (
                      <ErrorMessage>
                        La date de fin ne peut pas être avant la date de début
                      </ErrorMessage>
                    ) : null}
                  </Col>
                  <Col
                    xl={2}
                    md={12}
                    className="my-auto pt-xl-0 ml-xl-0  pt-lg-3 ml-lg-3 pl-lg-0 pr-lg-0 pt-md-3 pl-md-3 pt-sm-3"
                  >
                    <Button
                      onClick={getData}
                      style={{
                        color: "white",
                        paddingLeft: "7px",
                        paddingRight: "7px",
                      }}
                    >
                      <FontAwesomeIcon icon={faChartLine} /> Afficher
                    </Button>
                  </Col>
                </FormRow>
              </ChronoCard>
            </Col>
            <Col xl={"auto"} lg={6} md={7}>
              <ChronoCard style={{ minHeight: "143px" }}>
                <FormRow className="m-0">
                  <Col>
                    <div style={{ maxWidth: "347px" }}>
                      <GenericDate
                        isRequired
                        label="Date"
                        date={reportDate}
                        onDateChange={onReportDateChange}
                        width={80}
                      />
                    </div>
                    <div className="pt-3">
                      <Button
                        onClick={() => onDownload()}
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon icon={faDownload} /> Télécharger le
                        rapport du jour sélectionné
                      </Button>
                    </div>
                  </Col>
                </FormRow>
              </ChronoCard>
            </Col>
          </Row>
        </div>

        <StatsTickets currentDates={currentDates}></StatsTickets>
      </ContentBelowTopBar>
    </Fragment>
  );
};

export default BilletterieStatistique;
