import { sendGetRequest, sendPostRequest, sendDeleteRequest, sendPutRequest } from "../auth/apiEffects";

export const genericApi = {
    get: async <T>(payload): Promise<T | undefined | false> => {
        let uri = payload.requestDefinition.url(payload.extendedUrl || payload.extendedUrl);
        if (payload.searchQueryParams) { 
            const searchQueryParamString = new URLSearchParams(payload.searchQueryParams).toString();
            uri = uri.concat(`?${searchQueryParamString}`);
        }
        return (await sendGetRequest(uri)) as unknown as Promise<T | undefined | false>;
    },
    post: async <T>(payload): Promise<T | undefined | false> => {
        let uri = payload.requestDefinition.url(payload.extendedUrl || payload.extendedUrl);
        const { body } = payload;
        return (await sendPostRequest(uri, body)) as unknown as Promise<T | undefined | false>;
    },
    put: async <T>(payload): Promise<T | undefined | false> => {
        let uri = payload.requestDefinition.url(payload.extendedUrl || payload.extendedUrl);
        const { body } = payload;
        return (await sendPutRequest(uri, body)) as unknown as Promise<T | undefined | false>;
    },
    delete: async <T>(payload): Promise<T | undefined | false> => {
        console.log(payload)
        let uri = payload.requestDefinition.url(payload.extendedUrl || payload.extendedUrl);
        const { body } = payload;
        return (await sendDeleteRequest(uri, body)) as unknown  as Promise<T | undefined | false>;
    },
};
