/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactElement  } from 'react';
import ReactSlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

export const Slider: FC<any> = ({ isOpen, from, width, handleClose, cssClass, children }: any): ReactElement => {
   
    return (
        
        <ReactSlidingPane
            isOpen={isOpen}
            from={from}
            hideHeader={true}
            width={width}
            className={cssClass}
            onRequestClose={() => {
                if (handleClose)
                    handleClose();
            }}
        >
                {children}
        </ReactSlidingPane>
    )
}

export default Slider;

