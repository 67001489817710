import React from 'react';
import { useChronoApi } from "../../../auth/apiEffects";
import { LoadingIndicator } from "../../LoadingIndicator";
import styled from "styled-components";
import GenericTableCell from "../../common/generic/table/cell/GenericTableCell";
import GenericTableBodyWithPagination from "../../common/generic/table/GenericTableBodyWithPagination";
import GenericTable, { GenericTableRow } from "../../common/generic/table/GenericTable";
import GenericTableHeadSingleRow from "../../common/generic/table/GenericTableHeadSingleRow";

const CONTENT_MAX_SIZE = 75;

const LogsContainer = styled.div`
    padding: 20px 40px;
`;

const LogGtfsPage = () => {
    const {data: logGtfs, isLoading} = useChronoApi('/admin/gtfs/logs');

    const orderedLogs = logGtfs.sort((a, b) => a.date - b.date).reverse();
    
    if (isLoading) {
        return <LoadingIndicator />;
    }
    
    return (
        <LogsContainer>
            <h2>Afficher les logs GTFS</h2>
            <br />
            
            <GenericTable>
                <GenericTableHeadSingleRow>
                    <th>Date</th>
                    <th>Organisation</th>
                    <th>Message</th>
                </GenericTableHeadSingleRow>

                <GenericTableBodyWithPagination dataPerRow={3}>
                {
                    orderedLogs.map(log => {
                        const dateFormat = 'DD/MM/YYYY HH:mm';

                        return (
                            <GenericTableRow key={log.agencyCode}>
                                <GenericTableCell dateFormat={dateFormat}>{log.date}</GenericTableCell>
                                <GenericTableCell>{log.organisationCode}</GenericTableCell>
                                <GenericTableCell maxSize={CONTENT_MAX_SIZE}>{log.message}</GenericTableCell>
                            </GenericTableRow>
                        );
                    })
                }
                </GenericTableBodyWithPagination>
            </GenericTable>
        </LogsContainer>
    );
};

export default LogGtfsPage;
