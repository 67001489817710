/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './components/home/HomePage';
import StatisticsOptcPage from "./components/statistics/optc/StatisticsOptcPage";
import StatisticsFavorisPage from "./components/statistics/favoris/StatisticsFavorisPage";
import StatisticsRealTime from "./components/statistics/realTime/RealTimeStats";
import CartePage from './components/communication/carte/CartePage';
import MobileConfigPage from './components/admin/config/MobileConfigPage';
import ensureLogin from "./components/login/EnsureLogin";
import AdminMenu from './components/admin/AdminMenu';
import LogGtfsPage from './components/admin/gtfs/LogGtfsPage';
import UserProfilePage from './components/admin/user/UserProfilePage';
import UnauthorizedPage from "./components/errors/UnauthorizedPage";
import UsersPage from "./components/admin/user/UsersPage";
import ChronoUsersPage from "./components/support/ChronoUsersPage";
import AlertPage from "./components/communication/alert/AlertPage";
import NotificationPage from "./components/communication/notification/NotificationPage";
import RealtimePage from "./components/admin/realtime/RealtimePage";
import AchalandageConfig from "./components/admin/achalandage/ConfigPage";
import ResultComparer from "./components/debugTool/realtime/ResultComparer";
import { LoadingIndicator } from "./components/LoadingIndicator";


import {
    ROUTE_SUPPORT_CHRONO_USERS,
    ROUTE_ADMIN_GTFS_LOGS,
    ROUTE_ADMIN_GTFS_MENU,
    ROUTE_ADMIN_MOBILE_CONFIG,
    ROUTE_ADMIN_USERS,
    ROUTE_ADMIN_USER_PROFILE,
    ROUTE_COMMUNICATION_CARTES,
    ROUTE_COMMUNICATION_MESSAGES,
    ROUTE_HOME,
    ROUTE_COMMUNICATION_ALERTS,
    ROUTE_ADMIN_REALTIME,
    ROUTE_MONITORING_REALTIME,
    ROUTE_MONITORING_GTFS,
    ROUTE_STATS_USAGE,
    ROUTE_STATS_PLANIFICATIONS,
    ROUTE_STATS_FAVORIS,
    ROUTE_TICKETING_ACCOUNT,
    ROUTE_TICKETING_ORDER,
    ROUTE_TICKETING_REPORT,
    ROUTE_STATS_TICKETING,
    ROUTE_STATS_REALTIME,
    ROUTE_ACHALANDAGE_CONFIG,
    ROUTE_COMMUNICATION_NOTIFICATIONS,
    ROUTE_MONITORING_APPLICATION_LOGS,
    ROUTE_MONITORING_MOBILE_CONFIGURATION_LOGS,
    ROUTE_COMMUNICATION_CALENDAR,
    ROUTE_DEBUGTOOL_RESULTCOMPARER
} from "./routes";
import MonitoringRealtimePage from "./components/monitoring/Realtime/MonitoringRealtimePage";
import MonitoringGtfsPage from "./components/monitoring/Gtfs/MonitoringGtfsPage";
import ChronoContext from "./context/ChronoContext";
import { useChronoApi } from "./auth/apiEffects";
import StatisticsHeatMapPage from "./components/statistics/heatmap/StatisticsHeatMapPage";
import BilletterieStatistique from "./components/statistics/billetterie/BilletterieStatistique";
import { ROLE_CODENAME_ADMIN, isRoleAllowed, ROLE_CODENAME_SUPPORT, ROLE_CODENAME_USER, ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_EDITOR } from "./components/common/RoleHelper";
import { isUserRoleInArray } from "./components/common/RoleHelper";
import NoAccess from './components/common/NoAccess';
import BilletterieCompte from './components/billetterie/BilletterieCompte';
import BilletterieCommande from './components/billetterie/BilletterieCommande';
import BilletterieRapport from './components/billetterie/BilletterieRapport';
import ApplicationLogsPage from './components/monitoring/ApplicationLogs/ApplicationLogsPage'
import MobileConfigurationPage from "./components/monitoring/MobileLogConfiguration/ConfigurationPage";
import CalendarPage from "./components/communication/calendar/calendarPage";
import { MessagePage } from "./components/communication/message/MessagePage";


const SecuredRoute = ({ roleCodeName, component: Component, ...rest }) => {
    const staticData = useContext(ChronoContext).staticData;
    const userProfile = staticData.user.profile;
    const roleUser = staticData.user.profile.role;
    const role = staticData.roles[roleCodeName];
    const [allowed, setAllowed] = useState(0);

    useEffect(() => {
        if (userProfile && !userProfile.email) {
            setAllowed(0);
            return;
        }

        var isOK = isRoleAllowed(roleUser, role);
        setAllowed(isOK ? 1 : 2);
    }, [roleUser, role, userProfile]);

    if (allowed === 0)
        return <LoadingIndicator />;

    return (

        <Route {...rest} render={props => (
            allowed === 1
                ? <Component  {...props} />
                : <NoAccess {...props} />
        )} />
    );
};

const RouteWithOneAccess = ({ roleCodeNameArray: RoleCodeNameArray, component: Component, ...rest }) => {
    const staticData = useContext(ChronoContext).staticData;
    const roleUser = staticData.user.profile.role;
    const userProfile = staticData.user.profile;
    const userRoleName = Object.keys(staticData.roles).find(key => staticData.roles[key] === roleUser);
    const [allowed, setAllowed] = useState(0);

    useEffect(() => {
        if (!userRoleName || !userProfile.email) {
            setAllowed(0);
            return;
        }

        var isOK = isUserRoleInArray(userRoleName, RoleCodeNameArray);
        setAllowed(isOK ? 1 : 2);
    }, [userRoleName, userProfile]);

    if (allowed === 0)
        return <LoadingIndicator />;

    return (
        <Route {...rest} render={props => (
            allowed === 1
                ? <Component  {...props} />
                : <NoAccess {...props} />
        )} />
    );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
    const staticData = useContext(ChronoContext).staticData;
    const roleUser = staticData.user.profile.role;
    const userProfile = staticData.user.profile;
    const roleAdmin = staticData.roles[ROLE_CODENAME_ADMIN];
    const [allowed, setAllowed] = useState(0);

    useEffect(() => {
        if (!userProfile.email) {
            setAllowed(0);
            return;
        }

        var isOK = isRoleAllowed(roleUser, roleAdmin);
        setAllowed(isOK ? 1 : 2);
    }, [roleUser, userProfile]);

    if (allowed === 0)
        return <LoadingIndicator />;

    return (

        <Route {...rest} render={props => (
            allowed === 1
                ? <Component  {...props} />
                : <NoAccess {...props} />
        )} />
    );
};

const InternalRoute = ({ component: Component, ...rest }) => {
    const staticData = useContext(ChronoContext).staticData;
    const userProfile = staticData.user.profile;
    const [allowed, setAllowed] = useState(0);

    useEffect(() => {
        setAllowed(userProfile.isInternalTeam ? 1 : 2);
    }, [userProfile]);

    if (allowed === 0)
        return <LoadingIndicator />;

    return (
        <Route {...rest} render={props => (
            allowed === 1
                ? <Component  {...props} />
                : <NoAccess {...props} />
        )} />
    );
};

const AppRouting = () => {
    return (
        <Layout>
            <Switch>
                <Route exact path={ROUTE_HOME} component={HomePage} />

                <SecuredRoute exact roleCodeName={ROLE_CODENAME_USER} path={ROUTE_COMMUNICATION_MESSAGES()} component={MessagePage} />
                <PrivateRoute exact path={ROUTE_COMMUNICATION_CARTES()} component={CartePage} />
                <RouteWithOneAccess exact roleCodeNameArray={[ROLE_CODENAME_EDITOR, ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_ADMIN]} path={ROUTE_COMMUNICATION_ALERTS()} component={AlertPage} />
                <PrivateRoute exact path={ROUTE_COMMUNICATION_NOTIFICATIONS()} component={NotificationPage} />
                <SecuredRoute exact roleCodeName={ROLE_CODENAME_USER} path={ROUTE_COMMUNICATION_CALENDAR} component={CalendarPage} />

                <SecuredRoute exact roleCodeName={ROLE_CODENAME_USER} path={ROUTE_STATS_USAGE} component={StatisticsOptcPage} />
                <SecuredRoute exact roleCodeName={ROLE_CODENAME_USER} path={ROUTE_STATS_PLANIFICATIONS} component={StatisticsHeatMapPage} />
                <SecuredRoute exact roleCodeName={ROLE_CODENAME_USER} path={ROUTE_STATS_FAVORIS} component={StatisticsFavorisPage} />
                <SecuredRoute exact roleCodeName={ROLE_CODENAME_USER} path={ROUTE_STATS_REALTIME} component={StatisticsRealTime} />
                <RouteWithOneAccess exact roleCodeNameArray={[ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_ADMIN]} path={ROUTE_STATS_TICKETING} component={BilletterieStatistique} />

                <RouteWithOneAccess exact roleCodeNameArray={[ROLE_CODENAME_SUPPORT, ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_ADMIN]} path={ROUTE_TICKETING_ACCOUNT} component={BilletterieCompte} />
                <RouteWithOneAccess exact roleCodeNameArray={[ROLE_CODENAME_SUPPORT, ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_ADMIN]} path={ROUTE_TICKETING_ORDER} component={BilletterieCommande} />
                <RouteWithOneAccess exact roleCodeNameArray={[ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_ADMIN]} path={ROUTE_TICKETING_REPORT} component={BilletterieRapport} />

                <RouteWithOneAccess exact roleCodeNameArray={[ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_ADMIN]} path={ROUTE_SUPPORT_CHRONO_USERS} component={ChronoUsersPage} />

                <PrivateRoute exact path={ROUTE_ADMIN_USER_PROFILE()} component={UserProfilePage} />
                <PrivateRoute exact path={ROUTE_ADMIN_USERS} component={UsersPage} />

                <PrivateRoute exact path={ROUTE_ADMIN_MOBILE_CONFIG} component={MobileConfigPage} />
                <PrivateRoute exact path={ROUTE_ADMIN_REALTIME} component={RealtimePage} />

                <PrivateRoute exact path={ROUTE_MONITORING_APPLICATION_LOGS} component={ApplicationLogsPage} />
                <PrivateRoute exact path={ROUTE_MONITORING_MOBILE_CONFIGURATION_LOGS} component={MobileConfigurationPage} />

                <PrivateRoute exact path={ROUTE_MONITORING_REALTIME} component={MonitoringRealtimePage} />
                <PrivateRoute exact path={ROUTE_MONITORING_GTFS} component={MonitoringGtfsPage} />

                <PrivateRoute exact path={ROUTE_ADMIN_GTFS_MENU} component={AdminMenu} />
                <PrivateRoute exact path={ROUTE_ADMIN_GTFS_LOGS} component={LogGtfsPage} />
                <PrivateRoute exact path={ROUTE_ACHALANDAGE_CONFIG} component={AchalandageConfig} />

                <InternalRoute exact path={ROUTE_DEBUGTOOL_RESULTCOMPARER} component={ResultComparer} />

                <Redirect from='*' to={ROUTE_HOME} />
            </Switch>
        </Layout>
    )
};

const ProtectedAppRouting = ensureLogin(AppRouting);

const ErrorRouting = () => {
    return (
        <Switch>
            <Route exact path='/500' component={UnauthorizedPage} />
        </Switch>
    );
};

const App = () => {
    const context = useContext(ChronoContext);

    const { data: roles } = useChronoApi('/references/roles');
    const { data: status } = useChronoApi('/references/status');
    const { data: allAgencies } = useChronoApi('/references/agencies');

    const { data: agencies } = useChronoApi('/users/agencies');
    const { data: profile } = useChronoApi('/users/', { role: 0 });

    useEffect(() => {
        context.setRoles(roles);
    }, [roles]);
    useEffect(() => {
        context.setStatus(status);
    }, [status]);
    useEffect(() => {
        context.setAllAgencies(allAgencies);
    }, [allAgencies]);

    useEffect(() => {
        context.setUserAgencies(agencies);
    }, [agencies]);
    useEffect(() => {
        context.setUserProfile(profile);
    }, [profile]);

    return (
        <Fragment>
            <ProtectedAppRouting />
            <ErrorRouting />
        </Fragment>
    );
};

export default App;
