/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ReactNode } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { LoadingIndicator } from "../LoadingIndicator";

const StyledContainer = styled(Container)`
    > div {
        margin-top: 20px;
        padding: 0.5% 10% 0.5% 10%;
    }

    > form {
        padding: 0.5% 10% 3.5% 10%;
    }
`;

interface CommunicationContainerProps {
    children?: ReactNode;
    isProcessing?: boolean;
    useStyleContainer?: boolean;
    cssClass?: string;
}

export const CommunicationGridContainer = ({ children, isProcessing, useStyleContainer, cssClass }: CommunicationContainerProps) => {
    return (
        <div style={{ position: "relative" }} className={"h-100 " + cssClass ? cssClass : ""}>
            {isProcessing && (
                <div
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        zIndex: 5,
                        backgroundColor: "rgba(150, 150, 150, 0.7)",
                    }}
                >
                    <LoadingIndicator size={50} height={"100%"} />
                </div>
            )}
            {!useStyleContainer && <StyledContainer>{children}</StyledContainer>}
            {useStyleContainer === true && children}
        </div>
    );
};

export default CommunicationGridContainer;
