import { sendPostRequest } from '../../auth/apiEffects'
import { NotificationViewModel } from '../../types/viewmodels/notificationViewModel'


export const saveNotification = async (queryParams): Promise<false | NotificationViewModel | undefined> => {
    try {
        const savedNotificationId = await sendPostRequest<NotificationViewModel>("/notifications/" + queryParams.notificationId + queryParams.publishOrNot, queryParams.queryData);
        return savedNotificationId

    } catch (e) {
        console.log(e);
        return undefined;
    }
}