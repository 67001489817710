/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import { Row, Tab, Col, Button, DropdownButton, Dropdown } from "react-bootstrap";
import ChronoCard from "../../common/ChronoCard";
import styled from 'styled-components';
import RealTimeGraph from './RealTimeGraph';
import ChronoContext from "../../../context/ChronoContext";
import moment from 'moment';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericDate from "../../common/generic/GenericDate";
import { sendGetRequest } from "../../../auth/apiEffects";
import { getRoutesUrl } from "../../../auth/fetchUrlHelper";

interface IStatsByRoute {
    realtimeType: string;
    organization: string;
    routeGtfsId: string;

}
const ErrorMessage = styled.p`
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
`
export const FormRow = styled(Row)`
    margin-top: 30px;
    margin-bottom: 20px;
`

const RealTimeStats = () => {
    const dtDebut = moment().subtract(1, 'month').format('YYYY-MM-DD');
    const dtFin = moment().format('YYYY-MM-DD');

    const [currentDates, setCurrentDates] = useState({ startDate: dtDebut, endDate: dtFin });
    const [statsByAgency, setStatsByAgency] = useState<IStatsByRoute[]>([]);
    const [statsByRoute, setStatsByRoute] = useState<IStatsByRoute[]>([]);
    const [routes, setRoutes] = useState<any>([]);

    const [validated, setValidated] = useState(true);
    const [startDate, setStartDate] = useState(dtDebut);
    const [endDate, setEndDate] = useState(dtFin);
    const [isLoadingStatsAgency, setIsLoadingStatsAgency] = useState(true);
    const [isLoadingStatsRoutes, setIsLoadingStatsRoute] = useState(true);
    const [isLoadingRoutes, setIsLoadingRoute] = useState(true);

    const chronContext = useContext(ChronoContext);
    const staticData = chronContext ? chronContext.staticData : null;
    const agencies = staticData != null ? staticData.user.agencies : '';

    const [currentAgency, setCurrentAgency] = useState(agencies && agencies.length > 0 ? agencies[0].label : '');

    useEffect(() => {
        async function fetchData() {
            await getStatsForRoute();
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (agencies && agencies.length > 0)
            setCurrentAgency(agencies[0].label);
    }, [agencies]);

    useEffect(() => {
        async function fetchRoutes() {
            if (!(currentAgency && currentAgency.length > 0))
                return;

            setIsLoadingRoute(true);
            const data = await sendGetRequest(getRoutesUrl(currentAgency));
            setRoutes(data && (data as any).length > 0 && Array.isArray(data) ? data : []);
            setIsLoadingRoute(false);
        }

        fetchRoutes();
    }, [currentAgency]);

    useEffect(() => {
        async function fetchData() {
            await getStatsForAgency();
        }

        fetchData();
    }, [currentDates]);

    const getStatsForAgency = async () => {
        setIsLoadingStatsAgency(true);
        let dataStats: IStatsByRoute[] = [];

        const data: IStatsByRoute[] | false | undefined = await sendGetRequest(`/realtime/diagnostic/summary?startDate=${moment(currentDates.startDate).format('YYYY-MM-DD')}&endDate=${moment(currentDates.endDate).add(23, 'hours').add(59, 'minutes').add(59, 'seconds').format('YYYY-MM-DD HH:mm:ss')}`);

        if (data && (data)?.length > 0)
            dataStats = data.sort((a: {organization: string }, b: {organization: string }) => (a.organization > b.organization) ? 1 : ((b.organization > a.organization) ? -1 : 0));

        setStatsByAgency(dataStats);
        setIsLoadingStatsAgency(false);
    }

    const getStatsForRoute = async () => {
        setIsLoadingStatsRoute(true);
        let stats: IStatsByRoute[] = [];

        const data: IStatsByRoute[] | false | undefined = await sendGetRequest(`/realtime/diagnostic/route/summary`);

        if (data && (data)?.length > 0)
            stats = data.sort((a: {organization: string }, b: {organization: string }) => (a.organization > b.organization) ? 1 : ((b.organization > a.organization) ? -1 : 0));

        setStatsByRoute(stats);
        setIsLoadingStatsRoute(false);
    }


    const onStartDateChange = date => {
        const formatDate = moment(date).format('YYYY-MM-DD');
        const valide = !moment(endDate).isBefore(moment(formatDate));
        setValidated(valide);
        setStartDate(formatDate);
    }

    const onEndDateChange = date => {
        const formatDate = moment(date).format('YYYY-MM-DD');
        const valide = !moment(formatDate).isBefore(moment(startDate));
        setValidated(valide);
        setEndDate(formatDate);
    }

    function getData() {
        const valide = !moment(endDate).isBefore(moment(startDate));
        setValidated(valide);

        if (valide)
            setCurrentDates({ startDate: startDate, endDate: endDate });
    }


    return (
        <Fragment>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <TopBar title="Statistiques de temps réel">
                    <DropdownButton style={{ margin: '0 10px 0 0' }} id="transparentDropDown" drop="down"
                        title={currentAgency}
                        onSelect={(key, e) => {
                            setCurrentAgency(key);
                        }}
                    >
                        {agencies.map((item) =>
                            <Dropdown.Item key={item.label} eventKey={item.label}>{item.label}</Dropdown.Item>
                        )}
                    </DropdownButton>   
                </TopBar>
                <ContentBelowTopBar>
                    <ChronoCard>
                        <FormRow style={{ marginTop: 10 }}>

                            <Col xl={3} md={12}>
                                <GenericDate isRequired label="Début"
                                    date={startDate}
                                    onDateChange={onStartDateChange}
                                    width={80}
                                    maxDate={moment().toDate()}
                                />
                            </Col>
                            <Col xl={3} md={12}>
                                <GenericDate isRequired label="Fin"
                                    date={endDate}
                                    onDateChange={onEndDateChange}
                                    width={80}
                                    maxDate={moment().toDate()}
                                />
                                {
                                    !validated ? <ErrorMessage>La date de fin ne peut pas être avant la date de début</ErrorMessage> : null
                                }
                            </Col>

                            <Col xl={3} md={12} style={{ paddingLeft: 0 }}>
                                <Button onClick={getData} style={{ color: 'white', paddingLeft: '7px', paddingRight: '7px' }}>
                                    <FontAwesomeIcon icon={faChartLine} /> Afficher
                            </Button>
                            </Col>
                        </FormRow>
                    </ChronoCard>
                    {
                        currentAgency && currentAgency.length > 0 &&
                        <div>
                            <ChronoCard>
                                <RealTimeGraph data={statsByAgency}  title={"Succès des appels pour l'agence (%)"} isLoadingData={isLoadingStatsAgency} agencyCode={currentAgency} isReception={false}></RealTimeGraph>
                                <RealTimeGraph routes={routes} data={statsByRoute} title={"Succès des appels pour une ligne - 7 derniers jours (%)"} isLoadingData={isLoadingStatsRoutes || isLoadingRoutes} isReception={false} agencyCode={currentAgency}></RealTimeGraph>
                            </ChronoCard>
                            <ChronoCard>
                                <RealTimeGraph data={statsByAgency} title={"Réception de données pour l'agence (%)"} isLoadingData={isLoadingStatsAgency} agencyCode={currentAgency} isReception={true}></RealTimeGraph>
                                <RealTimeGraph routes={routes} data={statsByRoute} title={"Réception de données pour une ligne - 7 derniers jours (%)"} isLoadingData={isLoadingStatsRoutes || isLoadingRoutes} isReception={true} agencyCode={currentAgency}></RealTimeGraph>
                            </ChronoCard>
                        </div>
                    }
                </ContentBelowTopBar> 
            </Tab.Container>
        </Fragment>
    )
}

export default RealTimeStats;

