import { FormControl, InputGroup, Form } from "react-bootstrap";
import React from "react";

const GenericSelect = ({ title, value, onChange, isRequired, isDisabled, children, style = {}, feedback = '', invalid = false, canUserEdit = true, cssClass='' }) => {

    return (
        <InputGroup>
            <InputGroup.Prepend style={style ? style : {minWidth: 130}}>
                <InputGroup.Text style={style ? style : {minWidth: 130}}>{title}{isRequired ? ' *' : ''}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl as="select" className={cssClass} disabled={isDisabled || !canUserEdit} required={isRequired} value={value} onChange={onChange} 
                            style={ invalid ? { border: '1px solid #dc3545' } : undefined} >
                {children}
            </FormControl>
            {
                feedback 
                ? <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
                : null
            }
        </InputGroup>
    );
};

export default GenericSelect;
