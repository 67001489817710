import styled from "styled-components";
import ChronoCard from "../../common/ChronoCard";

const HomeCard = styled(ChronoCard)`
    height: 500px;
`;

export const TitleCard = styled.h3`
    display: inline;
    text-align: center;
    margin: 4px 0px 10px 0px;
`;

export const ITEMS = [
    'Nb vues'
];

export default HomeCard;