import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../actions/types/types";
import * as consoleApi from "../../../api";
import addResource from "../../actions/addResource";
import addUIItem from "../../actions/addUIItem";
import * as keys from '../../keys/index';
import * as statusRequest from '../requestStatus';

export function* watchDashboradStatsRequest() {
    yield takeLatest(types.GET_STATS_USER_CONNECTED_REQUESTED, getStatsForUserConnected);
}



export function* getStatsForUserConnected(action) {
    const { queryParams } = action.payload;
    const status = keys.STATS_CONNECTED_USER + '_STATUS';

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));

        const list = yield call(
            consoleApi.getStatForConnectedUserList,
            queryParams
        );

        if (!list) {
            throw new Error("Unable to get the stats for user connected.");
        }

        yield put(addResource(list, keys.STATS_CONNECTED_USER));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {
        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}


