import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../../actions/types/types";
import * as consoleApi from "../../../api/communication/notification";
import addResource from "../../actions/addResource";
import addUIItem from "../../actions/addUIItem";
import * as keys from '../../keys/communication/notification';
import * as statusRequest from '../requestStatus';


export function* watchMobileNotification() {
    yield takeEvery(types.SAVE_NOTIFICATION, saveNotification);
}

export function* saveNotification(action) {
    const { queryParams } = action.payload;
    const status = keys.SAVED_NOTIFICATION_STATUS;

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));

        const result = yield call(
            consoleApi.saveNotification,
            queryParams
        );

        if (!result) {
            throw new Error("Unable to save configuration log");
        }

        yield put(addResource(result, keys.SAVED_NOTIFICATION_ID));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {

        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}

