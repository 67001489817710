/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { LoadingIndicatorInApp } from "../../LoadingIndicator";
import { Row, Col, Button } from 'react-bootstrap';
import useUI from "../../../lib/hooks/useUI";
import useResources from "../../../lib/hooks/useResources";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faPen, faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Grid from "../../../lib/ui/Grid/Grid";
import * as constantesConfig from "../../../redux/keys/applicationLogs/configurationLogs";
import * as requestStatus from "../../../redux/sagas/requestStatus";
import PopupMobileconfig from './PopupMobileconfig'
import { ConfirmPopup } from '../../../lib/ui/index';
import { useDispatch } from 'react-redux';
import { saveConfigurationLog } from "../../../redux/actions/applicationLogs/requestConfigurationLogs";

const ConfigButton = ({ id, data, rowIndex, list, onRefresh }) => {
    const [isSaving, setIsSaving] = useState(false);

    const dispatch = useDispatch();
    const status = useUI(constantesConfig.CONFIGURATION_APP_TO_SAVE + '_STATUS' + id);
    const element = useResources(constantesConfig.CONFIGURATION_APP_TO_SAVE + id);

    useEffect(() => {
        if (status === requestStatus.REQUEST_SUCCEEDED && element && rowIndex >= 0 && isSaving === true) {
            list[rowIndex] = { ...element };
            if (onRefresh)
                onRefresh();
        }
        setIsSaving(status === requestStatus.REQUEST_PENDING);
    }, [status]);


    return (<Button variant="light" onClick={() => {
        data.id = id;

        if (data.active === true) {
            return ConfirmPopup({
                getCustomBody: () => {
                    return (
                        <div>
                            <h5 style={{fontWeight:'bold'}}>Confirmer désactivation log: {data.logType} </h5>
                            <div style={{ width: '100%', textAlign: 'center' }}>Êtes-vous sûr de vouloir désactiver le log?</div>
                            <div style={{ width: '100%', textAlign: 'center' }}>Les notifications associées seront désactivées</div>
                        </div>
                    )
                },
                style: {width:'500px'},
                onClickYes: () => {
                    setIsSaving(true);
                    data.active = false;
                    dispatch(saveConfigurationLog(constantesConfig.CONFIGURATION_APP_TO_SAVE + id, data));
                }
            });
        }
        else {
            data.active = true;
            dispatch(saveConfigurationLog(constantesConfig.CONFIGURATION_APP_TO_SAVE + id, data));
        }
    }
    }>
        {
            isSaving === true ? <FontAwesomeIcon icon={faSpinner} spin={true} />
                : <FontAwesomeIcon icon={data.active === true ? faTrash : faPowerOff} />

        }
    </Button>)
}

const ConfigurationGrid = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [rowIndex, setRowIndex] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const [dataRow, setDataRow] = useState({});
    const [refresh, setRefresh] = useState(true);

    const data = useResources(constantesConfig.CONFIGURATION_APP_LOGS);
    const status = useUI(constantesConfig.CONFIGURATION_APP_LOGS + '_STATUS');

    const onRefresh = () => {
        setRefresh(!refresh);
    }

    useEffect(() => {
        setIsLoading(status === requestStatus.REQUEST_PENDING);
    }, [status]);

    if (isLoading) {
        return <LoadingIndicatorInApp />;
    }

    const invalidRow = (row) => {
        return !(row.values.category && row.values.category.length > 0 && row.values.criticality && row.values.criticality.length);
    }

    const columns = [
        {
            Header: "ID (logtype)",
            accessor: "logType"
        },
        {
            Header: "OS",
            accessor: "os"
        },
        {
            Header: "Catégorie",
            accessor: "category",
        },
        {
            Header: "Criticité",
            accessor: "criticality",
        },
        {
            Header: "Notification",
            accessor: "notification",
            Cell: props => <span>{props.value === true ? 'Oui' : 'Non'}</span>
        },
        {
            Header: "Actif",
            accessor: "active",
            Cell: props => <span>{props.value === true ? 'Oui' : 'Non'}</span>
        },
        {
            Header: "Actions",
            accessor: "logMobileConfigurationId",
            Cell: props => <div>
                <Button variant="light" style={{ marginRight: '5px' }} onClick={() => {
                    setDataRow(props.data[props.row.index]);
                    setRowIndex(props.row.index);
                    setShowModal(true);
                }}>
                        <FontAwesomeIcon icon={faPen} />
                </Button>
                {
                    props.data[props.row.index].category && props.data[props.row.index].category.length > 0 && props.data[props.row.index].criticality && props.data[props.row.index].criticality.length > 0 &&
                    <ConfigButton id={'gridConfig_' + props.row.index} data={{ ...props.data[props.row.index] }} rowIndex={props.row.index} list={props.data} onRefresh={onRefresh} />
                }
                
            </div>
        },

    ];

    return (
        <Fragment>
            {
                data && data.length > 0 &&
                <div>
                    <PopupMobileconfig id="UpdateLogMobile" showModal={showModal} rowIndex={rowIndex} data={dataRow} showFooter={true} hideShowModal={() => { setShowModal(false) }} />
                    <Grid columns={columns} data={data} enableSelectedRow={true} invalidRow={invalidRow}/>
                </div>
            }
            {
                (!data || data.length === 0) &&
                <Row>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <h3>Aucun résultat</h3>
                    </Col>
                </Row>
            }

        </Fragment>
    )
}

export default ConfigurationGrid;