import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../../actions/types/types";
import * as consoleApi from "../../../api";
import addResource from "../../actions/addResource";
import addUIItem from "../../actions/addUIItem";
import * as status from "../requestStatus";
import * as keys from "../../keys";


export function* watchStops() {
  yield takeLatest(types.GET_REFERENCE_STOP_REQUESTED, doStops);
}

export function* doStops(action) {
  const { queryParams } = action.payload;

  try {
    yield put(addUIItem(status.REQUEST_PENDING, keys.STOP_REQUEST_STATUS));
    const stops = yield call(
      consoleApi.getReferenceStops,
      queryParams
    );

    if (!stops) {
      throw new Error("Unable to get stops reference logs.");
    }

    yield put(addResource(stops, keys.STOP_LIST));
    yield put(addUIItem(status.REQUEST_SUCCEEDED, keys.STOP_REQUEST_STATUS));
  } catch (e) {
    yield put(addUIItem(status.REQUEST_FAILED, keys.STOP_REQUEST_STATUS));
  }
}
