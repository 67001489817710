import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './redux/store';
import registerServiceWorker from './registerServiceWorker';
import { ThemeProvider } from 'styled-components';
import UnauthorizedPage from "./components/errors/UnauthorizedPage";
import { LoadingIndicator } from "./components/LoadingIndicator";

import GlobalStyles from './styles/globalStyles';
import './styles/index.css';
import GlobalState from "./context/GlobalState";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider }from './auth/authProvider';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const store = configureStore();



const themeSettings = {
    colors: {
        primary: '#00213C',
        primaryDark: '#00192D',
        secondary: '#00C5BE',
        secondaryDark: '#00928D',
        text: '#383838',
        lightGray: '#F1F1F1',
        mediumGray: '#CED4DA',
        darkGray: '#555',
        messageError: '#FF6469',
        messageWarning: '#FFBD30',
        messageInfo: '#00C5BE',
        invalidInput: '#e9ecef'
    },
    fontSizes: {
        sm: '0.7em',
        md: '1em',
        lg: '1.3em',
        xl: '1.6em',
    }
};

const ProtectedApp = () => {
    return (
        <AzureAD provider={authProvider} reduxStore={store}>
            {
                ({ authenticationState, error }) => {

                switch (authenticationState)
                {
                    case AuthenticationState.Authenticated:
                        return (<GlobalState><App /></GlobalState>);

                    case AuthenticationState.InProgress:
                        return (<LoadingIndicator />);

                    case AuthenticationState.Unauthenticated:
                        return (<UnauthorizedPage error={error} />);
                }
            }}
        </AzureAD>
    )
};

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Provider store={store}>
            <ThemeProvider theme={themeSettings}>
                    <Fragment>
                        <GlobalStyles />
                        <ProtectedApp />
                    </Fragment>
            </ThemeProvider>
        </Provider>
    </BrowserRouter>,
    rootElement,
);

registerServiceWorker();
