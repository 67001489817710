/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as keys from "../../../../../redux/keys";
import { getReferenceRoutes } from "../../../../../redux/actions/references/requestRoutes";
import useResources from "../../../../../lib/hooks/useResources";
import useUI from "../../../../../lib/hooks/useUI";
import { Dropdown } from "../../../../../lib/ui";
import { groupBy } from "../../../../../lib/toolbox";
import { LoadingIndicator } from "../../../../LoadingIndicator";
import * as requestStatus from "../../../../../redux/sagas/requestStatus";
import { synchronizeStepsValues } from "../../../../../service";

const createOptions = (routes) => {
    const routesByOrganization = groupBy(routes, "organizationCode");
    const organizations = Object.keys(routesByOrganization);
    let options: any[] = [];
    organizations.forEach((organization) => {
        options.push({
            label: `Agence - ${organization}`,
            value: ``,
            filterOn: organization,
            disabled: true,
        });
        routesByOrganization[organization].forEach((route) => {
            options.push({
                label: `${route.shortName} - ${route.longName}`,
                value: `{\"organizationCode\": \"${route.organizationCode}\", \"routeId\": \"${route.routeId}\", \"routeLabel\": \"${route.shortName} - ${route.longName}\", \"backgroundColor\": \"${route.backgroundColor}\", \"fontColor\": \"${route.fontColor}\"}`,
                filterOn: route.organizationCode,
            });
        });
    });

    return options;
};

const RouteDropdownStep = (props) => {
    const { stepsValues, setStepsValues, isStepsDirty, value, onChange, name } = props;
    const dispatch = useDispatch();
    const routes = useResources(keys.ROUTE_LIST);
    const routeRequestStatus = useUI(keys.ROUTE_REQUEST_STATUS);

    const [options, setOptions] = useState<any[]>([]);

    useEffect(() => {
        if (routes) {
            setOptions(createOptions(routes));
        }
    }, [routes]);

    useEffect(() => {
        setOptions([]);

        // in case of changing previous value we have to remove the selectedValue from the steps
        dispatch(getReferenceRoutes({ agencyCodeList: stepsValues[0] }));
    }, [stepsValues[0]]);

    useEffect(() => {
        setStepsValues(synchronizeStepsValues(stepsValues));
    }, [stepsValues[1]]);

    return (
        <>
            {routeRequestStatus === requestStatus.REQUEST_PENDING && <LoadingIndicator size={24} height={"inherit"} />}
            {routeRequestStatus === requestStatus.REQUEST_SUCCEEDED && options && (
                <Dropdown mode="list" name={name} options={options} selectedValues={value} onChange={onChange} filterOn={true} />
            )}
        </>
    );
};

export default RouteDropdownStep;
