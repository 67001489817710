export const SET_ROLES = 'SET_ROLES';
export const SET_STATUS = 'SET_STATUS';
export const SET_ALL_AGENCIES = 'SET_ALL_AGENCIES';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_AGENCIES = 'SET_USER_AGENCIES';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const staticReducer = (state, action) => {
    switch (action.type) {
        case SET_ROLES:
            return {
                ...state,
                roles: action.roles,
            };
        case SET_STATUS:
            return {
                ...state,
                status: action.status,
            };
        case SET_ALL_AGENCIES:
            return {
                ...state,
                allAgencies: action.allAgencies,
            };
            
        case SET_USER_AGENCIES:
        case SET_USER_PROFILE:
            return {
                ...state,
                user: userReducer(state.user, action),
            };
            
        case ADD_NOTIFICATION:
        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: notificationsReducer(state.notifications, action),
            };
        default:
            return state;
    }
};

const userReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_USER_AGENCIES:
            return {
                ...state,
                agencies: action.agencies,
            };
        case SET_USER_PROFILE:
            return {
                ...state,
                profile: action.profile,
            };
        default:
            return state;
    }
};

const notificationsReducer = (state = [], action) => {
    switch (action.type) {
        case ADD_NOTIFICATION:
            const filteredState = state.filter(notification => action.notification.message !== notification.message);
            return [
                ...filteredState,
                action.notification,
            ];
        case REMOVE_NOTIFICATION:
            return state.filter(notification => action.message !== notification.message);
        default:
            return state;
    }
};