import React, { FC, ReactElement } from "react";

import { MessageForm } from "./form/MessageForm";
import { MessageSummary } from "./MessageSummary";
import { ToastProvider } from "react-toast-notifications";
import { MessageSliderContainerProps } from "types/components/MessageComponent";
import { LoadingIndicator } from "components/LoadingIndicator";

export const MessageBaseContainer: FC<MessageSliderContainerProps> = (props: MessageSliderContainerProps): ReactElement => {
    console.log(props);
    const {
        isMessageCopy,
        isMessageEditable,
        isNewMessage,
        entity,
        operations,
        setEntity,
        setIsMessageCopy,
        setIsMessageEditable,
        isSliderLoading
    } = props;

    if (isSliderLoading) return <LoadingIndicator />

    if (isNewMessage || isMessageCopy || isMessageEditable) {
        return (
            <ToastProvider>
                <MessageForm
                    operations={operations}
                    entity={entity}
                    setEntity={setEntity}
                    isMessageCopy={isMessageCopy}
                    isMessageEditable={isMessageEditable}
                />
            </ToastProvider>
        );
    } else if (entity.id > 0 && !isMessageEditable) {
        return (
            <ToastProvider>
                <MessageSummary
                    operations={operations}
                    entity={entity}
                    setIsMessageCopy={setIsMessageCopy}
                    setIsMessageEditable={setIsMessageEditable}
                />
            </ToastProvider>
        );
    } else {
        return <></>;
    }
};
