import moment from "moment";
import { getCalendarMessages } from "../redux/actions/communication/requestMessage";
import addUIItem from "../redux/actions/addUIItem";
import addResource from "../redux/actions/addResource";
import * as requestStatus from "../redux/sagas/requestStatus";
import * as keys from "../redux/keys/index";
import * as miscService from "./miscService"

export const loadData = (yearValue, month, dispatch, onChange) => {
    const dt = moment([yearValue, month]);
    const startDate = moment(dt).startOf('month');
    const endDate = moment(dt).endOf('month');

    dispatch(getCalendarMessages(keys.CALENDAR_MESSAGE, {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD 23:59:59")
    }));

    if (onChange)
        onChange({ month: month, year: yearValue });
}

export const filterData = (status, agencies, messages, statusList, statusMessage, orgFilterList, dispatch) => {
    if ((status === requestStatus.REQUEST_SUCCEEDED || status === requestStatus.REQUEST_FAILED) && (agencies && agencies.length > 0)) {
        const messageList = messages && messages.length > 0 ? messages
            .filter((message) => miscService.isStatusInSelectedList(message, statusList, statusMessage))
            .filter((message) => miscService.isOrganizationInSelectedList(message, orgFilterList)) : [];

        dispatch(addResource([...messageList], keys.CALENDAR_MESSAGE + '_FILTERED'))
    }
}

export const getCalendarDayFrontColor = (message) => {
    const endDateBeforeNow = moment(message.endDate).isBefore(moment());
    const startDateBeforeNow = moment(message.startDate).isSameOrBefore(moment());
    const endDateAfterNow = moment(message.endDate).isSameOrAfter(moment());
    return message.isPublished && (endDateBeforeNow || (startDateBeforeNow && endDateAfterNow)) ? 'green' : 'red';
}

export const getEventList = (eventData, dt) => {
    return eventData && eventData.length > 0 ? eventData.filter((x) => moment(dt).isSameOrBefore(moment(x.endDate).format('YYYY-MM-DD')) && moment(dt).isSameOrAfter(moment(x.startDate).format('YYYY-MM-DD'))) : [];
}

export const getFormatedEventList = (eventData, dtFirstDate, dtLastDate) => {

    let results = [],
        messageIds = [],

    //get the messages for the week
       messagesForWeek = eventData.filter((x) => {
                            return (moment(dtFirstDate).isSameOrBefore(moment(x.endDate).format('YYYY-MM-DD')) && moment(dtFirstDate).isSameOrAfter(moment(x.startDate).format('YYYY-MM-DD'))) ||
                                (moment(dtLastDate).isSameOrBefore(moment(x.endDate).format('YYYY-MM-DD')) && moment(dtLastDate).isSameOrAfter(moment(x.startDate).format('YYYY-MM-DD'))) ||
                                (moment(dtFirstDate).isBefore(moment(x.startDate).format('YYYY-MM-DD')) && moment(dtLastDate).isAfter(moment(x.endDate).format('YYYY-MM-DD')))
                        });

    messagesForWeek = messagesForWeek.map(element => {
        return { ...element, alreadyShown: false }
    });

    for (var m = moment(dtFirstDate); m.isSameOrBefore(dtLastDate); m.add(1, 'days')) {

        let messagesForDay = messagesForWeek.filter((x) => moment(m).isSameOrBefore(moment(x.endDate).format('YYYY-MM-DD')) && moment(m).isSameOrAfter(moment(x.startDate).format('YYYY-MM-DD')))

        messagesForDay = getFormatedDataForDay(messagesForDay, 3, messageIds, m);

        let obj = {
            dt: m.format('YYYY-MM-DD'),
            data: messagesForDay
        }
        results.push(obj);

        messagesForWeek = messagesForWeek.map(x => {
            let me = messagesForDay.find(y => y.id === x.id);
            return me == null ? x : me;
        })
    }
    return results;
}

const getFormatedDataForDay = (eventsDay, nbMessagesAllowed, messageIds) => {

    let positions = [];

    const data = eventsDay.filter((x) => x.display !== false || x.display === undefined).sort((a, b) => {

        if (moment(moment(a.startDate).format("YYYY-MM-DD")).valueOf() > moment(moment(b.startDate).format("YYYY-MM-DD")).valueOf())
            return 1;
        else if (moment(moment(a.startDate).format("YYYY-MM-DD")).valueOf() < moment(moment(b.startDate).format("YYYY-MM-DD")).valueOf())
            return -1;
        else if (moment(moment(b.endDate).format("YYYY-MM-DD")).valueOf() > moment(moment(a.endDate).format("YYYY-MM-DD")).valueOf() > 0)
            return 1;
        else if (moment(moment(b.endDate).format("YYYY-MM-DD")).valueOf() < moment(moment(a.endDate).format("YYYY-MM-DD")).valueOf() > 0)
            return -1;
        else
            return 0;

    }).map((message, i) => {
        const p = getPosition(positions, nbMessagesAllowed, message);
        var mess = { ...message, display: i < nbMessagesAllowed, position: p, alreadyShown: messageIds.includes(message.id) };
        if (i < nbMessagesAllowed && !messageIds.includes(message.id))
            messageIds.push(message.id);
        return mess;
    });

    return data.concat(eventsDay.filter((x) => x.display === false));
}

const getPosition = (positions, nbMessagesAllowed, message) => {

    let p = message.position != undefined ? message.position : -1;

    if (p <= 0 || positions.includes(p)) {
        for (var i = 1; i <= nbMessagesAllowed; i++) {
            if (!positions.includes(i)) {
                p = i;
                break;
            }
        }
    }

    if (!positions.includes(p))
        positions.push(p);

    return p;
}


export const getFormatedMessages = (messages) => {
    return messages && messages.length > 0 ? messages.map((x) => {
        var obj =
        {
            id: x.id,
            agencyCodeList: x.agencyCodeList,
            fontColor: x.fontColor,
            backgroundColor: x.backgroundColor,
            isPublished: x.isPublished,
            title: x.contentFR,
            startDate: x.startDate,
            endDate: x.endDate
        };

        return obj;
    }) : []
}

export const getLengthData = (mess, width) => {
    const nb = Math.ceil(parseInt(width.replace('%', '')) / 100)
    const n = nb * 14;
    return mess.length > n ? mess.substring(0, n) + ' ...' : mess;
}


export const checkAllChildrenLoaded = (dt, nbWeek, noWeek, lastDateOfWeek, status, dispatch) => {

    if ((nbWeek - 1) === noWeek && moment(dt).format("YYYY-MM-DD") === moment(lastDateOfWeek).format("YYYY-MM-DD") && status == 1) {
        setTimeout(function () {
            dispatch(addUIItem(1, keys.CALENDAR_FILTER_STATUS + 'CHILDREN'));
        }, 500);

    }
    return;
}

export const getBadge = (message, agencies) => {
    if ((!message && !message.agencyCodeList) || !agencies) {
        return null
    }
    const badge = miscService.getBadge(message.agencyCodeList, agencies);

    return { label: badge.label, backgroundColor: badge.color, fontColor: 'white' };
};


export const findFormatedEvents = (dt, list) => {
    return list ? list.find(x => x.dt === moment(dt).format('YYYY-MM-DD')).data : [];
}

export const  getTextWidth = (text, font) => {
    var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    return metrics.width + 5 ;
};

