import React from "react";
import GenericSelect from "../GenericSelect";
import { LIST_GTFS_R_EFFECT } from "../../../communication/alert/AlertConstants";

const SelectGtfsrEffects = (props) => {
    return (
        <GenericSelect title="Effect" {...props}>
            {
                LIST_GTFS_R_EFFECT.map((effect:any, i) =>
                    <option key={effect.codename + '_' + i} value={effect.codename}>{effect.label}</option>
                )
            }
        </GenericSelect>
    );
};

export default SelectGtfsrEffects;
