import React, { FC, ReactElement} from 'react';
import { InputGroup, FormControl, Form } from 'react-bootstrap';

const Input:FC<any> = ({ label, labelWidth, value, placeholder = label, maxLength, isDisabled, isRequired, onChange, onKeyPress, type = 'text', style,  styleInput = null,  feedback, invalid, canUserEdit = true, format = '', cssClass ='', data_testid}:any):ReactElement => {
    const requiredText = isRequired ? ' *' : '';
    const invalidStyling = invalid ? {width: 130, background: 'white', border: '1px solid #dc3545' } : null 
    
    return (
        <InputGroup>
            {
                label ?
                    <InputGroup.Prepend style={style ? style : { minWidth: labelWidth ? labelWidth : 130}}>
                        <InputGroup.Text style={style ? style : { minWidth: labelWidth ? labelWidth : 130}}>{label + requiredText}</InputGroup.Text>
                    </InputGroup.Prepend> : null
            }
            <FormControl type={type}
                value={value}
                checked={!!value}
                placeholder={placeholder + requiredText} maxLength={maxLength}
                required={isRequired} disabled={isDisabled || !canUserEdit}
                onChange={onChange}
                onKeyPress={onKeyPress}
                className={cssClass}
                style={invalid ? invalidStyling : styleInput}
                data-testid={data_testid}
            />
            {
                value && maxLength ?
                    <InputGroup.Append>
                        <InputGroup.Text>
                            <small className="text-muted appended-counter"> {value.length} / {maxLength} </small>
                        </InputGroup.Text>
                    </InputGroup.Append>
                    : null
            }
            {
                format ?
                    <InputGroup.Append>
                        <InputGroup.Text>
                            <small className="text-muted appended-counter" style={{ fontStyle:'italic' }}> {format} </small>
                        </InputGroup.Text>
                    </InputGroup.Append>
                    : null
            }
            {
                feedback 
                ? <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
                : null
            }

           
            </InputGroup>
           
    );
};

export default Input;
