import {isValidUrl} from "../common/helper";
import moment from 'moment';

export const CODENAME_AGENCY_ALL = 'all';
export const LABEL_AGENCY_ALL = 'Toutes les organisations';
export const LABEL_AGENCY_NONE = 'Aucune oganisation';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';

export const getRoute = (routes, routeId) => {
    let matchedRoute = routes.find(route => route.routeId === routeId);

    if (matchedRoute) {
        return `${matchedRoute.shortName} - ${matchedRoute.longName}`;
    } else {
        return 'Aucune ligne';
    }
};

export const getStop = (stops, stopId) => {
    let matchedStop = stops.find(stop => stop.stopId === stopId);

    if (matchedStop) {
        return `${matchedStop.stopId} - ${matchedStop.name}`;
    } else {
        return 'Aucun arrêt';
    }
};

export const areUrlsValid = (urlFr, urlEn) => {
    return (urlFr === '' || isValidUrl(urlFr))
        && (urlEn === '' || isValidUrl(urlEn));
};

export const getSendDateStr = (startDate) => {
    const sDate = moment(startDate)
    return `Le ${sDate.format('DD MMM YYYY')}`
}

export const getRangeDateStr = (startDate, endDate) => {
    const sDate = moment(startDate);
    const eDate = moment(endDate);
    if (sDate.year() === eDate.year()) {
        if (sDate.month() === eDate.month()) {
            return `Du ${sDate.format('DD')} au ${eDate.format('DD MMM YYYY')}`;
        } else {
            return `Du ${sDate.format('DD MMM')} au ${eDate.format('DD MMM YYYY')}`;
        }
    } else {
        return `Du ${sDate.format('DD MMM YYYY')} au ${eDate.format('DD MMM YYYY')}`;
    }
};



export const communications_toast_text = (communicationsType) => {
    return {
        created: `Votre ${communicationsType} a bien été enregistré.`,
        modified: `Votre ${communicationsType} a bien été modifié.`,
        delete: `Votre ${communicationsType} a bien été supprimé.`,
        approuved: `Votre ${communicationsType} est bien passé au statut approuvé.`,
        published: `Votre ${communicationsType} a bien été publié.`,
        refused: `Votre ${communicationsType} a bien été refusé.`,
        error: `Une erreur s'est produite.`
    }
}