import { GET_DROPDOWNLIST_EMAIL_USER_LIST, GET_CHRONO_USER_LIST_REQUESTED, ACTIVATE_CHRONO_USER_REQUESTED, RESEND_EMAIL_CONFIRMATION_CHRONO_USER } from "../types/types";


export const getChronoUserList = (requestName, queryParams) => ({
    type: GET_CHRONO_USER_LIST_REQUESTED,
    payload: {
        requestName,
        queryParams
    }
});

export const getDropdownListUserEmail = (requestName, queryParams) => ({
    type: GET_DROPDOWNLIST_EMAIL_USER_LIST,
    payload: {
        requestName,
        queryParams
    }
});

export const activateChronoUser = (requestName, queryParams) => ({
    type: ACTIVATE_CHRONO_USER_REQUESTED,
    payload: {
        requestName,
        queryParams
    }
});

export const resendEmailConfirmationChronoUser = (requestName, queryParams) => ({
    type: RESEND_EMAIL_CONFIRMATION_CHRONO_USER,
    payload: {
        requestName,
        queryParams
    }
});
