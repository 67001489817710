/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import useUI from "../../../lib/hooks/useUI";
import useResources from "../../../lib/hooks/useResources";
import * as keys from "../../../redux/keys";
import Grid from "../../../lib/ui/Grid/Grid";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import addUIItem from "../../../redux/actions/addUIItem";
import { useDispatch } from "react-redux";
import { REQUEST_PENDING } from "../../../redux/sagas/requestStatus";
import { LoadingIndicatorInApp } from "../../LoadingIndicator";
import { convertDateToEasterTime } from "../../common/helper";

const ApplicationLogsGrid = () => {
    const dispatch = useDispatch();
    const columns = React.useMemo(
        () => [
            {
                Header: "ID (logtype)",
                accessor: "logType",
            },
            {
                Header: "Os",
                accessor: "os",
            },
            {
                Header: "Date dernier événement",
                accessor: "lastLogDate",
                Cell: props => <span>{props.value ? convertDateToEasterTime(props.value, 'YYYY-MM-DD HH:mm') : ''}</span>
            },
            {
                Header: "Categorie",
                accessor: "category",
            },
            {
                Header: "Nombre d'utilisateurs concernés",
                accessor: "nbUsers",
            },
            {
                Header: "Nombre d'occurence",
                accessor: "nbLogs",
            },
            {
                Header: "Criticité",
                accessor: "criticality",
            },
            {
                Header: "Voir plus",
                accessor: "showMore",
                Cell: (props) => (
                    <div>
                        <Button
                            id={"btnLoadDetail_" + props.row.index}
                            onClick={() => {
                                dispatch(
                                    addUIItem(
                                        {
                                            selectedIndex: props.row.index,
                                            showDetails: true,
                                            id: props.data[props.row.index].logType,
                                        },
                                        keys.SELECTED_APPLICATION_LOG_INDEX
                                    )
                                );
                            }}
                            variant="link"
                            className="message-log-ellipsis"
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </div>
                ),
            },
        ],
        []
    );
    const applicationLogs = useResources(keys.APPLICATION_LOGS) as [];
    const status = useUI(keys.APPLICATION_LOGS_REQUEST_STATUS);
       
    return (
        <Fragment>
            <div className="mt-4" data-testid="application-log-grid">
                {status !== REQUEST_PENDING && (
                    <>
                        {applicationLogs && applicationLogs.length > 0 && <Grid columns={columns} data={applicationLogs} showExport={true} csvFileName={'logsSummary'} csvSeparator={'|'} />}
                        {(!applicationLogs || applicationLogs.length === 0) && (
                            <Row>
                                <Col style={{ textAlign: "center" }}>
                                    <h3>Aucun résultat</h3>
                                </Col>
                            </Row>
                        )}
                    </>
                )}
                {status === REQUEST_PENDING && <LoadingIndicatorInApp />}
            </div>
        </Fragment>
    );
};

export default ApplicationLogsGrid;
