import React, { Fragment, FC, ReactElement, useState, useEffect, forwardRef, useImperativeHandle, ReactNode } from "react";
import { Card, Col, Button } from "react-bootstrap";
import { Slider } from "../../lib/ui/slider/slider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import CommunicationContainer from "./CommunicationContainer";

const CommunicationFormSlider = forwardRef(({ operations, children, handleClose, open }: any, ref): ReactElement => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    return (
        <>
            <Slider
                isOpen={open}
                from={"right"}
                width={"55%"}
                cssClass={"chrono-slider"}
                handleClose={handleClose}
            >
                <div className={"slider-padding-top-alert"} style={{ height: "100%" }}>
                    <CommunicationContainer isProcessing={isProcessing} cssClass={"com-communication-container"}>
                        <div className={"com-communication-container"}>
                            <div className="slider-padding-bottom">
                                <Button
                                    variant="light"
                                    className="mr-0 com-actions-button-width slider-button-close mt-1"
                                    onClick={handleClose}
                                >
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </Button>
                            </div>
                            <>
                                {children &&
                                    React.cloneElement(children, {
                                        ...children["props"],
                                        operations: {
                                            pre: () => {
                                                setIsProcessing(true);
                                                if (operations && operations.pre) operations.pre();
                                            },
                                            post: (isSuccess) => {
                                                setIsProcessing(false);
                                                if (operations && operations.post) operations.post();
                                                if (isSuccess === true && handleClose) handleClose();
                                            },
                                        },
                                    })}
                            </>
                        </div>
                    </CommunicationContainer>
                </div>
            </Slider>
        </>
    );
});

export default CommunicationFormSlider;
