import addResource from "../actions/addResource";
import addUIItem from "../actions/addUIItem";
import { useDispatch } from "react-redux";
import { Types, Categories } from "../keys/generic/types";

export const useCacheService = () => {
    const dispatch = useDispatch();

    const Save = (obj: any, key: string, type: string): void => {
        if (type === Categories.RESOURCES) {
            dispatch(addResource(obj, key));
        } else if (type === Categories.UI) {
            dispatch(addUIItem(obj, key));
        }
    };

    const Remove = (key: string, type: string): void => {
        if (type === Categories.RESOURCES) {
            dispatch(addResource(undefined, key));
        } else if (type === Categories.UI) {
            dispatch(addUIItem(undefined, key));
        } else if (type === Categories.REQUEST) {
            dispatch({
                type: Types.ADD_REQUEST_STATUS,
                payload: {
                    value: undefined,
                    key: key,
                },
            });
        }
    };

    return {
        Save: Save,
        Remove: Remove,
    };
};
