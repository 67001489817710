import type { ResquestsStoreInterface } from "../../types/store";
import * as actionsToDispatch from "../actions";
import { useSelector } from "react-redux";
import { getStore } from "../store";

export const useRequests = (requestName: string) => {
    const store = getStore();
    const requests = useSelector((state: ResquestsStoreInterface) => {
        const requests = state.requests;
        if (requests && requests[requestName]) {
            return requests[requestName];
        } else {
            return null;
        }
    });

    const actions = () => {
        let dispatchers = {};

        Object.keys(actionsToDispatch).forEach(function (callableName) {
            const type = actionsToDispatch[callableName]().type;
            dispatchers[callableName] = function (payload) {
                return store.dispatch({ type: type, payload: payload });
            };
        });

        let actions = { ...dispatchers };

        return actions;
    };

    return { requests, actions };
};
