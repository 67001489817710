
export const getRoutesUrl = (agencyCode): string => {
    if (!agencyCode) return '';

    return `/references/routes?agencyCodeList=${agencyCode}`
};

export const getStopsUrl = (agencyCode, routeId): string => {
    if (!agencyCode || !routeId) return '';

    return `/references/${agencyCode}/routes/${routeId}/arrets`;
};

export const getStopsUrlByRouteId = (agencyCode, routeId): string => {
    if (!agencyCode || !routeId) return '';

    return `/references/${agencyCode}/route/${routeId}/arrets`;
};

export const getStopsByOrgUrl = (agencyCode): string => {
    if (!agencyCode) return '';

    return `/references/${agencyCode}/arrets`;
};

export const getStatisticsDateParams = (startDate, endDate): string => {
    let paramStartDate;
    if (startDate) {
        paramStartDate = toDateStr(startDate);
    }

    let paramEndDate;
    if (endDate) {
        endDate.add(1, 'days');
        paramEndDate = toDateStr(endDate);
    }
    
    const params = {startDate: paramStartDate, endDate: paramEndDate};
    return '?' + new URLSearchParams(Object.entries(params));
};

const toDateStr = (date): string | null => {
    if (!date) return null;
    return (date.month() + 1) + '/' + date.date() + '/' + date.year();
};

