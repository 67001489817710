import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../../actions/types/types";
import * as consoleApi from "../../../api/communication/message";
import addResource from "../../actions/addResource";
import addUIItem from "../../actions/addUIItem";
import * as keys from '../../keys/communication/message';
import * as statusRequest from '../requestStatus';


export function* watchMobileMessage() {
    yield takeEvery(types.SAVE_MESSAGE, saveMessage);
    yield takeEvery(types.GET_CALENDAR_MESSAGES, getCalendarMessages);
}

export function* saveMessage(action) {
    const { queryParams } = action.payload;
    const status = keys.SAVED_MESSAGE_STATUS;

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));

        const result = yield call(
            consoleApi.saveMessage,
            queryParams
        );

        if (!result) {
            throw new Error("Unable to save configuration log");
        }

        yield put(addResource(result, keys.SAVED_MESSAGE_ID));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {

        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}


export function* getCalendarMessages(action) {
    const { queryParams } = action.payload;
    const status = keys.CALENDAR_MESSAGE + '_STATUS';

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));

        const messages = yield call(
            consoleApi.getMessages,
            queryParams
        );

        if (!messages) {
            throw new Error("Unable to get applications log details.");
        }

        yield put(addResource(messages, keys.CALENDAR_MESSAGE));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {
        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}

