import React, { Fragment, useContext } from 'react';
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import { useChronoApi } from "../../../auth/apiEffects";
import { LoadingIndicator } from "../../LoadingIndicator";
import { ThemeContext } from "styled-components";
import GenericTableCell from "../../common/generic/table/cell/GenericTableCell";
import GenericTableHead from "../../common/generic/table/GenericTableHead";
import GenericTable, { GenericTableRow } from "../../common/generic/table/GenericTable";
import GenericTableBody from "../../common/generic/table/GenericTableBody";
import { convertDateToEasterTime } from "../../common/helper";

// const GenericTableBody = styled.tbody`
//     height: calc(100vh - ${TOP_BAR_SIZE}px - ${2 * GENERIC_TABLE_HEADER_SIZE}px);
//     width: calc(100vw - ${MENU_WIDTH}px - ${SCROLLBAR_WIDTH}px);
//     display: block;
//     overflow-y: auto;
// `;
//
// const GenericTable = styled(Table)`
//     table-layout: fixed;
// `;

const MonitoringRealtimePage = () => {
    const theme = useContext(ThemeContext);
    const {data: statistics, isLoading} = useChronoApi('/realtime/agency/statistic');

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Fragment>
            <TopBar title="Monitoring temps réel" />
            <ContentBelowTopBar style={{paddingLeft: 0, paddingRight: 0}}>
                <GenericTable>
                    <GenericTableHead>
                        <GenericTableRow>
                            <th style={{backgroundColor: theme.colors.mediumGray}}>#</th>
                            <th colSpan="3">Perturbations</th>
                            <th colSpan="3">Voyages</th>
                            <th colSpan="3">Véhicules</th>
                        </GenericTableRow>
                        <GenericTableRow>
                            <th style={{backgroundColor: theme.colors.mediumGray}}>Code agence</th>

                            <th>Réussites</th>
                            <th>Exceptions</th>
                            <th>Dernière MAJ</th>

                            <th>Réussites</th>
                            <th>Exceptions</th>
                            <th>Dernière MAJ</th>

                            <th>Réussites</th>
                            <th>Exceptions</th>
                            <th>Dernière MAJ</th>
                        </GenericTableRow>
                    </GenericTableHead>

                    <GenericTableBody>
                        {
                            statistics.map(stat => {
                                const dateFormat = 'DD/MM/YYYY HH:mm';
                                const THRESHOLD = 1000;
                                const needWarning = stat.alertExceptionCount > THRESHOLD
                                    || stat.tripUpdateExceptionCount > THRESHOLD
                                    || stat.vehiclePositionExceptionCount > THRESHOLD;

                                return (
                                    <GenericTableRow key={stat.agencyCode}
                                        style={{backgroundColor: needWarning ? theme.colors.messageError : ''}}>
                                        <GenericTableCell
                                            style={{backgroundColor: theme.colors.mediumGray}}><b>{stat.agencyCode}</b></GenericTableCell>

                                        <GenericTableCell>{stat.alertFeedEntityCount}</GenericTableCell>
                                        <GenericTableCell>{stat.alertExceptionCount}</GenericTableCell>
                                        <GenericTableCell
                                            dateFormat={dateFormat}>{convertDateToEasterTime(stat.alertLastUpdateDate, 'YYYY-MM-DD HH:mm') }</GenericTableCell>

                                        <GenericTableCell>{stat.tripUpdateFeedEntityCount}</GenericTableCell>
                                        <GenericTableCell>{stat.tripUpdateExceptionCount}</GenericTableCell>
                                        <GenericTableCell
                                            dateFormat={dateFormat}>{convertDateToEasterTime(stat.tripUpdateLastUpdateDate, 'YYYY-MM-DD HH:mm')}</GenericTableCell>

                                        <GenericTableCell>{stat.vehiclePositionFeedEntityCount}</GenericTableCell>
                                        <GenericTableCell>{stat.vehiclePositionExceptionCount}</GenericTableCell>
                                        <GenericTableCell
                                            dateFormat={dateFormat}>{convertDateToEasterTime(stat.vehiclePositionLastUpdateDate, 'YYYY-MM-DD HH:mm')}</GenericTableCell>
                                    </GenericTableRow>
                                );
                            })
                        }
                    </GenericTableBody>
                </GenericTable>
            </ContentBelowTopBar>
        </Fragment>
    );
};

export default MonitoringRealtimePage;
