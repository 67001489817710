import React, { useContext } from "react";
import ChronoContext from "../../context/ChronoContext";

export const ROLE_CODENAME_ADMIN = 'Administrateur';
export const ROLE_CODENAME_APPROBATOR = 'Approbateur';
export const ROLE_CODENAME_EDITOR = 'Editeur';
export const ROLE_CODENAME_USER_ARTM = 'UtilisateurARTM';
export const ROLE_CODENAME_USER = 'Utilisateur';
export const ROLE_CODENAME_SUPPORT = 'Support';

const ROLE_LABEL_ADMIN = 'Administrateur';
const ROLE_LABEL_APPROBATOR = 'Approbateur';
const ROLE_LABEL_EDITOR = 'Éditeur';
const ROLE_LABEL_USER_ARTM = 'UtilisateurARTM';
const ROLE_LABEL_USER = 'Utilisateur';
const ROLE_LABEL_SUPPORT = 'Support';

export const LIST_ROLES = [
    {
        codename: ROLE_CODENAME_SUPPORT,
        label: ROLE_LABEL_SUPPORT,
    },
    {
        codename: ROLE_CODENAME_USER,
        label: ROLE_LABEL_USER,
    },
    {
        codename: ROLE_CODENAME_USER_ARTM,
        label: ROLE_LABEL_USER_ARTM,
    },
    {
        codename: ROLE_CODENAME_EDITOR,
        label: ROLE_LABEL_EDITOR,
    },
    {
        codename: ROLE_CODENAME_APPROBATOR,
        label: ROLE_LABEL_APPROBATOR,
    },
    {
        codename: ROLE_CODENAME_ADMIN,
        label: ROLE_LABEL_ADMIN,
    },
];

interface IRoleHelper {
    roleCodenameAllowed: string;
    conditionsAreAllowed?: () => boolean;
    children?: any
}

export const getRoleObjectFromRoleValue = (roles = [], roleValue: string) => {
    let matchingRole = LIST_ROLES.find(role => role.codename === ROLE_CODENAME_USER);
    
    Object.keys(roles).forEach((roleCodename) => {
        if (roles[roleCodename] === roleValue) {
            matchingRole = LIST_ROLES.find(role => role.codename === roleCodename);
        }
    });
    
    return matchingRole;
};

export const isRoleAllowed = (roleUser, roleAllowed) => {
    return roleUser >= roleAllowed;
};

export const isUserRoleInArray = (userRoleName, roleCodeNameArray) => {
    return roleCodeNameArray.includes(userRoleName);
};

export const RoleHelperMustHaveOne = ({ roleCodeNameArray = [], children }) => {
    const staticData = useContext(ChronoContext).staticData;
    const roleUser = staticData.user.profile.role;
    const userRoleName = Object.keys(staticData.roles).find(key => staticData.roles[key] === roleUser);

    if (isUserRoleInArray(userRoleName, roleCodeNameArray)) {
        return children;
    }

    return null;
};

export const RoleHelperInternal = ({ children }) => {
    const staticData = useContext(ChronoContext).staticData;
    const userProfile = staticData.user.profile;

    if (userProfile.isInternalTeam) {
        return children;
    }

    return null;
};


const RoleHelper: React.FC<IRoleHelper> = ({roleCodenameAllowed, conditionsAreAllowed = () => false, children}) => {
    const staticData = useContext(ChronoContext).staticData;
    const roleUser = staticData.user.profile.role;
    const roleAllowed = staticData.roles[roleCodenameAllowed];

    if (conditionsAreAllowed()) {
        return children;
    }
    if (isRoleAllowed(roleUser, roleAllowed)) {
        return children;
    }
    
    return null;
};

export default RoleHelper;
