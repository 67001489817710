import React from 'react';
import { Form } from "react-bootstrap";

const GenericTableCellSwitch = ({onSwitch, id, isActive}) => {
    return (
        <td>
            <Form.Switch
                id={`switch${id}`}
                label=""
                checked={isActive}
                onChange={onSwitch} />
        </td>
    )
};

export default GenericTableCellSwitch;
