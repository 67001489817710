import { addFilterEq, addFilterIn, getUniquenessUser } from "./keenHelper";
import {
    KEEN_DATA_AGENCY_CODE,
    KEEN_DATA_EVENT_TYPE, KEEN_DATA_ROUTE_SHORT_NAME, KEEN_DATA_STOP_CODE, KEEN_DATA_DIRECTION_ID, KEEN_DEFAULT_COLLECTION,
    KEEN_EVENT_ROUTES_SHOWN, KEEN_EVENT_SCHEDULES_SHOWN, KEEN_EVENT_STOPS_SHOWN,
    KEEN_TIMEFRAME_X_DAYS, KEEN_EVENT_MESSAGE_CLICK, KEEN_DATA_MESSAGE_ID
} from "./keenConstants";

export const getCountRoutesShownDays = (days, filterAgencyCodes, isUniquePerUser) => {
    let filters = addFilterEq([], KEEN_DATA_EVENT_TYPE, KEEN_EVENT_ROUTES_SHOWN);
    filters = addFilterIn(filters, KEEN_DATA_AGENCY_CODE, filterAgencyCodes);

    return {
        eventCollection: KEEN_DEFAULT_COLLECTION,
        timeframe: KEEN_TIMEFRAME_X_DAYS(days),
        filters,
        groupBy: KEEN_DATA_AGENCY_CODE,
        ...getUniquenessUser(isUniquePerUser)
    };
};

export const getCountRoutesShown = (dates, filterAgencyCodes, isUniquePerUser) => {
    let filters = addFilterEq([], KEEN_DATA_EVENT_TYPE, KEEN_EVENT_ROUTES_SHOWN);
    filters = addFilterIn(filters, KEEN_DATA_AGENCY_CODE, filterAgencyCodes);
    return {
        eventCollection: KEEN_DEFAULT_COLLECTION,
        timeframe: {
            start: dates.startDate.toISOString(),
            end: dates.endDate.toISOString(),
        },
        filters,
        groupBy: KEEN_DATA_AGENCY_CODE,
        ...getUniquenessUser(isUniquePerUser)
    };
};

export const getCountStopsShown = (dates, filterAgencyCodes, isUniquePerUser) => {
    let filters = addFilterEq([], KEEN_DATA_EVENT_TYPE, KEEN_EVENT_STOPS_SHOWN);
    filters = addFilterIn(filters, KEEN_DATA_AGENCY_CODE, filterAgencyCodes);
    
    return {
        eventCollection: KEEN_DEFAULT_COLLECTION,
        timeframe: {
            start: dates.startDate.toISOString(),
            end: dates.endDate.toISOString(),
        },
        filters,
        groupBy: KEEN_DATA_ROUTE_SHORT_NAME,
        ...getUniquenessUser(isUniquePerUser)
    };
};

export const getCountSchedulesShown = (dates, filterAgencyCodes, filterRoutes, direction, isUniquePerUser) => {
    let filters = addFilterEq([], KEEN_DATA_EVENT_TYPE, KEEN_EVENT_SCHEDULES_SHOWN);
    filters = addFilterIn(filters, KEEN_DATA_AGENCY_CODE, filterAgencyCodes);
    filters = addFilterIn(filters, KEEN_DATA_ROUTE_SHORT_NAME, filterRoutes);
    if (direction && direction != null && direction !== '')
        filters = addFilterEq(filters, KEEN_DATA_DIRECTION_ID, direction);


    return {
        eventCollection: KEEN_DEFAULT_COLLECTION,
        timeframe: {
            start: dates.startDate.toISOString(),
            end: dates.endDate.toISOString(),
        },
        filters,
        groupBy: KEEN_DATA_STOP_CODE,
        ...getUniquenessUser(isUniquePerUser)
    };
};

export const getCountDirectionsShown = (dates, filterAgencyCodes, filterRoutes, filterDirections, isUniquePerUser) => {
    let filters = addFilterEq([], KEEN_DATA_EVENT_TYPE, KEEN_EVENT_STOPS_SHOWN);
    filters = addFilterIn(filters, KEEN_DATA_AGENCY_CODE, filterAgencyCodes);
    filters = addFilterIn(filters, KEEN_DATA_ROUTE_SHORT_NAME, filterRoutes);
    filters = addFilterIn(filters, KEEN_DATA_DIRECTION_ID, filterDirections);

    return {
        eventCollection: KEEN_DEFAULT_COLLECTION,
        timeframe: {
            start: dates.startDate.toISOString(),
            end: dates.endDate.toISOString(),
        },
        filters,
        groupBy: KEEN_DATA_DIRECTION_ID,
        ...getUniquenessUser(isUniquePerUser)
    };
};

export const getCountClickMessage = (messageId, startDate, endDate) => {
    let filters = addFilterEq([], KEEN_DATA_EVENT_TYPE, KEEN_EVENT_MESSAGE_CLICK);
    filters = addFilterEq(filters, KEEN_DATA_MESSAGE_ID, messageId);

    return {
        eventCollection: KEEN_DEFAULT_COLLECTION,
        timeframe: {
            start: startDate,
            end: endDate,
        },
        analysisType: 'count',
        filters
    };
};