/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { PopupModal } from '../../../../lib/ui/index'
import CalendarMessageForm from './calendarMessageForm'
import moment from "moment";

export const CalendarMessagePopup = ({ id, currentDate, showModal, hideShowModal, messageList }) => {
    return (
        <PopupModal
            id={id}
            key={id}
            title={"Messages pour la journée du " + moment(currentDate).format('YYYY-MM-DD')}
            bodyStyle={{ minHeight: '80px', maxHeight:'550px', overflowY:'auto' }}
            show={showModal}
            showFooter={false}
            isSaving={false}
            cssClass={'js-popup-calendar-day'}
            headerStyle={{ width: '800px' }}
            errorMessage={''}
            onHide={() => {
                hideShowModal();
            }
            }>
                <CalendarMessageForm messageList={messageList}/>
        </PopupModal>
    )
};

export default CalendarMessagePopup
