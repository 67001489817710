import React, {Fragment, useEffect, useState} from "react";
import { useChronoApi } from "../../../../auth/apiEffects";
import { getStopsUrlByRouteId } from "../../../../auth/fetchUrlHelper";
import { InputGroup, FormControl } from 'react-bootstrap';
import ChronoMultiSelect from "../../../common/ChronoMultiSelect";
import GenericSelect from '../../../common/generic/GenericSelect';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectStops = ( props ) => {
    const { isDisabled, agencyCode, onStopChange, selectedRouteIdList, allStops, onStopSelect, value, multi=true, newRouteId } = props;
    const [isLoading, setIsLoading] = useState(false);

    const routeId = multi ? selectedRouteIdList[0] : newRouteId

    const { data: stops } = useChronoApi(getStopsUrlByRouteId(agencyCode, routeId))

    useEffect(() => {
        setIsLoading(true);
    }, [routeId]);

    useEffect(() => {
        if (allStops)
            onStopChange(stops);

        setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stops])

    const formatGroupLabel = data => (
        <div>
          <span>{data.label}</span>
          <span>{data.value}</span>
        </div>
    )

    if (!multi) {
        return (
            <div style={{ width: '100%' }}>
            {
                !isLoading && isLoading === false &&
                <GenericSelect title="Arrêt" isDisabled={isDisabled} {...props}>
                    <option key="all" value="" />
                    {
                        stops.map((stop, i) =>
                            <option key={stop.stopId + '_' + i} value={stop.stopId}>{stop.code} - {stop.name} ({stop.direction})</option>
                        )}
                </GenericSelect>
            }
            {
                isLoading === true &&
                <InputGroup>
                    <InputGroup.Prepend style={{ minWidth: 130 }}>
                        <InputGroup.Text style={{ minWidth: 130 }}>
                                {"Arrêt"}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl as="div" style={{ paddingTop: '10px', paddingLeft: '5px' }}>
                        <FontAwesomeIcon icon={faSpinner} spin={true} />
                    </FormControl>
                </InputGroup>
            }
            </div>
        );
    }
    
    return (
        <Fragment>
            <InputGroup>
                <InputGroup.Prepend style={{minWidth: 130}}>
                    <InputGroup.Text style={{minWidth: 130}}>{'Arrêt'}</InputGroup.Text>
                </InputGroup.Prepend>
                {
                    !isLoading && isLoading === false &&
                    <FormControl as="div" style={{ padding: 0, display: 'inline-table' }}>
                        <ChronoMultiSelect
                            isDisabled={isDisabled}
                            isMulti
                            value={value}
                            closeMenuOnSelect={false}
                            onChange={onStopSelect}
                            options={allStops}
                            formatGroupLabel={formatGroupLabel}
                            isSearchable={true}
                        />
                    </FormControl>
                }
                {
                    isLoading === true &&
                    <FormControl as="div" style={{ paddingTop: '10px', paddingLeft: '5px' }}>
                        <FontAwesomeIcon icon={faSpinner} spin={true} />
                    </FormControl>
                }
            </InputGroup>
        </Fragment>
    )
};

export default SelectStops;
