/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TitleCard } from "../../home/cards/BaseCardHome";
import ChronoXYPlot from "../../common/ChronoXYPlot";
import { getTextWidth } from "../../common/helper";
import { GraphsCard, StatsContainer } from "../global/StatsComponents";
import { sendGetRequest } from "../../../auth/apiEffects";
import DiscreteColorLegend from 'react-vis/dist/legends/discrete-color-legend';
import { FormControl, InputGroup, Row, Col } from "react-bootstrap";
import { LoadingIndicatorInApp } from "../../LoadingIndicator";
import moment from 'moment';
import { AnySchema } from 'yup';

const translateData = (dataList) => {
    if (dataList) {
        return dataList
            .map((d) => ({
                x: d.stopShortName,
                y: d.amountFavorite
            }));
    }

    return [];
};

const DIRECTIONS_ROUTES = [{ directionId: 0, directionName: 'Direction 0' },
                           { directionId: 1, directionName: 'Direction 1' }]

const StatsStopsShown = ({ filterAgency, filterRoute, currentDates, isUserHavingFavoriteMode}) => {
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [direction, setDirection] = useState<any>('');
    const [directionList, setDirectionList] = useState<any>('');

    useEffect(() => {
        async function fetchDirection() {
            const directionList = await sendGetRequest(`/references/${filterAgency}/routes/${filterRoute}/directions`);
            setDirectionList((directionList as any)?.length > 0 ? directionList : DIRECTIONS_ROUTES);
        }
        fetchDirection();
    }, [filterRoute]);

    const initFavoris = {
        organisation: '',
        routeID: '',
        routeNomCourt: '',
        arretID: '',
        arretCode: '',
        direction: ''
    };

    const [favorisList, setFavorisList] = useState<any>(initFavoris);
    const [translatedFavorisList, setTranslatedFavorisList] = useState<any>();
    const title = (isUserHavingFavoriteMode ? 'Utilisateurs ayant un favoris' : 'Ajouts de favoris') + ' par arrêt';
    const ITEMS = isUserHavingFavoriteMode ? ['Actifs'] : ['Nb favoris']

    useEffect(() => {
        setIsDataLoaded(false);

        async function fetchData() {
            const favorisList = await sendGetRequest(`/statistics/favoriteAmountByStop?organizationCode=${filterAgency}
                                                 &routeShortName=${filterRoute}
                                                 &directionCode=${direction}
                                                 &startDate=${moment(currentDates.startDate).format("YYYY-MM-DD HH:mm")}
                                                 &endDate=${moment(currentDates.endDate).format("YYYY-MM-DD HH:mm")}
                                                 &userHavingFavorite=${isUserHavingFavoriteMode}`
            );

            setFavorisList(favorisList);
            setIsDataLoaded(true)
        }
        fetchData();
    }, [direction, filterRoute, isUserHavingFavoriteMode]);

    useEffect(() => {
        if (favorisList.length > 0) {
            setTranslatedFavorisList(translateData(favorisList));
        }
    }, [favorisList])

    useEffect(() => {
        setDirection('');
    }, [filterAgency, filterRoute, currentDates]);

    const marginBottom = getTextWidth('abcdefghijklmnopqrstuvwxyz', '13px arial');

    const onDirectionSelect = evt => {
        setDirection(evt.target.value)
    }

    const  getCsvData = (dataList) => {
        let results: { Organisation: string; Route: string; 'Nombre de favoris': string; 'Arrêt': string }[] = [];
        dataList.forEach(row => {
            results.push(
                {
                    Organisation: filterAgency,
                    Route: filterRoute,
                    'Arrêt': row.stopShortName,
                    'Nombre de favoris': row.amountFavorite
                });
        });

        return results;
    }

    return (
        <StatsContainer>
            <GraphsCard>
                <Row>
                    
                    <div style={{textAlign:'center', width:'100%', paddingBottom:'15px'}}>
                        <TitleCard>{ title }</TitleCard>
                    </div>

                </Row>
                <Row style={{paddingBottom:'15px'}}>
                    <Col>
                        <DiscreteColorLegend height={60} width={100} items={ITEMS} style={{ marginBottom: -10 }} orientation={'vertical'} />
                    </Col>
                    <Col>
                        {<InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Direction</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl as="select" value={direction} onChange={onDirectionSelect}>
                                <option key="emptyChoice" value="" />
                                {
                                    directionList &&
                                    directionList.map(item => <option key={item.directionId} value={item.directionId}>{item.directionName}</option>)
                                }
                            </FormControl>
                        </InputGroup>}

                    </Col>
                    
                </Row>
                {
                    !isDataLoaded &&
                    <LoadingIndicatorInApp style={{paddingTop:'15px'}} />
                }
                {
                    isDataLoaded && translatedFavorisList.length === 0 &&
                    <h4 className="text-center" style={{ padding: 150 }}>Aucune données à afficher</h4>
                }
                { 
                    isDataLoaded && translatedFavorisList.length > 0 &&
                    <ChronoXYPlot data={translatedFavorisList} csvData={getCsvData(favorisList)} csvFileName={'statsStops'} showExport={true} isAxisYFixed={true} tickLabelAngle={-60}
                        margin={{ bottom: marginBottom }}
                        height={430}
                    />
                }
            </GraphsCard>
        </StatsContainer>
    );
};

export default StatsStopsShown;