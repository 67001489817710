import React, { Fragment, useState, useEffect } from 'react';
import GenericCell from "../../common/generic/table/cell/GenericCell";
import { sendPostRequest, sendGetRequest } from "../../../auth/apiEffects";
import { LoadingIndicatorInApp } from "../../LoadingIndicator";
import { Row, Col, Table, Badge } from 'react-bootstrap';
import { faSpinner, faSave, faTimes, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const Header = styled.div`
    font-size: 16px;
    font-weight: bold;
    padding: 5px 0px 15px 5px;
    text-align: left;
    background-color:#EFF1F1;
    height:50px;
`

const MappingIconeLegende = () => {

    const [icones, setIcones] = useState([]);
    const [originalIcones, setOriginalIcones] = useState([]);

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [show, setShow] = useState(false);


    useEffect(() => {
        async function fetchData() {
            const resultIcones = await sendGetRequest('/admin/achalandage/icone/liste');
            const formattedResultIcones = resultIcones ? resultIcones.map((obj) => {
                                                                    return { ...obj, isEdit: false, isSending: false, isInvalidDescriptionFR: false, isInvalidDescriptionEN: false };
                                                                })
                                                        : [];

            const iconesCopy = formattedResultIcones.map((icone) => {
                return { ...icone };
            });

            setIcones(formattedResultIcones);
            setOriginalIcones(iconesCopy);
            setIsDataLoaded(true);
        }
        fetchData();
    }, []);


    useEffect(() => {

        if (!isDataLoaded)
            return;

        setShow(isDataLoaded);
    }, [isDataLoaded]);


    const onDescriptionFRChange = (value, rowId) => {
        const iconeList = [...icones];
        const icone = iconeList.find(x => x.iconeAchalandageId === rowId);

        if (icone)
            icone.descriptionFR = value;

        setIcones(iconeList);
    }

    const onDescriptionENChange = (value, rowId) => {
        const iconeList = [...icones];
        const icone = iconeList.find(x => x.iconeAchalandageId === rowId);

        if (icone)
            icone.descriptionEN = value;

        setIcones(iconeList);
    }

    const onEdit = (iconeId) => {
        const iconeList = [...icones];
        const icone = iconeList.find(x => x.iconeAchalandageId === iconeId);
        if (icone)
            icone.isEdit = true;

        setIcones(iconeList);

    }
    const setIsSending = (isSending, icone, iconeList) => {
        icone.isSending = isSending;
        icone.isEdit = false;
        setIcones(iconeList);
    }

    const checkIsValid = (icone, iconeList) => {

        icone.isInvalidDescriptionFR = !icone.descriptionFR || icone.descriptionFR.trim().length === 0 || icone.descriptionFR.trim().length > 150;
        icone.isInvalidDescriptionEN = !icone.descriptionEN || icone.descriptionEN.trim().length === 0 || icone.descriptionEN.trim().length > 150;

        if (icone.isInvalidDescriptionFR || icone.isInvalidDescriptionEN)
            setIcones(iconeList);

        return !(icone.isInvalidDescriptionFR || icone.isInvalidDescriptionEN);

    }

    const onSave = async (rowId) => {
        const iconeList = [...icones];
        const icone = iconeList.find(x => x.iconeAchalandageId === rowId);
        const originalIcone = originalIcones.find(x => x.iconeAchalandageId === rowId);

        if (!icone || !checkIsValid(icone, iconeList))
            return;

        originalIcone.descriptionEN = icone.descriptionEN;
        originalIcone.descriptionFR = icone.descriptionFR;

        setIsSending(true, icone, iconeList);
        const dataToSend = { iconeAchalandageId: icone.iconeAchalandageId, descriptionFR: icone.descriptionFR, descriptionEN: icone.descriptionEN };

        await sendPostRequest('/admin/achalandage/icone/sauvegarde', dataToSend);
        setIsSending(false, icone, [...iconeList]);
    }

    const onCancel =  (rowId) => {

        const iconeList = [...icones];
        const icone = iconeList.find(x => x.iconeAchalandageId === rowId);
        const originalIcone = originalIcones.find(x => x.iconeAchalandageId === rowId);
        console.log(originalIcones);
        if (icone && originalIcone) {
            icone.descriptionFR = originalIcone.descriptionFR;
            icone.descriptionEN = originalIcone.descriptionEN;
            icone.isEdit = false;
            icone.isInvalidDescriptionEN = false;
            icone.isInvalidDescriptionFR = false;
        }

        setIcones(iconeList);
    }

    if (!show) {
        return <LoadingIndicatorInApp />;
    }

   
    return ( 
        <Fragment>
            {
                icones.length > 0 &&
                <Table>
                    <tbody>
                        {
                            icones.map((item, index) =>
                                <tr key={index}>
                                    <td style={{ padding: '5px'}}><img src={item.urlIcone} alt="" /></td>
                                    <td style={{ padding: '5px' }}><GenericCell maxSize={150} disabled={item.isSending} invalid={item.isInvalidDescriptionFR} isEdit={item.isEdit} value={item.descriptionFR} rowId={item.iconeAchalandageId} onChange={onDescriptionFRChange}/></td>
                                    <td style={{ padding: '5px' }}><GenericCell maxSize={150} disabled={item.isSending} invalid={item.isInvalidDescriptionEN} isEdit={item.isEdit} value={item.descriptionEN} rowId={item.iconeAchalandageId} onChange={onDescriptionENChange}/></td>
                                    {
                                        !item.isEdit && !item.isSending &&
                                        <td style={{ padding: '5px' }}>
                                            <Badge title={'Modifier'} style={{ cursor: 'pointer' }} pill variant="info" onClick={() => onEdit(item.iconeAchalandageId)}>
                                                <FontAwesomeIcon title={'Modifier'} icon={faPen} />
                                            </Badge>
                                           
                                        </td>
                                    }

                                    {
                                        item.isEdit && 
                                        <td style={{ padding: '5px' }}>
                                            <Badge title={'Enregistrer'} style={{ cursor: 'pointer' }} pill variant="success" onClick={() => onSave(item.iconeAchalandageId)}>
                                                <FontAwesomeIcon title={'Enregistrer'} icon={faSave} />
                                            </Badge>

                                            <Badge title={'Annuler'} style={{ marginLeft: '5px', cursor: 'pointer' }} pill variant="danger" onClick={() => onCancel(item.iconeAchalandageId)}>
                                                <FontAwesomeIcon title={'Annuler'}  icon={faTimes} />
                                            </Badge>
                                           
                                            
                                        </td>
                                    }

                                    {
                                        item.isSending &&
                                        <td style={{ padding: '5px' }}>
                                            <Badge title={'Sauvegarde en cours'} pill variant="secondary">
                                                <FontAwesomeIcon title={'Sauvegarde en cours'} icon={faSpinner} spin={true} />
                                            </Badge>
                                        </td>
                                    }
                                    
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            }
            {
                (!icones || icones.length === 0) &&
                <Row>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <h3>Aucun résultat</h3>
                    </Col>
                </Row>
            }

        </Fragment>
    )
}

export default MappingIconeLegende;