import { MsalAuthProvider, LoginType } from "react-aad-msal";
import env from '../env'

let authConfig;
let authProvider: MsalAuthProvider;
let authRequest;
let helper;

const getImportSignature = async () => {
    const environmentHelperFile = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" ? "helper_development" : "helper";
    return await import("../auth/" + environmentHelperFile + ".ts");
}

export const fetchConfig = async () => {
    if (!helper)
        helper = await getImportSignature();

    const sig = await helper.getSignature();
    const response = await fetch(env.baseApiUrl + '/Auth/getconfig',
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Signature': sig
            }
        });
    const config = await response.json();

    authRequest = {
        scopes: ['user.read', 'email', 'profile', 'openid', 'Mail.Send', config.audience]
    };

    authConfig = getAuthConfig(config.tenantId, config.clientId, config.authority);

    authProvider = new MsalAuthProvider(
        authConfig,
        authRequest,
        { loginType: LoginType.Redirect }
    );
};

const getAuthConfig = (tenantId, clientId, authority) => {
    return {
        auth: {
            authority: authority,
            clientId: clientId,
            postLogoutRedirectUri: window.location.origin,
            redirectUri: window.location.origin,
            validateAuthority: false,
            navigateToLoginRequestUrl: false,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: true,
        },
    }
};

export const getUserInfo = () => {
    let userInfo = authProvider?.getAccountInfo();
    return {
        name: userInfo?.account?.name,
        email: userInfo?.account?.userName
    };
};

export const logout = () => {
    authProvider.logout();
};

export const fetchWithProvider = async (fetch, url, options) => {
    const token = await authProvider.getIdToken();

    let expiry = Math.floor(Date.now() / 1000);
    if(!token?.idToken || parseInt(token.idToken.expiration) < expiry) {        
        console.log('IdToken expired.');
        authProvider.login();
    }

    if (!helper)
        helper = await getImportSignature();

    var sig = await helper.getSignature();
    if (!options)
        options = {};

    if (options.headers) {
        options.headers['Authorization'] = 'Bearer ' + token.idToken.rawIdToken;
        options.headers['Signature'] = sig;
    }

    else
        options.headers = {
            'Authorization': 'Bearer ' + token.idToken.rawIdToken,
            'Signature': sig
        };
    return fetch(url, options);
};


export { authConfig, authProvider, authRequest }
