/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactElement} from "react";
import { InputGroup,  FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import "./Dropdown.css";

const DropdownSearch: FC<any> = (props):ReactElement => {
  
  const { onChangeSearch } = props;

  return (
    <div className="filterFlexItem spaceRight">
      <InputGroup className="mb-2">
        <InputGroup.Prepend>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          id="inlineFormInputGroup"
          placeholder="Search"
          onChange={onChangeSearch}
        />
      </InputGroup>
    </div>
  );
};

export default DropdownSearch;
