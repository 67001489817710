import React, { FC, ReactElement} from "react";
import moment from "moment";

export const CalendarDay: FC<any> = ({ children, month, currentDate, firstDateOfWeek, lastDateOfWeek, noWeek, events, eventrenderer, nbWeek, handleClick }: any): ReactElement => {
    return (
        <div className={`container-calendar-day ${moment(currentDate).month() === month ? 'container-calendar-current-month' :'container-calendar-other-month'}`}>
            <div className='body-calendar-day' onClick={() => {
                if (handleClick)
                    handleClick(currentDate, events);
            }}>
                    <div className='calendar-day'>
                            {
                                moment(currentDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") &&
                                <span className="badge badge-pill badge-primary">{moment(currentDate).date()}</span>
                            }

                            {
                                moment(currentDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD") &&
                                <span>{moment(currentDate).date()}</span>

                            }
                    </div>

                    {
                        events && events.length > 0 && eventrenderer &&
                        eventrenderer(events, currentDate, firstDateOfWeek, lastDateOfWeek, noWeek, nbWeek)
                    }
                </div>
                {
                    children &&
                    <div className='bottom-calendar-day'>
                    {
                        
                        React.cloneElement(children,
                            { eventData: events, currentDate: currentDate }, null) 
                    }
                    </div>
                }
                
            </div>
    );
};

export default CalendarDay;
