export const KEEN_DEFAULT_COLLECTION = 'chrono_events';

export const KEEN_TIMEFRAME_X_DAYS = (days) => `this_${days}_days`;

export const KEEN_DATA_EVENT_TYPE = 'event_type';
export const KEEN_DATA_USER_ID = 'user_id';
export const KEEN_DATA_AGENCY_CODE = 'agency_code';
export const KEEN_DATA_ROUTE_SHORT_NAME = 'route_short_name';
export const KEEN_DATA_ROUTE_ID = 'route_id';
export const KEEN_DATA_STOP_CODE = 'stop_code';
export const KEEN_DATA_STOP_ID = 'stop_id';
export const KEEN_DATA_DIRECTION_ID = 'direction_id';
export const KEEN_DATA_DIRECTION_NAME = 'direction_name';
export const KEEN_DATA_MESSAGE_ID = 'message_id';

export const KEEN_EVENT_ROUTES_SHOWN = 'agency_routes_shown';
export const KEEN_EVENT_STOPS_SHOWN = 'route_stops_shown';
export const KEEN_EVENT_SCHEDULES_SHOWN = 'schedule_shown';
export const KEEN_EVENT_MESSAGE_CLICK = 'message_click';
