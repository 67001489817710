/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import GenericDate from "../../../../common/generic/GenericDate";
import GenericArea from "../../../../common/generic/GenericArea";
import GenericInput from "../../../../common/generic/GenericInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faSave } from "@fortawesome/free-solid-svg-icons";
import CommunicationFormHeader from "../../../CommunicationFormHeader";
import { getFormattedDate } from "../../../../common/helper";
import { DATE_FORMAT } from "../../../CommunicationUtils";
import { CODENAME_STATUS_CREATED } from "../../../../../statusUtils";
import GenericTime from "../../../../common/generic/GenericTime";
import CommunicationFormActionButtons, {
    TYPE_REQUEST_PUBLISH,
    TYPE_REQUEST_REFUSE,
    TYPE_REQUEST_SAVE,
    TYPE_REQUEST_APPROVE
} from "../../../CommunicationFormActionButtons";
import CenteredComponent from "../../../../common/CenteredComponent";
import moment from "moment";
import { CommunicationContainer } from "../../../CommunicationContainer";
import RoleHelper, { ROLE_CODENAME_EDITOR } from "../../../../common/RoleHelper";
import { Button, ButtonGroup, Col, Dropdown, Form, Row } from "react-bootstrap";
import { showPopupConfirm } from "../../../../common/Popups";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage, FormRow, LineSummary } from "../../../../../lib/ui";
import RouteDropdownStep from "./RouteDropdownStep";
import AgencyDropdownStep from "./AgencyDropdownStep";
import StopDropdownStep from "./StopDropdownStep";
import MessageMultiStepsSummary from "./MessageMultiStepsSummary";
import { useMessageService } from "service/communication/useMessageService";
import { MessageForm as MessageFormType } from "../../../../../types/forms/MessageForms";
import { useCommunicationEvent } from "components/communication/communicationEvent";
import { MessageFormProps } from "types/components/MessageComponent";
import ChronoContext from "context/ChronoContext";
import { messageFormValidator } from "../../validators/messageFormValidator";
import { useRole } from "../../../../../lib/hooks/useRole";
import { EMPTY_MESSAGE } from "../../MessageConstants";
import styled from "styled-components";

export const ContainerFeedOnly = styled.div`
    border: 1px solid ${(props) => props.theme.colors.mediumGray};
    border-radius: 4px;
    width: 100%;
    padding: 0 20px;
`;

const colorWhite = "#FFFFFF";
const colorBlack = "#000000";

const DEFAULT_COLORS = {
    backgroundColor: colorBlack,
    fontColor: colorWhite,
};

export const MessageForm: FC<MessageFormProps> = (props: MessageFormProps) => {
    const [publishAfterSave, setPublishAfterSave] = useState(false);
    const messageService = useMessageService();
    const entity = props.entity as MessageFormType;
    const { isMessageCopy, setEntity, isMessageEditable } = props;
    const context = useContext(ChronoContext);
    const status = context.staticData.status;
    const agencies = context.staticData != null ? context.staticData.user.agencies : "";
    const { roleApprobator, canUserEdit, hasRoleApprobator, hasRoleUserARTM } = useRole();
    const { operations } = props;

    const { watch, control, errors, formState, handleSubmit, setValue, register, reset } = useForm<any>({
        defaultValues: useMemo(() => {
            return entity;
        }, [entity]),
        resolver: yupResolver(messageFormValidator),
        reValidateMode: "onChange",
        mode: "onChange",
    });

    const values = watch();

    useEffect(() => {
        reset(entity);
    }, [entity]);

    useEffect(() => {
        return () => {
            setEntity(EMPTY_MESSAGE);
        };
    }, []);

    useEffect(() => {
        setValue("isVisibleInFeedOnly", !values || !values.routeList || values.routeList.length <= 0);
    }, [watch("routeList")]);

    useCommunicationEvent(
        {
            executeCallback: async () => {
                if (messageService.request.Save.data && entity.id > 0) {
                    messageService.updateModel(messageService.request.Save.data);
                } else {
                    //messageService.request.Save.refreshState(0);
                    if(publishAfterSave && messageService.request.Save.data) {
                        operations.pre();

                        setPublishAfterSave(false);
                       
                       let createdEntityModel = await messageService.mapToModel({ ...values,  colors: DEFAULT_COLORS, id: messageService.request.Save.data.id!, refusedReason: "" }, 3)
                        messageService.request.Publish.process(createdEntityModel)                        
                    }
                   
                }
                operations.post(true);
            },
        },
        messageService.request.Save.isSuccess,
        messageService.request.Save.isFail
    );

    useCommunicationEvent(
        {
            executeCallback: () => {
                if (messageService.request.Refuse.data && entity.id > 0) {
                    messageService.updateModel(messageService.request.Refuse.data);
                } else {
                    messageService.request.Refuse.refreshState(0);
                }
                operations.post(true);
            },
        },
        messageService.request.Refuse.isSuccess,
        messageService.request.Refuse.isFail
    );

    //handle event delete notif
    useCommunicationEvent(
        {
            successCallback: () => {
                messageService.removeElement(entity.id);
                operations.post(true);
            },
            afterPost: () => {
                messageService.request.Delete.refreshState(entity.id);
            },
        },
        messageService.request.Delete.isSuccess,
        messageService.request.Delete.isFail
    );

    //handle event publish notif
    useCommunicationEvent(
        {
            executeCallback: () => {
                console.log(messageService.request.Publish.data)

                messageService.updateModel(messageService.request.Publish.data);
                operations.post(true);
            },
            afterPost: () => {
                messageService.request.Publish.refreshState(entity.id);
                operations.post(true);
            },
        },
        messageService.request.Publish.isSuccess,
        messageService.request.Publish.isFail
    );

    const onClickSend = async (requestType: number, refusedReason = ""): Promise<void> => {
        operations.pre();
        let model;

        if (isMessageCopy) {
            model = messageService.mapToModel({ ...values, colors: DEFAULT_COLORS, id: 0, refusedReason }, requestType);
        } else {
            model = messageService.mapToModel({ ...values, colors: DEFAULT_COLORS, id: entity.id, refusedReason }, requestType);
        }
        setPublishAfterSave(requestType === TYPE_REQUEST_PUBLISH && model.id === 0)
        let overlappingMessage;
        console.log(entity);
        if (!isMessageEditable) {
            overlappingMessage = await messageService.hasOverlappingMessages(model);
        }

        if (overlappingMessage) {
            showPopupConfirm({
                message:
                    "Attention, un message pour " +
                    overlappingMessage +
                    " existe déjà pour la période sélectionnée. Un seul message peut apparaître à la fois, voulez-vous continuer ?",
                onClickYes: () => {
                    messageService.request.Save.process(model)
                },
                onClickNo: () => operations.post(),
                labelNo: "Annuler",
                labelYes: "Oui",
            });
        } else {
            if ((requestType === TYPE_REQUEST_PUBLISH || requestType === TYPE_REQUEST_APPROVE) && model.id > 0) {
                messageService.request.Publish.process(model);
            } else if (requestType === TYPE_REQUEST_REFUSE) {
                messageService.request.Refuse.process(model);
            } else {
                messageService.request.Save.process(model);
            }
        }
    };

    const onClickDelete = (): void => {
        operations.pre();
        messageService.request.Delete.process(entity.id);
    };

    return (
        <CommunicationContainer>
            <CommunicationFormHeader data={entity} isNew={entity.id === 0} agencies={agencies} isCopy={isMessageCopy} />

            <Form
                onSubmit={handleSubmit(() => {
                    onClickSend(TYPE_REQUEST_SAVE);
                })}
            >
                <div>
                    <FormRow style={{ marginBottom: 0 }}>
                        <Col xs={12} style={{ padding: 0 }}>
                            <strong>Sélectionnez une date et heure de début *</strong>
                        </Col>
                    </FormRow>
                    {!values.isNow && (
                        <FormRow style={{ marginTop: 10, marginBottom: 10 }}>
                            <Col xl={6} md={12} style={{ paddingLeft: 0 }}>
                                <Controller
                                    control={control}
                                    name="startDate"
                                    value={values.startDate}
                                    render={({ onChange, value }) => {
                                        return (
                                            <GenericDate
                                                cssClass={"js-start-date"}
                                                isRequired
                                                label="Début"
                                                minDate={new Date()}
                                                date={
                                                    value && getFormattedDate(value, DATE_FORMAT)
                                                    // : setValue("startDate", getFormattedDate(moment(), DATE_FORMAT))
                                                }
                                                onDateChange={onChange}
                                                width={80}
                                                canUserEdit={canUserEdit}
                                            />
                                        );
                                    }}
                                />
                                {errors.startDate && <ErrorMessage>{errors.startDate.message}</ErrorMessage>}
                            </Col>
                            <Col xl={6} md={12} style={{ paddingRight: 0 }}>
                                <Controller
                                    control={control}
                                    name="startTime"
                                    value={moment(values.startTime).format("HH:mm")}
                                    render={({ onChange, value }, { invalid }) => {
                                        return (
                                            <GenericTime
                                                cssClass={"js-start-time"}
                                                isRequired
                                                label="Heure"
                                                time={value}
                                                onTimeChange={onChange}
                                                canUserEdit={canUserEdit}
                                            />
                                        );
                                    }}
                                />
                                {errors.startTime && <ErrorMessage>{errors.startTime.message}</ErrorMessage>}
                            </Col>
                        </FormRow>
                    )}
                </div>
                {!values.isNow && (
                    <FormRow>
                        <Col style={{ paddingLeft: 0 }}>
                            <strong>Ou</strong>
                        </Col>
                    </FormRow>
                )}
                <FormRow style={{ marginTop: "5px" }}>
                    <Col style={{ paddingLeft: 0 }}>
                        <Controller
                            control={control}
                            name="isNow"
                            value={values.isNow}
                            render={({ onChange, value }, { invalid }) => {
                                return (
                                    <Form.Switch
                                        id={"startDateSwitch"}
                                        label="Maintenant"
                                        style={{ color: "black" }}
                                        checked={value}
                                        onChange={(e) => setValue("isNow", e.target.checked)}
                                    />
                                );
                            }}
                        />
                    </Col>
                </FormRow>
                <FormRow style={{ marginBottom: 0 }}>
                    <Col xs={12} style={{ padding: 0 }}>
                        <strong>Sélectionnez une date et heure de fin *</strong>
                    </Col>
                </FormRow>

                <FormRow>
                    <Col xl={6} md={12} style={{ paddingLeft: 0 }}>
                        <Controller
                            control={control}
                            name="endDate"
                            value={values.endDate}
                            render={({ onChange, value }, { invalid }) => {
                                return (
                                    <GenericDate
                                        cssClass={"js-end-date"}
                                        isRequired
                                        label="Fin"
                                        minDate={new Date()}
                                        date={value && getFormattedDate(value, DATE_FORMAT)}
                                        onDateChange={onChange}
                                        width={80}
                                        canUserEdit={canUserEdit}
                                    />
                                );
                            }}
                        />
                        {errors.endDate && <ErrorMessage>{errors.endDate.message}</ErrorMessage>}
                    </Col>
                    <Col xl={6} md={12} style={{ paddingRight: 0 }}>
                        <Controller
                            control={control}
                            name="endTime"
                            value={moment(values.endTime).format("HH:mm")}
                            render={({ onChange, value }, { invalid }) => {
                                return (
                                    <GenericTime
                                        cssClass={"js-end-time"}
                                        isRequired
                                        label="Heure"
                                        time={value}
                                        onTimeChange={onChange}
                                        canUserEdit={canUserEdit}
                                    />
                                );
                            }}
                        />
                        {errors.endTime ? <ErrorMessage>{errors.endTime.message}</ErrorMessage> : null}
                    </Col>
                </FormRow>
                <FormRow style={{ marginBottom: 0 }}>
                    <LineSummary
                        title="Organisations, lignes et arrêts"
                        eventKey="messageMulti"
                        valueList={[values.agencyCodeList, values.routeList, values.arretList]}
                        customSummary={<MessageMultiStepsSummary />}
                    >
                        <AgencyDropdownStep
                            name="agencyCodeList"
                            value={values.agencyCodeList}
                            onChange={(res) => {
                                setValue("agencyCodeList", res);
                            }}
                            {...register("agencyCodeList")}
                        />
                        <RouteDropdownStep
                            name="routeList"
                            value={values.routeList}
                            onChange={(res) => {
                                setValue("routeList", res);
                            }}
                            {...register("routeList")}
                        />
                        <StopDropdownStep
                            name="arretList"
                            value={values.arretList}
                            onChange={(res) => {
                                setValue("arretList", res);
                            }}
                            {...register("arretList")}
                        />
                    </LineSummary>
                </FormRow>
                <FormRow style={{ marginBottom: 0 }}>
                    <strong>Cible (utilisateurs ayant pour favoris)</strong>
                </FormRow>
                <FormRow>
                    <ContainerFeedOnly>
                        <CenteredComponent>
                            <Form.Group controlId="isVisibleFeedOnly" style={{ margin: "auto" }}>
                                <Controller
                                    control={control}
                                    name="isVisibleInFeedOnly"
                                    value={values.isVisibleInFeedOnly}
                                    render={({ onChange, value }) => {
                                        return (
                                            <Form.Check
                                                type="checkbox"
                                                label="Visible seulement dans le fil d'actualité?"
                                                disabled={!canUserEdit || (values.routeList && values.routeList.length <= 0)}
                                                checked={
                                                    value || (values.routeList && values.routeList.length <= 0 ? true : false)
                                                }
                                                onChange={(e) => setValue("isVisibleInFeedOnly", !value)}
                                            />
                                        );
                                    }}
                                />
                            </Form.Group>
                            <small>
                                <i>
                                    <FontAwesomeIcon style={{ color: "orange" }} icon={faExclamationTriangle} />
                                    &nbsp; En ne sélectionnant pas de ligne, le message ne sera visible que dans le fil
                                    d'actualité
                                </i>
                            </small>
                        </CenteredComponent>
                    </ContainerFeedOnly>
                </FormRow>
                <FormRow style={{ marginBottom: 0 }}>
                    <strong>Message Français</strong>
                </FormRow>
                <FormRow style={{ marginTop: 10 }}>
                    <Controller
                        control={control}
                        name="contentFR"
                        value={values.contentFR}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <GenericArea
                                    cssClass={"js-mess-fr"}
                                    value={value}
                                    isRequired
                                    label="Message"
                                    maxLength={150}
                                    feedback="Veuillez écrire un message français"
                                    canUserEdit={canUserEdit}
                                    onChange={onChange}
                                />
                            );
                        }}
                    />
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="urlFR"
                        value={values.urlFR}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <GenericInput
                                    cssClass={"js-url-fr"}
                                    label="Url (Français)"
                                    value={value}
                                    maxLength={512}
                                    placeholder="Lien URL"
                                    onChange={onChange}
                                />
                            );
                        }}
                    />
                    {errors.urlFR && <ErrorMessage>{errors.urlFR.message}</ErrorMessage>}
                    {values.urlFR && !values.urlFR.startsWith("http") && values.urlFR.length > 0 && (
                        <ErrorMessage>Veuillez composer le URL commençant avec http://</ErrorMessage>
                    )}
                </FormRow>
                <FormRow style={{ marginBottom: 0 }}>
                    <strong>Message Anglais</strong>
                </FormRow>
                <FormRow style={{ marginTop: 10 }}>
                    <Controller
                        control={control}
                        name="contentEN"
                        value={values.contentEN}
                        render={({ onChange, value }) => {
                            return (
                                <GenericArea
                                    cssClass={"js-mess-en"}
                                    isRequired
                                    label="Message"
                                    value={value}
                                    maxLength={150}
                                    feedback="Veuillez écrire un message anglais"
                                    canUserEdit={canUserEdit}
                                    onChange={onChange}
                                />
                            );
                        }}
                    />
                </FormRow>
                <FormRow>
                    <Controller
                        control={control}
                        name="urlEN"
                        value={values.urlEN}
                        render={({ onChange, value }, { invalid }) => {
                            return (
                                <GenericInput
                                    cssClass={"js-url-en"}
                                    label="Url (Anglais)"
                                    value={value}
                                    maxLength={150}
                                    placeholder="Lien URL"
                                    onChange={onChange}
                                />
                            );
                        }}
                    />
                    {errors.urlEN && <ErrorMessage>{errors.urlEN.message}</ErrorMessage>}
                    {values.urlEN && !values.urlEN.startsWith("http") && values.urlEN.length > 0 && (
                        <ErrorMessage>Veuillez composer le URL commençant avec http://</ErrorMessage>
                    )}
                </FormRow>
                <FormRow>
                    <small className="text-muted">*Champs obligatoires</small>
                </FormRow>
                <FormRow>
                    <RoleHelper roleCodenameAllowed={ROLE_CODENAME_EDITOR} conditionsAreAllowed={() => hasRoleUserARTM}>
                        <Col>
                            <Dropdown as={ButtonGroup} drop="up">
                                <Button type="submit" disabled={!formState.isValid}>
                                    <span>
                                        <FontAwesomeIcon icon={faSave} /> Enregistrer
                                    </span>
                                </Button>

                                <CommunicationFormActionButtons
                                    onClickSend={onClickSend}
                                    onClickDelete={onClickDelete}
                                    isFormFilled={formState.isValid}
                                    itemExists={(entity.id > 0 && roleApprobator) || hasRoleUserARTM}
                                    hideApprove={!hasRoleApprobator && !hasRoleUserARTM}
                                    allowAccessButton={hasRoleUserARTM}
                                    showRefused={entity.id > 0}
                                    isValidated={formState.isValid}
                                    hasBeenModified={formState.isDirty || entity.statusCode === status[CODENAME_STATUS_CREATED]}
                                />
                            </Dropdown>
                        </Col>
                    </RoleHelper>
                </FormRow>
            </Form>
        </CommunicationContainer>
    );
};
