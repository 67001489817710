import React, { Fragment, useContext } from 'react';
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import { Card } from "react-bootstrap";
import ChronoContext from "../../../context/ChronoContext";
import ChronoCard from "../../common/ChronoCard";

const ResultComparer = () => {
    const staticData = useContext(ChronoContext).staticData;
    const userAgencies = staticData.user.agencies;

  
    return (
        <Fragment>
            <TopBar title="Temps Reel - Comparaison de resultat" />
            <ContentBelowTopBar>
                <ChronoCard>
                    <Card>
                        <Card.Title>
                            Un titre de carte
                        </Card.Title>
                        <Card.Body style={{ minHeight: '85px' }}>
                            Un corps de carte
                        </Card.Body>
                    </Card>
                </ChronoCard>
            </ContentBelowTopBar>
        </Fragment>
    );
   
};

export default ResultComparer;
