/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useCallback, Fragment, useState, useRef, useEffect } from 'react';
import { Row, Card, Col, Button } from 'react-bootstrap';
import { faTrash, faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-confirm-alert/src/react-confirm-alert.css';
import RoleHelper, { getRoleObjectFromRoleValue, ROLE_CODENAME_ADMIN } from '../../common/RoleHelper';
import { sendDeleteRequest, sendGetRequest } from "../../../auth/apiEffects";
import styled, { ThemeContext } from "styled-components";
import ChronoBadge from "../../common/ChronoBadge";
import { getTruncatedText } from "../../common/helper";
import { showPopupConfirm } from "../../common/Popups";
import { LoadingIndicator } from "../../LoadingIndicator";
import { ContentBelowTopBar, TopBar } from "../../common/ContentNavigation";
import ChronoContext from "../../../context/ChronoContext";
import SvgIcon from "../../common/SvgIcon";
import UserProfilePage from "./UserProfilePage"
import GenericPopup from "../../common/generic/GenericPopup";
import { ISendGetRequest } from './UserProfileInterface'


const CardContainer = styled(Row)`
    padding: 20px 60px;
`;

const CardUserContainer = styled(Card)`
    height: 280px;
    width: 250px;
    border-radius: 12px !important;
    background-color: white;
    box-shadow: 0 24px 59px 0 rgba(51,59,69,0.15);
    margin: 20px;
`;

const CardUserFooter = styled(Card.Footer)`
    height: 60px;
`;

const CustomText = styled.div`
    width: 200px;
    color:  ${props => (props.theme.colors.darkGray)};
    font-weight: 800;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
    color: ${props => (props.theme.colors.darkGray)};
`;

const UsersPage = () => {
    //const [isDeleting, setIsDeleting] = useState(false);
    const [users, setUsers] = useState<ISendGetRequest[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showFooter, setShowFooter] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        async function fetchData() {
            await loadUsers();
            setIsLoading(false);
        }
        fetchData();
    }, []);


    const loadUsers = async () => {
        const results: ISendGetRequest[] | false | undefined = await sendGetRequest(`/users/` + true);
        if(results && results.length) {
            setUsers(results)
        }
    }

    const sendRequestDeleteUser = useCallback(async (userId) => {
       // setIsDeleting(true);
        await sendDeleteRequest('/users/' + userId);
        await loadUsers();
       // setIsDeleting(false);
    }, [users]);
    
    if (isLoading) {
        return <LoadingIndicator />
    }
    
    return (
        <Fragment>
            <TopBar title="Utilisateurs">
                <Col>
                    <Button onClick={() => {
                        setIsSaving(false);
                        setShowFooter(false);
                        setShowModal(true);
                        }}
                        style={{ float: 'right' }}>
                        Ajouter un utilisateur&nbsp;
                        <SvgIcon icon="plus" />
                    </Button>
                </Col>
            </TopBar>
            <ContentBelowTopBar>
                <CardContainer>
                {
                        users.map((user) =>
                            <CardUser key={user.utilisateurId} user={user} sendRequestDeleteUser={sendRequestDeleteUser} loadUsers={loadUsers} />
                    )
                }
                </CardContainer>
            </ContentBelowTopBar>
            <PopupUser
                userId={0}
                showModal={showModal}
                showFooter={showFooter}
                isSaving={isSaving}
                onLoaded={() => { setShowFooter(true) }}
                onChangeIsSaving={(param) => { setIsSaving(param) }}
                hideShowModal={() => { setShowModal(false) }}
                loadUsers={loadUsers}
            />

        </Fragment>
    );
};

const CardUser = ({ user, sendRequestDeleteUser, loadUsers}) => {
    const staticData = useContext(ChronoContext).staticData;
    const theme = useContext(ThemeContext);

    const username = getTruncatedText(user.displayName ? user.displayName : user.email, 23);

    return (
        <CardUserContainer key={user.utilisateurId}>
            <Card.Body className="my-auto">
                <ChronoBadge
                    textSize={theme.fontSizes.xl}
                    color={theme.colors.secondary}
                    size={65}
                    opacity={0.55}
                    code={user.displayName ? user.displayName[0] : user.email[0]}
                />
                <br />
                <CustomText>{username}</CustomText>
                <CustomText>{getRoleObjectFromRoleValue(staticData?.roles || [], user?.role || '')?.label || ''}</CustomText>
            </Card.Body>
            <CardUserFooter>
                <ActionButtons userId={user.utilisateurId} sendRequestDeleteUser={sendRequestDeleteUser} loadUsers={loadUsers} />
            </CardUserFooter>
        </CardUserContainer>
    )
};

const PopupUser = ({ userId, showModal, showFooter, isSaving, onLoaded, onChangeIsSaving, hideShowModal, loadUsers }) => {

    const childRef = useRef();

    return (
        <GenericPopup
            title="Utilisateur"
            lblYes="Sauvegarder"
            bodyStyle={{ minHeight: '80px'}}
            show={showModal}
            showFooter={showFooter}
            isSaving={isSaving}
            cssClass={'js-popup-user'}
            headerStyle={{width:'800px'}}
            onClickYes={async () => {
                onChangeIsSaving(true);
                const issaved =  childRef?.current ? await( childRef?.current as any)?.saveData(): false;

                if (!issaved) {
                    onChangeIsSaving(false);
                    return;
                }
                if (loadUsers)
                    await loadUsers();

                hideShowModal();
            }}
            onHide={() => hideShowModal()}>
            <UserProfilePage onLoaded={onLoaded} ref={childRef} params={{ id: userId }} />
        </GenericPopup>
    )
};

const ActionButtons = ({ userId, sendRequestDeleteUser, loadUsers }) => {
    const [showModal, setShowModal] = useState(false);
    const [showFooter, setShowFooter] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
       
    return (
        <RoleHelper roleCodenameAllowed={ROLE_CODENAME_ADMIN}>
            <Button style={{float: 'left'}} variant="light" title="Supprimer"
                    onClick={() => {
                        showPopupConfirm({
                            message: 'Voulez-vous vraiment supprimer cet utilisateur?',
                            onClickYes: () => sendRequestDeleteUser(userId),
                        });
                    }}>
                <ButtonIcon icon={faTrash} />
            </Button>

            <Button variant="light"
                style={{ float: 'right' }}
                onClick={() => {
                    setIsSaving(false);
                    setShowFooter(false);
                    setShowModal(true);
                }}
                        title="Préférences">
                <ButtonIcon icon={faCog} />
            </Button>

            <PopupUser
                userId={userId}
                showModal={showModal}
                showFooter={showFooter}
                isSaving={isSaving}
                onLoaded={() => {
                    setShowFooter(true);
                }}
                onChangeIsSaving={(param) => { setIsSaving(param) }}
                hideShowModal={() => { setShowModal(false) }}
                loadUsers={loadUsers}
                />
 
        </RoleHelper>
    )
};

export default UsersPage;
