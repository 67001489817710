import { sendPostRequest, sendPutRequest } from '../../auth/apiEffects'
import { AlertViewModel } from '../../types/viewmodels/alertViewModels'

export const saveAlert = async (queryParams): Promise<false | AlertViewModel | undefined> => {
    try {
        const insertOrUpdateRequest = queryParams.alertId === 0 ? sendPostRequest : sendPutRequest;
        const savedAlertId = await insertOrUpdateRequest<AlertViewModel>("/alert", queryParams.queryData);
        return savedAlertId

    } catch (e) {
        console.log(e);
        return undefined;
    }
}