import React, { FC, ReactElement} from 'react';
import { Container } from 'react-bootstrap';
import CommunicationFormHeader from "../../CommunicationFormHeader";
import { getFormattedDate } from "../../../common/helper";
import { BoxHeaderDashed, BoxTextDashed, ColDashed, SiblingRowDashed } from "../../CommunicationStructure";
import { DATE_FORMAT, getRoute, getStop } from "../../CommunicationUtils";
import { useChronoApi } from "../../../../auth/apiEffects";
import { getRoutesUrl, getStopsUrlByRouteId } from "../../../../auth/fetchUrlHelper";
import CenteredComponent from "../../../common/CenteredComponent";
import { AlertFormBaseProps} from "../../../../types/components/alertComponent";

const AlertSummary: FC<AlertFormBaseProps> = ({ entity }: AlertFormBaseProps): ReactElement=> {
    const { data: routes } = useChronoApi(getRoutesUrl(entity.agencyCode));
    const { data: stops } = useChronoApi(getStopsUrlByRouteId(entity.agencyCode, entity.routeId));
    
    return (
        <Container>
            <CommunicationFormHeader data={entity} />

            <SiblingRowDashed>
                <ColDashed>
                    <CenteredComponent>
                        <BoxHeaderDashed>DÉBUT</BoxHeaderDashed>
                        <BoxTextDashed>{getFormattedDate(entity.start, DATE_FORMAT)}</BoxTextDashed>
                    </CenteredComponent>
                </ColDashed>
                <ColDashed>
                    <CenteredComponent>
                        <BoxHeaderDashed>FIN</BoxHeaderDashed>
                        <BoxTextDashed>{getFormattedDate(entity.end, DATE_FORMAT)}</BoxTextDashed>
                    </CenteredComponent>
                </ColDashed>
            </SiblingRowDashed>
        
            <SiblingRowDashed>
                <ColDashed>
                    <BoxHeaderDashed>ORGANISATION</BoxHeaderDashed>
                    <BoxTextDashed>{entity.agencyCode}</BoxTextDashed>
                </ColDashed>
                <ColDashed>
                    <BoxHeaderDashed>LIGNE</BoxHeaderDashed>
                    <BoxTextDashed>{getRoute(routes, entity.routeId)}</BoxTextDashed>
                </ColDashed>
                <ColDashed>
                    <BoxHeaderDashed>ARRÊT</BoxHeaderDashed>
                    <BoxTextDashed>{getStop(stops, entity.stopId)}</BoxTextDashed>
                </ColDashed>
            </SiblingRowDashed>
            
            <SiblingRowDashed>
                <ColDashed>
                    <BoxHeaderDashed>CAUSE</BoxHeaderDashed>
                    <BoxTextDashed>{entity.cause}</BoxTextDashed>
                </ColDashed>
                <ColDashed>
                    <BoxHeaderDashed>EFFECT</BoxHeaderDashed>
                    <BoxTextDashed>{entity.effect}</BoxTextDashed>
                </ColDashed>
            </SiblingRowDashed>

            <SiblingRowDashed>
                <ColDashed>
                    <BoxHeaderDashed>Contenu FR</BoxHeaderDashed>
                    <BoxTextDashed>{entity.textFR ? entity.textFR : '-'}</BoxTextDashed>
                </ColDashed>
                <ColDashed>
                    <BoxHeaderDashed>Contenu EN</BoxHeaderDashed>
                    <BoxTextDashed>{entity.textEN ? entity.textEN : '-'}</BoxTextDashed>
                </ColDashed>
            </SiblingRowDashed>
        </Container>
    )
};

export default AlertSummary;