import { createStore, applyMiddleware } from "redux";
// import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import createReducer from "./createReducer";

let store;
const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
    const middlewares = [sagaMiddleware];

    store = createStore(
        createReducer(),
        composeWithDevTools(applyMiddleware(...middlewares)) // TODO remove when not DEV environnment
    );
        
    // store.asyncReducers = {};

    // store.injectReducer = (key, asyncReducer) => {
    //     store.asyncReducers[key] = asyncReducer;
    //     store.replaceReducer(createReducer(store.asyncReducers));
    // };

    // store.async

    sagaMiddleware.run(rootSaga);
    return store;
};

const getStore = () => store;

export { getStore, sagaMiddleware };
export default configureStore;
