import styled from "styled-components";
import { Card } from "react-bootstrap";

const ChronoCard = styled(Card)`
    margin: 20px 0px;
    padding: 20px;
    width: 100%;
    border-color: transparent !important;
    border-radius: 12px !important;
    box-shadow: 0 24px 59px 0 rgba(51,59,69,0.15);
`;

export default ChronoCard;