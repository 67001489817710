/* eslint-disable react-hooks/exhaustive-deps */
import KeenAnalysis from "keen-analysis";
import { useChronoApi } from "../../../auth/apiEffects";
import { useEffect, useState } from "react";
import { KEEN_DATA_USER_ID } from "./keenConstants";
import { IKeenData } from "../optc/StatsRoutesShown";

interface IFilter {
    [key: string]: string;
}

export const useKeenClient = () => {
    const {data: keenConfig, isLoading} = useChronoApi('/statistics/keenaccount', [], false);
    const [keenClient, setKeenClient] = useState(undefined);

    useEffect(() => {
        if (keenConfig) {
            setKeenClient(new KeenAnalysis({
                cache: {
                    maxAge: 1000 * 60 * 60 * 24,
                },
                ...keenConfig,
            }));
        }
    }, [keenConfig]);

    return {keenClient, isLoading};
};

export const useKeenQuery = (keenClient, keenParams, translateKeenData, filters: IFilter[] = []) => {
    const [keenData, setKeenData] = useState<IKeenData[]>([]);
    const [keenError, setKeenError] = useState<string>('');
  
    useEffect(() => {
        const fetchKeenData = async () => {
            if (keenClient) {
                try {
                    const data = await keenClient.query(keenParams);
                    const translatedData = translateKeenData(data.result);
                    setKeenData(translatedData);
                    console.log('keenData', data);
                    console.log('translatedData keenData', translatedData);
                } catch (error) {
                    setKeenError(error as string);
                    console.log('keenParams', keenParams);
                    console.log('keenError', keenError);
                }
            }
        };

        fetchKeenData();
    }, [keenClient, ...filters]);
 
    return {keenData, keenError};
};

export const executeKennQuery = async (keenClient, keenParams, translateKeenData, translateParams) => {
    if (keenClient) {
        try {
            const data = await keenClient.query(keenParams);

            const translatedData = translateKeenData(data.result, translateParams);
            return translatedData;
        } catch (error) {
            console.log('keenParams', keenParams);
            console.log('keenError', error);
        }
    }
}

export const addFilterEq = (filters, filterOn, filterValue) => {
    if (!filterValue) {
        return filters;
    }

    return [
        ...filters,
        {
            propertyName: filterOn,
            operator: 'eq',
            propertyValue: filterValue,
        },
    ];
};

export const addFilterIn = (filters, filterOn, filterValues) => {
    if (!filterValues || filterValues.length === 0) {
        return filters;
    }

    return [
        ...filters,
        {
            propertyName: filterOn,
            operator: 'in',
            propertyValue: Array.isArray(filterValues) ? filterValues : [filterValues],
        },
    ];
};

export const getUniquenessUser = (isUniquePerUser) => {
    return {
        analysisType: isUniquePerUser ? 'count_unique' : 'count',
        targetProperty: isUniquePerUser ? KEEN_DATA_USER_ID : null,
    }
};
