/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Range } from "rc-slider";
import styled, { ThemeContext } from "styled-components";
import "rc-slider/assets/index.css";

const FloatingRight = styled.span`
    float: right;
    font-size: 0.8em;
`;

const FloatingLeft = styled.span`
    float: left;
    font-size: 0.8em;
`;

const SliderHours = ({onNewHours}) => {
    const theme = useContext(ThemeContext);
    const [sliderHours, setSliderHours] = useState({startHour: 0, endHour: 24});

    const currentMin = Math.round(sliderHours.startHour);
    const currentMax = Math.round(sliderHours.endHour);

    const onChange = ([minValue, maxValue]) => {
        setSliderHours({startHour: minValue, endHour: maxValue});
    };

    useEffect(() => {
        onNewHours(sliderHours);
    }, [sliderHours]);

    return (
        <div>
            <Range
                style={{marginBottom: 15}}
                dots
                allowCross={false}
                handleStyle={[
                    {backgroundColor: theme.colors.secondaryDark, border: 'none', boxShadow: '0 0 5px #57c5f7'},
                    {backgroundColor: theme.colors.secondaryDark, border: 'none', boxShadow: '0 0 5px #57c5f7'}
                ]}
                trackStyle={[
                    {backgroundColor: theme.colors.secondary, height: 10, marginLeft: 0, marginTop: -3}
                ]}
                dotStyle={{borderColor: theme.colors.darkGray}}
                activeDotStyle={{borderColor: theme.colors.secondary}}
                railStyle={{backgroundColor: theme.colors.lightGray, border: 'none'}}
                max={24}
                value={[currentMin, currentMax]}
                onChange={onChange}
            />

            <FloatingLeft>
                {sliderHours.startHour + 'h'}
            </FloatingLeft>
            <FloatingRight>
                {sliderHours.endHour + 'h'}
            </FloatingRight>
        </div>
    );
};

export default SliderHours;
