import React, { useState, useContext, useEffect} from 'react';
import { StatsCard, StatsContainer } from "../global/StatsComponents";
import { Card, Row, Col } from "react-bootstrap";
import ChronoBadge from "../../common/ChronoBadge";
import ChronoContext from "../../../context/ChronoContext";
import { sendGetRequest } from "../../../auth/apiEffects";
import { LoadingIndicator } from "../../LoadingIndicator";
import moment from 'moment';
import { AGENCIES_EXO, getSimplifiedAgencyLabels } from 'components/common/helper';

const translateData = (dataList) => {
    if (dataList) {
        return dataList.map((d) => ({
            x: d.organization,
            y: d.amountFavorite,
        }));
    }

    return [];
};

const StatsSummaryAgencyShown = ({ filterAgency, onClickSelectBar, onClickUnselectBar, currentDates, isSelected, isUserHavingFavoriteMode}) => {
    const staticData = useContext(ChronoContext).staticData;
    const userAgencies = staticData.user.agencies;
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const initFavoris = {
        organisation: '',
        routeID: '',
        routeNomCourt: '',
        arretID: '',
        arretCode: '',
        direction: ''
    };

    const [favorisList, setFavorisList] = useState<any>(initFavoris);
    const [favorisListAllAgency, setFavorisListAllAgency] = useState<any>(initFavoris);
    const [translatedFavorisList, setTranslatedFavorisList] = useState<any>();
    const title = isUserHavingFavoriteMode ? 'Utilisateurs ayant un favoris' : 'Nombre d\'arrêts favoris ajoutés';

    useEffect(() => {
        setIsDataLoaded(false);

        async function fetchData() {
            const favorisList = await sendGetRequest(`/statistics/favoriteAmountByOrg?organizationCodeList=${filterAgency}
                                                 &startDate=${moment(currentDates.startDate).format("YYYY-MM-DD HH:mm")}
                                                 &endDate=${moment(currentDates.endDate).format("YYYY-MM-DD HH:mm")}
                                                 &userHavingFavorite=${isUserHavingFavoriteMode}`
            );

            const agencyLabels = getSimplifiedAgencyLabels(userAgencies);

            const favorisListAllAgency = await sendGetRequest(`/statistics/favoriteAmountByOrg?organizationCodeList=${agencyLabels.join(',')},${AGENCIES_EXO.join(',')}
                                                 &startDate=${moment(currentDates.startDate).format("YYYY-MM-DD HH:mm")}
                                                 &endDate=${moment(currentDates.endDate).format("YYYY-MM-DD HH:mm")}
                                                 &userHavingFavorite=${isUserHavingFavoriteMode}`
            );

            setFavorisList(favorisList);
            setFavorisListAllAgency(favorisListAllAgency)
            setIsDataLoaded(true)
        }
        fetchData();
    }, [currentDates, filterAgency, isUserHavingFavoriteMode]);

    useEffect(() => {
        if (favorisList.length > 0) {
            setTranslatedFavorisList(translateData(favorisList));
        }
    }, [favorisList])

    const getColor = (item) => {
        return userAgencies.length > 0 ? userAgencies.find(x => x.label === item.x.trim()).color : 'black';
    }

    const onClickCard = (item) => {
        if (isSelected) 
            onClickUnselectBar(item);
        else
            onClickSelectBar(item);

        return;
    }

    return (
        <StatsContainer>
            <StatsCard>
                {
                    !isDataLoaded ? <LoadingIndicator style={{marginTop: -200}} /> :
                        translatedFavorisList.length > 0 ?
                        translatedFavorisList.map((item, i) =><Row>
                            
                            <Col>
                            <Card key={i} onClick={() => onClickCard(item)} style={{cursor: 'pointer', margin: 'auto'}}>
                                <Card.Header style={{fontSize: 16, width: '100%'}}>
                                    { title }
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col className="align-self-center">
                                            <ChronoBadge color={getColor(item)} code={item.x} />
                                        </Col>
                                        <Col className="align-self-center" style={{fontSize: 40}}>
                                            {item.y}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            </Col>
                            <Col>
                            <Card key={i} onClick={() => onClickCard(item)} style={{cursor: 'pointer', margin: 'auto'}}>
                                <Card.Header style={{fontSize: 16, width: '100%'}}>
                                Nombre d'arrêts favoris totale ajoutés
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col className="align-self-center">
                                            <ChronoBadge color="#00A0A5" code="ARTM" />
                                        </Col>
                                        <Col className="align-self-center" style={{fontSize: 40}}>
                                        {favorisListAllAgency.map(i => i.amountFavorite).reduce((a, b) => a + b)}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            </Col>
                            </Row>
                            )
                            : <h3 style={{margin: 'auto'}}>Aucun résultat</h3>
                }
            </StatsCard>
        </StatsContainer>
    )
}

export default StatsSummaryAgencyShown;
