import { FC } from "react";
import { confirmAlert } from "react-confirm-alert";

interface showPopupConfirmProps {
    message: string;
    onClickYes?: () => void;
    onClickNo?: () => void;
    labelYes?: string;
    labelNo?: string;
}

export const showPopupConfirm = ({ message, onClickYes, onClickNo, labelYes, labelNo }: showPopupConfirmProps) => {
    confirmAlert({
        title: "Confirmation",
        message: message,
        buttons: [
            {
                label: labelYes !== undefined ? labelYes : "Yes",
                onClick: onClickYes ? onClickYes : () => {},
            },
            {
                label: labelNo !== undefined ? labelNo : "No",
                onClick: onClickNo ? onClickNo : () => {},
            },
        ],
    });
};
