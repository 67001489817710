import * as types from "../types/types";

export const getEvery = (request) => ({
    type: types.GET_REQUEST_API_EVERY,
    payload: {
        request,
    },
});

export const getLatest = (request) => ({
    type: types.GET_REQUEST_API_LATEST,
    payload: {
        request,
    },
});

export const postEvery = (request) => ({
    type: types.POST_REQUEST_API_EVERY,
    payload: {
        request,
    },
});

export const putEvery = (request) => ({
    type: types.PUT_REQUEST_API_EVERY,
    payload: {
        request,
    },
});

export const deleteEvery = (request) => ({
    type: types.DELETE_REQUEST_API_EVERY,
    payload: {
        request,
    },
});

export const postLatest = (request) => ({
    type: types.POST_REQUEST_API_LATEST,
    payload: {
        request,
    },
});
