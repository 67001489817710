import React, { useState } from "react";
import styled from 'styled-components';
import { Card, Spinner } from 'react-bootstrap';
import BaseCardHome, { TitleCard } from "./BaseCardHome";
import SliderDates from "../SliderDates";
import { useChronoApi } from "../../../auth/apiEffects";
import ChronoXYPlot from "../../common/ChronoXYPlot";
import { getStatisticsDateParams } from "../../../auth/fetchUrlHelper";
import moment from 'moment';
import DiscreteColorLegend from 'react-vis/dist/legends/discrete-color-legend';


const favoriteTranslateData = (dataList) => {
    if (dataList) {
        return dataList.map((d) => ({
            x: d.agencyCode,
            y: d.total
        }));
    }

    return [];
};

const FloatingSpinner = styled(Spinner)`
    float: right;
    color: ${props => (props.theme.colors.secondary)}
`;

const StatisticsCard = () => {
    const [currentDates, setCurrentDates] = useState({});

    const maxDate = new Date();
    maxDate.setHours(0);
    maxDate.setMinutes(0);
    maxDate.setSeconds(0);
    const minDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), 1, 0, 0, 0);

    const {data: favorites, isLoading: isLoadingFavorites} = useChronoApi('/statistics/favorite' + getStatisticsDateParams(moment(currentDates.startDate), moment(currentDates.endDate)));

    return (
        <BaseCardHome>
            <Card.Title>
                <TitleCard>Nouveaux arrêts ajoutés en favoris</TitleCard>
                { isLoadingFavorites && <FloatingSpinner animation="grow" />}
            </Card.Title>

            <DiscreteColorLegend height={60} width={100} items={['Nb ajout']} style={{ marginBottom: 0 }} />
            <ChronoXYPlot data={favoriteTranslateData(favorites)} tickLabelAngle={-50} margin={{bottom: 110}}/>
            
            <SliderDates onNewDates={setCurrentDates}
                         minDate={minDate}
                         maxDate={maxDate} />
        </BaseCardHome>
    )
};

export default StatisticsCard;