// @ts-nocheck
import React, { ReactNode, FC, MouseEventHandler } from "react";
import { Col, Row, Button } from "react-bootstrap";
import ChronoBadge from "../common/ChronoBadge";
import StatusBadge from "../common/StatusBadge";
import styled from "styled-components";
import { RowDashed } from "./CommunicationStructure";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import * as miscService from "../../service";
import { showPopupConfirm } from "../common/Popups";

const CenteredCol = styled(Col)`
    margin: auto;
`;

interface CommunicationFormHeaderProps {
    data: any;
    isNew?: boolean;
    isSendingData?: boolean;
    agencies?: [];
    icon?: any;
    showStopPublication?: boolean;
    showCopyButton?: boolean;
    showDeleteButton?: boolean;
    isCopy?: boolean;
    onClickEdit?: Function;
    onClickStopPublication?: MouseEventHandler<HTMLElement>;
    onClickCopy?: Function;
    onClickDelete?: Function;
    cssIconDelete?: string;
    statusBadge?: ReactNode;
}

const CommunicationFormHeader: FC<CommunicationFormHeaderProps> = ({
    data,
    isNew,
    isSendingData,
    agencies,
    icon,
    showStopPublication,
    showCopyButton,
    showDeleteButton,
    isCopy,
    onClickEdit,
    onClickStopPublication,
    onClickCopy,
    onClickDelete,
    cssIconDelete,
    statusBadge,
}: CommunicationFormHeaderProps) => {
    const badge =
        data.agencyCodeList && data.agencyCodeList.length > 0 && agencies && miscService.getBadge(data.agencyCodeList, agencies);

    if (isNew || isCopy) {
        return (
            <Row>
                <h2>Nouveau</h2>
            </Row>
        );
    }

    const status = data.status === undefined ? data.statusCode : data.status;
    return (
        <RowDashed>
            {badge && <CenteredCol align="center">{<ChronoBadge color={badge.color} code={badge.label} />}</CenteredCol>}

            <CenteredCol align="center">
                {data.createdBy ? (
                    <span>
                        Créé par <strong>{data.createdBy.email}</strong>
                        <br />
                    </span>
                ) : null}
                {data.approvedBy ? (
                    <span>
                        Approuvé par <strong>{data.approvedBy.email}</strong>
                        <br />
                    </span>
                ) : null}
                {data.publishedBy ? (
                    <span>
                        Publié par <strong>{data.publishedBy.email}</strong>
                        <br />
                    </span>
                ) : null}
                {data.refusedBy ? (
                    <span>
                        Refusé par <strong>{data.refusedBy.email}</strong>
                        <br />
                    </span>
                ) : null}
            </CenteredCol>

            <CenteredCol align="center">
                {statusBadge && statusBadge}
                {!statusBadge && <StatusBadge status={status} data={data} />}
            </CenteredCol>

            {icon && onClickEdit && (
                <Col align="center" lg={1}>
                    <FontAwesomeIcon icon={icon} onClick={() => onClickEdit(true)} style={{ cursor: "pointer" }} />
                </Col>
            )}

            {showStopPublication === true && (
                <Col align="center" lg={3}>
                    <Button disabled={isSendingData} onClick={onClickStopPublication}>
                        {isSendingData ? "Patientez..." : <span>Arrêter publication</span>}
                    </Button>
                </Col>
            )}

            {showCopyButton === true && onClickCopy && (
                <Col align="center" lg={1}>
                    <FontAwesomeIcon
                        icon={faCopy}
                        onClick={() => {
                            if (onClickCopy) onClickCopy();
                        }}
                        className="communication-header-copy"
                    />
                </Col>
            )}

            {showDeleteButton === true && onClickDelete && (
                <Col align="center" lg={1}>
                    <FontAwesomeIcon
                        icon={faTrashAlt}
                        color={'black'}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            if (onClickDelete) {
                                showPopupConfirm({
                                    message: "Voulez-vous vraiment supprimer?",
                                    onClickYes: () => onClickDelete(),
                                });
                            }
                        }}
                        className={cssIconDelete ? cssIconDelete :''}
                    />
                </Col>
            )}
        </RowDashed>
    );
};

export default CommunicationFormHeader;
