import {
    CODENAME_STATUS_ALL,
    CODENAME_STATUS_AWAITING_ACTIVATION,
    CODENAME_STATUS_ACTIVE,
    CODENAME_STATUS_EXPIRED,
} from "../statusUtils";

import { CODENAME_AGENCY_ALL, LABEL_AGENCY_ALL } from "../components/communication/CommunicationUtils";
import { isUserRoleInArray, ROLE_CODENAME_ADMIN, ROLE_CODENAME_APPROBATOR, ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_EDITOR } from "../components/common/RoleHelper";

import moment from "moment";

export const isStatusInSelectedList = (item, statusList, status) => {
    if (!item && !item.length > 0) return false;

    const itemStatus = Object.keys(status).find((key) => status[key] === item.statusCode);

    if (statusList.some((x) => x.value === itemStatus)) return true;

    const startDateAfterNow = moment(item.startDate).isAfter(moment());
    const endDateBeforeNow = moment(item.endDate).isBefore(moment());
    const startDateBeforeNow = moment(item.startDate).isSameOrBefore(moment());
    const endDateAfterNow = moment(item.endDate).isSameOrAfter(moment());

    if (item.isPublished && statusList.some((x) => x.value === CODENAME_STATUS_AWAITING_ACTIVATION) && startDateAfterNow)
        return true;

    if (item.isPublished && statusList.some((x) => x.value === CODENAME_STATUS_EXPIRED) && endDateBeforeNow) return true;

    if (
        item.isPublished &&
        statusList.some((x) => x.value === CODENAME_STATUS_ACTIVE) &&
        startDateBeforeNow &&
        endDateAfterNow
    )
        return true;
};

export const isOrganizationInSelectedList = (message, agencyFilterList) => {
    if (!message) return false;

    if (agencyFilterList.some((x) => message.agencyCodeList.includes(x.value))) return true;
};

export const getStatusList = (evt, optionList, value) => {
    if (evt.action === "select-option" && evt.option.value === CODENAME_STATUS_ALL) return optionList;
    else if (evt.action === "select-option" && evt.option.value !== CODENAME_STATUS_ALL && value.length >= optionList.length - 1)
        return optionList;
    else if (evt.action === "deselect-option" && evt.option.value === CODENAME_STATUS_ALL) return [];
    else if (evt.action === "deselect-option") return value.filter((x) => x.value !== CODENAME_STATUS_ALL);
    else return value ? value : [];
};

export const getOrgList = (evt, optionList, value) => {
    if (evt.action === "select-option" && evt.option.value === CODENAME_AGENCY_ALL) return optionList;
    else if (evt.action === "select-option" && evt.option.value !== CODENAME_AGENCY_ALL && value.length >= optionList.length - 1)
        return optionList;
    else if (evt.action === "deselect-option" && evt.option.value === CODENAME_AGENCY_ALL) return [];
    else if (evt.action === "deselect-option") return value.filter((x) => x.value !== CODENAME_AGENCY_ALL);
    else return value ? value : [];
};

export const getOptionsFilterOrganizationList = (agencies) => {
    let orgs = [{ label: LABEL_AGENCY_ALL, value: CODENAME_AGENCY_ALL }];
    const data =
        agencies && agencies.length > 0
            ? agencies.map((item) => {
                  return { label: item.label, value: item.label };
              })
            : [];

    if (data && data.length > 0) orgs = orgs.concat(data);

    return orgs;
};

export const getBadge = (agencyCodeList, agencies) => {
    if(!agencyCodeList || !agencies) {
        return null
    }
    if(!Array.isArray(agencyCodeList)) {
        agencyCodeList = [agencyCodeList]
    }
    if (agencyCodeList.length > 1) {
        return { label: "MULTI", color: "#ced4da" };
    }

    const badgeColor = agencies.length > 0 ? agencies.find((x) => x.label === agencyCodeList[0]).color : "black";

    return { label: agencyCodeList[0], color: badgeColor }
};

export const CanCopy = (roles, currentRole, item) => {
    const userRoleName = Object.keys(roles).find(key => roles[key] === currentRole);
    return isUserRoleInArray(userRoleName, [ROLE_CODENAME_ADMIN, ROLE_CODENAME_APPROBATOR, ROLE_CODENAME_USER_ARTM, ROLE_CODENAME_EDITOR]);
}
