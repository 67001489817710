import { RESOURCE_ADDED } from "../actions/types/types";
import { saveState } from "./helper";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
      case RESOURCE_ADDED:
      const { resourceCategory, resource } = action.payload;
      const resources = state;
     
      return saveState(resources, resourceCategory, resource);

    default:
      return state;
  }
};
