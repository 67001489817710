import { confirmAlert } from "react-confirm-alert";
import React, { FC, ReactElement} from 'react';

export const ConfirmPopup:FC<any> = ({ message, onClickYes, onClickNo, title, style, getCustomBody }:any):ReactElement => {

    return (<>
    {!getCustomBody &&
            confirmAlert({
                title: title ? title : 'Confirmation',
                message: message,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: onClickYes ? onClickYes : null
                    },
                    {
                        label: 'No',
                        onClick: onClickNo ? onClickNo : null
                    }
                ]
            })
    }
    {getCustomBody &&
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="react-confirm-alert">
                            <div className="react-confirm-alert-body" style={style}>
                                {getCustomBody()}
                                <div className="react-confirm-alert-button-group">
                                    <button onClick={() => {
                                        if (onClickYes)
                                            onClickYes();
                                        onClose();
                                    }}>Yes</button>
                                    <button onClick={onClose}>No</button>
                                </div>
                            </div>
                        </div>
                    );
                }
            })
    }
    </>
    )
};

export default ConfirmPopup;

