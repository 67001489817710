import * as yup from "yup";
import moment from "moment";
import { getFormattedDate } from "../../../common/helper";
import { DATE_FORMAT } from "../../CommunicationUtils";

export const messageFormValidator = yup.object({
    startDate: yup.date().when("isNow", {
        is: false,
        then: yup.date().required("La date de début ne peut être vide"),
        otherwise: yup.date(),
    }),
    startTime: yup.string().when("isNow", {
        is: false,
        then: yup.string().required("L'heure de début ne peut être vide"),
        otherwise: yup.string(),
    }),
    endDate: yup
        .date()
        .required()
        .test("is-greater", "La date de fin ne peut être antérieure", function (value) {
            var { startDate, isNow } = this.parent;

            if (isNow)
                startDate = moment();

            return (
                moment(value).isSameOrAfter(getFormattedDate(startDate, DATE_FORMAT)) &&
                moment(value).isSameOrAfter(moment(), "day")
            );
        }),
    endTime: yup
        .string()
        .required()
        .test("is-greater", "La date et l'heure de fin ne peut être antérieure ou égale", function (value) {
            var { startDate, endDate, startTime, isNow } = this.parent;

            if (isNow) {
                startDate = moment();
                startTime = moment().format('HH:mm');
            }

            return (
                moment(getFormattedDate(endDate, DATE_FORMAT) + " " + value).isSameOrAfter(
                    getFormattedDate(startDate, DATE_FORMAT) + " " + startTime
                ) && moment(getFormattedDate(endDate, DATE_FORMAT) + " " + value).isSameOrAfter(moment())
            );
        }),
    agencyCodeList: yup.array(yup.string()).required(),
    routeList: yup.array(),
    arretList: yup.array(),
    isNow: yup.boolean(),
    isVisibleInFeedOnly: yup.boolean(),
    contentFR: yup.string().required().min(1).max(150),
    urlFR: yup.string().url("L'URL est mal formé."),
    contentEN: yup.string().required().max(150),
    urlEN: yup.string().url("L'URL est mal formé."),
});

/* to remove */ 
export const formDefaultValues = {
    startDate: moment().format(DATE_FORMAT),
    startTime: moment().add(10, "minute").format("HH:mm"),
    endDate: moment().add(1, "days").format(DATE_FORMAT),
    endTime: moment().add(10, "minute").format("HH:mm"),
    agencyCodeList: [],
    routeList: [],
    arretList: [],
    isVisibleInFeedOnly: false,
    contentFR: "",
    urlFR: "",
    contentEN: "",
    urlEN: "",
    isNow: false,
};
