import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import * as types from "../../actions/types/types";
import * as consoleApi from "../../../api";
import addResource from "../../actions/addResource";
import addUIItem from "../../actions/addUIItem";
import * as constantesDetails from '../../keys/applicationLogs/applicationLogDetails';
import * as statusRequest from '../requestStatus';

export function* watchApplicationLogDetails() {
    yield takeLatest(types.GET_APPLICATION_LOG_DETAILS_REQUESTED, doApplicationLogDetails);
    yield takeEvery(types.GET_DROPDOWNLIST_ITEMS_LOG_DETAILS, getDropDownListItem);
}

export function* doApplicationLogDetails(action) {
    const { queryParams } = action.payload;
    const status = constantesDetails.APPLICATION_LOG_DETAILS + '_STATUS';

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));

        const applicationLogDetails = yield call(
            consoleApi.getApplicationLogDetails,
            queryParams
        );

        if (!applicationLogDetails) {
            throw new Error("Unable to get applications log details.");
        }
        
        yield put(addResource(applicationLogDetails, constantesDetails.APPLICATION_LOG_DETAILS));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {
        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}

export function* getDropDownListItem(action) {
    const { queryParams } = action.payload;
    const status = queryParams.resourceName + '_STATUS';

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));

        const results = yield call(
            consoleApi.getDropdownListItemForLogDetailProperty,
            { propertyName: queryParams.propertyName, criteria: queryParams.criteria }
        );

        if (!results) {
            throw new Error("Unable to get data.");
        }

        yield put(addResource(results, queryParams.resourceName));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {

        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}
