import { useState } from 'react';

export const useInput = (initialValue: any = '', onChange?: any) => {
    const [value, setValue] = useState(initialValue);

    return {
        value,
        onChange: event => {
            onChange ?
                onChange(event) :
                setValue(event.target.value);
        },
        reset: () => setValue(initialValue),
    };
};

export const useInputCheckBox = (initialValue: any, onChange?: any) => {
    const [value, setValue] = useState(initialValue);

    return {
        checked: !!value,
        onChange: event => {
            onChange ?
                onChange(event) :
                setValue(event.target.checked);
        },
        reset: () => setValue(initialValue),
    };
};
