import { Col, Container, Row } from "react-bootstrap";
import ChronoBadge from "../ChronoBadge";
import CenteredComponent from "../CenteredComponent";
import { getTruncatedText } from "../helper";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { withRouter } from 'react-router-dom';
import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ROUTE_COMMUNICATION_MESSAGES } from "../../../routes";
import ChronoContext from "../../../context/ChronoContext";
import * as miscService from "../../../service";
import { RouteComponentProps } from 'react-router-dom';

type IAgencyContainerMessage = RouteComponentProps & {
    message: any,
   
}

const ContainerMessage = styled(Container)`
    cursor: pointer;
    
    &:hover {
        background: ${props => (props.theme.colors.secondaryDark)};
        color: white;
    }
`;

const ButtonNext = styled(FontAwesomeIcon)`
    font-size: 20px;
    color: ${props => (props.theme.colors.darkGray)};
    opacity: 0.3;
    margin: auto;
`;

const LineSeparator = styled.hr`
    margin: 0 -20px;
`;

const AgencyContainerMessage: React.FC<IAgencyContainerMessage> = ({history, message}) => {
    const staticData = useContext(ChronoContext).staticData;
    const agencies = staticData.user.agencies;
    const badge = miscService.getBadge(message.agencyCodeList, agencies);
    
    return (
        <ContainerMessage
            onClick={() => {
                history.push(ROUTE_COMMUNICATION_MESSAGES(message.id))
            }}>
            <Row>
                <Col xs={4} sm={3} md={2} style={{margin: 'auto'}}>
                    <ChronoBadge color={badge?.color} code={badge?.label} />
                </Col>
                <Col xs={5} sm={7} md={8}>
                    <CenteredComponent verticalOnly>
                        {getTruncatedText(message.contentFR, 90)}
                    </CenteredComponent>
                </Col>
                <Col xs={3} sm={2} md={1} style={{margin: "auto"}}>
                    <ButtonNext icon={faChevronRight} />
                </Col>
            </Row>
            <LineSeparator />
        </ContainerMessage>
    )
};

export default withRouter(AgencyContainerMessage);