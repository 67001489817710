import React, {FC, ReactElement, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import styled from "styled-components";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ErrorMessage = styled.p`
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
`

const PopupModal:FC<any> = ({ children, id, cssClass, headerStyle, title, onClickYes, lblYes, show, onHide, showFooter = true, bodyStyle, isSaving = false, errorMessage = '' }:any): ReactElement => {

    const [showPopup, setShowPopup] = useState<Boolean>(false);

    useEffect(() => {
        setShowPopup(show);
    }, [show]);

    const onClosePopup = () => {
        if (onHide) {
            onHide();
        }
    };

    const onClickSave = () => {
        if (onClickYes)
            onClickYes();
    };

    return (
        <div id={id} key={id}>
            <Modal className={cssClass} show={showPopup} onHide={onClosePopup} centered backdrop="static" >
                <Modal.Header style={headerStyle} closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={bodyStyle}>
                    {children}
                    {
                        errorMessage.trim().length > 0 &&
                        <ErrorMessage>{errorMessage}</ErrorMessage>
                    }
                </Modal.Body>

                {
                    showFooter === true &&
                    <Modal.Footer>
                        {
                            !isSaving &&
                            <Button variant="primary" onClick={() => onClickSave()}>
                                {lblYes}
                            </Button>

                        }

                        {
                            isSaving &&
                            <Button disabled>
                                <span>Sauvegarde en cours... <FontAwesomeIcon icon={faSpinner} spin={true} /></span>
                            </Button>
                        }


                        <Button variant="secondary" onClick={() => onClosePopup()}>
                            Annuler
                    </Button>
                    </Modal.Footer>

                }
            </Modal>
        </div>
    )
};

export default PopupModal;