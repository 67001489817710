/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactElement } from "react";
import { Badge } from "reactstrap";

export const OrganizationBadge: FC<any> = ({ badgeModel }: any): ReactElement => {
    return (
        <div className="badge-container">
            <Badge
                className="badge-organization"
                style={{ color: badgeModel?.fontColor, backgroundColor: badgeModel?.backgroundColor }}
            >
                {badgeModel?.label}
            </Badge>
        </div>
    );
};

export default OrganizationBadge;
