/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ReactElement, FC } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import NotificationSummary from "./NotificationSummary";
import NotificationForm from "./NotificationForm";
import { ToastProvider } from "react-toast-notifications";
import { useNotificationService } from "../../../../service/communication/useNotificationService";
import { NotificationFormBaseProps } from '../../../../types/components/notificationComponent';

const NotificationBaseContainer: FC<NotificationFormBaseProps> = ({ entity, operations }: NotificationFormBaseProps) : ReactElement => {
    const [isNotificationEdit, setIsNotificationEdit] = useState(false);
    const service = useNotificationService();
  
    if (!service.isNotificationPublished(entity) || isNotificationEdit) {
    return (
      <ToastProvider>
            <NotificationForm
                entity={entity}
                operations={operations}
        />
      </ToastProvider>
    );
  } else {
    return (
      <ToastProvider>
            <NotificationSummary entity={entity} operations={operations}  onClickEdit={(val) => setIsNotificationEdit(val)} />
      </ToastProvider>
    );
  }
};

export default NotificationBaseContainer;
