/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, ReactElement, useState, useEffect, Fragment } from "react";
import { Button } from "react-bootstrap";
import Emitter from "../../../../components/common/helperEmitter";

export const MultiStep: FC<any> = (props):ReactElement => {
    const {
        children,
        multiStepkey,
        nextButtonTitle,
        previousButtonTitle,
        cancelButtonTitle,
        saveButtonTitle,
        valueList,
        onSave,
        onCancel,
        showSave = false,
        showCancel = false,
    } = props;

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [stepsValues, setStepsValues] = useState<any[]>(valueList);
    const [onChangeSteps, setOnChangeSteps] = useState<any[]>([]);
    const [cancelData, setCancelData] = useState<any[]>([]);
    const stepCount = children ? children.length : 0;

    Emitter.on("CLOSE_MODAL_MULTISTEP", () => onCancelHandle());

    useEffect(() => {
        const stepsData = children.map((step) => {
            return step.props.value;
        });
        setCancelData([...cancelData, ...stepsData]);
        setStepsValues([...stepsData]);
    }, []);

    useEffect(() => {
        // if a child change directly a step, the change is reflected
        executeStepOnChange();
    }, [stepsValues]);

    useEffect(() => {
        const onChangeFunction = children.map((step) => {
            return step.props.onChange;
        });
        setOnChangeSteps([...onChangeSteps, ...onChangeFunction]);
    }, [children]);

    const executeStepOnChange = () => {
        onChangeSteps.forEach((onChange, index) => {
            if (stepsValues[index]) {
                onChange(stepsValues[index]);
            }
        });
    };

    const executeCancelOnChange = () => {
        onChangeSteps.forEach((onChange, index) => {
            if (cancelData[index]) {
                onChange(cancelData[index]);
            }
        });
    };

    const onNextHandle = (e) => {
        e.preventDefault();
        executeStepOnChange();
        if (currentStep < stepCount) setCurrentStep(currentStep + 1);
    };

    const onPreviousHandle = (e) => {
        e.preventDefault();
        executeStepOnChange();
        if (currentStep > 1 && currentStep <= stepCount) setCurrentStep(currentStep - 1);
    };

    const onSaveHandle = (e) => {
        e.preventDefault();
        executeStepOnChange();
        if (onSave) onSave();
    };

    const onCancelHandle = (e:any=null) => {
        if (e) {
            e.preventDefault();
        }
        executeCancelOnChange();
        if (onCancel) onCancel();
    };

    const onChangeMultiStep = (e, index) => {
        let onChangeValues = { ...stepsValues };

        if (e.target.type === "checkbox") {
            if (e.target.checked) {
                if (onChangeValues[index]) {
                    onChangeValues[index] = [...onChangeValues[index], e.target.value];
                } else {
                    onChangeValues[index] = [e.target.value];
                }
            } else {
                const newValues = [...stepsValues[index]];
                const indexCheckbox = newValues.indexOf(e.target.value);
                newValues.splice(indexCheckbox, 1);
                onChangeValues[index] = newValues;
            }
        }
        setStepsValues(onChangeValues);
    };

    return (
        <>
            {children &&
                children.map((step, index) => {
                    if (index === currentStep - 1) {
                        return (
                            <div key={multiStepkey + "_" + index} style={{ minHeight: "110px" }}>
                                {React.cloneElement(step, {
                                    ...step["props"],
                                    stepsValues: stepsValues,
                                    setStepsValues: setStepsValues,
                                    onChange: (e) => {
                                        onChangeMultiStep(e, index);
                                    },
                                })}
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            <div style={{ textAlign: "center", paddingTop: "15px" }}>
                {showCancel === true && (
                    <Button onClick={onCancelHandle} style={{ marginRight: "10px" }}>
                        <span>{cancelButtonTitle}</span>
                    </Button>
                )}
                {currentStep > 1 && (
                    <Button onClick={onPreviousHandle} style={{ marginRight: "10px" }}>
                        <span>{previousButtonTitle}</span>
                    </Button>
                )}

                {currentStep < stepCount && (
                    <Button
                        disabled={stepsValues[currentStep - 1].length === 0}
                        onClick={onNextHandle}
                        style={{ marginRight: "10px" }}
                    >
                        <span>{nextButtonTitle}</span>
                    </Button>
                )}

                {(showSave === true || currentStep === stepCount) && (
                    <Button onClick={onSaveHandle} style={{ marginRight: "10px" }}>
                        <span>{saveButtonTitle}</span>
                    </Button>
                )}
            </div>
        </>
    );
};

export default MultiStep;
