
import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import styled from "styled-components";
import { MEDIA_SCREEN_MENU_SWITCH_SIZE, MENU_WIDTH } from "../navMenu/NavMenu";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChronoToastStack from "./ChronoToastStack";

export const TOP_BAR_SIZE = 80;


const ContentBelowTopBarStyled = styled(Container)`
    height: calc(100vh - ${props => props.barheight ? props.barheight : TOP_BAR_SIZE}px);
    max-width: 100% !important;
    position: relative;
    top: ${props => props.barheight ? props.barheight : TOP_BAR_SIZE}px;
    overflow-y: auto;
    
    @media screen and (max-width: ${MEDIA_SCREEN_MENU_SWITCH_SIZE}px) {
       height: auto;
       top: 0;
    }
`;

const TopBarContainer = styled(Row)`
    position: fixed;
    height: ${props => props.barheight ? props.barheight : TOP_BAR_SIZE}px;
    width: calc(100% - ${MENU_WIDTH}px);
    background-color: white;
    align-items: center;
    box-shadow: 0 24px 59px 0 rgba(51, 59, 69, 0.15);
    margin: 0 !important;
    padding: 0 10px;
    z-index: 2;
    
     @media screen and (max-width: ${MEDIA_SCREEN_MENU_SWITCH_SIZE}px) {
        width: 100%;
        position: sticky;
        top: 0;
    }
`;

const TopBarTitle = styled.h2`
    color: #212121;
    font-size: 1.5em;
    margin: auto;
`;

const ButtonBack = styled(FontAwesomeIcon)`
    font-size: 1.2em;
    color: ${props => (props.theme.colors.text)};
`;

export const CustomLink = styled(Link)`
    position: relative;
    top: 2px;
    margin: 0 10px 0 0;
`;

interface ITopBar {
    title?: string;
    backRoute?: string;
    barheight?: string;
}
/** @deprecated use TopBar inside UI Lib - 20210225 */
export const TopBar: React.FC<ITopBar> = ({title='', backRoute='', children, barheight}) => {

    return (
        <TopBarContainer barheight={barheight}>
            {
                title &&
                <Col>
                    <TopBarTitle>
                        {
                            backRoute ?
                                <CustomLink to={backRoute}>
                                    <ButtonBack icon={faArrowAltCircleLeft} />
                                </CustomLink> : null
                        }
                        {title}
                    </TopBarTitle>
                </Col>
            }
            {children}
        </TopBarContainer>
    )
};

/** @deprecated use ContentBelowTopBar inside UI Lib - 20210505 */
export const ContentBelowTopBar = ({children, barheight=undefined}) => {
    
    return (
        <ContentBelowTopBarStyled barheight={barheight}>
            <ChronoToastStack />
            { children }
        </ContentBelowTopBarStyled>
    )
};
