import React, { Fragment, useState, useEffect, useContext } from 'react';
import { LoadingIndicator } from "../LoadingIndicator";
import { Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { convertDateToEasterTime } from "../common/helper";
import { showPopupConfirm } from "../common/Popups";
import useUI from "../../lib/hooks/useUI";
import useResources from "../../lib/hooks/useResources";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Grid from "../../lib/ui/Grid/Grid";
import * as keys from "../../redux/keys/index";
import * as requestStatus from "../../redux/sagas/requestStatus";
import { useDispatch } from 'react-redux';
import { CustomBadge } from "../common/StatusBadge";
import { ThemeContext } from "styled-components";
import { LoadingIndicatorInApp } from "../LoadingIndicator";
import { activateChronoUser, resendEmailConfirmationChronoUser } from "../../redux/actions/user/requestChronoUser";

const EnableSwitch = ({ list, data, rowIndex, onRefresh }) => {
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useDispatch();
    const status = useUI(keys.CHRONO_USER_ACTIVATE + '_STATUS' + data.userID);
    const element = useResources(keys.CHRONO_USER_ACTIVATE + data.userID);


    useEffect(() => {
        if (status === requestStatus.REQUEST_SUCCEEDED && element == true && rowIndex >= 0 && isSaving === true) {
            list[rowIndex].userStatus = data.userStatus === 2 ? 1 : 2;
            list[rowIndex].email = (data.emailToConfirm && data.emailToConfirm.length > 0) ? data.emailToConfirm : data.email;
            list[rowIndex].emailToConfirm = '';
            console.log(list[rowIndex])
            if (onRefresh)
                onRefresh();
        }
        setIsSaving(status === requestStatus.REQUEST_PENDING);
    }, [status]);

    return (<div>
        {
            isSaving === false &&
            <Form.Switch
                id={`switch${rowIndex}`}
                label=""
                checked={data.userStatus === 1} onChange={() => {
                    return showPopupConfirm({
                        message: (data.userStatus === 2) ? 'Voulez-vous vraiment réactiver ce compte Chrono ?' : 'Voulez-vous vraiment désactiver ce compte Chrono ?',
                        onClickYes: () => {
                            data.userStatus = data.userStatus === 2 ? 1 : 2;
                            setIsSaving(true);
                            dispatch(activateChronoUser(keys.CHRONO_USER_ACTIVATE, {
                                userId: data.userID
                            }));
                        },
                        labelYes: 'Oui',
                        labelNo: 'Annuler'
                    })
                }} />

        }
        {
            isSaving === true && <FontAwesomeIcon icon={faSpinner} spin={true} />
        }
    </div>)
}


const ResendEmailButton = ({ data }) => {
    const [isSending, setIsSending] = useState(false);
    const dispatch = useDispatch();
    const status = useUI(keys.CHRONO_USER_RESEND_EMAIL_CONFIRMATION + '_STATUS' + data.email);
    const showButton = data.userStatus === 2 && data.emailToConfirm !== null && data.emailToConfirm.length > 0

    useEffect(() => {
        setIsSending(status === requestStatus.REQUEST_PENDING);
    }, [status]);

    return (<div>
        {
            showButton && isSending === false &&
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip>
                        Renvoyer le courriel de confirmation à cet utilisateur
                    </Tooltip>
                }
            >
                <Button
                    size="sm"
                    onClick={() => {
                        return showPopupConfirm({
                            message: 'Voulez-vous vraiment envoyer un courriel de confirmation à cet utilisateur ?',
                            onClickYes: () => {
                                setIsSending(true);
                                dispatch(resendEmailConfirmationChronoUser(keys.CHRONO_USER_RESEND_EMAIL_CONFIRMATION, {
                                    email: data.email
                                }));
                            },
                            labelYes: 'Oui',
                            labelNo: 'Annuler'
                        })}
                    }
                    style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faEnvelope} />
                </Button>
            </OverlayTrigger>
        }
        {
            showButton && isSending && <FontAwesomeIcon icon={faSpinner} spin={true} />
        }
    </div>)
}

const ChronoUserList = () => {
    const theme = useContext(ThemeContext);

    const [isLoading, setIsLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);
    const chronoUsers = useResources(keys.CHRONO_USERR_LIST);
    const status = useUI(keys.CHRONO_USERR_LIST + '_STATUS');

    useEffect(() => {
        setIsLoading(status === requestStatus.REQUEST_PENDING);
    }, [status]);

    if (isLoading) {
        return <LoadingIndicatorInApp />;
    }

    const onRefresh = () => {
        setRefresh(!refresh);
    }

    const columns = [
        {
            Header: "Courriel Utilisateur",
            accessor: "email",
            Cell: props => <div>
                <span>{props.value ? props.value : 'Compte anonyme'}</span><br />
                {
                    props.data[props.row.index].emailToConfirm && props.data[props.row.index].emailToConfirm.length > 0 &&
                    <CustomBadge style={{ backgroundColor: theme.colors.messageWarning, width:'100%', fontSize:'14px' }}>
                        {props.data[props.row.index].emailToConfirm}
                    </CustomBadge>
                }
                        </div>
        },
        {
            Header: "Date de création du compte",
            accessor: "creationDate",
            Cell: props => <span>{props.value ? convertDateToEasterTime(props.value, 'YYYY-MM-DD HH:mm') : ''}</span>
        },
        {
            Header: "Date de dernière connection",
            accessor: "lastConnectionDate",
            Cell: props => <span>{props.value ? convertDateToEasterTime(props.value, 'YYYY-MM-DD HH:mm') : ''}</span>
        },

        {
            Header: "Accepte d'être contacté",
            accessor: "isEmailSubscribed",
            Cell: props => <CustomBadge style={{ display: 'inline-grid', backgroundColor: props.value == true ? theme.colors.messageInfo : theme.colors.messageError}}> {props.value == true ? 'Oui' : 'Non' }</CustomBadge>
           
        },
        {
            Header: "Compte Actif",
            accessor: "userStatus",
            Cell: props => <CustomBadge style={{
                display: 'inline-grid', backgroundColor: props.value === 2 && (props.data[props.row.index].emailToConfirm !== null && props.data[props.row.index].emailToConfirm.length > 0)
                                                         ? theme.colors.messageWarning : props.value === 1 ? theme.colors.messageInfo : theme.colors.messageError
            }}>
                {
                    props.value === 2 && (props.data[props.row.index].emailToConfirm !== null && props.data[props.row.index].emailToConfirm.length > 0)
                        ? 'En attente de confirmation de mail' : props.value === 1 ? 'Oui' : 'Non'
                }
            </CustomBadge>
        },
       
        {
            Header: "Actions", 
            accessor: "userID",
            Cell: props => <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <EnableSwitch id={'gridUserChrono_' + props.row.index} data={{ ...props.data[props.row.index] }} rowIndex={props.row.index} list={props.data} onRefresh={onRefresh} />
                <ResendEmailButton data={{ ...props.data[props.row.index] }} />
            </div>         
        },

    ];

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Fragment>
            {
                chronoUsers && chronoUsers.length > 0 &&
                <div>
                    <Grid columns={columns} data={chronoUsers} enableSelectedRow={true}  />
                </div>
            }
            {
                (status === undefined) &&
                <Row style={{ width: '100%' }}>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <h3>Lancez une recherche pour trouver un compte</h3>
                    </Col>
                </Row>
            }
            {
                (status !== undefined) && (!chronoUsers || chronoUsers.length === 0) &&
                <Row style={{ width:'100%' }}>
                    <Col span={12} style={{ textAlign: 'center' }}>
                        <h3>Aucun résultat</h3>
                    </Col>
                </Row>
            }
        </Fragment>
)}

export default ChronoUserList;