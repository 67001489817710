/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactElement} from "react";
import DropdowBox from "./DropdownBox";
import DropdownSelect from "./DropdownSelect";
import DropdownList from "./DropdownList";
import "./Dropdown.css";

const Dropdown: FC<any> = (props, ref):ReactElement => {
    /**
     * @filterOn type: string, select the key that will be use by the DropdownFilter in the options data structure
     */
    const { mode } = props;

    let components = {
        select: <DropdownSelect {...props} />,
        box: <DropdowBox {...props} />,
        list: <DropdownList {...props} />,
    };

    return <>{components[mode]}</>;
};

export default Dropdown;
