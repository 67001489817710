import React, { useState } from 'react';
import { DropdownButton, Dropdown, Pagination, InputGroup } from 'react-bootstrap';
import GenericTableBody from "./GenericTableBody";

const generatePageNumbers = (currentPage, lastPageNumber) => {
    let pageNumbers: Array<Number> = [];
    let isPreviousNumber = true;
    for (let i = 1; i <= lastPageNumber; i++) {
        if (i < 3 || i > lastPageNumber - 2 || (i > currentPage - 2 && i < currentPage + 2)) {
            isPreviousNumber = true;
            pageNumbers.push(i);
        } else if (isPreviousNumber) {
            isPreviousNumber = false;
            pageNumbers.push(0);
        }
    }

    return pageNumbers;
};

const ChooserNumberPageDisplay = ({ itemPerPage, setItemPerPage, setCurrentPage }) => {
    return (
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>
                    Afficher
                </InputGroup.Text>
            </InputGroup.Prepend>
            <DropdownButton
                id="chooserNumberPageDisplay"
                as={InputGroup.Append}
                title={itemPerPage}
                onSelect={(key, e) => {
                    setItemPerPage((e.target as any).text);
                    setCurrentPage(1);
                }}
                variant="outline-secondary">
                <Dropdown.Item>5</Dropdown.Item>
                <Dropdown.Item>10</Dropdown.Item>
                <Dropdown.Item>15</Dropdown.Item>
                <Dropdown.Item>20</Dropdown.Item>
                <Dropdown.Item>25</Dropdown.Item>
            </DropdownButton>
        </InputGroup>
    )
};

const CustomPagination = ({ totalItems, itemPerPage, currentPage, setCurrentPage}) => {
    const lastPageNumber = Math.ceil(totalItems / itemPerPage);
    const generatedPageNumbers = generatePageNumbers(currentPage, lastPageNumber);
    
    return (
        <Pagination>
            <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)}
                             disabled={currentPage === 1 || totalItems === 1} />
            {
                generatedPageNumbers.map((number, index) => {
                    if (number > 0) {
                        return (
                            <Pagination.Item key={index}
                                             active={currentPage === number}
                                             disabled={currentPage === number}
                                             onClick={(e) => setCurrentPage(parseInt((e.target as any).text, 10))}>
                                {number}
                            </Pagination.Item>
                        )
                    } else if (number === 0) {
                        return (<Pagination.Ellipsis disabled key={index} />);
                    } else {
                        return null;
                    }
                })
            }
            <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)}
                             disabled={currentPage === lastPageNumber || totalItems === 1} />
        </Pagination>
    )
};

const GenericTableBodyWithPagination = ({children, dataPerRow}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(5);

    const totalItems = children.length;
    const indexOfLast = currentPage * itemPerPage;
    const indexOfFirst = indexOfLast - itemPerPage;

    if (totalItems === 0) {
        return <span />;
    }

    return (
        <GenericTableBody>
            {children.slice(indexOfFirst, indexOfLast)}
            <tr className="custom-tr-pagination" style={{ backgroundColor: 'transparent' }} >
                <td colSpan={dataPerRow} className="text-center custom-tr-pagination">
                    <CustomPagination
                        totalItems={totalItems}
                        itemPerPage={itemPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage} />

                    <ChooserNumberPageDisplay
                        itemPerPage={itemPerPage} setItemPerPage={setItemPerPage} setCurrentPage={setCurrentPage}/>
                </td>
            </tr>
        </GenericTableBody>
    );
};

export default GenericTableBodyWithPagination;
