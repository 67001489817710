import React, { useState } from "react";
import moment from 'moment';
import { Dropdown } from "../../../../lib/ui";

;

const COLLECTIONS_OPTIONS = [
    { label: 'Dernière heure', value: '1h' },
    { label: 'Dernière journée', value: '1d' },
    { label: 'Dernière semaine', value: '1w' },
    { label: 'Dernier mois', value: '1m' },
    { label: 'Personnalisée', value: '0' }
];

const FORMAT_DATE = 'YYYY-MM-DD HH:mm:ss';

const SelectLastReceptionDate = (props) => {
    const [value, setValue] = useState(props.value ? props.value : COLLECTIONS_OPTIONS[0].value);

    const calculateLastHour = () => {
        let dt = moment();
        let startDate = moment(dt).subtract(1, 'hour').format(FORMAT_DATE);
        let endDate = moment(dt).format(FORMAT_DATE);

        return { startDate: startDate, endDate: endDate };
    }


    const calculateLastDay = () => {
        let dt = moment();
        let startDate = moment(dt).subtract(1, 'day').format(FORMAT_DATE);
        let endDate = moment(dt).format(FORMAT_DATE);

        return { startDate: startDate, endDate: endDate };
    }

    const calculateLastWeek = () => {
        let dt = moment();
        let startDate = moment(dt).subtract(1, 'week').format(FORMAT_DATE);
        let endDate = moment(dt).format(FORMAT_DATE);

        return { startDate: startDate, endDate: endDate };
    }

    const calculateLastMonth = () => {
        let dt = moment();
        let startDate = moment(dt).subtract(1, 'months').format(FORMAT_DATE);
        let endDate = moment(dt).format(FORMAT_DATE);

        return { startDate: startDate, endDate: endDate  };
    }

    const onChange = (e) => {
        let res: any;

        setValue(e.target.value);
        switch (e.target.value) {
            case "1h":
                res = calculateLastHour();
                break;
            case "1d":
                res = calculateLastDay();
                break;
            case "1w":
                res = calculateLastWeek();
                break;
            case "1m":
                res = calculateLastMonth();
                break;
            default:
                res = {};
                break;
        }

        res.value = e.target.value;
        if (props.onChange)
            props.onChange(res);
    }

    return (
        <Dropdown
            mode="select"
            label={props.label ? props.label : "Date de réception des logs"}
            options={props.displayOptions ? COLLECTIONS_OPTIONS.filter(x => props.displayOptions.includes(x.value)) : COLLECTIONS_OPTIONS}
            onChange={onChange}
            selectedValue={value}
        />
    );
};

export default SelectLastReceptionDate;
