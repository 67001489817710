import { useContext, useState, useEffect } from "react";
import { useApiRequest } from "../../redux/hooks/useApiRequest";
import moment from 'moment';
import { AlertForm } from "../../types/forms/alertForms";
import { AlertViewModel, AlertParamViewModel } from "../../types/viewmodels/alertViewModels";
import { CommunicationStatus } from "../../types/entities/communicationEntities";
import * as config from "../../components/communication/alert/AlertConstants";
import ChronoContext from "../../context/ChronoContext";
import { CODENAME_STATUS_CREATED, CODENAME_STATUS_PUBLISHED } from "../../statusUtils";
import { useCacheService } from "../../redux/hooks/useCacheService";
import useUI from "../../lib/hooks/useUI";
import { DATE_FORMAT } from "../../components/communication/CommunicationUtils";
import { TYPE_REQUEST_PUBLISH } from "../../components/communication/CommunicationFormActionButtons";
import { Categories } from '../../redux/keys/generic/types';
import { Status } from "../../types/enum/Status";

export const useAlertService = () => {

    //states
    const [isProcessingSave, setIsProcessingSave] = useState(false);
    const [isErrorSave, setIsErrorSave] = useState(false);
    const [isSuccessSave, setIsSuccessSave] = useState(false);
    const [dataSave, setDataSave] = useState(null);
    const searchParams = useUI(config.SEARCH_PARAM_KEY);
    const [isNewAlert, setIsNewAlert] = useState(false);

    //hook
    const context = useContext(ChronoContext);
    const requestAlertList = useApiRequest(config.GET_ALERTS);
    const requestAlertInsert = useApiRequest(config.INSERT_ALERT);
    const requestAlertUpdate = useApiRequest(config.UPDATE_ALERT);
    const requestAlertGetById = useApiRequest(config.GET_ALERTS_BY_ID);
    const requestAlertDeleteById = useApiRequest(config.DELETE_ALERT_BY_ID);
    const cacheService = useCacheService();

    // hook for save
    useEffect(() => {
        if (requestAlertInsert.isFail === true && isProcessingSave === true) {
            setIsErrorSave(true);
            setIsProcessingSave(false);
        }
    }, [requestAlertInsert.isFail])

    useEffect(() => {
        if (requestAlertInsert.isSuccess === true && isProcessingSave === true) {
            requestAlertList.actions.process(searchParams);

        }
    }, [isNewAlert, requestAlertInsert.isSuccess])

    useEffect(() => {
        if (!requestAlertList.isLoading && isProcessingSave === true && requestAlertInsert.isSuccess === true) {
            setIsSuccessSave(true);
            setIsProcessingSave(false);
            setDataSave(requestAlertInsert.result.data);
        }
    }, [requestAlertList.isLoading])

    //forms parameters
    const defaultValues = { status: [], startDate: moment().add(-15, 'd'), endDate: moment(), filterDate: '1d', filterByDateType: '2' }

    //methods
    const mapToParam = (values: any, statusList: Array<CommunicationStatus>, orgList: Array<any>, userOrgList: Array<any>): AlertParamViewModel => {
        const param = {
            status: statusList ? statusList.filter(x => x.value != null).map(x => x.value as unknown as Status) : [],
            organizations: orgList && orgList.length > 0 ? orgList.filter(x => x.value != null).map(x => x.label) : userOrgList?.filter(x => x.value != null).map(x => x.label),
            startDate: values.startDate && values.startDate != null ? moment(values.startDate).utc().format(DATE_FORMAT) : values.startDate,
            endDate: values.endDate && values.endDate != null ? moment(values.endDate).utc().format(DATE_FORMAT + ' 23:59:59') : values.endDate,
            filterByDateType: values.filterByDateType
        };
        cacheService.Save(param, config.SEARCH_PARAM_KEY, Categories.UI);
        return param as AlertParamViewModel;
    }

    const mapToModel = (model: AlertForm, requestType: number, entity?: any): AlertViewModel => {
        const status = context.staticData.status;
        model.status = requestType === TYPE_REQUEST_PUBLISH ? status[CODENAME_STATUS_PUBLISHED] : status[CODENAME_STATUS_CREATED];
        model.start = moment(moment(model.start).format(DATE_FORMAT + ' 00:00:00')).toDate();
        model.end = moment(moment(model.end).format(DATE_FORMAT + ' 00:00:00')).toDate();
        model.createdById = entity?.createdById;
        model.id = entity?.id;
        return model as AlertViewModel;
    }

    const isAlertEditable = (alert: AlertViewModel) => {
        const status = context.staticData.status;
        return alert.status !== status[CODENAME_STATUS_PUBLISHED] || alert.id === 0;
    }

    const getNewAlert = (): AlertForm => {
        return config.EMPTY_ALERT as AlertForm
    }

    const clearCacheOperations = (id: any): void => {
        cacheService.Remove(config.DELETE_ALERT_BY_ID + id, Categories.REQUEST);
        cacheService.Remove(config.UPDATE_ALERT + id, Categories.REQUEST);
        cacheService.Remove(config.INSERT_ALERT, Categories.REQUEST);
    }

    const save = (model: AlertViewModel): void => {
        clearCacheOperations(model.id);
        setIsProcessingSave(true);
        setIsNewAlert(model.id === 0);
        requestAlertInsert.actions.process(model);
    }

    const update = (model: AlertViewModel): void => {
        clearCacheOperations(model.id);
        requestAlertUpdate.actions.process(model, undefined, { alertId: model.id });
    }

    const updateModel = (model: AlertViewModel): void => {
        if (!model)
            return;
      
        const index = requestAlertList.result.data.findIndex(x => x.id === model.id);
        requestAlertList.result.data[index] = { ...model };

        //const list = [...requestAlertList.result.data];
        //list[index] = { ...model };
        //cacheService.Save(list, config.GET_ALERTS, Categories.RESOURCES);
    }

    const getById = (alertId: number): void => {
        requestAlertGetById.actions.process('', { alertId: alertId }, { alertId: alertId });
    }

    const deleteById = (alertId?: number, keySuffix?:any): void => {
        clearCacheOperations(alertId);
        requestAlertDeleteById.actions.process('', { alertId: alertId }, { alertId: alertId, keySuffix: keySuffix});
    }

    const removeElement = (id?: number): void => {
        const index = requestAlertList.result.data.findIndex(x => x.id === id);
        requestAlertList.result.data.splice(index, 1);

        //const list = [...requestAlertList.result.data];
        //cacheService.Save(list.splice(index, 1), config.GET_ALERTS, Categories.RESOURCES);
    }

    const cleanCache = (): void => {
        clearCacheOperations(0);
        cacheService.Remove(config.SEARCH_PARAM_KEY, Categories.UI);
        cacheService.Remove(config.ALERT_LIST_GET, Categories.RESOURCES);
    }

    const canPublish = (model: AlertViewModel): boolean => {
        var dt = moment().format(DATE_FORMAT);
        return moment(model.end).isAfter(moment(dt));
    }


    // interfaces
    return {
        request: {
            GetAll: {
                isLoading: requestAlertList.isLoading,
                isSuccess: requestAlertList.isSuccess,
                isFail: requestAlertList.isFail,
                process: requestAlertList.actions.process,
                data: requestAlertList.result.data
            },
            Save: {
                isLoading: isProcessingSave,
                isSuccess: isSuccessSave,
                isFail: isErrorSave,
                process: save,
                data: dataSave,
                changeResourceName: requestAlertUpdate.setResource,
                getResource: requestAlertUpdate.getResource,
                refreshState: (id) => {
                    setIsSuccessSave(false);
                    setIsErrorSave(false);
                    clearCacheOperations(id);
                }
            },
            Update: {
                isLoading: requestAlertUpdate.isLoading,
                isSuccess: requestAlertUpdate.isSuccess,
                isFail: requestAlertUpdate.isFail,
                process: update,
                data: requestAlertUpdate.result.data,
                changeResourceName: requestAlertUpdate.setResource,
                getResource: requestAlertUpdate.getResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                }
            },
            GetById: {
                isLoading: requestAlertGetById.isLoading,
                isSuccess: requestAlertGetById.isSuccess,
                isFail: requestAlertGetById.isFail,
                process: getById,
                data: requestAlertGetById.result.data,
                changeResourceName: requestAlertGetById.setResource,
            },
            Delete: {
                isLoading: requestAlertDeleteById.isLoading,
                isSuccess: requestAlertDeleteById.isSuccess,
                isFail: requestAlertDeleteById.isFail,
                process: deleteById,
                data: requestAlertDeleteById.result.data,
                changeResourceName: requestAlertDeleteById.setResource,
                refreshState: (id) => {
                    clearCacheOperations(id);
                }
            }
        },
        Filter: {
            defaultValues: defaultValues,
            searchParams: searchParams
        },
        Constantes: {
            DISPLAY_DATE_FORMAT: DATE_FORMAT
        },
        mapToParam: mapToParam,
        mapToModel: mapToModel,
        isAlertEditable: isAlertEditable,
        getNewAlert: getNewAlert,
        cleanCache: cleanCache,
        updateModel: updateModel,
        removeElement: removeElement,
        canPublish: canPublish
    };
};


