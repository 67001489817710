/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactElement, useState, useEffect } from "react";
import DropdownFilter from "./DropdownFilter";
import DropdownSearch from "./DropdownSearch";
import { Form } from "react-bootstrap";

import "./Dropdown.css";
import { debounce } from "../../../toolbox";

const DropdownList:FC<any> = (props):ReactElement => {
    const { selectedValues, options, onChange, filterOn } = props;

    const [filteredOptions, setFilteredOptions] = useState(options);
    const [searchTerm, setSearchTerm] = useState(null);
    const [filter, setFilter] = useState(null);
    const [values, setValues] = useState(selectedValues);

    const executeFiltersCondition = (filters, searchTerm, option) => {
        if (filters && searchTerm) {
            return filters.some((filter) => filter.label === option.filterOn) && option.label.indexOf(searchTerm) > -1;
        }

        if (filters) {
            return filters.some((filter) => filter.label === option.filterOn);
        }

        if (searchTerm) {
            return option.label.indexOf(searchTerm) > -1;
        }

        return true;
    };

    const handleFilter = (filters, searchTerm, options) => {
        return options.map((option) => {
            if (executeFiltersCondition(filters, searchTerm, option)) {
                return {
                    ...option,
                    hide: false,
                };
            } else {
                return {
                    ...option,
                    hide: true,
                };
            }
        });
    };

    useEffect(() => {
        if (options) {
            const filterResult = handleFilter(filter, searchTerm, options);

            if (filterResult) {
                setFilteredOptions(filterResult);
            } else {
                setFilteredOptions(options);
            }
        }
    }, [options, filter, searchTerm]);

    const handleCheckbox = async (e) => {
        if (e.target.checked) {
            setValues([...values, e.target.value]);
        } else {
            let newValues = [...values];
            const index = values.indexOf(e.target.value);
            newValues.splice(index, 1);
            setValues(newValues);
        }
        onChange(e);
    };

    const handleSearch = debounce((v) => {
        setSearchTerm(v)
    }, 200)

    return (
        <>
            <div className="filterFlexContainer">
                {filterOn && <DropdownSearch {...props} onChangeSearch={(e) => handleSearch(e.target.value)} />}
                {filterOn && <DropdownFilter {...props} onChangeFilter={(e) => setFilter(e)} />}
            </div>
            <div className="dropdownListBody">
                {filteredOptions.map((option) => {
                    return (
                        <div id={option["value"]} className="checkBoxLine">
                            <Form.Check
                                id={option["value"]}
                                label={option["label"]}
                                onChange={handleCheckbox}
                                value={option["value"]}
                                disabled={option["disabled"]}
                                defaultChecked={values.includes(option["value"])}
                                className={`${option["disabled"] ? "disabled" : ""} ${option["hide"] ? "hide" : ""}`}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default DropdownList;
