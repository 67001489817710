/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import GenericTableCell from "../common/generic/table/cell/GenericTableCell";
import GenericTable, { GenericTableRow } from "../common/generic/table/GenericTable";
import GenericTableBody from "../common/generic/table/GenericTableBody";
import GenericTableHeadSingleRow from "../common/generic/table/GenericTableHeadSingleRow";
import { sendPostRequest, sendGetRequest } from "../../auth/apiEffects";
import { LoadingIndicator } from "../LoadingIndicator";
import { Row, Col, Button } from 'react-bootstrap';
import { Header } from './BilletterieCompte';
import { convertDateToEasterTime } from "../common/helper";
import { faReply, faSpinner, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showPopupConfirm } from "../common/Popups";
import styled from 'styled-components';
import GenericTextArea from "../common/generic/GenericTextArea";
import { useToasts } from 'react-toast-notifications'
import { ISendGetRequest } from './AllOrderInterface'

const FormRow = styled(Row)`
    margin-top: 5px;
`

let ordersSendingEmail: string[] = [];

const AllOrders = ({searchParams, validated}) => {
    const [orders, setOrders] = useState<ISendGetRequest[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSendingAll, setIsSendingAll] = useState(false);
    const [toastMessageType, setToastMessageType] = useState('');
    const [isSendingEmail, setIsSendingEmail] = useState(false);

    const { addToast } = useToasts();

    useEffect(() => {
        setIsLoading(true);
        async function fetchData() {
            const results: ISendGetRequest[] | undefined | false = await sendGetRequest(`/commande/liste/historique?${searchParams}`);
            const formattedResult = results ? results.map((obj) => {
                return { ...obj, isEdit: false, isSending: false, isDirty: false };
            }) : [];

            setOrders(formattedResult);
            setIsLoading(false);
        }
        fetchData();
    }, [searchParams]);


    useEffect(() => {
        displayToast();
    }, [toastMessageType, isSendingAll])

    const getMessagesToast = () => {
        return {
            success: { text: `Vos commentaires ont  bien été enregistrés.`, type: 'success' },
            error: { text: `Une erreur s'est produite.`, type: 'error' }
        }
    }
    const displayToast = () => {
        if (toastMessageType === null) return;
        if (isSendingAll === true) return;
        const content = getMessagesToast();
        const mess = content[toastMessageType] !== undefined ? content[toastMessageType] : content.error;
        return addToast(mess.text, {
            appearance: mess.type,
            autoDismiss: true,
        })
    }

    const onNotesChange = (value, rowId, dateCommande) => {
        const orderList = [...orders];
        const order = getOrder(dateCommande, rowId, orderList);
        if (order)
            order.notes = value;

        setOrders(orderList);
    }

    const onDoubleClick = (orderNo, dateCommande) => {
        const orderList = [...orders];
        const order = getOrder(dateCommande, orderNo, orderList) ;
        if (order) {
            order.isEdit = true;
            order.isDirty = true;
        }

        setOrders(orderList);

    }

    const onBlur = (orderNo, dateCommande) => {
        const orderList = [...orders];
        const order = getOrder(dateCommande, orderNo, orderList);
        if (order)
            order.isEdit = false;

        setOrders(orderList);

    }

    const getOrder = (dateCommande, orderNo, orderList) => {
        const order = orderList.find(x => x.dateCommande === dateCommande);
        if (order)
            return order.details.find(x => x.noCommande === orderNo);
    }

    const onSave = async () => {

        try {
            setIsSendingAll(true);

            var orderList = [...orders];
            var ordersToSave: { id: string; notes: []}[] = []

      
            orderList.forEach((ord) => {
                var detailsList = ord.details.filter((det) => {
                    return det.isDirty === true;
                });
                if (detailsList && detailsList.length > 0){
                    // Confusing systax - why concat with the empty array
                    // ordersToSave = ordersToSave.concat(detailsList.map((d) => {
                    //     return { id: d.noCommande, notes: d.notes };
                    // }))
                    
                    ordersToSave = detailsList.map((d) => {
                        return { id: d.noCommande, notes: d.notes };
                    })
                }
            });
            if (ordersToSave.length <= 0 )
                return;

            await sendPostRequest('/commande/updateOrderNotes', ordersToSave);
            resetDirtyState();
            setToastMessageType('success');
        }
        catch (err)
        {
            setToastMessageType('error');
        }
        finally
        {
            setIsSendingAll(false);
        }
    }

    const resetDirtyState = () => {
        var orderList = [...orders];
         // eslint-disable-next-line array-callback-return
         orderList.map((ord) => {
             // eslint-disable-next-line array-callback-return
             ord.details.map((det) => {
                det.isDirty = false;
            });
        });
        setOrders(orderList);
    }

    function onSendEmail(noCommande, dateCommande) {
        if (isSendingAll)
            return;

        return showPopupConfirm({
            message: 'Voulez-vous vraiment renvoyer cette facture ?',
            onClickYes: () => sendEmail(noCommande, dateCommande)
        });
    }

    async function sendEmail(noCommande, dateCommande) {
        const orderList = [...orders];
        const orderDetail = orderList.find(x => x.dateCommande === dateCommande);
        const order = orderDetail ? orderDetail.details.find(x => x.noCommande === noCommande) : null;
        
        if (!order || order == null)
            return;

        ordersSendingEmail.push(order.noCommande);
        setIsSendingEmail(true);

        order.isSending = true;
        setOrders(orderList);
        await sendPostRequest('/commande/' + noCommande);

        ordersSendingEmail = remove(order.noCommande, ordersSendingEmail);
        setIsSendingEmail(ordersSendingEmail.length > 0);

        order.isSending = false;
        setOrders([...orderList]);
    }

    const remove = (noCommande, emailList) => {
        emailList = emailList.filter((no) => {
            return no !== noCommande;
        });

        return emailList;
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Fragment>
            {
                orders.length > 0 &&
                <div style={{padding: "0px 10px 0px 10px"}}>
                    {
                        orders.sort((a, b) => new Date(b.dateCommande).valueOf() - new Date(a.dateCommande).valueOf()).map((item, i) => {
                            return (
                                <div key= {i}>
                                    <FormRow>
                                        <Col xl={6} md={6}>
                                            <Header>{convertDateToEasterTime(item.details[0].dateHeureAchat, 'YYYY-MM-DD')}</Header>
                                        </Col>
                                        {
                                            i === 0 &&
                                            <Col xl={6} md={6}>
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button onClick={onSave} disabled={isSendingAll || isSendingEmail}>
                                                        {!isSendingAll ? <span><FontAwesomeIcon icon={faSave} /> Enregistrer</span> : <span><FontAwesomeIcon icon={faSpinner} spin={true} /> Sauvegarde en cours</span>}
                                                    </Button>
                                                </div>
                                            </Col>
                                        }
                                       
                                    </FormRow>
                                    <FormRow>
                                        <GenericTable>
                                            <GenericTableHeadSingleRow style={{ backgroundColor:'#DCDCDC'}}>
                                                <th style={{ width: '10%' }}>ID commande</th>
                                                <th style={{ width: '15%' }}>Nom du titre</th>
                                                <th style={{ width: '20%' }}>Identifiant titre</th>
                                                <th style={{ width: '12%' }}>ID paiement</th>
                                                <th style={{ width: '15%' }}>Date et heure achat</th>
                                                <th style={{ width: '16%' }}>Commentaire</th>
                                                <th style={{ width: '12%' }}>Renvoi de la facture</th>
                                            </GenericTableHeadSingleRow>
                                            <GenericTableBody key={i + '_inside'} style={{ borderTop: 'none' }}>
                                            {
                                        
                                                (item.details.sort((a, b) => new Date(b.dateHeureAchat).valueOf() - new Date(a.dateHeureAchat).valueOf())).map((ticket, j) => {
                                                return (
                                                    <Fragment key={j}>
                                                    
                                                        <GenericTableRow  key={ticket.idsProduits}>
                                                            <GenericTableCell>{ticket.noCommande}</GenericTableCell>
                                                            <GenericTableCell>{ticket.nomsProduits}</GenericTableCell>
                                                            <GenericTableCell>{ticket.idsProduits}</GenericTableCell>
                                                            <GenericTableCell
                                                                style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', maxWidth: 300}}
                                                                onClick={() => window.open(ticket.urlPaiement, '_blank')}>

                                                                {ticket.paiementId}</GenericTableCell>
                                                            <GenericTableCell>{convertDateToEasterTime(ticket.dateHeureAchat, 'YYYY-MM-DD HH:mm')}</GenericTableCell>
                                                            <td onBlur={() => onBlur(ticket.noCommande, item.dateCommande)} onDoubleClick={() => onDoubleClick(ticket.noCommande, item.dateCommande)} style={{ padding: '5px', verticalAlign: 'middle' }}><GenericTextArea maxSize={150} disabled={ticket.isSending || isSendingAll} isEdit={ticket.isEdit} value={ticket.notes} rows={"5"} cols={"5"} rowId={ticket.noCommande} changeParameter={item.dateCommande}  onChange={onNotesChange} /></td>
                                                            <GenericTableCell> {ticket.isSending === true ? <span><FontAwesomeIcon icon={faSpinner} spin={true} /></span> : <span  onClick={() => onSendEmail(ticket.noCommande, item.dateCommande)} style={{ cursor: 'pointer' }}><FontAwesomeIcon  icon={faReply} /></span>} </GenericTableCell>
                                                        </GenericTableRow>
                                                    </Fragment>
                                                    )
                                                })
                                            }
                                                   
                                            </GenericTableBody>
                                        </GenericTable>
                                    </FormRow>
                                </div>
                                )
                            })
                        }
                </div>
            }
            {
                (!orders || orders.length === 0) && validated &&
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <h3>Aucun résultat</h3>
                        </Col>
                    </Row>
            }
            </Fragment>
    )
}

export default AllOrders;