import * as  React from 'react';
import { Row, Col, Dropdown, Button, ButtonGroup } from 'react-bootstrap';
import CommunicationFormActionButtons from "./CommunicationFormActionButtons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'


const CommunicationActionButtonGrid = ({ isProcessing, disabled, onClickSend, onClickDelete, hideApprove, hideRefuse, hidePublish, canPublish, data }) => {
    return (<Dropdown variant="light" as={ButtonGroup} drop="down" onClick={(e) => e.stopPropagation()}>
        {
            (isProcessing === false && (!hideApprove || !hidePublish)) ?
            <CommunicationFormActionButtons
                key={'test'+data.id}
                disabled={disabled}
                cssClass={'com-btn-actions ' + (disabled ? 'btn-secondary' : 'btn-primary')}
                onClickSend={onClickSend}
                onClickDelete={onClickDelete}
                isFormFilled={canPublish}
                itemExists={true}
                isValidated={true}
                hideApprove={hideApprove}
                showRefused={!hideRefuse}
                hidePublish={hidePublish}
                hasBeenModified={true}
        /> : null
        }
        {
            isProcessing === true &&
            <FontAwesomeIcon icon={faSpinner} spin={true} />
        }

    </Dropdown>)
}


export default CommunicationActionButtonGrid;