import React, { Fragment, useContext, useState } from 'react';
import { Row, Col, Accordion, Card } from "react-bootstrap";
import { ContentBelowTopBar, TopBar } from '../common/ContentNavigation';
import PublishGTFS from './gtfs/PublishGTFS';
import RebuildGTFS from './gtfs/RebuildGTFS';
import DeactivateGTFS from './gtfs/DeactivateGTFS';

import { faMobileAlt, faCogs, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { ThemeContext } from "styled-components";
import Alert from 'react-bootstrap/Alert';
import RoleHelper, { ROLE_CODENAME_ADMIN } from "../common/RoleHelper";



const AdminMenuContainer = styled.div`
    padding: 20px 40px;
`;

const CustomHr = styled.hr`
    margin: 0;
`;

const CustomAccordion = styled(Accordion)`
    margin: 15px;
    width: 100%;
`;

const AccordionTitle = styled.div`
    font-size: 1.2em
`;

const getHeaderColor = (theme, isActive, isHovering) => {
    if (isActive) {
        if (isHovering) {
            return theme.colors.secondaryDark;
        } else {
            return theme.colors.secondary;
        }
    } else {
        if (isHovering) {
            return theme.colors.darkGray;
        } else {
            return theme.colors.mediumGray;
        }
    }
};

const PanelPublications = () => {
    const theme = useContext(ThemeContext);
    const [isActive, setIsActive] = useState(true);
    const [isHovering, setIsHovering] = useState(false);

    return (
        <CustomAccordion defaultActiveKey="0">
            <Card>
                <Accordion.Toggle className="adminCardHeader"
                                  onClick={() => setIsActive(!isActive)}
                                  as={Card.Header} eventKey="0"
                                  onMouseEnter={() => setIsHovering(true)}
                                  onMouseLeave={() => setIsHovering(false)}
                                  style={{backgroundColor: getHeaderColor(theme, isActive, isHovering)}}>
                    <AccordionTitle>
                        <FontAwesomeIcon icon={faMobileAlt} /> Gérer les publications
                    </AccordionTitle>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <PublishGTFS />
                    </Card.Body>
                </Accordion.Collapse>
                <CustomHr />
            </Card>
        </CustomAccordion>
    )
};

const PanelTimetables = () => {
    const theme = useContext(ThemeContext);
    const [isActive, setIsActive] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    return (
        <CustomAccordion>
            <Card>
                <Accordion.Toggle className="adminCardHeader"
                                  onClick={() => setIsActive(!isActive)}
                                  as={Card.Header} eventKey="1"
                                  onMouseEnter={() => setIsHovering(true)}
                                  onMouseLeave={() => setIsHovering(false)}
                                  style={{backgroundColor: getHeaderColor(theme, isActive, isHovering)}}>
                    <AccordionTitle>
                        <FontAwesomeIcon icon={faCogs} /> Reconstruire un horaire
                    </AccordionTitle>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <RebuildGTFS />
                    </Card.Body>
                </Accordion.Collapse>
                <CustomHr />
            </Card>
        </CustomAccordion>
    )
};

const PanelDeactivations = (onChangeActivation) => {
    const theme = useContext(ThemeContext);
    const [isActive, setIsActive] = useState(true);
    const [isHovering, setIsHovering] = useState(false);

    return (
        <CustomAccordion defaultActiveKey="0">
            <Card>
                <Accordion.Toggle className="adminCardHeader"
                    onClick={() => setIsActive(!isActive)}
                    as={Card.Header} eventKey="0"
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    style={{ backgroundColor: getHeaderColor(theme, isActive, isHovering) }}>
                    <AccordionTitle>
                        <FontAwesomeIcon icon={faArrowCircleDown} /> Gérer la consommation des GTFS
                    </AccordionTitle>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <DeactivateGTFS onChangeActivation={onChangeActivation}/>
                    </Card.Body>
                </Accordion.Collapse>
                <CustomHr />
            </Card>
        </CustomAccordion>
    )
};


const AdminMenu = () => {
    const [isGtfsActive, setIsGtfsActive] = useState(true);

    return (
        <Fragment>
            <TopBar title="Administration" />
            <ContentBelowTopBar>
                <AdminMenuContainer>
                    {
                        !isGtfsActive &&
                        <Row>
                            <Col>
                                <Alert variant='danger' >
                                    La consommation des GTFS est désactivée
                                </Alert>
                            </Col>
                        </Row>

                    }
                    <Row>
                        <PanelPublications />
                    </Row>
                    <Row>
                        <PanelTimetables />
                    </Row>
                    <RoleHelper roleCodenameAllowed={ROLE_CODENAME_ADMIN}>
                        <Row>
                            <PanelDeactivations onChangeActivation={(active) => {
                                setIsGtfsActive(active);
                            }} />
                        </Row>
                    </RoleHelper>
                </AdminMenuContainer>
            </ContentBelowTopBar>
        </Fragment>
    );
};

export default AdminMenu;
