import React from 'react';
import { InputGroup, FormControl, Form } from 'react-bootstrap';
interface IGenericInput {
    label?: string;
    value: string;
    placeholder?: string;
    maxLength?: number | string;
    isDisabled?: boolean;
    isRequired?: boolean;
    onChange?: (ev: any) => void;
    onKeyPress?: (ev: any) => void;
    type?: string;
    invalid?: boolean;
    style?: any;
    format?: string;
    feedback?: string;
    cssClass?: string;
    canUserEdit?: boolean;
}

const GenericInput: React.FC<IGenericInput> = ({ 
    label = '', value = '', placeholder = label, maxLength = undefined,
    isDisabled = undefined, isRequired = undefined, onChange = undefined,
    onKeyPress = undefined, type = 'text', style = undefined, feedback = undefined,
    invalid = undefined, canUserEdit = true, format = '', cssClass =''
}) => {
    const requiredText = isRequired ? ' *' : '';
    const invalidStyling = invalid ? {width: 130, background: 'white', border: '1px solid #dc3545' } : undefined 
    
    return (
        <InputGroup>
            {
                label ?
                    <InputGroup.Prepend style={style ? style : { minWidth: 130}}>
                        <InputGroup.Text style={style ? style : {minWidth: 130}}>{label + requiredText}</InputGroup.Text>
                    </InputGroup.Prepend> : null
            }
            <FormControl type={type}
                         value={value}
                         placeholder={placeholder + requiredText} maxLength={Number(maxLength)}
                         required={isRequired} disabled={isDisabled || !canUserEdit}
                         onChange={onChange} 
                         onKeyPress={onKeyPress}
                         className={cssClass}
                         style={invalidStyling}/>
            {
                value && maxLength ?
                    <InputGroup.Append>
                        <InputGroup.Text>
                            <small className="text-muted appended-counter"> {value.length} / {maxLength} </small>
                        </InputGroup.Text>
                    </InputGroup.Append>
                    : null
            }
            {
                format ?
                    <InputGroup.Append>
                        <InputGroup.Text>
                            <small className="text-muted appended-counter" style={{ fontStyle:'italic' }}> {format} </small>
                        </InputGroup.Text>
                    </InputGroup.Append>
                    : null
            }
            {
                feedback 
                ? <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
                : null
            }

           
            </InputGroup>
           
    );
};

export default GenericInput;
