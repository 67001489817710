import React from 'react';

const GenericTableBody = ({children, ...props}) => {
    return (
        <tbody {...props}>
        {children}
        </tbody>
    );
};

export default GenericTableBody;
