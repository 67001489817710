export enum Types {
    GET_RESOURCES = "GET_RESOURCES",
    ADD_RESOURCES = "ADD_RESOURCES_NEW",
    CANCEL_SAGA = "CANCEL_SAGA",
    ADD_UI_ITEM = "ADD_UI_ITEM_NEW",
    ADD_REQUEST_STATUS = "ADD_REQUEST_STATUS"
}

export enum Categories {
    UI = "ui",
    REQUEST = "request",
    RESOURCES = "resources"
}