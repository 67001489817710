export const ROUTE_HOME = '/home';

export const ROUTE_COMMUNICATION_MESSAGES = (messageId = ':id?') => '/communication/messages/' + messageId;
export const ROUTE_COMMUNICATION_MESSAGES_TEST = (messageId = ':id?') => '/communication/messagestest/' + messageId;
export const ROUTE_COMMUNICATION_CARTES = (carteId = ':id?') => '/communication/cartes/' + carteId;
export const ROUTE_COMMUNICATION_ALERTS = (alertId = ':id?') => '/communication/alerts/' + alertId;
export const ROUTE_COMMUNICATION_NOTIFICATIONS = (notificationtId = ':id?') => '/communication/notifications/' + notificationtId;

export const ROUTE_STATS_USAGE = '/stats/usage';
export const ROUTE_STATS_PLANIFICATIONS = '/stats/planifications';
export const ROUTE_STATS_FAVORIS = '/stats/favoris';
export const ROUTE_STATS_TICKETING = '/stats/billetterie';
export const ROUTE_STATS_REALTIME = '/stats/tempsReel';

export const ROUTE_TICKETING_ACCOUNT = '/billetterie/compte';
export const ROUTE_TICKETING = (ticketId = ':id?') => '/stats/billetterie/' + ticketId;
export const ROUTE_TICKETING_REPORT = '/billetterie/rapport';

export const ROUTE_TICKETING_ORDER = '/billetterie/commande';

export const ROUTE_SUPPORT_CHRONO_USERS = '/support/chronoUsers';

export const ROUTE_ADMIN_USERS = '/admin/users';
export const ROUTE_ADMIN_USER_PROFILE = (id = ':id') => '/admin/users/' + id;

export const ROUTE_ADMIN_MOBILE_CONFIG = '/admin/mobile-config';
export const ROUTE_ADMIN_REALTIME = '/admin/realtime';

export const ROUTE_MONITORING_APPLICATION_LOGS = '/monitoring/applicationLogs'
export const ROUTE_MONITORING_MOBILE_CONFIGURATION_LOGS = '/monitoring/mobileLogConfiguration'
export const ROUTE_MONITORING_REALTIME = '/monitoring/realtime';
export const ROUTE_MONITORING_GTFS = '/monitoring/gtfs';

export const ROUTE_ADMIN_GTFS_MENU = '/admin/gtfs/menu';
export const ROUTE_ADMIN_GTFS_LOGS = '/admin/gtfs/logs';

export const ROUTE_ACHALANDAGE_CONFIG = '/admin/achalandage/config';
export const ROUTE_COMMUNICATION_CALENDAR = '/communication/calendar/calendarPage';

export const ROUTE_DEBUGTOOL_RESULTCOMPARER = '/debugTool/realtime/resultComparer';



