import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import * as types from "../../actions/types/types";
import * as consoleApi from "../../../api";
import addResource from "../../actions/addResource";
import addUIItem from "../../actions/addUIItem";
import * as constanteConfig from '../../keys/applicationLogs/configurationLogs';
import * as statusRequest from '../requestStatus';


export function* watchMobileConfigLogs() {
    yield takeLatest(types.GET_MOBILE_CONFIG_LOG_REQUESTED, getMobileConfigurationLog);
    yield takeEvery(types.SAVE_MOBILE_CONFIG_LOG, saveMobileConfigurationLog);
    yield takeEvery(types.GET_MOBILE_CONFIGURATION_LIST_ITEMS, getMobileConfigurationListItems);
}

export function* getMobileConfigurationLog(action) {
    const { queryParams } = action.payload;
    const status = constanteConfig.CONFIGURATION_APP_LOGS + '_STATUS';

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));

        const results = yield call(
            consoleApi.getConfigurationLog,
            queryParams
        );

        if (!results) {
            throw new Error("Unable to get configuration logs.");
        }

        yield put(addResource(results, constanteConfig.CONFIGURATION_APP_LOGS));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {

        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}

export function* saveMobileConfigurationLog(action) {
    const { queryParams } = action.payload;
    const status = constanteConfig.CONFIGURATION_APP_TO_SAVE + '_STATUS' + (queryParams.id ? queryParams.id : '');

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));

        const result = yield call(
            consoleApi.saveConfigurationLog,
            queryParams
        );

        if (!result) {
            throw new Error("Unable to save configuration log");
        }

        yield put(addResource(result, constanteConfig.CONFIGURATION_APP_TO_SAVE + (queryParams.id ? queryParams.id : '')));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {

        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}

export function* getMobileConfigurationListItems(action) {
    const { queryParams } = action.payload;
    const status = queryParams.resourceName + '_STATUS';

    try {
        yield put(addUIItem(statusRequest.REQUEST_PENDING, status));

        const results = yield call(
            consoleApi.getMobileConfigurationListItems,
            { propertyName: queryParams.propertyName, criteria: queryParams.criteria }
        );

        if (!results) {
            throw new Error("Unable to get data.");
        }

        yield put(addResource(results, queryParams.resourceName));
        yield put(addUIItem(statusRequest.REQUEST_SUCCEEDED, status));

    } catch (e) {

        yield put(addUIItem(statusRequest.REQUEST_FAILED, status));
    }
}

