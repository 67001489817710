import React, { Fragment } from 'react';
import GenericTableCell from "../common/generic/table/cell/GenericTableCell";
import GenericTable, { GenericTableRow } from "../common/generic/table/GenericTable";
import GenericTableBody from "../common/generic/table/GenericTableBody";
import GenericTableHeadSingleRow from "../common/generic/table/GenericTableHeadSingleRow";
import { useChronoApi } from "../../auth/apiEffects";
import { LoadingIndicator } from "../LoadingIndicator";
import { Row, Col, InputGroup } from 'react-bootstrap';
import ChronoCard from "../common/ChronoCard";
import { FormRow } from './BilletterieCommande';
import { convertDateToEasterTime } from "../common/helper";



const Order = ({ searchParams, validated }) => {
    const { data: order, isLoading } = useChronoApi(`/commande/${searchParams}`);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Fragment>
            {
                order && order.noCommande  &&

                <ChronoCard>
                    <FormRow style={{ marginTop: 10 }}>
                        <Col xl={3} md={6} xs={12}>
                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: 150 }}>
                                    <InputGroup.Text style={{ minWidth: 150, backgroundColor: 'white', border: 'none', fontWeight: 'bold' }}>{'Numéro commande *'}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>

                        <Col xl={9} md={6} xs={12}>
                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: 150 }}>
                                    <InputGroup.Text style={{ minWidth: 150 }}>{'#' + order.noCommande}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>
                    </FormRow>

                    <FormRow style={{ marginTop: 10 }}>
                        <Col xl={3} md={6} xs={12}>
                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: 150 }}>
                                    <InputGroup.Text style={{ minWidth: 150, backgroundColor: 'white', border: 'none', fontWeight: 'bold' }}>{'Date et heure de commande'}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>

                        <Col xl={9} md={6} xs={12}>
                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: 150 }}>
                                    <InputGroup.Text style={{ minWidth: 150, backgroundColor: 'white', border: 'none' }}>{convertDateToEasterTime(order.dateHeureCommande, 'YYYY-MM-DD à HH:mm:ss')}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>
                    </FormRow>

                    <FormRow style={{ marginTop: 10 }}>
                        <Col xl={3} md={6} xs={12}>
                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: 150 }}>
                                    <InputGroup.Text style={{ minWidth: 150, backgroundColor: 'white', border: 'none', fontWeight: 'bold' }}>{'Id paiement'}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>

                        <Col xl={9} md={6} xs={12}>
                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: 150 }}>
                                    <InputGroup.Text style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', minWidth: 150, backgroundColor: 'white', border: 'none' }}
                                        onClick={() => window.open(order.paiementUrl, '_blank')}>{'#' + order.paymentToken}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>
                    </FormRow>

                    <FormRow style={{ marginTop: 10 }}>
                        <Col xl={3} md={6} xs={12}>
                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: 150 }}>
                                    <InputGroup.Text style={{ minWidth: 150, backgroundColor: 'white', border: 'none', fontWeight: 'bold' }}>{'Id client'}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>

                        <Col xl={9} md={6} xs={12}>
                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: 150 }}>
                                    <InputGroup.Text style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', minWidth: 150, backgroundColor: 'white', border: 'none' }}
                                        onClick={() => window.open(order.clientUrl, '_blank')}>{order.courriel}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>
                    </FormRow>

                    <FormRow style={{ marginTop: 10 }}>
                        <Col xl={3} md={6} xs={12}>
                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: 150 }}>
                                    <InputGroup.Text style={{ minWidth: 150, backgroundColor: 'white', border: 'none', fontWeight: 'bold' }}>{'Montant total'}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>

                        <Col xl={9} md={6} xs={12}>
                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: 150 }}>
                                    <InputGroup.Text style={{ minWidth: 150, backgroundColor: 'white', border: 'none' }}>{order.amount.toFixed(2) + ' $'}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>
                    </FormRow>

                    <FormRow style={{ marginTop: 10 }}>
                        <Col xl={3} md={6} xs={12}>
                            <InputGroup>
                                <InputGroup.Prepend style={{ minWidth: 150 }}>
                                    <InputGroup.Text style={{ minWidth: 150, backgroundColor: 'white', border: 'none', fontWeight: 'bold' }}>{'Articles'}</InputGroup.Text>
                                </InputGroup.Prepend>
                            </InputGroup>
                        </Col>

                        <Col xl={8} md={6} xs={12}>
                            <GenericTable>
                                <GenericTableHeadSingleRow>
                                    <th>Noms article</th>
                                    <th>Identifiants article</th>
                                    <th>Montant</th>
                                    <th>Quantité</th>
                                </GenericTableHeadSingleRow>

                                <GenericTableBody>
                                    {
                                        order && order.items && order.items.length > 0 ?
                                            order.items.map(item => {

                                                return (
                                                    <GenericTableRow key={item.id}>
                                                        <GenericTableCell>{item.title} <br/> {item.organisationCode + ' - ' + item.description}</GenericTableCell>
                                                        <GenericTableCell>{item.no}</GenericTableCell>
                                                        <GenericTableCell>{item.amount.toFixed(2)}</GenericTableCell>
                                                        <GenericTableCell>{item.quantite}</GenericTableCell>
                                                    </GenericTableRow>
                                                );

                                            })
                                            : <GenericTableRow>
                                                <GenericTableCell colSpan={4}>
                                                    Aucune donnée
                                                    </GenericTableCell>
                                            </GenericTableRow>

                                    }
                                </GenericTableBody>
                            </GenericTable>
                        </Col>
                    </FormRow>

                </ChronoCard>
            }

            {
                (!order || !order.noCommande) && validated &&
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <h3>Aucun résultat</h3>
                    </Col>
                </Row>
            }
        </Fragment>
    )
}

export default Order;