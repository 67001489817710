import { Col, Row, FormControl } from "react-bootstrap";
import React, { useContext, useState } from "react";
import BaseCardHome, { TitleCard } from "./BaseCardHome";
import styled from "styled-components";
import AgencyContainerMessage from "../../common/agency/AgencyContainerMessage";
import ChronoContext from "../../../context/ChronoContext";
import {
    LABEL_STATUS_ALL,
    LABEL_STATUS_NONE,
    CODENAME_STATUS_ALL,
    CODENAME_STATUS_ACTIVE,
    CODENAME_STATUS_AWAITING_ACTIVATION,
    CODENAME_STATUS_EXPIRED,
    LIST_STATUS,
} from "../../../statusUtils";
import moment from "moment";

import DropdownCheckboxes from "../../communication/DropdownCheckboxes";

const CustomRow = styled(Row)`
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
`;

const CustomCol = styled(Col)`
    text-align: center;
    margin: auto;
`;

const FilteredMessages = ({ filteredMessages }) => {
    return filteredMessages.length === 0 ? (
        <CustomCol>
            <h4>Aucun message</h4>
        </CustomCol>
    ) : (
        filteredMessages.map((message) => <AgencyContainerMessage key={message.id} message={message} />)
    );
};

const LineSeparator = styled.hr`
    margin: 0;
`;

const CardListMessages = ({ messages }) => {
    const staticData = useContext(ChronoContext).staticData;
    const status = staticData.status;
    const dropdownOptionData = LIST_STATUS.map((x) => {
        return { label: x.label, value: x.codename };
    });
    const [statusList, setStatusList] = useState(dropdownOptionData);

    // eslint-disable-next-line array-callback-return
    const filteredMessages = messages.filter((message) => {
        if (!message) return false;

        var messageStatus = Object.keys(status).find((key) => status[key] === message.statusCode);
        if (statusList.find((x) => x.value === messageStatus) !== undefined) return true;

        if (statusList.find((x) => x.value === CODENAME_STATUS_AWAITING_ACTIVATION) !== undefined)
            return moment(message.startDate).isAfter(moment());
        if (statusList.find((x) => x.value === CODENAME_STATUS_EXPIRED) !== undefined)
            return moment(message.endDate).isBefore(moment());
        if (statusList.find((x) => x.value === CODENAME_STATUS_ACTIVE) !== undefined)
            return moment(message.startDate).isBefore(moment()) && moment(message.endDate).isAfter(moment());
        return false;
    });

    const onStatusChange = (value, evt) => {
        if (evt.action === "select-option" && evt.option.value === CODENAME_STATUS_ALL) setStatusList(dropdownOptionData);
        else if (
            evt.action === "select-option" &&
            evt.option.value !== CODENAME_STATUS_ALL &&
            value.length >= dropdownOptionData.length - 1
        )
            setStatusList(dropdownOptionData);
        else if (evt.action === "deselect-option" && evt.option.value === CODENAME_STATUS_ALL) setStatusList([]);
        else if (evt.action === "deselect-option") setStatusList(value.filter((x) => x.value !== CODENAME_STATUS_ALL));
        else setStatusList(value ? value : []);
    };

    return (
        <BaseCardHome style={{ padding: 0 }}>
            <Row style={{ padding: "20px 30px" }}>
                <Col sm={12} md={12} lg={12} xl={4}>
                    <TitleCard>Messages</TitleCard>
                </Col>
                <Col style={{ textAlign: "end" }}>
                    <FormControl
                        as="div"
                        style={{
                            padding: 0,
                            display: "inline-table",
                            width: "40%",
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            color: "black",
                            borderRadius: "30px !important",
                            textAlign: "start",
                        }}
                        id="selectMultiStatus"
                    >
                        <DropdownCheckboxes
                            valueList={statusList}
                            onChange={onStatusChange}
                            defaultLabel={LABEL_STATUS_ALL}
                            labelButton={"choisis"}
                            optionData={dropdownOptionData}
                            labelNone={LABEL_STATUS_NONE}
                            numberOptions={
                                dropdownOptionData && dropdownOptionData.length > 1 ? dropdownOptionData.length - 1 : null
                            }
                            getOptionValue={(option) => option.label}
                        />
                    </FormControl>
                </Col>
            </Row>

            <LineSeparator />

            <CustomRow noGutters>
                <FilteredMessages filteredMessages={filteredMessages} />
            </CustomRow>
        </BaseCardHome>
    );
};

export default CardListMessages;
