import React, { Fragment, useEffect, useState } from "react";
import { TitleCard } from "../../home/cards/BaseCardHome";
import ChronoXYPlot from "../../common/ChronoXYPlot";
import { StatsCard, StatsContainer } from "../global/StatsComponents";
import DiscreteColorLegend from "react-vis/dist/legends/discrete-color-legend";
import { sendGetRequest } from "../../../auth/apiEffects";
import { LoadingIndicator } from "../../LoadingIndicator";
import moment from "moment";
import ChronoCard from "../../common/ChronoCard";
import { FormControl, InputGroup, Row, Col } from "react-bootstrap";
import { CardTicket } from "./CardTicket";

interface IDonnees {
  data: {x: string; y: string; }[]; color: string;
}

interface IDataList {
  statisticPerDayList: IStatisticPerDay[]
}
interface IStatisticPerDay {
  organisationCode: string;
  date: string
}
interface ILegend {
  title: string;
  color: string;
  strokeWidth: number;
}

interface IGetSalesCsvData {
  Organisation: string;
  Date: string;
  [key: string]: string | number;
}
interface IRectentes {
  id?: string;
  totalTicketSold: number;
  totalAmountValidated: number;
  totalIncome: number;
}
interface IGetDonneesRectentes {
  yesterdaySold: number;
  yesterdayValidated: number;
  yesterdayIncome: string;

  todaySold: number;
  todayValidated: number;
  todayIncome: string;

} 

interface IDataByDate { totalTicketSold: number; totalAmountValidated: number; totalIncome: number}
const salesTranslateData = (dataList): IDonnees[] => {
  var donnees : IDonnees[]  = [];
    if (dataList && dataList.length > 0) {
    // eslint-disable-next-line array-callback-return
    dataList.map((s) => {
      donnees.push({
        data: s.statisticPerDayList.map((element) => {
          var res = {
            x: moment(element.date).format("YYYY-MM-DD"),
            y: element.totalIncome,
          };
          return res;
        }),
        color: s.couleur ? s.couleur : "green",
      });
    });
  }
  return donnees;
};

const getsalesCsvData = (dataList: IDataList[] , propertyYName: string, propertyYValue: string | number): IGetSalesCsvData[] => {
    let donnees: IGetSalesCsvData[] = [];
    if (dataList && dataList.length > 0) {
        // eslint-disable-next-line array-callback-return
        dataList.forEach((s) => {
            s.statisticPerDayList.forEach((element) => {
                var res = {
                    'Organisation': element.organisationCode,
                    'Date': moment(element.date).format("YYYY-MM-DD")
                };
                res[propertyYName] = element[propertyYValue]
                donnees.push(res);
            });
        });
    }
    return donnees;
};

const validationsTranslateData = (dataList) : IDonnees[] => {
  var donnees : IDonnees[] = [];
  if (dataList && dataList.length > 0) {
    // eslint-disable-next-line array-callback-return
    dataList.map((s) => {
      donnees.push({
        data: s.statisticPerDayList.map((element) => {
          var res = {
            x: moment(element.date).format("YYYY-MM-DD"),
            y: element.totalAmountValidated,
          };
          return res;
        }),
        color: s.couleur ? s.couleur : "green",
      });
    });
  }
  return donnees;
};

const ticketsSoldTranslateData = (dataList): IDonnees[] => {
  var donnees: IDonnees[] = [];
  if (dataList && dataList.length > 0) {
    // eslint-disable-next-line array-callback-return
    dataList.map((s) => {
      donnees.push({
        data: s.statisticPerDayList.map((element) => {
          var res = {
            x: moment(element.date).format("YYYY-MM-DD"),
            y: element.totalTicketSold,
          };
          return res;
        }),
        color: s.couleur ? s.couleur : "green",
      });
    });
  }
  return donnees;
};

const userTicketsSoldTranslateData = (dataList): IDonnees[] => {
    var donnees : IDonnees[] = [];
    if (dataList && dataList.length > 0) {
        // eslint-disable-next-line array-callback-return
        dataList.forEach((s) => {
            donnees.push({
                data: s.statisticPerDayList.map((element) => {
                    var res = {
                        x: moment(element.date).format("YYYY-MM-DD"),
                        y: element.nbUserTicketSold,
                    };
                    return res;
                }),
                color: s.couleur ? s.couleur : "green",
            });
        });
    }
    return donnees;
};

const userTicketsValidatedTranslateData = (dataList): IDonnees[] => {
    var donnees : IDonnees[] = [];
    if (dataList && dataList.length > 0) {
        // eslint-disable-next-line array-callback-return
        dataList.forEach((s) => {
            donnees.push({
                data: s.statisticPerDayList.map((element) => {
                    var res = {
                        x: moment(element.date).format("YYYY-MM-DD"),
                        y: element.nbUserValidatedTicket,
                    };
                    return res;
                }),
                color: s.couleur ? s.couleur : "green",
            });
        });
    }
    return donnees;
};

const getDataByDate = (date, dataList) => {
  var result = {};
  var donnees : IDataByDate[] = [];
  if (dataList && dataList.length > 0) {
    // eslint-disable-next-line array-callback-return
    dataList.map((s) => {
      var element = s.statisticPerDayList.find(
        (x) => moment(x.date).format("YYYY-MM-DD") === date
      );
      donnees.push(element);
    });

    if (donnees && donnees.length > 0) {
      result = donnees.reduce(function (a, b) {
        return {
          totalTicketSold: a.totalTicketSold + b.totalTicketSold,
          totalAmountValidated: a.totalAmountValidated + b.totalAmountValidated,
          totalIncome: a.totalIncome + b.totalIncome,
        };
      });

      result = { ...result, id: 1 };
    }
  }
  return result;
};

const getLegendSales = (): ILegend[] => {
  var results: ILegend[] = [];
  results.push({
    title: "CA en $",
    color: "white",
    strokeWidth: 0,
  });

  return results;
};

const getLegendValidations = (): ILegend[] => {
  var results: ILegend[] = [];
  results.push({
    title: "Nb activations",
    color: "white",
    strokeWidth: 0,
  });

  return results;
};

const getLegendTicketSold = (): ILegend[] => {
  var results: ILegend[] = [];
  results.push({
    title: "Nb titres",
    color: "white",
    strokeWidth: 0,
  });

  return results;
};

const getLegendUser = (): ILegend[] => {
    var results: ILegend[] = [];
    results.push({
        title: "Nb utilisateurs",
        color: "white",
        strokeWidth: 0,
    });

    return results;
};


const getDonneesRectentes = (dataList) => {
  let result: IGetDonneesRectentes = {} as IGetDonneesRectentes;
  const dtToday = moment();
  const dtYesterday = moment().subtract(1, "days");
  const yesterday: IRectentes = getDataByDate(
    moment(dtYesterday).format("YYYY-MM-DD"),
    dataList
  ) as IRectentes;
  const today: IRectentes = getDataByDate(moment(dtToday).format("YYYY-MM-DD"), dataList) as IRectentes;

  if (yesterday && yesterday.id) {
    result.yesterdaySold = yesterday.totalTicketSold;
    result.yesterdayValidated = yesterday.totalAmountValidated;
    result.yesterdayIncome =
      yesterday.totalIncome > 0 ? "$" + yesterday.totalIncome : "-";
  }

  if (today && today.id) {
    result.todaySold = today.totalTicketSold;
    result.todayValidated = today.totalAmountValidated;
    result.todayIncome = today.totalIncome > 0 ? "$" + today.totalIncome : "-";
  }

  return result;
};

 const TYPES_UNITES_VALIDATIONS = [
    { label: 'Activations totales', id: 0 },
    { label: 'Utilisateurs ayant activés un titre', id: 1 },
];

const TYPES_UNITES_TITRES_VENDUS = [
    { label: 'Ventes totales', id: 0 },
    { label: 'Utilisateurs ayant achetés un titre', id: 1 },
];

const StatsTickets = ({ currentDates }) => {
  const [stats, setStats] = useState<IDataList[] | undefined | false>([]);
  const [recentStatsIncome, setRecentStatsIncome] = useState({});
  const [recentStatsValidated, setRecentStatsValidated] = useState({});
  const [recentStatsSold, setRecentStatsSold] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [uniteVente, setUniteVente] = useState(0);
  const [uniteValidation, setUniteValidation] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      let recentData: IDataList[] | false | undefined = [];
      let donneesRecentes = {} as IGetDonneesRectentes;

      const dtToday = moment();
      const dtYesterday = moment().subtract(2, "days");
      const data: IDataList[] | false | undefined = await sendGetRequest(
        `/statistics/ticketStatistic?startDate=${moment(currentDates.startDate)
          .subtract(1, "days")
          .format("YYYY-MM-DD")}&endDate=${moment(currentDates.endDate)
          .add(23, "hours")
          .add(59, "minutes")
          .add(59, "seconds")
          .format("YYYY-MM-DD HH:mm:ss")}`
      );

      if (
        !(
          moment(currentDates.endDate).isSameOrAfter(dtToday, "day") &&
          moment(currentDates.startDate).isSameOrBefore(dtYesterday, "day")
        )
      ) {
        recentData = await sendGetRequest(
          `/statistics/ticketStatistic?startDate=${moment(dtYesterday).format(
            "YYYY-MM-DD"
          )}&endDate=${moment(dtToday)
            .add(23, "hours")
            .add(59, "minutes")
            .add(59, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")}`
        );
      } else recentData = data || [];

      donneesRecentes = getDonneesRectentes(recentData);

      setRecentStatsIncome({
        title: `Chiffre d'affaires`,
        yesterday:
          donneesRecentes && donneesRecentes.yesterdayIncome
            ? donneesRecentes.yesterdayIncome
            : "",
        today:
          donneesRecentes && donneesRecentes.todayIncome
            ? donneesRecentes.todayIncome
            : "",
      });
      setRecentStatsValidated({
        title: `Nombre d'activations`,
        yesterday:
          donneesRecentes && donneesRecentes.yesterdayValidated
            ? donneesRecentes.yesterdayValidated
            : "",
        today:
          donneesRecentes && donneesRecentes.todayValidated
            ? donneesRecentes.todayValidated
            : "",
      });
      setRecentStatsSold({
        title: `Titres vendus`,
        yesterday:
          donneesRecentes && donneesRecentes.yesterdaySold
            ? donneesRecentes.yesterdaySold
            : "",
        today:
          donneesRecentes && donneesRecentes.todaySold
            ? donneesRecentes.todaySold
            : "",
      });

      setStats(data);
      setIsLoading(false);
    }

    fetchData();
  }, [currentDates]);

    const onUniteVenteSelect = evt => {
        setUniteVente(parseInt(evt.target.value));
    }

    const onUniteValidationSelect = evt => {
        setUniteValidation(parseInt(evt.target.value));
    }



  
  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Fragment>
      {stats && stats.length > 0 && (
        <ChronoCard>
          <StatsContainer>
            <StatsCard>
              <Row>
                <Col xl={4} md={4} sm={6} xs={12}></Col>
                <Col
                  xl={4}
                  md={4}
                  sm={6}
                  xs={12}
                  style={{
                    marginTop: 4,
                    marginBottom: 10,
                    textAlign: "center",
                  }}
                >
                  <TitleCard>Données récentes</TitleCard>
                </Col>
                <Col xl={4} md={4} sm={6} xs={12}></Col>
              </Row>
              <Row>
                <Col xl={4} md={4} sm={6} xs={12}>
                  <CardTicket data={recentStatsIncome}></CardTicket>
                </Col>
                <Col xl={4} md={4} sm={6} xs={12}>
                  <CardTicket data={recentStatsSold}></CardTicket>
                </Col>
                <Col xl={4} md={4} sm={6} xs={12}>
                  <CardTicket data={recentStatsValidated}></CardTicket>
                </Col>
              </Row>
            </StatsCard>
          </StatsContainer>

          <StatsContainer>
            <StatsCard>
              <TitleCard>Chiffre d'affaires</TitleCard>
              <DiscreteColorLegend
                height={60}
                width={300}
                items={getLegendSales()}
                style={{ marginBottom: 0 }}
                orientation="horizontal"
              />
              <ChronoXYPlot
                id={"gphAffaire"}
                isAxisYFixed={true}
                csvData={getsalesCsvData(stats, "Chriffre d'affaires", 'totalIncome')}
                csvFileName={'caExport'}
                showExport={true}
                height={400}
                isLine={true}
                colorStroke={"blue"}
                data={salesTranslateData(stats)}
                tickLabelAngle={-70}
                margin={{ left: 50, bottom: 90 }}
                defaultScrollXPositionEnd={true}
              />
            </StatsCard>
          </StatsContainer>

          <StatsContainer>
            <StatsCard>
                <TitleCard>Ventes de titres</TitleCard>
                <Row style={{ paddingBottom: '15px' }}>
                              <Col xl={7} md={6} sm={6} xs={12}>
                                  {
                                      uniteVente === 0 &&
                                      <DiscreteColorLegend
                                          height={60}
                                          width={300}
                                          items={getLegendTicketSold()}
                                          style={{ marginBottom: 0 }}
                                          orientation="horizontal"
                                      />
                                  }
                            
                                  {
                                      uniteVente === 1 &&
                                      <DiscreteColorLegend
                                          height={60}
                                          width={300}
                                          items={getLegendUser()}
                                          style={{ marginBottom: 0 }}
                                          orientation="horizontal"
                                      />
                                      
                                  }
                    </Col>
                        <Col>
                            {<InputGroup>
                                <InputGroup.Prepend>
                                          <InputGroup.Text>Unités</InputGroup.Text>
                                </InputGroup.Prepend>
                                      <FormControl as="select" style={{width:'100px'}} value={uniteVente} onChange={onUniteVenteSelect}>
                                    {
                                        TYPES_UNITES_TITRES_VENDUS &&
                                        TYPES_UNITES_TITRES_VENDUS.map(item => <option key={item.id} value={item.id}>{item.label}</option>)
                                    }
                                </FormControl>
                            </InputGroup>}

                        </Col>

                </Row>

                {
                    uniteVente === 0 &&
                    <ChronoXYPlot
                        id={"gphTitre"}
                        isAxisYFixed={true}
                        showExport={true}
                        height={400}
                        isLine={true}
                        csvData={getsalesCsvData(stats, "Nbre de billets vendus", 'totalTicketSold')}
                        csvFileName={'ticketsSoldExport'}
                        colorStroke={"violet"}
                        data={ticketsSoldTranslateData(stats)}
                        tickLabelAngle={-70}
                        margin={{ left: 50, bottom: 90 }}
                        defaultScrollXPositionEnd={true}
                    />
                          }

                          {
                              uniteVente === 1 &&
                              <ChronoXYPlot
                                  id={"gphTitreUser"}
                                  isAxisYFixed={true}
                                  showExport={true}
                                  csvData={getsalesCsvData(stats, "Nbre de billets vendus / utilisateur", 'nbUserTicketSold')}
                                  csvFileName={'userTicketsSoldExport'}
                                  height={400}
                                  isLine={true}
                                  colorStroke={"violet"}
                                  data={userTicketsSoldTranslateData(stats)}
                                  tickLabelAngle={-70}
                                  margin={{ left: 50, bottom: 90 }}
                                  defaultScrollXPositionEnd={true}
                              />
                          }
            
            </StatsCard>
          </StatsContainer>

          <StatsContainer>
            <StatsCard>
                          <TitleCard>Activations de titres</TitleCard>
                          <Row style={{ paddingBottom: '15px' }}>
                              <Col xl={7} md={6} sm={6} xs={12}>
                                  {
                                      uniteValidation === 0 &&
                                      <DiscreteColorLegend
                                          height={60}
                                          width={300}
                                          items={getLegendValidations()}
                                          style={{ marginBottom: 0 }}
                                          orientation="horizontal"
                                      />
                                  }

                                  {
                                      uniteValidation === 1 &&
                                      <DiscreteColorLegend
                                          height={60}
                                          width={300}
                                          items={getLegendUser()}
                                          style={{ marginBottom: 0 }}
                                          orientation="horizontal"
                                      />

                                  }
                              </Col>
                              <Col>
                                  {<InputGroup>
                                      <InputGroup.Prepend>
                                          <InputGroup.Text>Unités</InputGroup.Text>
                                      </InputGroup.Prepend>
                                      <FormControl as="select" style={{ width: '100px' }} value={uniteValidation} onChange={onUniteValidationSelect}>
                                          {
                                              TYPES_UNITES_VALIDATIONS &&
                                              TYPES_UNITES_VALIDATIONS.map(item => <option key={item.id} value={item.id}>{item.label}</option>)
                                          }
                                      </FormControl>
                                  </InputGroup>}

                              </Col>

                          </Row>

                          {
                              uniteValidation === 0 &&
                              <ChronoXYPlot
                                  id={"gphActivations"}
                                  isAxisYFixed={true}
                                  showExport={true}
                                  csvData={getsalesCsvData(stats, "Nbre de validations", 'totalAmountValidated')}
                                  csvFileName={'ticketsValidationsExport'}
                                  height={400}
                                  isLine={true}
                                  colorStroke={"violet"}
                                  data={validationsTranslateData(stats)}
                                  tickLabelAngle={-70}
                                  margin={{ left: 50, bottom: 90 }}
                                  defaultScrollXPositionEnd={true}
                              />
                          }
                          {
                              uniteValidation === 1 &&
                              <ChronoXYPlot
                                  id={"gphActivationsUser"}
                                  isAxisYFixed={true}
                                  showExport={true}
                                  csvData={getsalesCsvData(stats, "Nbre de validations / utilisateur", 'nbUserValidatedTicket')}
                                  csvFileName={'userTicketsValidationsExport'}
                                  height={400}
                                  isLine={true}
                                  colorStroke={"violet"}
                                  data={userTicketsValidatedTranslateData(stats)}
                                  tickLabelAngle={-70}
                                  margin={{ left: 50, bottom: 90 }}
                                  defaultScrollXPositionEnd={true}
                              />
                          }
            </StatsCard>
          </StatsContainer>
        </ChronoCard>
      )}
      {!stats ||
        (stats.length === 0 && (
          <Row>
            <Col style={{ textAlign: "center", paddingTop: 10 }}>
              <h3>Aucun résultat</h3>
            </Col>
          </Row>
        ))}
    </Fragment>
  );
};

export default StatsTickets;
