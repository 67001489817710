import { useSelector } from "react-redux";

const useResources = (resourceName) => {
  const resource = useSelector<any>((state) => 
  {
    const resources = state.resources
    if(resources && resources[resourceName]) {
      return resources[resourceName]
    } else {
      return null
    }
  });

  return resource;
  ;
};

export default useResources;